import Grid from "@mui/material/Grid";
// import "../Music-player/Player.css";
import "./Faq.css"
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Box, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme, useMediaQuery, Snackbar, Tabs, Tab, Typography } from '@mui/material';
import { useState } from "react";

import { Link, Navigate, useNavigate } from 'react-router-dom'
import SidebarNew from "../SidebarNew/SidebarNew";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    cardbg: {
        borderRadius: "12px !important",
        background: "rgba(255, 255, 255, 0.10)",
        boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
        backdropFilter: "blur(37.5px)",
        padding: "0 !important",

    },

    pwrback: {
        borderRadius: "12px !important",
        border: "1px solid rgba(255, 255, 255, 0.20)",
        background: "#000000 !important",
    },

    learn: {
        borderRadius: "12px !important",
        background: 'transparent !important',
        boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
    },

    containWidth: {
        maxWidth: '1440px',
        margin: 'auto',
        background: 'none !important',
        padding: '0 !important',
        boxShadow: 'none !important',

        "@media (max-width: 1199.98px)": {
            padding: '20px !important',
        },
    }

});

const accord = [
    {
        title:
            "Why should I want to upload my music to Dreamster instead of other regular music platforms?",
        desc: "Uploading your music to Dreamster offers several unique advantages. As a web 3 Music token gated platform, Dreamster allows you to turn your music into digital assets, providing you with new revenue streams. With Dreamster, you can directly interact with your fans through our backstage pass feature, giving you a closer and more personalized connection with your audience. Additionally, our platform's social NFT marketplace and centralized crypto exchange offer you opportunities to monetize your music in innovative ways.",
    },
    {
        title: "How do I own music as a digital asset?",
        desc: "Owning music as a digital asset on Dreamster involves purchasing NFTs (Non-Fungible Tokens) that represent ownership of the music. When you buy an NFT on our platform, you acquire a unique token that is securely stored on the blockchain, signifying your ownership of the specific music piece. You can also store this unique token in our Decentralized wallet by downloading on iOS and Google playstores",
    },
    {
        title: "Now I own the music, what can I do with it?",
        desc: "As the owner of the music NFT, you can enjoy several benefits. You can listen to the music anytime through your Dreamster account, interact with the musician via our backstage pass feature, and benefit from exclusive content and events. Additionally, you have the option to trade or sell the NFT on our marketplace, potentially realizing a profit if the value of the digital asset increases over time. Music NFTs are NOT the same as DIGITAL ART NFTs. This is a separate valued asset class that people use to listen to.",
    },
    {
        title: "What is web 3 Music?",
        desc: "Web 3 Music refers to the integration of blockchain technology into the music industry, creating a decentralized and tokenized environment. This approach empowers musicians to have more control over their creations, enables fans to own and trade music as digital assets, and fosters a more direct and engaging relationship between artists and their audience.",
    },
    {
        title: "What is an NFT (Non-Fungible Token)?",
        desc: "Music NFTs reshape lives by empowering artists with creative control and fair compensation. They grant fans unique access to artists and immersive experiences, revolutionizing how we connect with music. And for collectors, music NFTs enrich their journey by forging deeper bonds with artists and the global community.",
    },
    {
        title: "Can I listen to music for free and create my own playlist?",
        desc: "While Dreamster offers a unique experience centered around owning music as digital assets, yes you can to listen to music for free, depending on the artist's settings and promotions. However, the primary focus is on enabling fans to purchase and own music NFTs aka Digital Music Assets. Creating your own playlist is possible once you sign up on the platform, allowing you to curate and enjoy your personalized music collection.",
    },
    {
        title: "What are Dreamster tokens and how can I use them?",
        desc: "Dreamster tokens are the native cryptocurrency of our platform, designed to facilitate transactions within the Dreamster ecosystem. You can use Dreamster tokens to interact directly with musicians even when you don’t own the NFT. You can access rooms such as virtual face to face meet, purchase music NFTs, gain access to exclusive content and events, and participate in our social NFT marketplace. Additionally, holding Dreamster tokens also known as “Staking” offers you rewards for locking them for periods at a time and certain perks and privileges within the platform, enhancing your overall experience.",
    },
    {
        title: "How many products does Dreamster offer?",
        desc: `Dreamster offers several innovative products to enhance your music experience:

      1. Web 3 Music Token Gated Platform: Turn your music into digital assets and listen to music and interact directly with musicians through our backstage pass feature.
      
      2. Social NFT Marketplace: Buy, sell, and trade music NFTs in our vibrant community.
      
      3. Centralized Crypto Exchange: Easily buy and sell cryptocurrencies, including Dreamster tokens.
      
      4. Decentralized Wallet: Securely store your digital assets, including music NFTs and cryptocurrencies.
      `,
    },
    {
        title: "Is Dreamster compliant and regulated?",
        desc: "Yes, Dreamster is committed to maintaining compliance with relevant regulations and industry standards. We are trademarked globally, hold licenses in multiple jurisdictions in US, Middle East, European Union and Panama. We adhere to strict security protocols and regulatory requirements to ensure a safe and trustworthy environment for our users. Our compliance team continuously monitors the evolving legal landscape to ensure we meet all necessary guidelines.",
    },
    {
        title: "Who conducts the security and audits for Dreamster?",
        desc: "Dreamster's security and audits are conducted by reputable third-party cybersecurity firms and blockchain experts. We use firms such as SourceHat, Opris, Fireblocks, Chainlink, Google authenticater and more. These professionals perform regular security assessments, audits, and vulnerability tests to ensure the integrity and security of our platform. We prioritize the safety of our users' data and assets, implementing the latest security measures and best practices.",
    },
    {
        title:
            "Can I buy my Daughter Music and give it to her as a gift so she can own it forever?",
        desc: "Absolutely! Dreamster allows you to purchase music NFTs as gifts for others. Once you buy a music NFT, you can transfer ownership to your daughter, enabling her to own and enjoy the music forever. This unique feature makes Dreamster a perfect platform for sharing the gift of music with your loved ones. Download the Dreamster wallet and store, send and receive NFTs, Crypto tokens and swap as well.",
    },
    {
        title:
            "Will musicians stop uploading their music to platforms like Apple and Spotify?",
        desc: "Dreamster provides musicians with new opportunities to monetize their work and connect with fans, but it doesn't necessarily mean they will stop using other platforms like Apple Music and Spotify. Many musicians may choose to use Dreamster alongside traditional platforms to diversify their revenue streams and reach different segments of their audience.",
    },
    {
        title: "Will Dreamster have top celebrities using the platform?",
        desc: "Dreamster aims to attract top musicians and celebrities to join our platform by offering unique benefits and opportunities. We are actively engaging with well-known artists and influencers to bring their music and exclusive content to Dreamster. Stay tuned for announcements about high-profile musicians joining our community and our updated roadmaps!",
    },
];

const drawerWidth = 300;

function Faq() {
    const classes = useStyles();


    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const mobileUp = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <>
            <ToastContainer

                autoClose={3000}
                position="top-right"

            />
            <Box sx={{ display: mobileUp ? 'flex' : 'block' }}>
                <SidebarNew />
                <Box
                    component="main"
                    // className="explore-box"
                    sx={{
                        flexGrow: 1,
                        py: 1,
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        marginTop: "80px",
                        // marginBottom: "100px",
                    }}
                >

                    <Grid container spacing={0} >
                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={11}
                            xl={11}
                            sx={{ padding: '15px' }}
                        >
                            <div className="faq-main">
                                <div className="how-to faq">FAQs?</div>
                                {accord.map((row, ind) => {
                                    return (
                                        <Accordion
                                            expanded={expanded === `panel ${ind + 1}`}
                                            onChange={handleChange(`panel ${ind + 1}`)}
                                            className="accord-home"
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel4bh-content"
                                                id="panel4bh-header"
                                            >
                                                <Typography sx={{
                                                    flexShrink: 1

                                                }}>
                                                    {row.title}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>{row.desc}</Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })}
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

        </>
    );
}

export default Faq;
