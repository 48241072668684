import React, { useEffect, useRef, useState } from 'react'
import SendIcon from '@mui/icons-material/Send';
import Grid from '@mui/material/Grid'
import artist1 from '../../img/artist1.png'
import artist2 from '../../img/artist2.png'
import artist3 from '../../img/artist3.png'
import artist4 from '../../img/artist4.png'
import Avatar from '@mui/material/Avatar';
import SwitchVideoIcon from '@mui/icons-material/SwitchVideo';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Input from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import './Testing.css'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';

function Testing() {
    // substring



    const [chat, setChat] = useState([
        { img: `${artist1}`, name: 'Sankar', noftify: 'Hi da How are you doing long time no see' },
        { img: `${artist2}`, name: 'Barath', noftify: 'Sankar Eod Podra' },
        { img: `${artist3}`, name: 'Priya', noftify: 'Saami Ac ah konjam kammi pannuda' },
        { img: `${artist4}`, name: 'Gowtham', noftify: 'da figma unnaku send panniten' },
        { img: `${artist1}`, name: 'Lucky', noftify: 'Bharath kumar! inga vaa!!' },
        { img: `${artist3}`, name: 'Vignesh', noftify: 'Hi da How are you doing long time no see' },
        { img: `${artist4}`, name: 'Ramesh', noftify: 'Bro Dreamster Merge pannitingala' },
        { img: `${artist2}`, name: 'Arun', noftify: 'Hi da How are you doing long time no see' },
    ])

    //personal chat

    const [perChat, setPerChat] = useState([])


    const [display, setDisplay] = useState()


    console.log(perChat, 'perchat');

    //search

    const [search, setSearch] = useState()

    const [message, setMessage] = useState([]);


    const [updated, setUpdated] = useState('');



    const handleChangeSend = (event) => {
        setUpdated(event.target.value);

    };

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleClickSend();
        }
    };


    const handleClickSend = (e) => {
        // 👇 "message" stores input field value

        if (updated?.trim() !== '') {
            setMessage([...message, updated])
            setUpdated('')
        }
    };

    const handleDeleteTodo = (index) => {
        const newTodos = [...message];
        newTodos.splice(index, 1);
        setMessage(newTodos);
    };

    //image upload

    const [imageUrl, setImageUrl] = useState(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        console.log(reader, "result of file");

        reader.onloadend = () => {
            setImageUrl(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };


    // internal css


    const msgBg = {
        height: '670px',
        overflowY: 'scroll',
        margin: '0px',
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        opacity: '0.7'
    }




    return (
        <div className='testing'>
            <Grid container spacing={1} sx={{ justifyContent: 'center' }} >
                {/* <Grid xs={12} sm={12} md={12} lg={12} xl={8}> */}


                {/* <div className="colab-msg-box">
                        <div className="colab-avatar-cont vr">
                            <Stack direction="row" spacing={-0.5}>
                                <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={artist1} />
                                <Avatar sx={{ width: 30, height: 30 }} alt="Travis Howard" src={artist2} />
                                <Avatar sx={{ width: 30, height: 30 }} alt="Cindy Baker" src={artist3} />
                            </Stack>
                        </div>
                        <div className="colab-chat-area msg">


                            <div className="chat-area-inner-text vr">
                                <div>
                                    <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={perChat.img} />
                                </div>
                                <div className="collab-send-text-main">
                                    <div className="username">{perChat.name}(dev)</div>
                                    <div className="txt1">{perChat.noftify}</div>
                                    <div className="time">11:45am</div>
                                </div>
                            </div>
                            {message.map((todo, ind) => {
                                return (

                                    <div className="chat-area-inner-text user vr" key={ind}>
                                        <div>
                                            <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={artist3} />
                                        </div>
                                        <div className="collab-send-text-main2 user">
                                            <div className="username">Me(dev)</div>
                                            <div className="txt1">{todo}</div>
                                            <div className="time">11:45am</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>


                        <div className="colab-text-input">
                            <div className="smile">
                                <SentimentSatisfiedAltIcon />
                            </div>
                            <div>
                                <input type="text" placeholder="Start typing..."
                                    value={updated}
                                    onChange={handleChangeSend}
                                    onKeyDown={handleEnterKeyPress}

                                />
                            </div>
                            <div className="colab-send">
                                <SendIcon />
                            </div>
                        </div>
                    </div> */}

                {/* </Grid> */}
                <Grid xs={12} sm={3} md={4} lg={3} xl={4}>

                    {/* <div className="library-head-div">
                                        <div className="library-para">
                                            Messages
                                        </div>

                                    </div> */}
                    {display ? (<>
                        <div className="colab-msg-box">
                            <div className="colab-avatar-cont vr">
                                <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                                    <ArrowBackIosOutlinedIcon onClick={(() => { setDisplay(false) })} />
                                    <Avatar sx={{ width: 35, height: 35 }} alt="Remy Sharp" src={imageUrl} />
                                    <input type="file" accept="image/*" onChange={handleImageUpload} />
                                </Stack>
                            </div>
                            <div className='colab-chat-area.msg'>


                                <div className="chat-area-inner-text vr">
                                    {/* <div>
                                        <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={perChat.img} />
                                    </div> */}
                                    <div className="collab-send-text-main">
                                        <div className="username">{perChat.name}(dev)</div>
                                        <div className="txt1">{perChat.noftify}</div>
                                        <div className="time">11:45am</div>
                                    </div>
                                </div>
                                {message.map((todo, ind) => {
                                    return (

                                        <div className="chat-area-inner-text user vr" key={ind}>
                                            {/* <div>
                                                <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={artist3} />
                                            </div> */}
                                            <div className="collab-send-text-main2 user">
                                                <div className="username">Me(dev)</div>
                                                <div className="txt1">{todo}</div>
                                                <div className="time">11:45am</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>


                            <div className="colab-text-input">
                                <div className="smile">
                                    <SentimentSatisfiedAltIcon />
                                </div>
                                <div>
                                    <input type="text" placeholder="Start typing..."
                                        value={updated}
                                        onChange={handleChangeSend}
                                        onKeyDown={handleEnterKeyPress}

                                    />
                                </div>
                                <div className="colab-send">
                                    <SendIcon />
                                </div>
                            </div>
                        </div>
                    </>) :
                        (<>

                            <div className='msglist'>
                                <div className='msg-list-head'>
                                    <div className='chats'>
                                        Chats
                                    </div>
                                    <div className='msg-lsit-head-svg'>
                                        <MoreHorizIcon />
                                        <SwitchVideoIcon />
                                        <ModeEditOutlinedIcon />
                                    </div>
                                </div>
                                <div className='msg-list-input'>
                                    {/* <SearchOutlinedIcon />  <input type='search' placeholder='Search Messenger' /> */}

                                    <FormControl fullWidth sx={{ m: 0.5 }}>
                                        {/* <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel> */}
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            startAdornment={<InputAdornment position="start"><SearchOutlinedIcon /></InputAdornment>}
                                            label="Amount"
                                            className='msg-input'
                                            placeholder='Search Messenger'
                                            onChange={((e) => { setSearch(e.target.value) })}
                                        // value={search}
                                        />
                                    </FormControl>

                                </div>
                                <div className='msg-list-area'>
                                    {chat.filter((obj) => {
                                        return (
                                            search?.toLocaleLowerCase() === undefined ? obj : obj.name.toLocaleLowerCase().includes(search)
                                        )

                                    }).map((row, ind) => {
                                        return (
                                            <div key={ind} className='msg-list-chat-main' onClick={(() => { setPerChat(row); setDisplay(true) })}>
                                                <div>
                                                    <Avatar sx={{ width: 56, height: 56 }} alt="Remy Sharp" src={row.img} />
                                                </div>
                                                <div className='msg-list-chat-name'>
                                                    {row.name}
                                                    <div className='chat-desc' id='sentence'>
                                                        {(row.noftify?.length > 30) ? row.noftify.substring(0, 28) + '...' : row.noftify}
                                                        <span>4w</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </>)

                    }

                </Grid>
                <Grid xs={12} sm={12} md={6} lg={8} xl={9}>

                    {/* {messageList.map((row, index) => (
<div className={(String(row.from) === ids) ? 'msg-send-txt' : 'msg-receive-txt'}>
<ul>
<li key={index} className='todo'>
{row.message}
</li>
</ul>
</div>
))}

<div className='set-msg'>
<FormControl sx={{ m: 1, width: '100%', border: '1px solid var(--clr-font)', borderRadius: '10px' }} variant="outlined">
<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
<OutlinedInput
id="outlined-adornment-password"
type='text'

endAdornment={
<InputAdornment position="end">
<IconButton
aria-label="toggle password visibility"

edge="end"
>
<SendIcon onClick={()=>{createMessage()}} />
</IconButton>
</InputAdornment>
}
label="Password"
value={updated}
placeholder='Start the conversation'
// inputRef={sendRef}
onChange={handleChangeSend}
onKeyDown={()=>{createMessage()}}
/>
</FormControl>
</div> */}
                    <div className="colab-msg-box">
                        <div className="colab-avatar-cont vr">
                            <Stack direction="row" spacing={-0.5}>
                                <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={artist1} />
                                <Avatar sx={{ width: 30, height: 30 }} alt="Travis Howard" src={artist2} />
                                <Avatar sx={{ width: 30, height: 30 }} alt="Cindy Baker" src={artist3} />
                            </Stack>
                        </div>
                        <div className="colab-chat-area msg">


                            <div className="chat-area-inner-text vr">
                                <div>
                                    <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={perChat.img} />
                                </div>
                                <div className="collab-send-text-main">
                                    <div className="username">{perChat.name}(dev)</div>
                                    <div className="txt1">{perChat.noftify}</div>
                                    <div className="time">11:45am</div>
                                </div>
                            </div>
                            {message.map((todo, ind) => {
                                return (

                                    <div className="chat-area-inner-text user vr" key={ind}>
                                        <div>
                                            <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={artist3} />
                                        </div>
                                        <div className="collab-send-text-main2 user">
                                            <div className="username">Me(dev)</div>
                                            <div className="txt1">{todo}</div>
                                            <div className="time">11:45am</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>


                        <div className="colab-text-input">
                            <div className="smile">
                                <SentimentSatisfiedAltIcon />
                            </div>
                            <div>
                                <input type="text" placeholder="Start typing..."
                                    value={updated}
                                    onChange={handleChangeSend}
                                    onKeyDown={handleEnterKeyPress}

                                />
                            </div>
                            <div className="colab-send">
                                <SendIcon />
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid xs={12} sm={12} md={6} lg={4} xl={3} >

                    {/* <div className="library-head-div">
<div className="library-para">
Messages
</div>

</div> */}
                    <div className='msglist'>
                        <div className='msg-list-head'>
                            <div className='chats'>
                                Chats
                            </div>
                            <div className='msg-lsit-head-svg'>
                                <MoreHorizIcon />
                                <SwitchVideoIcon />
                                <ModeEditOutlinedIcon />
                            </div>
                        </div>
                        <div className='msg-list-input'>
                            {/* <SearchOutlinedIcon />  <input type='search' placeholder='Search Messenger' /> */}

                            <FormControl fullWidth sx={{ m: 0.5 }}>
                                {/* <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel> */}
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start"><SearchOutlinedIcon /></InputAdornment>}
                                    label="Amount"
                                    className='msg-input'
                                    placeholder='Search Messenger'
                                    onChange={((e) => { setSearch(e.target.value) })}
                                // value={search}
                                />
                            </FormControl>

                        </div>
                        <div className='msg-list-area'>
                            {chat.filter((obj) => {
                                return (
                                    search?.toLocaleLowerCase() === undefined ? obj : obj.name.toLocaleLowerCase().includes(search)
                                )

                            }).map((row, ind) => {
                                return (
                                    <div key={ind} className='msg-list-chat-main' onClick={(() => { setPerChat(row) })}>
                                        <div>
                                            <Avatar sx={{ width: 56, height: 56 }} alt="Remy Sharp" src={row.img} />
                                        </div>
                                        <div className='msg-list-chat-name'>
                                            {row.name}
                                            <div className='chat-desc' id='sentence'>
                                                {(row.noftify?.length > 30) ? row.noftify.substring(0, 28) + '...' : row.noftify}
                                                <span>4w</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>

                </Grid>
            </Grid>

        </div >
    )
}

export default Testing;
