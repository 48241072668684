import BalanceABI from "./Web3/balanceAbi.json";
import balanceAddress from "./Web3/balanceAddress";

const consts = {
  // MainUrl: "http://localhost:3000",
  Main: "Development",
  // BackendUrl: "http://localhost:3500/api",
  // SocketUrl: "http://localhost:3500",
  IPFS: "https://ipfs.io/ipfs/",
  sitekey: "6Le8GUgpAAAAAFpiowAcmvj1QzqwNxyQebOImzbZ",
  // secret_key:"6Le8GUgpAAAAAO5zqa_C4ZpkpJxIAJKcjDnohGxU"

  MainUrl: "https://dreamstermusic.com",

  // test DToken

  chainId: 56,
  Abi: BalanceABI,
  Address: balanceAddress,

  // test Token

  // chainId: 97,
  //  Abi: approveABI,
  // Address: approveAddress,
  // BackendUrl: "http://192.168.29.77:3500/api",

  BackendUrl: "https://dreamstermusic.com/api/api",
  SocketUrl: "https://dreamstermusic.com",

  route: "",

  nftmintContract: "0x17579149b3126B55f7568363Eeb6b7551eE078C7", // bnb testnet
  tradeContract: "0x97E6273ec0Cb567c1aD29D3F3677AaE21e0d743b", //bnb testnet
  transfer_proxy: "0x14977A86a962cbDf2719e0F9aE857aBD922f8Eab",
  // tradeContract: "0xd491067a1Fd419579506C3E9D63737b6e7F346D4",//check
  // tradeContract: "0x20fdcf670064B9CF6136259e1447ccf9e7F0a730", //check
  Pinata_ApiKey: "560adbe3ab74cdd4b941",
  Pinata_Secret: "6e56a7962bddc5470b56ef368108e32b860e010ab3b4ea6e8d324c1142b86d3e",
  Pinata_baseURL: "https://api.pinata.cloud/pinning/pinFileToIPFS",
  Pinata_baseURL_json: "https://api.pinata.cloud/pinning/pinJSONToIPFS",
  Pinata_url: 'https://gateway.pinata.cloud/ipfs/',
  nft_contract_owner_address: "0xAf0932232d709d1Ce969043E77c74E69F186905d",
  nft_contract_owner_private: "7b55df938ff43b96a75042a5b1bc4ee05946cbe113a8cee4e8dbd8195d5290d5",

  // Goole Login - client cred

  fireBase_apikey: "AIzaSyDbQWSj4LJ7CTfF3KRrf0Iaty2K4uZ6YSo",
  authDomain: "dreamster-music-19be3.firebaseapp.com",
  projectId: "dreamster-music-19be3",
  storageBucket: "dreamster-music-19be3.appspot.com",
  messagingSenderId: "145594161715",
  appId: "1:145594161715:web:11cd542b9ad8f4bef0e146",
  measurementId: "G-KWZ584LSPF",

  // Goole Login - developer cred

  //   fireBase_apikey: "AIzaSyCazdipc556tEap9lVktyTZsz8Oyj1Fhfw",
  // authDomain: "auth-6e6cb.firebaseapp.com",
  // projectId: "auth-6e6cb",
  // storageBucket: "auth-6e6cb.appspot.com",
  // messagingSenderId: "402334363460",
  // appId: "1:402334363460:web:9b107a55efbb39a852b111",
  // measurementId: "G-Q75EL8VX3C",

  //zoom details:
  Zoom_sdkKey: "o4g6R98TgCBRswO_xgLg",
  // Zoom_redirectUrl: "http://localhost:3000/backstagepass",
  // Zoom_sign_api: "http://localhost:3500/api/zoom/createSing"
  Zoom_redirectUrl: "https://dreamstermusic.com/backstagepass",
  Zoom_sign_api: "https://dreamstermusic.com/api/api/zoom/createSing",
};

export default consts;
