// reducers.js
import { SEND_DATA } from './action';
import { INDEX_DATA } from './action';
import { SEND_PLAY_DATA } from './action'
import { persistReducer } from 'redux-persist';
import persistConfig from './persist';


const initialState = {
  songs:[],
  play_mood:"",
  index:0
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_DATA:
     
        return {
          ...state,
          songs: action.payload,
        };
        case SEND_PLAY_DATA:
     
        return {
          ...state,
          play_mood: action.payload,
        };
        case INDEX_DATA:
     
        return {
          ...state,
          index: action.payload,
        };
      
  
    default:
      return state;
  }
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;

