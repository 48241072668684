import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'
import BalanceABI from '../../Web3/balanceAbi.json'
import balanceAddress from '../../Web3/balanceAddress'
import nodata from '../../img/newnodata.gif'
import Axios from "../../Axios";
import consts from "../../Constant";
import '../Upload-Music/UploadMusic.css'
import Container from '@mui/material/Container'
import StackABI from '../../Web3/stakeAbi.json'
import stakeAddress from '../../Web3/stakeAddress'
import approveABI from '../../Web3/approveAbi.json'
import approveAddress from '../../Web3/approveAddress'
import Web3 from 'web3';
import { useTheme } from '@mui/material/styles';
import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import '../Hoisting/Hoisting.css'
import { useState, useEffect, useRef } from "react";
import '../CreateVirtualMeet/CreateVirtualMeet.css'
import '../Colabration/Colabration.css'
import '../Polls&Survey/PollsSurvey.css'
import Tabs from '@mui/material/Tabs';
import ava1 from "../../img/ava1.webp";
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import HeaderNew from "../HeaderNew/HeaderNew";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import { useNavigate, useLocation } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { pink } from '@mui/material/colors';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import { toast, ToastContainer } from "react-toastify";

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: 'var(--clr-bcg)',

    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'var(--clr-bcg)',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'

    },
}));

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

const drawerWidth = 280;

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'var(--clr-bcg)',
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    '@media(max-width:575.98px)': {
        width: '90%'
    },

};

function Participate2() {
    const theme = useTheme();
    const [validate, setValidate] = useState('')
    const [onesurvey, setOnesurvey] = useState({})

    const [progress, setProgress] = React.useState(70);
    const [progress2, setProgress2] = React.useState(30);
    const [selectedValue, setSelectedValue] = React.useState(Array(onesurvey?.Survey?.length).fill(''));

    const [err, setErr] = useState(null)
    // history artist
    const [artHistTab, setArtHistTab] = useState([
        { name: 'Sankar', title: 'Rock', view: '1.5M', follow: '25,564' },
        { name: 'Sankar', title: 'Rock', view: '1.5M', follow: '25,564' },
        { name: 'Sankar', title: 'Rock', view: '1.5M', follow: '25,564' },
        { name: 'Sankar', title: 'Rock', view: '1.5M', follow: '25,564' },
        { name: 'Sankar', title: 'Rock', view: '1.5M', follow: '25,564' },
        { name: 'Sankar', title: 'Rock', view: '1.5M', follow: '25,564' },
    ])
    const requestAcc = useRef(true)

    const navigate = useNavigate()
    const [polls, setPolls] = useState([]);
    const [poll, setPoll] = useState(false);
    const [balance, setBalance] = useState()
    const [id, setid] = useState()

    const handleClosePoll = () => setPoll(false);
    const [surveys, setSurveys] = useState([])
    const [survey, setSurvey] = useState(false)
    const [count, setcount] = useState()
    const handleOpenSurvey = (row) => {
        setid(row._id)
        setSurvey(true)
        setOnesurvey(row)
        const totalCount = row?.Survey.reduce((acc, curr) => acc + curr.Count, 0);
        setcount(totalCount)
        console.log(row?.Survey, totalCount)

    }
    const handleCloseSurbey = () => {
        setid()
        setSelectedValue(Array(onesurvey?.Survey?.length).fill(''))
        setsurveyitems([])
        setSurvey(false);
        setcount()
    }

    const inputRef = useRef(null)


    const [message, setMessage] = useState([]);

    const [updated, setUpdated] = useState('');



    const handleChangeSend = (event) => {
        setUpdated(event.target.value);

    };

    const account = localStorage.getItem("accounts");
    const [metaAccount, setMetaAccount] = useState()
    const [islogin, setIslogin] = useState(false)

    const [value, setValue] = React.useState(0);
    const [surveyitems, setsurveyitems] = React.useState([]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tokens = localStorage.getItem("iphephile");

    const getToken = async () => {
        try {
            if (window.ethereum) {
                const web3Instance = new Web3(window.ethereum);

                await window.ethereum.enable();
                // setWeb3(web3Instance);

                await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                const accounts = await web3Instance.eth.getAccounts();

                // const contractInstance = new web3Instance.eth.Contract(
                //     consts?.Abi,
                //     consts?.Address
                // );

                // const Token = await contractInstance.methods.token().call()

                const tokenInstance = new web3Instance.eth.Contract(
                    consts?.Abi,
                    consts?.Address//token address and abi to check token balance
                );
                const balances = await tokenInstance.methods.balanceOf(accounts[0]).call()
                const bal = await web3Instance.utils.fromWei(String(balances), 'ether')
                setBalance(Number(bal))


            }
        } catch (err) {
            console.log(err)
        }
    };

    const handleClickSend = (e) => {
        // 👇 "message" stores input field value

        if (updated?.trim() !== '') {
            setMessage([...message, updated])
            setUpdated('')
        }
    };


    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleClickSend();
        }
    };
    const isMounted = useRef(false)
    const location = useLocation()
    useEffect(() => {

        if (!isMounted.current) {
            getPolls()
            getSurveys()
            if (location.state == 1) {
                setValue(1)
            }
            //getToken()
            isMounted.current = true
        } else {
            console.log("fetched")
        }

    }, [])
    //to get Polls history

    const getPolls = async () => {

        try {

            const datas = await Axios.post("/poll/get_poll", {}, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        setPolls(res?.data?.result)

                    }
                    else {
                        setPolls([])

                    }
                }).catch((err) => {
                    setPolls([])

                })

        } catch (error) {
            setPolls([])


        }

    }
    const getSurveys = async () => {

        try {

            const datas = await Axios.post("/poll/get_survey", {}, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        setSurveys(res?.data?.result)

                    }
                    else {
                        setSurveys([])

                    }
                }).catch((err) => {
                    setSurveys([])

                })

        } catch (error) {
            setSurveys([])


        }

    }

    const getValue = (a, b) => {
        const value = (b / a) * 100
        return value && !isNaN(value) && value !== "Infinity" ? value : 0
    }

    const onChangeNetwork = async (chainIds) => {
        try {
            const WEB = new Web3(window.ethereum);

            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: WEB.utils.toHex(chainIds) }]
            });
            // navigateToBack()

        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                var data = {
                    "name": "BNB Smart Chain Mainnet",
                    "chain": "BSC",
                    "rpc": [
                        "https://bsc-dataseed1.bnbchain.org",
                        "https://bsc-dataseed2.bnbchain.org",
                        "https://bsc-dataseed3.bnbchain.org",
                        "https://bsc-dataseed4.bnbchain.org",
                        "https://bsc-dataseed1.defibit.io",
                        "https://bsc-dataseed2.defibit.io",
                        "https://bsc-dataseed3.defibit.io",
                        "https://bsc-dataseed4.defibit.io",
                        "https://bsc-dataseed1.ninicoin.io",
                        "https://bsc-dataseed2.ninicoin.io",
                        "https://bsc-dataseed3.ninicoin.io",
                        "https://bsc-dataseed4.ninicoin.io",
                        "https://bsc.publicnode.com",
                        "wss://bsc.publicnode.com",
                        "wss://bsc-ws-node.nariox.org"
                    ],
                    "faucets": [],
                    "nativeCurrency": {
                        "name": "BNB Chain Native Token",
                        "symbol": "BNB",
                        "decimals": 18
                    },
                    "infoURL": "https://www.bnbchain.org/en",
                    "shortName": "bnb",
                    "chainId": 56,
                    "networkId": 56,
                    "slip44": 714,
                    "explorers": [
                        {
                            "name": "bscscan",
                            "url": "https://bscscan.com",
                            "standard": "EIP3091"
                        }
                    ]
                }


                const WEB = new Web3(window.ethereum);
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: data?.chain,
                            chainId: WEB.utils.toHex(chainIds),
                            nativeCurrency: data?.nativeCurrency,
                            rpcUrls: [data?.rpc[0]]
                        }
                    ]
                });
            }
            else if (err.code === 4001) {
                navigate(0)
            }
            else {
                console.log(err)
            }
        }
    }

    const tranferToken = async (row, type, amounts, account) => {
        try {
            if (account == undefined) {
                toast.error("Account Num is Unavailable")
                setIslogin(false)

            }
            else if (window.ethereum) {
                const web3Instance = new Web3(window.ethereum);

                const browserChainId = await web3Instance.eth.getChainId()

                await window.ethereum.enable();
                // setWeb3(web3Instance);

                await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                const accounts = await web3Instance.eth.getAccounts();

                if (Number(browserChainId) == consts.chainId) {
                    const contractInstance = new web3Instance.eth.Contract(
                        // BalanceABI,
                        // balanceAddress
                        consts.Abi,
                        consts.Address,
                    );
                    const Transfer = await contractInstance.methods.transfer(
                        account,
                        web3Instance.utils.toWei(String(amounts), 'ether')

                    ).send({ from: accounts[0] }).then(async (response) => {
                        // return true
                        const data = {
                            _id: row?._id,
                            answer: type,
                            amount: row?.amount
                        }

                        setIslogin(true)

                        await Axios.post("/poll/give_answer", data).then((res) => {
                            if (res?.data?.success) {

                                toast.success(res?.data?.message)
                                setIslogin(false)

                                getPolls()

                            }
                            else {
                                toast.error(res?.data?.message)
                                setIslogin(false)
                            }
                        }).catch((err) => {

                            console.log(err)

                            setIslogin(false)
                        })

                    }).catch((err) => {
                        toast.error("Error While transfering token")
                        // return false
                    })



                    //  return bal
                }
                else {
                    onChangeNetwork(consts.chainId)
                }


            }
        } catch (err) {
            return false

        }
    };


    useEffect(() => {
        if (requestAcc.current) {

            if (!metaAccount && tokens) {
                requestAccount()
            }
        }


        requestAcc.current = false


    }, [])



    const requestAccount = async () => {

        if (window.ethereum) {

            try {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                setMetaAccount(accounts[0])
                getToken()
                localStorage.setItem('accounts', accounts[0])

            } catch (error) {
                console.log(error, 'Error connecting...');
            }

        } else {
            alert('Please Add Metamask Extension in Your Browser');
        }
    }

    const [title, setTitle] = useState('Participate')

    const [divs, setDivs] = useState([{ id: 1 }]);

    const addDiv = () => {
        const newDiv = { id: divs.length + 1 };
        setDivs([...divs, newDiv]);
    }

    // Array(data.length).fill('')

    const handleChangeCheck = (event, index1, item) => {
        const newSelectedValues = [...selectedValue];
        newSelectedValues[index1] = event.target.value;
        setSelectedValue(newSelectedValues);

        const newItem = {
            survey_id: item,
            answer: event.target.value,
        };

        let updatedItems;


        const index = surveyitems.findIndex(item => item.survey_id === newItem.survey_id);

        if (index !== -1) {

            updatedItems = [
                ...surveyitems.slice(0, index),
                ...surveyitems.slice(index + 1),
                newItem,
            ];
        } else {

            updatedItems = [...surveyitems, newItem];
        }


        setsurveyitems(updatedItems);

    };



    const controlProps = (item) => ({

        checked: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
    });
    const controlProps1 = (item, index) => ({

        checked: selectedValue[index] === 'Strong',
        onChange: (e) => handleChangeCheck(e, index, item),
        value: "Strong",
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': "Strong" },
    });
    const controlProps2 = (item, index) => ({

        checked: selectedValue[index] === 'Agree',
        onChange: (e) => handleChangeCheck(e, index, item),
        value: "Agree",
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': "Agree" },
    });
    const controlProps3 = (item, index) => ({

        checked: selectedValue[index] === 'Disagree',
        onChange: (e) => handleChangeCheck(e, index, item),
        value: "Disagree",
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': "Disagree" },
    });


    const [yesVotes, setYesVotes] = useState(0);
    const [noVotes, setNoVotes] = useState(10);

    const handleYesClick = () => {
        setYesVotes(yesVotes + 1);
    };

    const handleClick = async (row, type) => {
        try {
            if (row?.amount > balance === true) {
                toast.error("Insufficient amount of token to submit your answer to the poll")
            }
            else {
                const tokens = await tranferToken(row, type, row?.amount, row?.Artist?.account)
                // if (tokens === true) {
                //     toast.error("Error while transferring the token")

                // }
                // else {
                //     const data = {
                //         _id: row?._id,
                //         answer: type,
                //         amount: row?.amount
                //     }

                //     setIslogin(true)

                //     await Axios.post("/poll/give_answer", data).then((res) => {
                //         // console.log(res,"res")
                //         if (res?.data?.success) {

                //             toast.success(res?.data?.message)
                //             setIslogin(false)

                //             getPolls()

                //         }
                //         else {
                //             toast.error(res?.data?.message)
                //             setIslogin(false)
                //         }
                //     }).catch((err) => {

                //         console.log(err)

                //         setIslogin(false)
                //     })
                // }
                // }
            }

        } catch (error) {
            console.log(error)
            toast.error("Error while submitting your Answer")
        }
    };

    const giveAnswer = async (row, type) => {
        try {
            if (onesurvey?.Survey[0]?.isAnswerd == true) {
                toast.error("Already Answered")
            }
            else if (id === "") {
                toast.error("Survey Id is Missing")
            } else if (surveyitems.length <= 0) {
                toast.error("Select the survey question and answer")
            }
            else if (surveyitems.length != onesurvey?.Survey?.length) {
                toast.error("Please Answer All Questions")
            }
            else {
                const datas = {
                    _id: id,
                    data: surveyitems,
                }
                setIslogin(true)

                await Axios.post("/poll/give_survey_answer", datas).then((res) => {
                    if (res?.data?.success) {

                        toast.success(res?.data?.message)
                        setIslogin(false)
                        handleCloseSurbey()

                        getSurveys()

                    }
                    else {
                        toast.error(res?.data?.message)
                        handleCloseSurbey()

                        setIslogin(false)
                    }
                }).catch((err) => {

                    console.log(err)

                    setIslogin(false)
                })
            }
        } catch (error) {
            console.log(error)
            toast.error("Error while submitting your Answer")
        }
    };

    const handleNoClick = () => {
        setNoVotes(noVotes + 1);
    };

    const getPercentage = (a, b, c) => {
        const totalVotes = b + c;
        const yesPercentage = (b / a) * 100;
        return isNaN(yesPercentage) ? 0 : yesPercentage
    };

    var tooltips = (
        <div className="tootip-main">
            <div className="progress-yes">
                <CircularProgressWithLabel value={progress} />
            </div>
            <div className="no-f-user">
                No of Followers
                <div>
                    {polls[0]?.TotalFollowers ? polls[0]?.TotalFollowers : 0}
                </div>
            </div>

        </div>
    )

    return (

        <div className="Hoisting explore dashboard">
            {/* <SnackBar /> */}

            <Box sx={{ display: 'flex' }} className='display-block'>

                <HeaderNew title={title} />

                <ToastContainer

                    autoClose={3000}
                    position="top-right"

                />

                <Box component="main"
                    className="Hoisting-box"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>

                    <Grid container spacing={0} >
                        <Container maxWidth="xl">
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="poll-head-tab">

                                    <Box sx={{ width: '100%' }} className='nft-corner-tab'>
                                        <Box sx={{ borderColor: 'divider' }}>
                                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example nft" className="nft-tab-style polls">
                                                <Tab label="Poll" {...a11yProps(0)} />
                                                <Tab label="Survey" {...a11yProps(1)} />
                                            </Tabs>
                                        </Box>
                                    </Box>
                                </div>

                            </Grid>
                        </Container>
                    </Grid>
                    <CustomTabPanel value={value} index={0}>
                        <Box
                            component="main"
                            sx={{ flexGrow: 1, p: 0 }}
                            className="UploadMusic-box3 create-exclusive"
                        >
                            <Container maxWidth="xl" >
                                <Grid container spacing={2} >
                                    {polls?.length > 0 ? <>{polls && polls?.map((row, index) => {
                                        return (
                                            <Grid xs={12} sm={12} md={12} lg={7} xl={6} sx={{ p: 1 }} key={row?._id}>
                                                <div className="div-dropzone-1mxhs-1 history">
                                                    <div className="designer-info-frame-parent ">
                                                        <img
                                                            className="designer-info-frame"
                                                            loading="eager"
                                                            alt=""
                                                            src={row?.Artist?.profilePicture && !row?.Artist?.profilePicture?.includes('localhost') ? row?.Artist?.profilePicture : ava1} />
                                                        <div className="eatsleepdesignrepeat">
                                                            <div className="kiiara-castillo-parent">
                                                                <div className="kiiara-castillo">{row?.Artist?.name}</div>
                                                                <div className="eatsleepdesignrepeat1">
                                                                    @{row?.Artist?.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="histry-title"><span>{index + 1}.</span>
                                                        {row?.question ? row?.question : "-"}
                                                    </div>

                                                    <Box sx={{ width: '100%' }}>
                                                        < LinearProgress variant="determinate" value={getPercentage(Number(row?.TotalUser), Number(row?.TotalYes), Number(row?.TotalNo))} className="participate-progress" />
                                                        <div className="pati-num">
                                                            {getPercentage(Number(row?.TotalUser), Number(row?.TotalYes), Number(row?.TotalNo))}%
                                                            <div>
                                                                {100 - getPercentage(Number(row?.TotalUser), Number(row?.TotalYes), Number(row?.TotalNo))}%
                                                            </div>

                                                        </div>

                                                    </Box>

                                                    {row?.isAnswerd === false ? <div className="paticipate-ans-btn">
                                                        {islogin === false ? <><Button onClick={() => { handleClick(row, "YES") }}>{row?.answers[0] ? row?.answers[0]?.toUpperCase() : "-"}</Button>
                                                            <Button onClick={() => { handleClick(row, "NO") }}>{row?.answers[1] ? row?.answers[1]?.toUpperCase() : "-"}</Button></> :
                                                            <Button></Button>}

                                                    </div>
                                                        :
                                                        <div className="paticipate-ans-btn">
                                                            {row?.isYesans === true ? <Button>{row?.answers[0] ? row?.answers[0]?.toUpperCase() : "-"}</Button> : <></>}
                                                            {row?.isNoans === true ? <Button>{row?.answers[1] ? row?.answers[1]?.toUpperCase() : "-"}</Button> : <></>}

                                                        </div>
                                                    }
                                                </div>

                                            </Grid>
                                        )
                                    })}</> :
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='feed-music-box'>
                                                <div className='music-detail'>


                                                    <div className='album-detail'>

                                                        <div className='no-music'>
                                                            <div className='time-music'>

                                                                <div className="newno">
                                                                    <img src={nodata} />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>}


                                </Grid>
                            </Container>


                            {/* 
                        <div>
                            <Track />
                        </div> */}
                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Box
                            component="main"
                            sx={{ flexGrow: 1, p: 0 }}
                            className="UploadMusic-box3 create-exclusive"
                        >
                            <Container maxWidth="xl" >
                                <Grid container spacing={2} >
                                    {surveys?.length > 0 ? <>{surveys && surveys?.map((row, index) => {
                                        return (
                                            <Grid xs={12} sm={12} md={12} lg={7} xl={6} sx={{ p: 1 }} key={row?._id}>
                                                <div className="div-dropzone-1mxhs-1 history" onClick={() => { handleOpenSurvey(row) }}>
                                                    <div className="designer-info-frame-parent ">
                                                        <img
                                                            className="designer-info-frame"
                                                            loading="eager"
                                                            alt=""
                                                            src={row?.Artist?.profilePicture && !row?.Artist?.profilePicture?.includes('localhost') ? row?.Artist?.profilePicture : ava1}
                                                        />
                                                        <div className="eatsleepdesignrepeat">
                                                            <div className="kiiara-castillo-parent">
                                                                <div className="kiiara-castillo">{row?.Artist?.name}</div>
                                                                <div className="eatsleepdesignrepeat1">
                                                                    @{row?.Artist?.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="histry-title">
                                                        <strong>Title:</strong> {row?.title ? row?.title : "-"}
                                                    </div>
                                                    <div className="progress-yes">
                                                        <CircularProgressWithLabel value={getValue(Number(row?.TotalFollowers), Number(row?.TotalUser))} /><div className="f-r-a-m-e">User Participated in this Survey</div>
                                                    </div>
                                                    <div className="progress-yes">
                                                        <div className="f-r-a-m-e">Number Of Questions : {row?.TotalSurvey ? row?.TotalSurvey : '0'}</div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        )
                                    })}</> :
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='feed-music-box'>
                                                <div className='music-detail'>


                                                    <div className='album-detail'>

                                                        <div className='no-music'>
                                                            <div className='time-music'>

                                                                <div className="newno">
                                                                    <img src={nodata} />
                                                                </div>



                                                            </div>

                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>}

                                </Grid>
                            </Container>

                        </Box>

                    </CustomTabPanel>
                </Box>
                <Modal
                    open={poll}
                    onClose={handleClosePoll}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="back-icon-survey-hist">
                            <CancelTwoToneIcon onClick={handleClosePoll} />
                        </div>
                        <TableContainer component={Paper} className="polls-table">
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Name</TableCell>
                                        <TableCell align="center">Song</TableCell>
                                        <TableCell align="center">Views</TableCell>
                                        <TableCell align="center">Followers</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {artHistTab.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >

                                            <TableCell align="center">{row.name}</TableCell>
                                            <TableCell align="center">{row.title}</TableCell>
                                            <TableCell align="center">{row.view}</TableCell>
                                            <TableCell align="center">{row.follow}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>



                    </Box>
                </Modal>
                <Modal
                    open={survey}

                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="back-icon-survey-hist">
                            <CancelTwoToneIcon onClick={handleCloseSurbey} />
                        </div>
                        <div className="survey-questions">
                            {onesurvey?.Survey?.map((row, ind) => {
                                return (
                                    <div key={ind} className="div-dropzone-1mxhs-1 history survey-attc">
                                        <div className="histry-title"><span>{ind + 1}</span>
                                            {row?.question}
                                        </div>
                                        <div className="survey-check-main">
                                            <FormControl>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                >

                                                    {row?.isAnswerd === true ?
                                                        <FormControlLabel control={<Radio {...controlProps(row?.isStrongagree)} color="success" />} label="Strongly Agree" /> :
                                                        <FormControlLabel control={<Radio {...controlProps1(row?._id, ind)} sx={{ color: pink[800], '&.Mui-checked': { color: pink[600], }, }} />} label="Strongly Agree" />
                                                    }


                                                    {row?.isAnswerd === true ?

                                                        <FormControlLabel control={<Radio {...controlProps(row?.isAgree)} color="success" />} label="Agree" /> :
                                                        <FormControlLabel control={<Radio {...controlProps2(row?._id, ind)} sx={{ color: pink[800], '&.Mui-checked': { color: pink[600], }, }} />} label="Agree" />
                                                    }


                                                    {row?.isAnswerd === true ?
                                                        <FormControlLabel control={<Radio {...controlProps(row?.isdisAgree)} color="success" />} label="Disagree" /> :
                                                        <FormControlLabel control={<Radio {...controlProps3(row?._id, ind)} sx={{ color: pink[800], '&.Mui-checked': { color: pink[600], }, }} />} label="Disagree" />
                                                    }


                                                </RadioGroup>
                                            </FormControl>


                                        </div>
                                    </div>
                                )

                            })}


                        </div>

                        {count < onesurvey?.Survey?.length ? <>{islogin === false ?
                            <div style={{ textAlign: 'center' }}>
                                <Button
                                    onClick={() => { giveAnswer() }}
                                    className="button"
                                    disableElevation={true}
                                    variant="contained"
                                    sx={{
                                        color: "#fff",
                                        fontSize: "18",
                                        background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",
                                        borderRadius: "72.4637680053711px",
                                        "&:hover": {
                                            background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",

                                        },
                                        marginTop: '20px',
                                        width: 220,
                                        height: 37,
                                    }}
                                >{`Submit your Answer `}</Button></div> :
                            <div style={{ textAlign: 'center' }}>
                                <Button

                                    className="button"
                                    disableElevation={true}
                                    variant="contained"
                                    sx={{
                                        color: "#fff",
                                        fontSize: "18",
                                        background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",
                                        borderRadius: "72.4637680053711px",
                                        "&:hover": {
                                            background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",

                                        },
                                        marginTop: '20px',
                                        width: 220,
                                        height: 37,
                                    }}
                                >{`Processing ... `}</Button>
                            </div>} </> : <></>}

                        {/* <Button onClick={() => { giveAnswer() }}>answer survey</Button> */}

                        {/* giveAnswer */}

                    </Box>
                </Modal>
            </Box>
        </div>
    );
}

export default Participate2;