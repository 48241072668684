import React, { useEffect, useState, useRef } from "react";
import logo from "../../img/d.dark.png";
import Web3 from "web3";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../../fireBase/firebase";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "../../img/eyelash.svg";
import Axios from "../../Axios";
import "react-toastify/dist/ReactToastify.css";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import "./Login.css";
import "../../Media.css";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import EastIcon from "@mui/icons-material/East";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const Login = () => {
  const [openpass, setOpenPass] = React.useState(false);
  const handleOpenTime = () => setOpenPass(true);
  const handleCloseTime = () => setOpenPass(false);

  const [showPasswordone, setShowPasswordone] = React.useState(false);
  const handleClickShowPasswordone = () => setShowPasswordone((show) => !show);

  const [showPasswordtwo, setShowPasswordtwo] = React.useState(false);
  const handleClickShowPasswordtwo = () => setShowPasswordtwo((show) => !show);

  const [showPasswordthree, setShowPasswordthree] = React.useState(false);
  const handleClickShowPasswordthree = () =>
    setShowPasswordthree((show) => !show);

  const [today, setDate] = useState(new Date());
  const [expanded, setExpanded] = useState(false);
  const [login, setLogin] = useState(false);
  const [recent, setResent] = useState(false);
  const [islogin2, setIslogin2] = useState(false);

  const navigate = useNavigate();
  const femail = useRef(null);
  const remail = useRef(null);
  const lemail = useRef(null);
  const lpassword = useRef(null);
  const inpassref = useRef(null);
  const [open, setOpen] = useState(null);
  const [opens, setOpens] = useState(null);

  const [remailerr, setremailerr] = useState(null);
  const [emailerr, setemailerr] = useState(null);
  const [passworderr, setpassworderr] = useState(null);
  const [otp, setOtp] = useState('');
  const [otperr, setOtpErr] = useState('');
  const [verify, setVerify] = useState(false)

  const [lemailerr, setlemailerr] = useState(null);
  const [lpassworderr, setlpassworderr] = useState(null);
  const [femailerror, setfemailerror] = useState(null);
  const [islogin, setIslogin] = useState(false);
  const [active, setActive] = useState();
  const [is_send, setIssend] = useState(false);
  const [regMail, setRegmail] = useState('');

  const current = new Date();
  const date = current.toLocaleString("en-us", {
    month: "long",
    year: "numeric",
    day: "numeric",
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 60);
  }, []);

  const time = today.toLocaleTimeString({
    hour: "numeric",
    hour12: true,
    minute: "numeric",
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  useEffect(() => {
    if (localStorage.getItem("nickname")) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, []);

  const message = localStorage.getItem("URL");

  const handleresendOpen = () => {
    setOpen(false);
    setResent(true);
    setremailerr("");
  };
  const handleresendClose = () => {
    setResent(false);
    setOpenPass(false);
    setremailerr("");
  };

  const femailhandleChange = () => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const inputValue = femail.current.value;

    if (inputValue.trim() === "") {
      setfemailerror("");
    } else if (!emailRegex.test(inputValue)) {
      setfemailerror("*Invalid Email");
    } else {
      setfemailerror("");
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [password, setPassword] = React.useState(false);
  const [lnameerr, setLnameerr] = useState(null);

  const handleClickPassword = () => setPassword((show) => !show);

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = () => {
    const passwordregex = new RegExp(
      /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/,
      "gm"
    );
    const inputValue = lpassword.current.value;

    if (inputValue.trim() === "") {
      setlpassworderr("");
    } else {
      setlpassworderr("");
    }
  };

  const handleChange1 = () => {
    setlpassworderr("");
  };

  const handleChange2 = () => {
    setlpassworderr("");
    setlemailerr("");
  };
  const handleCloses = () => {
    setpassworderr("");
    setemailerr("");
    setOpens(false);
  };

  const ressendEmail = async () => {
    try {
      const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!remail.current.value) {
        setremailerr("*Email is Required");
      } else if (!emailRegex.test(remail.current.value)) {
        setremailerr("*Invalid Email");
      } else {
        // console.log("Login")
        const mailData = {
          email: remail.current.value,
        };
        window.localStorage.removeItem("iphephile");
        setIslogin(true);

        const { data } = await Axios.post("/re_send", mailData)
          .then(async (res) => {
            // console.log(res,"res")
            if (res?.data?.success) {
              setVerify(true)
              await setRegmail(localStorage.getItem("regMail"));

              toast.success(
                `Email send to you mail,Please Verify Your ${remail.current.value}`,
                {
                  duration: 1000, // Set the duration for which the toast is visible
                }
              );

              setremailerr("");

              setIslogin(false);
            } else {
              setVerify(false)
              setIslogin(false);

              // console.log(res,"daa")
              if (res?.data.message === "User Does Not Exist") {
                toast.error(res?.data?.message);
              } else if (res?.data.message === "Email already Verified") {
                //setIslogin(false)

                toast.error(res?.data?.message);
                setTimeout(() => {
                  handleresendClose()
                }, 1000);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setVerify(false)
            if (
              err?.response?.data?.message === "User Does Not Exist" ||
              err?.response?.data?.message === "BLOCKED_USER" ||
              err?.response?.data?.message === "Please Verify Email" ||
              err?.response?.data?.message === "USER DOES NOT EXIST"
            ) {
              toast.error(err?.response?.data?.message);
            }
            setIslogin(false);
          });
      }
    } catch (error) {
      setIslogin(false);

      //   toast.error("Something Went Wrong")
    }
  };

  const loginTo = async () => {
    try {
      const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!lemail.current.value) {
        setlemailerr("*Email is Required");
      } else if (!emailRegex.test(lemail.current.value)) {
        setlemailerr("*Invalid Email");
      } else if (!lpassword.current.value) {
        setlpassworderr("*Password is required");
      } else {
        setIslogin(true);
        const loginData = {
          email: lemail.current.value,
          password: lpassword.current.value,
        };
        window.localStorage.removeItem("iphephile");

        await Axios.post("/login", loginData)
          .then((res) => {
            // console.log(res,"res")
            if (res?.data?.success) {
              window.localStorage.setItem(
                "iphephile",
                res?.data?.result?.token
              );
              window.localStorage.setItem(
                "usrId",
                res?.data?.result?.user?._id
              );
              window.localStorage.setItem(
                "role",
                res?.data?.result?.user?.role
              );
              window.localStorage.setItem("isPauses", false);
              window.localStorage.setItem("currents", 0);
              toast.success("LoggedIn Successfully");

              if (message !== null) {
                navigate(`${message}`);
                localStorage.removeItem("URL");
              } else {
                setTimeout(() => {
                  // navigate("/playernew");
                  navigate("/");
                  setIslogin(false);
                }, 3000);
              }
            } else {
              if (
                res?.data?.message === "Please Enter a Valid Email Address" ||
                res?.data?.message === "BLOCKED_USER" ||
                res?.data?.message === "Please Verify Email" ||
                res?.data?.message === "USER DOES NOT EXIST"
              ) {
                setlemailerr(res?.data?.message);
              }
              if (
                res?.data?.message === "Incorrect password" ||
                res?.data?.message === "PASSWORD TOO SHORT MIN 5"
              ) {
                setlpassworderr(res?.data?.message);
              } else {
                setlemailerr(res?.data?.message);
              }
              setIslogin(false);
            }
          })
          .catch((err) => {
            console.log(err);
            if (
              err?.response?.data?.message ===
              "Please Enter a Valid Email Address" ||
              err?.response?.data?.message === "BLOCKED_USER" ||
              err?.response?.data?.message === "Please Verify Email" ||
              err?.response?.data?.message === "USER DOES NOT EXIST"
            ) {
              setlemailerr(err?.response?.data?.message);
            }
            if (
              err?.response?.data?.message === "Incorrect password" ||
              err?.response?.data?.message === "PASSWORD TOO SHORT MIN 5"
            ) {
              setlpassworderr(err?.response?.data?.message);
            } else {
              setlemailerr(err?.response?.data?.message);
            }
            setIslogin(false);
          });
      }
    } catch (error) {
      if (
        error?.response?.data?.message === "Invalid Email" ||
        error?.response?.data?.message === "Please Verify Email" ||
        error?.response?.data?.message === "USER DOES NOT EXIST"
      ) {
        setemailerr(error?.response?.data?.message);
      }
      if (
        error?.response?.data?.message === "Incorrect password" ||
        error?.response?.data?.message === "PASSWORD TOO SHORT MIN 5"
      ) {
        setpassworderr(error?.response?.data?.message);
      } else {
        setemailerr(error?.response?.data?.message);
      }
      setIslogin(false);
    }
    localStorage.setItem("NewUser", "false")
  };

  const wallerRegister = async (account) => {
    try {
      if (account.length < 1) {
        setLnameerr("*Connect To your Wallet");
        // loadWeb3()
      } else {
        const registerdata = {
          signup_type: "wallet",
          account: account[0],
        };
        setIslogin(true);
        const { data } = await Axios.post("/wallet_register", registerdata);
        if (data.success === true) {
          setOpens(false);
          handleCloses();
          setLnameerr(null);

          if (data.message === "Wallet User Not Register") {
            toast.error(data.message);
            setTimeout(() => {
              navigate("/register");
            }, 2000);
          } else {
            toast.success(data.message, {
              duration: 500, // Set the duration for which the toast is visible
            });
            window.localStorage.setItem("iphephile", data?.result?.token);
            setTimeout(() => {
              if (history?.state?.emailVerify === 4) {
                window.localStorage.setItem("usrId", data?.result?.user?._id);
                navigate(`/artistpage/${data?.result?.user?._id}`, {
                  state: { emailVerify: 4 },
                });
              } else if (message !== null) {
                window.localStorage.setItem("usrId", data?.result?.user?._id);
                navigate(`${message}`);
                localStorage.removeItem("URL");
              } else {
                window.localStorage.setItem("usrId", data?.result?.user?._id);
                navigate(`/artistpage/${data?.result?.user?._id}`);
              }
            }, 1000);
          }
        } else {
          // setIslogin(false)

          toast.error(data.message);
          setTimeout(() => {
            navigate("/register");
          }, 2000);
        }
      }
    } catch (error) {
      //setIslogin(false)

      // console.log(error, "err")
      if (error?.response?.data?.message === "EMAIL ALREADY EXISTS") {
        setemailerr(error?.response?.data?.message);
      } else {
        setLnameerr(error?.response?.data?.message);
      }
    }
  };

  const signinwithGoogle = async (e) => {
    try {

      const provider = await new GoogleAuthProvider();
      return signInWithPopup(auth, provider)
        .then((res) => {
          onAuthStateChanged(auth, async (user) => {
            if (user) {
              const registerdata = {
                name: user.displayName,
                firstname: user.displayName,
                lastname: user.displayName,
                email: user.email,
                signup_type: "google",

              };
              setIslogin2(true);

              await Axios.post("/register", registerdata)
                .then((res) => {
                  if (res.data.success) {
                    toast.success(res.data.message);
                    window.localStorage.setItem(
                      "iphephile",
                      res?.data?.result?.token
                    );
                    window.localStorage.setItem(
                      "usrId",
                      res?.data?.result?.user?._id
                    );
                    window.localStorage.setItem(
                      "role",
                      res?.data?.result?.user?.role
                    );
                    window.localStorage.setItem("isPauses", false);
                    window.localStorage.setItem("currents", 0);

                    setTimeout(() => {
                      setIslogin2(false);
                      navigate("/");
                    }, 2000);
                  } else {
                    if (res.data.message == "User Does Not Exist") {
                      toast.error("Username does not exist,Please Create a new Account");
                      setTimeout(() => {
                        navigate("/register");
                      }, 2000);
                    }
                    else {
                      toast.error(res.data.message);

                    }
                    setTimeout(() => {
                      setIslogin2(false);
                    }, 2000);
                  }
                })
                .catch((error) => {
                  console.log(error);


                  if (error?.response?.data?.message == "User Does Not Exist") {
                    toast.error("Username does not exist,Please Create a new Account");
                    setTimeout(() => {
                      navigate("/register");
                    }, 2000);
                  }
                  else {

                    toast.error(error?.response?.data?.message);

                  }

                  setTimeout(() => {
                    setIslogin2(false);
                  }, 2000);
                  // toast.error("Error while Register to dreamster")
                });
            } else {
              console.log("user");
            }
          });
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setTimeout(() => {
            setIslogin2(false);
          }, 1000);
        });

    } catch (error) {
      toast.error(error?.response?.data?.message);
      setTimeout(() => {
        setIslogin2(false);
      }, 1000);
    }
  };

  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useLocation();

  const loadWeb3 = async () => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      try {
        await window.ethereum.enable();
        setWeb3(web3Instance);

        await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const accounts = await web3Instance.eth.getAccounts();
        setAccounts(accounts);
        setLnameerr(null);
        setIsLoading(false);
        wallerRegister(accounts);
      } catch (error) {
        console.error("Error connecting to MetaMask:", error.message);
        setLnameerr("Please Connect to Your Wallet");
        setIsLoading(false);
      }
    } else {
      console.error("MetaMask not detected. Please install it.");
      setLnameerr("MetaMask not detected. Please install it.");
      setIsLoading(false);
    }
  };

  const hiddenFileInput = useRef(null);

  const forgotPassSendEmail = async () => {
    try {
      const re = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
      if (femail.current.value === "") {
        setfemailerror("Please Enter your Email Address");
      } else if (!re.test(femail.current.value)) {
        setfemailerror("Please Enter Valid mail");
      } else {
        setIssend(true);
        const { data } = await Axios.post("/forgot", {
          email: femail.current.value,
        });
        // console.log(data)

        if (data?.success) {
          setfemailerror("");
          toast.success(
            `Please Check your Mail ${femail.current.value} to Reset your Password`,
            {
              duration: 1000, // Set the duration for which the toast is visible
            }
          );
          // console.log(data)
          femail.current.value = null;
          setIssend(true);
          setTimeout(() => {
            // navigate(`${consts.route}/`)

            navigate("/");
            setIssend(false);
          }, 2000);
        } else {
          setActive(false);
          setIssend(false);
          femail.current.value = null;
          if (data?.message === "User Not Found") {
            setfemailerror("User Not Found");
          } else if (data?.message === "Admin Blocked You") {
            setfemailerror("Admin Blocked You");
          } else {
            toast.error("Somthing Went Wrong");
          }
        }
      }
    } catch (error) {
      setActive(false);
      setIssend(false);

      console.log(error, "err");
    }
  };

  const handleNewVerify = async () => {
    const payload = {
      otpcode: otp,
      email: regMail
    };

    const { data } = await Axios.post("/verify", payload);

    if (data?.success) {
      toast.success(data?.message);
      setTimeout(() => {
        toast.success('Please Login');
      }, 2500)
      setTimeout(() => {
        // navigate("/login");
        handleresendClose()
      }, 1500);
    } else {

      toast.error(data?.message);
      setOtpErr(data?.message);
    }

  }


  return (
    <>
      <div className="login-page">
        <ToastContainer autoClose={3000} position="top-right" />
        <div className="outer-box">
          <div className="inner-box">
            <div className="inner-popup-pad1">
              <div className="dream-logo">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <h1 className="log-head">Login on Dreamster</h1>
              <div className="inner-popup">
                <div className="inputbox">
                  <Typography variant="p" component="p" className="email">
                    Email Address
                  </Typography>

                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant="outlined"
                    className="input-box"
                  >
                    <OutlinedInput
                      className="input-box-txt"
                      placeholder="Email Address"
                      inputRef={lemail}
                      onChange={() => {
                        setlemailerr("");
                      }}
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                  </FormControl>
                  {lemailerr ? (
                    <div className="errorDiv" style={{ textAlign: "center" }}>
                      {lemailerr}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="inputbox">
                  <div className="pwd-spc">
                    <Typography variant="p" component="p" className="email">
                      Password
                    </Typography>
                    <Link onClick={handleOpenTime}>Forgot your password?</Link>
                  </div>
                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant="outlined"
                    className="input-box"
                  >
                    <OutlinedInput
                      className="input-box-txt"
                      placeholder="Password"
                      inputRef={lpassword}
                      onChange={() => {
                        handlePasswordChange();
                      }}
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Visibility style={{ fill: "#000" }} />
                            ) : (
                              <img src={VisibilityOff} alt={VisibilityOff} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  {lpassworderr ? (
                    <div className="errorDiv" style={{ textAlign: "center" }}>
                      {lpassworderr}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {lnameerr ? (
                  <div className="errorDiv" style={{ textAlign: "center" }}>
                    {lnameerr}
                  </div>
                ) : (
                  <></>
                )}

                <div className="submit-btn">
                  {islogin === false ? (
                    <Button
                      onClick={() => {
                        loginTo();
                      }}
                    >
                      Sign in
                    </Button>
                  ) : (
                    <Button>Processing ...</Button>
                  )}
                </div>

                <div className="meta-google">
                  <Button
                    onClick={() => {
                      loadWeb3();
                    }}
                  >
                    <img
                      width="30"
                      height="30"
                      src="https://img.icons8.com/color/30/metamask-logo.png"
                      alt="metamask-logo"
                    />
                  </Button>

                  {islogin2 === false ? <Button
                    onClick={() => {
                      signinwithGoogle();
                    }}
                  >
                    <img
                      width="30"
                      height="30"
                      src="https://img.icons8.com/fluency/30/google-logo.png"
                      alt="google-logo"
                    />
                  </Button> : (
                    <Button>Processing ...</Button>
                  )}
                </div>

                <div className="already-acct">
                  <span>Don't have a account? </span>
                  <Link to="/register">Sign up now</Link>
                  <br></br>
                  <div className="verify">
                    <span>Don't have verify Email? </span>
                    <Link to="" onClick={() => { handleresendOpen() }}>
                      Resend Verification Mail
                    </Link>
                  </div>
                </div>

                <div className="copyright">
                  <p>© 2024 Dreamster - All rights reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* forget password */}
      <Modal
        open={openpass}
        onClose={handleCloseTime}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="Change-Password-popup-main withdraw-popup">
          <div className="popup">
            <CloseIcon onClick={handleCloseTime} />
          </div>

          <div className="add-new">
            <p className="new-act-head">Change Password</p>
          </div>
          <div className="Change-Password-main-box">
            <div className="change-password-text">
              <Typography variant="h5" component="h5">
                Change Password
              </Typography>
              <Typography variant="p" component="p">
                Fill the following information to reset your password.
              </Typography>
            </div>
            <div className="accnt-detail inner-popup">
              <Typography
                variant="p"
                component="p"
                className="Old-Password-text"
              >
                Old Password
              </Typography>
              <FormControl
                sx={{ m: 1, width: "100%" }}
                variant="outlined"
                className="input-box"
              >
                <OutlinedInput
                  className="input-box-txt"
                  placeholder="Password"
                  inputRef={inpassref}
                  onChange={() => {
                    handleChange();
                  }}
                  id="outlined-adornment-password"
                  type={showPasswordone ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordone}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPasswordone ? (
                          <Visibility />
                        ) : (
                          <img src={VisibilityOff} alt={VisibilityOff} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

              <Typography
                variant="p"
                component="p"
                className="Old-Password-text"
              >
                New Password
              </Typography>
              <FormControl
                sx={{ m: 1, width: "100%" }}
                variant="outlined"
                className="input-box"
              >
                <OutlinedInput
                  className="input-box-txt"
                  placeholder="Password"
                  id="outlined-adornment-password"
                  type={showPasswordtwo ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordtwo}
                        edge="end"
                      >
                        {showPasswordtwo ? (
                          <Visibility />
                        ) : (
                          <img src={VisibilityOff} alt={VisibilityOff} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

              <Typography
                variant="p"
                component="p"
                className="Old-Password-text"
              >
                Confirm Password
              </Typography>
              <FormControl
                sx={{ m: 1, width: "100%" }}
                variant="outlined"
                className="input-box"
              >
                <OutlinedInput
                  className="input-box-txt"
                  placeholder="Password"
                  id="outlined-adornment-password"
                  type={showPasswordthree ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordthree}
                        edge="end"
                      >
                        {showPasswordthree ? (
                          <Visibility />
                        ) : (
                          <img src={VisibilityOff} alt={VisibilityOff} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

              <div className="submit-btn">
                <Button>
                  Change <EastIcon />{" "}
                </Button>
              </div>

              <div className="check-agree">
                <div className="check-box">
                  <Checkbox />
                </div>
                <p>
                  This site is protected by reCAPTCHA and the Google.{" "}
                  <span>Privacy Policy</span> and <span>Terms of Service</span>{" "}
                  apply.
                </p>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      {/* forget password */}
      <Modal
        open={openpass}
        className="login-page"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="withdraw-popup verify">
          <div className="dream-logo">
            <div className="popup">
              <CloseIcon onClick={handleresendClose} />
            </div>
            <Link to="/">
              <img src={logo} alt="logo" className="img-fluid" />
            </Link>
          </div>
          <h6 className="new-act-head">Forgot password?</h6>

          <div className="inner-popup">
            <FormControl
              sx={{ m: 1, width: "100%" }}
              variant="outlined"
              className="input-box"
            >
              <OutlinedInput
                className="input-box-txt"
                placeholder="Email"
                inputRef={femail}
                onChange={() => {
                  femailhandleChange();
                }}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
              />
            </FormControl>
            {femailerror ? (
              <div className="errorDiv" style={{ textAlign: "center" }}>
                {femailerror}
              </div>
            ) : (
              <></>
            )}
            <div className="submit-btn">
              {is_send === false ? (
                <Button
                  className="binance-btn"
                  onClick={() => {
                    forgotPassSendEmail();
                  }}
                >
                  Send Mail
                </Button>
              ) : (
                <Button className="binance-btn">Processing ...</Button>
              )}
            </div>
            <div className="already-acct">
              <span>Back to Login? </span>
              <Link
                onClick={() => {
                  setOpenPass(false);
                }}
              >
                Login{" "}
              </Link>
            </div>
          </div>
        </Box>
      </Modal>
      {/* email verification */}
      <Modal
        open={recent}
        className="login-page"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="withdraw-popup verify">
          <div className="dream-logo">
            {/* <div className='popup' >
            <CloseIcon onClick={handleresendClose} style={{color:"red"}}/>
          </div> */}
            <Link to="/">
              <img src={logo} alt="logo" className="img-fluid" />
            </Link>
          </div>
          <h6 className="new-act-head">Resend Verification Mail?</h6>

          <div className="inner-popup">
            {
              verify == false ?
                <FormControl
                  sx={{ m: 1, width: "100%" }}
                  variant="outlined"
                  className="input-box"
                >
                  <OutlinedInput
                    className="input-box-txt"
                    placeholder="Email"
                    inputRef={remail}
                    onChange={() => {
                      setremailerr(null);
                    }} //remailhandleChange()
                    id="outlined-adornment-weight"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl> : <></>
            }
            {remailerr ? <div className="errorDiv">{remailerr}</div> : <></>}
            {
              verify == true ?
                <>
                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant="outlined"
                    className="input-box"
                  >
                    <OutlinedInput
                      className="input-box-txt"
                      placeholder="Enter OTP code"
                      // inputRef={remail}
                      onChange={(e) => { setOtpErr(''); setOtp(e.target.value) }}
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                  </FormControl>
                  <div style={{ textAlign: 'center', color: 'red' }}>{otperr ? otperr : null}</div>
                  <div className="submit-btn">
                    <Button onClick={() => { handleNewVerify() }}>
                      Submit
                    </Button>
                  </div>
                </> :
                <></>
            }
            {
              verify == false ?

                <div className="submit-btn">
                  {islogin === false ? (
                    <Button
                      className="binance-btn"
                      onClick={() => {
                        ressendEmail();
                      }}
                    >
                      {" "}
                      Resend Mail
                    </Button>
                  ) : (
                    <Button className="binance-btn"> Processing ...</Button>
                  )}
                </div> : <></>
            }
            <div className="verify-bck" onClick={handleresendClose}>
              <KeyboardBackspaceIcon /> Back
            </div>
          </div>
        </Box>
      </Modal>
      {/* email verification */}



    </>
  );
};

export default Login;
