import Grid from "@mui/material/Grid";
import * as React from "react";
import { useState, useRef, useEffect } from "react";
// import "../Music-player/Player.css";
import "../Fanzone/Fanzone.css";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import Header from "../Header/Header";
import Box from "@mui/material/Box";
import Footer from "../Footer/Footer";
import "./BackStagePass.css";
import dt from "../../img/dt1.png";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import Axios from "../../Axios";
import { ToastContainer, toast } from "react-toastify";
import balanceAddress from "../../Web3/balanceAddress";
import balanceAbi from "../../Web3/balanceAbi.json";
import Web3 from "web3";
import consts from "../../Constant";
import nodata2 from "../../img/newnodata.gif";
import rocket from "../../img/rocket2.gif";
import Backdrop from "@mui/material/Backdrop";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  cardbg: {
    borderRadius: "12px !important",
    background: "rgba(255, 255, 255, 0.10)",
    boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
    backdropFilter: "blur(37.5px)",
    padding: "0 !important",
  },

  pwrback: {
    borderRadius: "12px !important",
    border: "1px solid rgba(255, 255, 255, 0.20)",
    background: "#000000 !important",
  },

  learn: {
    borderRadius: "12px !important",
    background: "transparent !important",
    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important",
  },

  containWidth: {
    maxWidth: "1440px",
    margin: "auto",
    background: "none !important",
    padding: "0 !important",
    boxShadow: "none !important",

    "@media (max-width: 1199.98px)": {
      padding: "20px !important",
    },
  },
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// const socket = io.connect("http://43.205.10.212", { path: "/usersocket" })

function Join() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [events, setEvents] = useState([]);

  const getEvents = async () => {
    try {
      const { data } = await Axios.post(
        "/followers/get_one_room",
        { room_id: params.room_id },
        {
          headers: { Authorization: window.localStorage.getItem("iphephile") },
        }
      );

      var todayTime = new Date().getTime();
      if (data?.success == true) {

        setEvents(data?.result);
        console.log(String(new Date(data?.result[0]?.EventDate).getTime() < todayTime))
        if (new Date(data?.result[0]?.EventDate).getTime() < todayTime) {
          setOpen(true)
        }
      } else {
        setEvents([]);
      }
    } catch (error) {
      console.log("🚀 ~ getEvents ~ error:", error);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  const testLive = async (id) => {
    try {
      const { data } = await Axios.post(
        "/followers/checkLiveRoom",
        { room_id: id },
        {
          headers: { Authorization: window.localStorage.getItem("iphephile") },
        }
      );
      if (data?.success == true) {
        // get Amount to user to artist
        const web3 = new Web3(window.ethereum);
        const contractInstance = new web3.eth.Contract(
          balanceAbi,
          balanceAddress
        );
        const account = await window.ethereum.request({
          method: "eth_accounts",
        });
        var sendAmount;
        // navigate('/virtualMeet', { state: { data: data?.result, user: data?.user } })
        var authEndpoint = consts.Zoom_sign_api;
        var meetingNumber = data?.result?.zoom_data?.id;
        var role = 0;
        if (data?.result?.requestor1 == window.localStorage.getItem("usrId")) {
          role = 1;
          sendAmount = true;
        } else {
          role = 0;
          if (
            data?.result?.requestor2?.includes(
              window.localStorage.getItem("usrId")
            )
          ) {
            sendAmount = true;
          } else {
            sendAmount = await contractInstance.methods
              .transfer(
                "0x52307495A627c14DF5590556b0F4cFd994334b14",
                (Number(data?.result?.token) * 10 ** 18).toString()
              )
              .send({ from: account[0] });
            await Axios.post(
              "/followers/updateAmount",
              {
                Room_id: data?.result?._id,
              },
              {
                headers: {
                  Authorization: window.localStorage.getItem("iphephile"),
                },
              }
            );
          }
        }
        if (sendAmount) {
          fetch(authEndpoint, {
            method: "POST",
            headers: {
              Authorization: window.localStorage.getItem("iphephile"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              meetingNumber: meetingNumber,
              role: role,
            }),
          })
            .then((res) => res.json())
            .then((response) => {
              // navigate('/zoomconnect', { state: { data: data?.result, signature: response?.signature } })
              startMeeting(response.signature, data?.result, data?.currentUser);
            })
            .catch((error) => {
              toast.error("Error Joining Meeting");
            });
        } else {
          toast.error("Error Joining");
        }
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log("🚀 ~ testLive ~ error:", error);
      if (error?.code == 4001) {
        toast.error("Error Joining");
      }
    }
  };

  function startMeeting(signature, datas, user) {
    try {
      // var signature = location?.state?.signature

      const { ZoomMtg } = require("@zoom/meetingsdk");
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareWebSDK();
      document.getElementById("zmmtg-root").style.display = "block";
      // var datas = location?.state?.data
      ZoomMtg.init({
        leaveUrl: consts?.Zoom_redirectUrl,
        patchJsMedia: true,
        success: (success) => {
          ZoomMtg.join({
            signature: signature,
            sdkKey: consts?.Zoom_sdkKey,
            meetingNumber: datas?.zoom_data?.id,
            passWord: datas?.zoom_data?.password,
            userName: user?.name ? user.name : "Anonymous",
            userEmail: user?.email,
            tk: "",
            success: (success) => { },
            error: (error) => {
              console.log(error, "ramesh error");
            },
          });
        },
        error: (error) => {
          console.log(error, "err2");
        },
      });
    } catch (error) {
      console.log("🚀 ~ startMeeting ~ error:", error);
    }
  }

  // function startMeeting(signature) {
  //     var sdkKey = consts.Zoom_sdkKey
  //     var meetingNumber = '81874337673'
  //     var passWord = 'BtJr0Z'
  //     var userName = 'Lucky'
  //     var userEmail = 'ramesh.alpharive@gmail.com'
  //     var leaveUrl = consts.Zoom_redirectUrl
  //     document.getElementById('zmmtg-root').style.display = 'block'

  //     ZoomMtg.init({
  //         leaveUrl: leaveUrl,
  //         patchJsMedia: true,
  //         success: (success) => {
  //             console.log(success)

  //             ZoomMtg.join({
  //                 signature: signature,
  //                 sdkKey: sdkKey,
  //                 meetingNumber: meetingNumber,
  //                 passWord: passWord,
  //                 userName: userName,
  //                 userEmail: userEmail,
  //                 tk: registrantToken,
  //                 success: (success) => {
  //                     console.log(success)
  //                 },
  //                 error: (error) => {
  //                     console.log(error)
  //                 }
  //             })

  //         },
  //         error: (error) => {
  //             console.log(error)
  //         }
  //     })
  // }

  //Owl Carousel Settings
  const options = {
    margin: 30,
    items: 4,
    responsiveClass: true,
    // nav: true,
    autoplay: true,
    autoplayTimeout: 3000,
    dots: false,
    loop: true,
    smartSpeed: 2000,
    autoHeightClass: "owl-height",
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };
  const navigateTo = () => {
    navigate("/polls_survey");
  };




  return (
    <div className="join-meet">
      <ToastContainer position="top-right" />
      <div className="fanzone" sx={{ bgcolor: "#000" }}>
        <Grid container spacing={0} className="playerBanner2">
          <div>
            {/* //className="playerBanner2" */}

            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.containWidth}>
                <Header />
              </Item>
            </Grid>
          </div>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              container
              spacing={0}
              className="calender-grid-cont new-height"
            >
              <Grid
                item
                xs={11}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                className="selected-date-grid"
              >
                <div className="date-calender-whole-div">
                  <div className="selected-date join-date">
                    <span className="date">{new Date().getDate()}</span>
                    <span className="month">
                      {monthNames[new Date().getMonth()]}
                      <div>{new Date().getFullYear()}</div>
                    </span>
                  </div>

                  <div className="today join-title">JOIN EVENT</div>
                  {events?.length > 0 ? (
                    <>
                      {events?.map((data, index) => (
                        <div className="today-inner-cont" key={index}>
                          <div className="inner-cont2">
                            <div className="inner-cont3">
                              <img src={data?.Users?.profilePicture ? data?.Users?.profilePicture : dt} alt="dt" />
                              <div className="calander-song-genre">
                                {data?.track_Name}
                                <div className="country">
                                  {data?.description}
                                </div>
                                <div className="calender-timing">
                                  <div className="calender-date-n-time">
                                    {new Date(data?.EventDate).toLocaleString(undefined, { 'hour12': true })}
                                    {/* {data?.EventDate?.split(" ")[0]} */}
                                  </div>
                                  {/* <div className="calender-date-n-time">
                                    {data?.EventDate?.split(" ")[1]}
                                  </div> */}
                                </div>
                              </div>
                            </div>

                            {open == true ? <div
                              onClick={() => {
                                testLive(data?._id);
                                window.localStorage.setItem("Playing", "false");
                              }}
                            >
                              <Button className="join">Join</Button>

                            </div> : <div>
                              <Button className="join">Event is Ended</Button>

                            </div>}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="newno">
                      <img src={nodata2} />
                    </div>
                  )}
                </div>
              </Grid>

              {/* <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                  backdropFilter: "blur(2px)",
                }}
                open={open}
                
              >
                <img src={rocket} />
              </Backdrop> */}
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </div>
    </div>
  );
}

export default Join;
