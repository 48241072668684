import React from "react";
import Header2 from "../Header/Header2";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'
import Track from "../Music-traxk/Track";
import './Explore.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ava from "../../img/ava1.webp";
import Axios from "../../Axios";
import vijay from '../../img/vijay.mp3'
import Container from '@mui/material/Container'
import { Navigate, useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { useTheme } from '@mui/material/styles';
import { Button } from "@mui/material";
import cart from '../../img/cart.png'
import music from '../../img/music-exp.png'
import exp3 from '../../img/exp3.png'
import exp4 from '../../img/exp4.png'
import exp5 from '../../img/exp5.png'
import exp6 from '../../img/exp6.png'
import exp7 from '../../img/exp7.png'
import exp8 from '../../img/exp8.png'
import exp9 from '../../img/exp9.png'
import exp10 from '../../img/exp10.png'
import exp11 from '../../img/exp11.png'

import p1 from '../../img/p1.png'
import p2 from '../../img/p2.png'
import p3 from '../../img/p3.png'
import p4 from '../../img/p4.png'
import p5 from '../../img/p5.png'

import c1 from '../../img/c1.png'
import c2 from '../../img/c2.png'
import c3 from '../../img/c3.png'
import c4 from '../../img/c4.png'
import c5 from '../../img/c5.png'
import c6 from '../../img/c6.png'
import c7 from '../../img/c7.png'
import c8 from '../../img/c8.png'

import i1 from '../../img/i1.png'
import i2 from '../../img/i2.png'
import i3 from '../../img/i3.png'


const content = [

    { clsName: 'explore-card2', heading: 'Trending Playlists', para: 'Explore premium music available to purchase.', img: `${music}` },
    { clsName: 'explore-card3', heading: 'Underground Trending', para: 'Explore premium music available to purchase.', img: `${exp3}` },
    { clsName: 'explore-card4', heading: 'My Favourites', para: 'Explore premium music available to purchase.', img: `${exp4}` },
    { clsName: 'explore-card5', heading: 'Best New Releases', para: 'Explore premium music available to purchase.', img: `${exp5}` },
    { clsName: 'explore-card6', heading: 'Under The Radar', para: 'Explore premium music available to purchase.', img: `${exp6}` },
    { clsName: 'explore-card7', heading: 'Top Albums', para: 'Explore premium music available to purchase.', img: `${exp7}` },
    { clsName: 'explore-card8', heading: 'Remixables', para: 'Explore premium music available to purchase.', img: `${exp8}` },
    { clsName: 'explore-card9', heading: 'Most Loved', para: 'Explore premium music available to purchase.', img: `${exp9}` },
    { clsName: 'explore-card10', heading: 'Feeling Lucky?', para: 'Explore premium music available to purchase.', img: `${exp10}` },
    { clsName: 'explore-card11', heading: 'heart break', para: 'Explore premium music available to purchase.', img: `${exp11}` },
]


const content2 = [
    { claNme: 'playlist-card1', text: 'Chill', img: `${p1}` },
    { claNme: 'playlist-card2', text: 'Upbeat', img: `${p2}` },
    { claNme: 'playlist-card3', text: 'Intense', img: `${p3}` },
    { claNme: 'playlist-card4', text: 'Provoking', img: `${p4}` },
    { claNme: 'playlist-card5', text: 'Intimate', img: `${p5}` },
    { claNme: 'playlist-card1', text: 'Tender', img: `${p1}` },
    { claNme: 'playlist-card2', text: 'Romantic', img: `${p2}` },
    { claNme: 'playlist-card3', text: 'Peaceful', img: `${p3}` },
    { claNme: 'playlist-card4', text: 'Easygoing', img: `${p4}` },
    { claNme: 'playlist-card5', text: 'Sentimental', img: `${p5}` },
]

const content3 = [
    { img: `${c1}`, txt1: 'Haan808s Time Turners', txt2: 'Haan808 / ماهان اسدی' },
    { img: `${c2}`, txt1: 'Critical Music Selects', txt2: 'Critical Music' },
    { img: `${c3}`, txt1: 'Lofi Xmas', txt2: 'Boarderella' },
    { img: `${c4}`, txt1: 'FUXWITHIT Finds', txt2: 'FUXWITHIT' }
]

const content4 = [
    { img: `${c5}`, txt1: 'APOKI', txt2: '2.1K Followers' },
    { img: `${c6}`, txt1: 'Kato On The Track', txt2: '2.1K Followers' },
    { img: `${c7}`, txt1: '𝔟𝔟𝔶 𝔦𝔳𝔶', txt2: '2.1K Followers' },
    { img: `${c8}`, txt1: 'Matt OX', txt2: '2.1K Followers' }
]

const drawerWidth = 280;

const title = 'Welcome';

function Explore() {
    const theme = useTheme();
    const [name, setName] = React.useState();
    const [limit, setLimit] = React.useState(4);
    const [users, setUsers] = React.useState([]);
    const [playlist, setPlaylist] = React.useState([]);
    const tokens = localStorage.getItem("iphephile");

    const navigate = useNavigate()

    React.useEffect(() => {
        getuserList()
        getPlayList()
    }, [])

    const getuserList = async () => {
        try {


            const datas = await Axios.post("/followers/get_all_users", '', {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    console.log(res, "res")
                    if (res?.data?.success) {
                        setUsers(res?.data?.result)


                    }
                    else {
                        //toast.error(err?.response?.data?.message)
                        setUsers([])
                    }
                }).catch((err) => {
                    // console.log(err,"err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }

    const getPlayList = async () => {
        try {

            const datas = await Axios.post("/followers/get_fav_playlist", {
                limit: limit,
                name: name
            }, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    // console.log(res, "res")
                    if (res?.data?.success) {
                        setPlaylist(res?.data?.result)


                    }
                    else {

                        setPlaylist([])
                    }
                }).catch((err) => {
                    console.log(err, "err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }


    return (
        <div className="explore">
            <Box sx={{ display: 'flex' }}>

                <Header2 name={name} setName={setName} />

                <Box component="main"
                    className="explore-box"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '50px', marginBottom: '100px' }}>


                    <Grid container spacing={0} className="heading-container">
                        <Container maxWidth="lg">
                            <Grid xs={12} sm={12} md={8} lg={8} xl={12}>

                                <div className='feeds'>
                                    <div className="back-svg-div">
                                        <ArrowBackIcon className="back-svg" onClick={(() => { navigate(-1) })} />
                                    </div>
                                    <h2>Explore</h2>
                                </div>
                                {/* <div className="explore-heading">
                                    Explore
                                </div> */}
                            </Grid>
                        </Container>

                    </Grid>

                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: 3 }}
                        className="explore-box2"
                    >
                        <Container maxWidth="lg">
                            <Grid container spacing={2} >
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="explore-content-heading">
                                        Just For You
                                    </div>
                                    <div className="description-explore">
                                        Content curated for you based on your likes, reposts, and follows. Refreshes often so if you like a track, favorite it.
                                    </div>

                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Card sx={{ display: 'flex' }} className="explore-card1" onClick={((e) => { navigate('/premiumtracks', { state: "Premium Tracks" }) })} >
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                                <div className="content-heading">
                                                    Premium Tracks
                                                </div>
                                                <div className="content-para" >
                                                    Explore premium music available to purchase.
                                                </div>
                                            </CardContent>

                                        </Box>
                                        <CardMedia
                                            component="img"
                                            sx={{ width: 151 }}
                                            src={cart}
                                            alt="Live from space album cover"
                                        />
                                    </Card>
                                </Grid>

                                {content.map((row, obj) => {
                                    return (
                                        <>
                                            <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                                                <Card sx={{ display: 'flex' }} className={row.clsName} onClick={((e) => { navigate('/premiumtracks', { state: `${row.heading}` }) })} >
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                                            <div className="content-heading">
                                                                {row.heading}
                                                            </div>
                                                            <div className="content-para" >
                                                                {row.para}
                                                            </div>
                                                        </CardContent>

                                                    </Box>
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ width: 200, height: 150 }}
                                                        src={row.img}
                                                        alt="Live from space album cover"
                                                    />
                                                </Card>
                                            </Grid>
                                        </>
                                    )
                                })}


                            </Grid>
                        </Container>

                        <Container maxWidth="lg">
                            <Grid container spacing={2} >
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="explore-content-heading">
                                        Playlists to Fit Your Mood
                                    </div>
                                    <div className="description-explore">
                                        Playlists made by Dreamster users, sorted by mood and feel
                                    </div>

                                </Grid>

                                {content2.map((row, obj) => {
                                    return (
                                        <>
                                            <Grid xs={12} sm={6} md={6} lg={4} xl={2.2}>
                                                <Card sx={{ minWidth: "auto", minHeight: "auto" }} className={row.claNme} onClick={((e) => { navigate('/premiumtracks', { state: `${row.text}` }) })} >
                                                    <CardContent>
                                                        <Typography sx={{ fontSize: 12, textAlign: 'center' }} className="playlist-text" gutterBottom>
                                                            {row.text}
                                                        </Typography>
                                                        <div className="emoji-div">
                                                            <img src={row.img} alt='emoji' />
                                                        </div>
                                                    </CardContent>

                                                </Card>
                                            </Grid>
                                        </>
                                    )
                                })}


                            </Grid>
                        </Container>

                        <Container maxWidth="lg">
                            <Grid container spacing={2} >
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="explore-content-heading">
                                        Playlists We Love Right Now
                                    </div>
                                    <div className="description-explore">
                                        Playlists made by Dreamster users, sorted by mood and feel
                                    </div>

                                </Grid>

                                {playlist && playlist.length > 0 ? <>

                                    {playlist.map((row, obj) => {
                                        return (
                                            <>
                                                <Grid xs={12} sm={6} md={6} lg={4} xl={3}>
                                                    <Card sx={{ maxWidth: 240, margin: 'auto' }} className="love-playlist explore" onClick={((e) => { navigate(`/showplaylist/${row?._id}`, { state: { row } }) })}>
                                                        <CardMedia
                                                            sx={{ height: 200 }}
                                                            image={row.img ? row.img : ava}
                                                            title="Playlist Image"
                                                        />
                                                        <CardContent >
                                                            <div className="love-txt1">
                                                                {row?.playlist}
                                                            </div>
                                                            <div className="love-txt2">
                                                                {row?.createUser?.name}
                                                            </div>
                                                            <div className="love-display">
                                                                <div><img src={i1} />{row?.reposts?.length ? row?.reposts?.length : 0}</div>
                                                                <div><img src={i2} />{row?.fav_count ? row?.fav_count : 0}</div>
                                                            </div>

                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </>
                                        )
                                    })}

                                </> : <></>}


                                <Grid xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <div className="explore-more-btn">
                                        {playlist && playlist.length > 4 ? <Button>Explore More Playlists</Button> : <></>}
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>

                        <Container maxWidth="lg">
                            <Grid container spacing={2} >
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="explore-content-heading">
                                        Artists You Should Follow
                                    </div>
                                    <div className="description-explore">
                                        Playlists made by Dreamster users, sorted by mood and feel
                                    </div>

                                </Grid>

                                {users && users.length > 0 ? <>

                                    {users.map((row, obj) => {
                                        return (
                                            <>
                                                <Grid xs={12} sm={6} md={6} lg={4} xl={3}>


                                                    <Card sx={{ maxWidth: 240, margin: 'auto' }} className="love-playlist explore" >
                                                        {/* onClick={((e) => { navigate('/premiumtracks', { state: `${row.txt1}`}) })} */}
                                                        <div

                                                            className="cirle-img"

                                                            title="green iguana"

                                                        >
                                                            <img src={row?.profilePicture ? row?.profilePicture : ava} alt='img' />
                                                        </div>
                                                        <CardContent >
                                                            <div className="love-txt1">
                                                                {row?.name ? row?.name : 'Wallet User'} <img src={i3} />
                                                            </div>
                                                            <div className="love-txt2">
                                                                {row?.TotalFollowers ? row?.TotalFollowers : 0} Followers
                                                            </div>





                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </>
                                        )
                                    })}

                                </> : <></>}


                             
                            </Grid>
                        </Container>

                     
                    </Box>
                </Box>

            </Box>
        </div>
    );
}

export default Explore;