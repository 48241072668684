import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import consts from "../../Constant";
import '../../App.css'
import ZoomMtgEmbedded from "@zoom/meetingsdk/embedded"
// import { ZoomMtg } from '@zoom/meetingsdk';
// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareWebSDK();

function ZoomConnect() {

    const location = useLocation()
    const [sign, setSign] = useState()

    const client = ZoomMtgEmbedded.createClient()

    useEffect(() => {
        if (location?.state?.data && location?.state?.signature) {
            console.log(location.state, "stasfasdfyu")
            // startMeeting()
            setSign(location?.state?.signature)
        }
    }, [location?.state])

    // useEffect(() => {
    //     if (sign != "") {
    //         startMeeting(sign)
    //     }
    // }, [sign])

    function startMeeting() {
        try {
            var signature = location?.state?.signature
            console.log("🚀 ~ startMeeting ~ signature:", signature)

            const { ZoomMtg } = require('@zoom/meetingsdk');
            ZoomMtg.preLoadWasm();
            ZoomMtg.prepareWebSDK();
            document.getElementById('zmmtg-root').style.display = 'block'
            var datas = location?.state?.data
            console.log("🚀 ~ startMeeting ~ datas:", datas)
            ZoomMtg.init({
                leaveUrl: consts?.Zoom_redirectUrl,
                patchJsMedia: true,
                success: (success) => {
                    console.log(success, "succ1")

                    ZoomMtg.join({
                        signature: signature,
                        sdkKey: consts?.Zoom_sdkKey,
                        meetingNumber: datas?.zoom_data?.id,
                        passWord: datas?.zoom_data?.password,
                        userName: "sasi",
                        // userEmail: "ckramesh0006@gmail.com",
                        tk: "",
                        success: (success) => {
                            console.log(success, "succ2")
                        },
                        error: (error) => {
                            console.log(error, "ramesh error")
                        }
                    })

                },
                error: (error) => {
                    console.log(error, "err2")
                }
            })
        } catch (error) {
            console.log("🚀 ~ startMeeting ~ error:", error)

        }

    }

    // function startMeeting(signature) {
    //     console.log("🚀 ~ startMeeting ~ signature:", signature)
    //     let meetingSDKElement = document.getElementById('meetingSDKElement')
    //     var datas = location?.state?.data
    //     console.log("🚀 ~ startMeeting ~ datas:", datas)
    //     client.init({
    //         zoomAppRoot: meetingSDKElement,
    //         language: 'en-US',
    //         patchJsMedia: true,
    //     }).then(() => {
    //         client.join({
    //             sdkKey: consts.Zoom_sdkKey,
    //             signature: signature,
    //             meetingNumber: datas?.zoom_data?.id,
    //             password: datas?.zoom_data?.password,
    //             userName: "ramesh test",
    //             userEmail: "ramesh.alpharive@gmail.com"
    //         }).then(() => {
    //             console.log('joined successfully')
    //         }).catch((error) => {
    //             console.log(error, "rameshkumarsfd")
    //         })

    //     }).catch((error) => {
    //         console.log("🚀 ~ startMeeting ~ error:", error)
    //     })
    // }


    return (
        <div>

            <button onClick={() => { startMeeting() }} >Conform Join</button>
        </div>
    );
}

export default ZoomConnect;