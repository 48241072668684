import React from "react";
import Header2 from "../Header/Header2";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'
import Track from "../Music-traxk/Track";
import './Playlist.css'
import '../Explore/Explore.css'
import vijay from '../../img/vijay.mp3'
import Container from '@mui/material/Container'
import { Navigate, useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import userProfile from "../../img/creator-user.png"
import creatorAvatar from "../../img/creator-avatar.png"
import { useTheme } from '@mui/material/styles';
import { Button, Stack } from "@mui/material";
import cart from '../../img/cart.png'
import music from '../../img/music-exp.png'
import exp3 from '../../img/exp3.png'
import Copy from "../../img/copy.png"
import exp4 from '../../img/exp4.png'
import exp5 from '../../img/exp5.png'
import exp6 from '../../img/exp6.png'
import exp7 from '../../img/exp7.png'
import exp8 from '../../img/exp8.png'
import exp9 from '../../img/exp9.png'
import exp10 from '../../img/exp10.png'
import exp11 from '../../img/exp11.png'
import addbutton from '../../img/add-button.png'

import p1 from '../../img/p1.png'
import p2 from '../../img/p2.png'
import p3 from '../../img/p3.png'
import p4 from '../../img/p4.png'
import p5 from '../../img/p5.png'

import artist1 from '../../img/artist1.png'
import artist2 from '../../img/artist2.png'
import artist3 from '../../img/artist3.png'
import artist4 from '../../img/artist4.png'
import playlist1 from '../../img/playlist1.png'
import playlist2 from '../../img/playlist2.png'
import playlist3 from '../../img/playlist3.png'

import i1 from '../../img/i1.png'
import i2 from '../../img/i2.png'
import i3 from '../../img/i3.png'

import '../Particpate/Particpate.css'
import Cards from '../../img/card-1.png';
import Cards1 from '../../img/card-2.png';
import Cards2 from '../../img/card-3.png';
import Cards3 from '../../img/card-3.png';
import Cards4 from '../../img/card-4.png';
import Cards5 from '../../img/card-5.png';
import Cards6 from '../../img/card-6.png';
import Cards7 from '../../img/card-7.png';
import Cards8 from '../../img/card-8.png';
import Cards9 from '../../img/card-9.png';
import Cards10 from '../../img/card-10.png';
import Cards11 from '../../img/card-11.png';
import Cards12 from '../../img/card-12.png';
import thunder from '../../img/thunder.png';
import icon from '../../img/c-icon.png';
import cardmenu from '../../img/card-menu.png';
import Link from '@mui/material/Link';
import join from '../../img/join-icon.svg';
import TextField from '@mui/material/TextField';
import ListIcon from '@mui/icons-material/List';
import HeaderNew from "../HeaderNew/HeaderNew";

const content = [
    { clsName: 'trend explore-card2', heading: 'Select Trending playlist', para: '', btn: 'Select from Library ' },
]

const content2 = [
    { claNme: 'playlist-card1', text: 'Chill', img: `${p1}` },
    { claNme: 'playlist-card2', text: 'Upbeat', img: `${p2}` },
    { claNme: 'playlist-card3', text: 'Intense', img: `${p3}` },
    { claNme: 'playlist-card4', text: 'Provoking', img: `${p4}` },
    { claNme: 'playlist-card5', text: 'Intimate', img: `${p5}` },
    { claNme: 'playlist-card1', text: 'Tender', img: `${p1}` },
    { claNme: 'playlist-card2', text: 'Romantic', img: `${p2}` },
    { claNme: 'playlist-card3', text: 'Peaceful', img: `${p3}` },
    { claNme: 'playlist-card4', text: 'Easygoing', img: `${p4}` },
    { claNme: 'playlist-card5', text: 'Sentimental', img: `${p5}` },
]

const content3 = [
    { img: `${artist1}`, txt1: '0x453712f21ddda4b7c00b4dcd3383b13f8…', txt2: '', btn: "follow" },
    { img: `${artist2}`, txt1: '0x453712f21ddda4b7c00b4dcd3383b13f8…', txt2: '', btn: "follow" },
    { img: `${artist3}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "follow" },
    { img: `${artist4}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "follow" },
    { img: `${artist1}`, txt1: '0x453712f21ddda4b7c00b4dcd3383b13f8…', txt2: '', btn: "follow" },
    { img: `${artist2}`, txt1: '0x453712f21ddda4b7c00b4dcd3383b13f8…', txt2: '', btn: "follow" },
    { img: `${artist3}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "follow" },
    { img: `${artist4}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "follow" }
]

const content4 = [
    { img: `${playlist1}`, txt1: 'Music Nft', txt2: '0.235 Dster', txt3: "X036sdnkjficmscchuhglas", btn: "Play" },
    { img: `${playlist2}`, txt1: 'Music Nft', txt2: '0.235 Dster', txt3: "X036sdnkjficmscchuhglas", btn: "Play" },
    { img: `${playlist3}`, txt1: 'Music Nft', txt2: '0.235 Dster', txt3: "X036sdnkjficmscchuhglas", btn: "Play", }
]

const content5 = [
    { img: `${artist3}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "Follow" },
]

const drawerWidth = 280;

const title = 'Welcome';

function Playlist() {
    const theme = useTheme();

    const navigate = useNavigate()

    return (
        <div className="explore dashboard playlist">
            <Box sx={{ display: 'flex' }}>

                <HeaderNew />
                <Box component="main"
                    className="explore-box"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '50px', marginBottom: '100px' }}>


                    <Grid container spacing={0} className="heading-container">
                        <Container maxWidth="lg">
                            <Grid xs={12} sm={12} md={8} lg={8} xl={12}>
                                <div className="explore-heading">
                                    Dashboard
                                </div>
                            </Grid>
                        </Container>

                    </Grid>

                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: 3 }}
                        className="explore-box2"
                    >

                        <Container maxWidth="xl">
                            <Grid container spacing={2} className="whole-flex-spc">
                                <Grid xs={12} sm={12} md={12} lg={9} xl={9} className="dash-spc">
                                    <Grid container spacing={2} className="dash-spc-contain dash-spc-contain-mid-part">
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6} className="dash-spc dash-spc-left">
                                            <Card sx={{ display: 'flex' }} className="explore-card1 explore-card1-new" onClick={((e) => { navigate('/premiumtracks', { state: "Premium Tracks" }) })} >
                                                <Stack flexDirection="row" className="select-tract-box" sx={{ display: 'flex' }}>
                                                    <CardContent className="select-tract-box-left" sx={{ flex: '1 0 auto' }}>
                                                        <div className="content-heading">
                                                            Select Your Track
                                                        </div>
                                                        <div className="btn-flex Choose-from-album">
                                                            <div className="dash-btn lib"><a href="/">Choose from album</a></div>
                                                        </div>
                                                    </CardContent>
                                                    <div className="select-tract-box-right">
                                                        <Card className="card-participate">
                                                            <div className="img-card">
                                                                <img src={Cards12} />
                                                            </div>
                                                            <label>Kiiara Castillo</label>
                                                            <p>@eatsleepdesignrepeat</p>
                                                        </Card>
                                                    </div>
                                                </Stack>
                                            </Card>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={1} xl={1} className="dash-spc dash-spc-mid">
                                            <div className="addbutton"><img src={addbutton} alt="addbutton" /></div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={5} xl={5} className="dash-spc dash-spc-right">

                                            <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="follow-us-grid">
                                                <Card sx={{ display: 'flex' }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                                            <div className="content-heading">
                                                                Select Trending playlist
                                                            </div>
                                                            <div className="content-btn dash-btn lib"><a>Follow</a></div>
                                                            {content5.map((row, obj) => {
                                                                return (
                                                                    <>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="artist">
                                                                            <div className="Card">
                                                                                <CardMedia
                                                                                    sx={{ height: 200 }}
                                                                                    image={row.img}
                                                                                    title="green iguana"
                                                                                    className="artist-img"
                                                                                />
                                                                                <div className="artist-txt">
                                                                                    <div className="love-txt1">
                                                                                        {row.txt1}
                                                                                    </div>
                                                                                    <div className="love-txt2">
                                                                                        {row.txt2}
                                                                                    </div>
                                                                                    <div className="btn artist-follow">
                                                                                        {row.btn}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    </>
                                                                )
                                                            })}
                                                        </CardContent>

                                                    </Box>
                                                    {/* <CardMedia
                                                component="img"
                                                sx={{ width: 200, height: 150 }}
                                                src={row.img}
                                                alt="Live from space album cover"
                                            /> */}
                                                </Card>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} className="dash-spc-contain CREATOR-block-outer">
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6} className="dash-spc CREATOR-block-inner">
                                            <div className="creator_card creator_card-inner">
                                                <div className="token_creator token_creator-inner">
                                                    <h6>CREATOR</h6>
                                                    <div className="user_profile">
                                                        <div><img src={userProfile} alt='userprofile' /></div>
                                                        <div>
                                                            <p>Kiiara Castillo</p>
                                                            <span>@eatsleepdesignrepeat</span>
                                                        </div>
                                                    </div>
                                                    <div className="user_profile counts">
                                                        <div>
                                                            <p>Total Tokens</p>
                                                            <h6>3.69 Dster</h6>
                                                        </div>
                                                        <div>
                                                            <p>Ends in</p>
                                                            <h6>04h 12m</h6>
                                                        </div>
                                                    </div>
                                                    <div className="outer_copyright">
                                                        <div className="copyright">
                                                            <p>Dster Token address</p>
                                                            <img src={Copy} alt='Copy' />
                                                        </div>
                                                        <span>0xaF489a41e001dDeaFeC79829df5D56CC4b3a9Aa5</span>
                                                    </div>


                                                </div>
                                                <div className="dash-btn buy"><a href="/">BUY TOKENS</a></div>
                                                <div className="creatorAvatar"><img src={creatorAvatar} alt="creatorAvatar" /></div>

                                            </div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={1} xl={1} className="dash-spc">

                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={5} xl={5} className="dash-spc your-top-mix-classname">
                                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <div className="explore-content-heading">
                                                    Your Top MIX
                                                </div>
                                            </Grid>
                                            <div className='participate-card'>
                                                <Grid container className='Your-Top-MIX-part'>
                                                    <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                                        <Card className="card-participate">
                                                            <div className="img-card">
                                                                <img src={Cards12} />
                                                            </div>
                                                            <div className="card-body card-body-title-card">
                                                                <div className="card-title">
                                                                    <p>Jump</p>
                                                                    <p>mainnet</p>
                                                                </div>
                                                                <div className="toggle-icon">
                                                                    <ListIcon />
                                                                </div>
                                                            </div>
                                                            <div className="card-body card-body--card">
                                                                <div className="card-title">
                                                                    <p>24-01-2024 I 15:35 to 17:45  </p>

                                                                </div>
                                                                <div className="toggle-icon">

                                                                    <img src={icon} />
                                                                </div>
                                                            </div>
                                                            <div className="join-btn">
                                                                <div className="join-icon">
                                                                    <img src={join} />
                                                                </div>
                                                                <Link to="#">

                                                                    Join 100 Dster</Link>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                                        <Card className="card-participate">

                                                            <div className="img-card">
                                                                <img src={Cards} />
                                                            </div>
                                                            <div className="card-body card-body-title-card">
                                                                <div className="card-title">
                                                                    <p>ManTra</p>
                                                                    <p>mainnet</p>
                                                                </div>
                                                                <div className="toggle-icon">

                                                                    <ListIcon />
                                                                </div>

                                                            </div>
                                                            <div className="card-body card-body--card">
                                                                <div className="card-title">
                                                                    <p>24-01-2024 I 15:35 to 17:45  </p>

                                                                </div>
                                                                <div className="toggle-icon">
                                                                    <img src={icon} />
                                                                </div>
                                                            </div>
                                                            <div className="join-btn">
                                                                <div className="join-icon">
                                                                    <img src={join} />
                                                                </div>
                                                                <Link to="#">Join 100 Dster</Link>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                                        <Card className="card-participate">

                                                            <div className="img-card">
                                                                <img src={Cards1} />
                                                            </div>
                                                            <div className="card-body card-body-title-card">
                                                                <div className="card-title">
                                                                    <p>DOJO</p>
                                                                    <p>mainnet</p>
                                                                </div>

                                                                <div className="toggle-icon">

                                                                    <ListIcon />
                                                                </div>


                                                            </div>
                                                            <div className="card-body card-body--card">
                                                                <div className="card-title">
                                                                    <p>24-01-2024 I 15:35 to 17:45  </p>

                                                                </div>
                                                                <div className="toggle-icon">
                                                                    <img src={icon} />
                                                                </div>
                                                            </div>
                                                            <div className="join-btn">
                                                                <div className="join-icon">
                                                                    <img src={join} />
                                                                </div>
                                                                <Link to="#">Join 100 Dster</Link>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                                        <Card className="card-participate">

                                                            <div className="img-card">
                                                                <img src={Cards3} />
                                                            </div>
                                                            <div className="card-body card-body-title-card">
                                                                <div className="card-title">
                                                                    <p>DOJO</p>
                                                                    <p>mainnet</p>
                                                                </div>
                                                                <div className="toggle-icon">
                                                                    <img src={cardmenu} />
                                                                </div>


                                                            </div>
                                                            <div className="card-body card-body--card">
                                                                <div className="card-title">
                                                                    <p>24-01-2024 I 15:35 to 17:45  </p>

                                                                </div>
                                                                <div className="toggle-icon">
                                                                    <img src={icon} />
                                                                </div>
                                                            </div>
                                                            <div className="join-btn">
                                                                <div className="join-icon">
                                                                    <img src={join} />
                                                                </div>
                                                                <Link to="#">Join 100 Dster</Link>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    {/* 
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Card  className="card-participate">

                                        <div className="img-card">
                                            <img src={Cards4} />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>Slime</p>
                                                <p>mainnet</p>
                                            </div>
                                            <div className="toggle-icon">
                                              
                                              <ListIcon />
                                          </div>


                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>24-01-2024 I 15:35 to 17:45  </p>

                                            </div>
                                            <div className="toggle-icon">
                                                <img src={icon} />
                                            </div>
                                        </div>
                                        <div className="join-btn">
                                        <div className="join-icon">
                                                <img src={join} />
                                            </div>
                                            <Link to="#">Join 100 Dster</Link>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Card  className="card-participate">

                                        <div className="img-card">
                                            <img src={Cards5} />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>Mmangwane Mpulele</p>
                                                <p>mainnet</p>
                                            </div>
                                            <div className="toggle-icon">
                                              
                                                <ListIcon />
                                            </div>


                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>24-01-2024 I 15:35 to 17:45  </p>

                                            </div>
                                            <div className="toggle-icon">
                                                <img src={icon} />
                                            </div>
                                        </div>
                                        <div className="join-btn">
                                        <div className="join-icon">
                                                <img src={join} />
                                            </div>
                                            <Link to="#">Join 100 Dster</Link>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Card  className="card-participate">

                                        <div className="img-card">
                                            <img src={Cards6} />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>Blues-corn</p>
                                                <p>mainnet</p>
                                            </div>
                                            <div className="toggle-icon">
                                              
                                              <ListIcon />
                                          </div>


                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>24-01-2024 I 15:35 to 17:45  </p>

                                            </div>
                                            <div className="toggle-icon">
                                              
                                            <img src={icon} />
                                            </div>
                                        </div>
                                        <div className="join-btn">
                                        <div className="join-icon">
                                                <img src={join} />
                                            </div>
                                            <Link to="#">Join 100 Dster</Link>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Card  className="card-participate">

                                        <div className="img-card">
                                            <img src={Cards7} />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>Blues-corn</p>
                                                <p>mainnet</p>
                                            </div>
                                            <div className="toggle-icon">
                                              
                                                <ListIcon />
                                            </div>

                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>24-01-2024 I 15:35 to 17:45  </p>

                                            </div>
                                            <div className="toggle-icon">
                                                <img src={icon} />
                                            </div>
                                        </div>
                                        <div className="join-btn">
                                        <div className="join-icon">
                                                <img src={join} />
                                            </div>
                                            <Link to="#">Join 100 Dster</Link>
                                        </div>
                                    </Card>
                                </Grid>


                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Card  className="card-participate">

                                        <div className="img-card">
                                            <img src={Cards8} />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>Jump</p>
                                                <p>mainnet</p>
                                            </div>
                                            <div className="toggle-icon">
                                              
                                              <ListIcon />
                                          </div>


                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>24-01-2024 I 15:35 to 17:45  </p>

                                            </div>
                                            <div className="toggle-icon">
                                                <img src={icon} />
                                            </div>
                                        </div>
                                        <div className="join-btn">
                                        <div className="join-icon">
                                                <img src={join} />
                                            </div>
                                            <Link to="#">Join 100 Dster</Link>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Card  className="card-participate">

                                        <div className="img-card">
                                            <img src={Cards9} />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>Jump</p>
                                                <p>mainnet</p>
                                            </div>
                                            <div className="toggle-icon">
                                              
                                              <ListIcon />
                                          </div>


                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>24-01-2024 I 15:35 to 17:45  </p>

                                            </div>
                                            <div className="toggle-icon">
                                                <img src={icon} />
                                            </div>
                                        </div>
                                        <div className="join-btn">
                                        <div className="join-icon">
                                                <img src={join} />
                                            </div>
                                            <Link to="#">Join 100 Dster</Link>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Card  className="card-participate">

                                        <div className="img-card">
                                            <img src={Cards10} />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>Jump</p>
                                                <p>mainnet</p>
                                            </div>
                                            <div className="toggle-icon">
                                              
                                                <ListIcon />
                                            </div>


                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>24-01-2024 I 15:35 to 17:45  </p>

                                            </div>
                                            <div className="toggle-icon">
                                                <img src={icon} />
                                            </div>
                                        </div>
                                        <div className="join-btn">
                                        <div className="join-icon">
                                                <img src={join} />
                                            </div>
                                            <Link to="#">Join 100 Dster</Link>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Card  className="card-participate">

                                        <div className="img-card">
                                            <img src={Cards11} />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>Jump</p>
                                                <p>mainnet</p>
                                            </div>
                                            <div className="toggle-icon">
                                              
                                                <ListIcon />
                                            </div>


                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>24-01-2024 I 15:35 to 17:45  </p>

                                            </div>
                                            <div className="toggle-icon">
                                                <img src={icon} />
                                            </div>
                                        </div>
                                        <div className="join-btn">
                                        <div className="join-icon">
                                                <img src={join} />
                                            </div>
                                            <Link to="#">Join 100 Dster</Link>
                                        </div>
                                    </Card>
                                </Grid> */}
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={3} xl={3} className="dash-spc">
                                    <Grid container spacing={2} className="dash-spc-contain">
                                        {/* <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="dash-spc">
                        </Grid> */}

                                        <Container maxWidth="lg" className="artist_top_cards-container">
                                            <Grid container spacing={2} className="artist_top_cards">
                                                <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="artist_top_cards-grid">
                                                    <div className="explore-content-heading">
                                                        Top Playlist
                                                    </div>
                                                </Grid>
                                                <div className="top-playlist-items-list">
                                                    {content3.map((row, obj) => {
                                                        return (

                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="artist">
                                                                <div className="Card">
                                                                    <CardMedia
                                                                        sx={{ height: 200 }}
                                                                        image={row.img}
                                                                        title="green iguana"
                                                                        className="artist-img"
                                                                    />
                                                                    <div className="artist-txt">
                                                                        <div className="love-txt1">
                                                                            {row.txt1}
                                                                        </div>
                                                                        <div className="love-txt2">
                                                                            {row.txt2}
                                                                        </div>
                                                                        <div className="btn artist-follow">
                                                                            {row.btn}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        )
                                                    })}
                                                </div>
                                            </Grid>
                                        </Container>


                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>

                        {/* <div>
                            <Track  audiosource={vijay}/>
                        </div> */}
                    </Box>
                </Box>

            </Box>
        </div>
    );
}

export default Playlist;