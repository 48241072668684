import React, { useState } from "react";
import Header2 from "../Header/Header2";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'
import Track from "../Music-traxk/Track";
import './Dashboard.css'
import '../Explore/Explore.css'
import vijay from '../../img/vijay.mp3'
import Container from '@mui/material/Container'
import { Navigate, useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import userProfile from "../../img/creator-user.png"
import { useTheme } from '@mui/material/styles';
import { Button } from "@mui/material";
import cart from '../../img/cart.png'
import music from '../../img/music-exp.png'
import exp3 from '../../img/exp3.png'
import Copy from "../../img/copy.png"
import exp4 from '../../img/exp4.png'
import exp5 from '../../img/exp5.png'
import exp6 from '../../img/exp6.png'
import exp7 from '../../img/exp7.png'
import exp8 from '../../img/exp8.png'
import exp9 from '../../img/exp9.png'
import exp10 from '../../img/exp10.png'
import exp11 from '../../img/exp11.png'

import p1 from '../../img/p1.png'
import p2 from '../../img/p2.png'
import p3 from '../../img/p3.png'
import p4 from '../../img/p4.png'
import p5 from '../../img/p5.png'

import artist1 from '../../img/artist1.png'
import artist2 from '../../img/artist2.png'
import artist3 from '../../img/artist3.png'
import artist4 from '../../img/artist4.png'
import playlist1 from '../../img/playlist1.png'
import playlist2 from '../../img/playlist2.png'
import playlist3 from '../../img/playlist3.png'

import i1 from '../../img/i1.png'
import i2 from '../../img/i2.png'
import i3 from '../../img/i3.png'
import HeaderNew from "../HeaderNew/HeaderNew";

const content = [

    { clsName: 'trend explore-card2', heading: 'Trending Playlists', para: 'Add Or Upload your songs to today trending playlists', btn: 'Add' },
    { clsName: 'trend explore-card3', heading: 'Your album and playlist', para: 'Some of the best up-and-coming music all in one place', btn: 'Playlist' },
]

const content2 = [
    { claNme: 'playlist-card1', text: 'Chill', img: `${p1}` },
    { claNme: 'playlist-card2', text: 'Upbeat', img: `${p2}` },
    { claNme: 'playlist-card3', text: 'Intense', img: `${p3}` },
    { claNme: 'playlist-card4', text: 'Provoking', img: `${p4}` },
    { claNme: 'playlist-card5', text: 'Intimate', img: `${p5}` },
    { claNme: 'playlist-card1', text: 'Tender', img: `${p1}` },
    { claNme: 'playlist-card2', text: 'Romantic', img: `${p2}` },
    { claNme: 'playlist-card3', text: 'Peaceful', img: `${p3}` },
    { claNme: 'playlist-card4', text: 'Easygoing', img: `${p4}` },
    { claNme: 'playlist-card5', text: 'Sentimental', img: `${p5}` },
]

const content3 = [
    { img: `${artist1}`, txt1: '0x453712f21ddda4b7c00b4dcd3383b13f8…', txt2: '', btn: "follow" },
    { img: `${artist2}`, txt1: '0x453712f21ddda4b7c00b4dcd3383b13f8…', txt2: '', btn: "follow" },
    { img: `${artist3}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "follow" },
    { img: `${artist4}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "follow" },
    { img: `${artist1}`, txt1: '0x453712f21ddda4b7c00b4dcd3383b13f8…', txt2: '', btn: "follow" },
    { img: `${artist2}`, txt1: '0x453712f21ddda4b7c00b4dcd3383b13f8…', txt2: '', btn: "follow" },
    { img: `${artist3}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "follow" },
    { img: `${artist4}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "follow" }
]

const content4 = [
    { img: `${playlist1}`, txt1: 'Music Nft', txt2: '0.235 Dster', txt3: "X036sdnkjficmscchuhglas", btn: "Play" },
    { img: `${playlist2}`, txt1: 'Music Nft', txt2: '0.235 Dster', txt3: "X036sdnkjficmscchuhglas", btn: "Play" },
    { img: `${playlist3}`, txt1: 'Music Nft', txt2: '0.235 Dster', txt3: "X036sdnkjficmscchuhglas", btn: "Play", }
]

const drawerWidth = 280;

const title = 'Welcome';

function Explore() {
    const theme = useTheme();

    const navigate = useNavigate()
    const [title, setTitle] = useState('dashboard')
    return (
        <div className="explore dashboard">
            <Box sx={{ display: 'flex' }}>

                <HeaderNew title={title} />
                <Box component="main"
                    className="explore-box"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '50px', marginBottom: '100px' }}>


                    <Grid container spacing={0} className="heading-container">
                        <Container maxWidth="lg">
                            <Grid xs={12} sm={12} md={8} lg={8} xl={12}>

                            </Grid>
                        </Container>

                    </Grid>

                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: 3 }}
                        className="explore-box2"
                    >
                        {/* <Container maxWidth="xl"> */}
                        <Grid container spacing={2} className="whole-flex-spc">
                            <Grid xs={12} sm={12} md={12} lg={7} xl={7} className="dash-spc">
                                <Grid container spacing={2} className="dash-spc-contain">
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="dash-spc">
                                        <Card sx={{ display: 'flex' }} className="explore-card1" onClick={((e) => { navigate('/premiumtracks', { state: "Premium Tracks" }) })} >
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                    <div className="content-heading">
                                                        Buy Premium Tracks
                                                    </div>
                                                    <div className="content-para" >
                                                        Explore premium music available to purchase.
                                                    </div>
                                                    <div className="btn-flex">
                                                        <div className="dash-btn lib"><a href="/">Library</a></div>
                                                        <div className="dash-btn add"><a href="/">Add Songs</a></div>

                                                    </div>
                                                </CardContent>

                                            </Box>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 151 }}
                                                src={cart}
                                                alt="Live from space album cover"
                                            />
                                        </Card>
                                    </Grid>

                                    {content.map((row, obj) => {
                                        return (
                                            <>
                                                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <Card sx={{ display: 'flex' }} className={row.clsName} onClick={((e) => { navigate('/premiumtracks', { state: `${row.heading}` }) })} >
                                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                                                <div className="content-heading">
                                                                    {row.heading}
                                                                </div>
                                                                <div className="content-para" >
                                                                    {row.para}
                                                                </div>
                                                                <div className="content-btn dash-btn lib"><a>{row.btn}</a></div>
                                                            </CardContent>

                                                        </Box>
                                                        {/* <CardMedia
                                                        component="img"
                                                        sx={{ width: 200, height: 150 }}
                                                        src={row.img}
                                                        alt="Live from space album cover"
                                                    /> */}
                                                    </Card>
                                                </Grid>
                                            </>
                                        )
                                    })}

                                    <Container maxWidth="lg" className="love-playlist">
                                        <Grid container spacing={2} >
                                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <div className="explore-content-heading">
                                                    Recent playlist
                                                </div>

                                            </Grid>

                                            {content4.map((row, obj) => {
                                                return (
                                                    <>
                                                        <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Card sx={{ maxWidth: 240, margin: 'auto' }} className="love-playlist" onClick={((e) => { navigate('/premiumtracks', { state: `${row.txt1}` }) })}>
                                                                {/* <div className="cirle-img"
                                                        title="green iguana"> */}
                                                                <img src={row.img} alt='img' />
                                                                {/* </div> */}
                                                                <CardContent >
                                                                    <div className="love-txt1 love-txt">
                                                                        {row.txt1}
                                                                    </div>
                                                                    <div className="love-txt2 love-txt">
                                                                        {row.txt2}
                                                                    </div>
                                                                    <div className="love-txt3 love-txt">
                                                                        {row.txt3}
                                                                    </div>
                                                                    <div className="dash-btn play">
                                                                        <a> {row.btn}</a>
                                                                    </div>






                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </>
                                                )
                                            })}
                                        </Grid>
                                    </Container>

                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={5} xl={5} className="dash-spc">
                                <Grid container spacing={2} className="dash-spc-contain">
                                    <div className="creator_card">
                                        <div className="token_creator">
                                            <h6>CREATOR</h6>
                                            <div className="user_profile">
                                                <div><img src={userProfile} alt='userprofile' /></div>
                                                <div>
                                                    <p>Kiiara Castillo</p>
                                                    <span>@eatsleepdesignrepeat</span>
                                                </div>
                                            </div>
                                            <div className="user_profile counts">
                                                <div>
                                                    <p>Total Tokens</p>
                                                    <h6>3.69 Dster</h6>
                                                </div>
                                                <div>
                                                    <p>Ends in</p>
                                                    <h6>04h 12m</h6>
                                                </div>
                                            </div>
                                            <div className="outer_copyright">
                                                <div className="copyright">
                                                    <p>Dster Token address</p>
                                                    <img src={Copy} alt='Copy' />
                                                </div>
                                                <span>0xaF489a41e001dDeaFeC79829df5D56CC4b3a9Aa5</span>
                                            </div>


                                        </div>

                                        <div className="dash-btn buy"><a href="/">BUY TOKENS</a></div>

                                    </div>

                                    <Container maxWidth="lg">
                                        <Grid container spacing={2} className="artist_top_cards">
                                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <div className="explore-content-heading">
                                                    Top Artists
                                                </div>

                                            </Grid>

                                            {content3.map((row, obj) => {
                                                return (
                                                    <>
                                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="artist">
                                                            <div className="Card">
                                                                <CardMedia
                                                                    sx={{ height: 200 }}
                                                                    image={row.img}
                                                                    title="green iguana"
                                                                    className="artist-img"
                                                                />
                                                                <div className="artist-txt">
                                                                    <div className="love-txt1">
                                                                        {row.txt1}
                                                                    </div>
                                                                    <div className="love-txt2">
                                                                        {row.txt2}
                                                                    </div>
                                                                    <div className="btn artist-follow">
                                                                        {row.btn}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </>
                                                )
                                            })}
                                        </Grid>
                                    </Container>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* </Container> */}


                        {/* <div>
                            <Track  audiosource={vijay}/>
                        </div> */}
                    </Box>
                </Box>

            </Box>
        </div>
    );
}

export default Explore;