import React, { useEffect, useState } from 'react'
import './Recent.css'
import { Avatar, Box, Grid } from '@mui/material'
import bell from '../../img/bell.png'
import Badge from '@mui/material/Badge';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLocation } from 'react-router-dom';

import Axios from '../../Axios'
import { Button } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ava1 from "../../img/ava1.webp";
import Musiccontrol from '../Music-Control/Musiccontrol';
import Tooltip from "@mui/material/Tooltip";
import pasue from '../../img/pause.png'
import playnew from '../../img/playnew.png'
import Companylogo1 from "../../img/musicwave.gif";
import { ToastContainer, toast } from "react-toastify";
import Popover from '@mui/material/Popover';
import { NavLink, useNavigate } from 'react-router-dom';
import SidebarNew from '../SidebarNew/SidebarNew'
import nodta from '../../img/nodata.gif';

// function Recent({ grubChat, component, setComponent, search, setRecentPlayStart, start, topMusicStart, setStart, setTopMusicStart, recentPlayStart }) {
function Recent(props) {

    const [recent, setRecent] = useState([
        {},
        {},
        {},
        {},
        {},
        {},

    ])

    const [value, setValue] = React.useState(0);
    const [topartist, setTopartist] = useState([])
    const [recentPlays, setRecentPlays] = useState([])
    const [name, setName] = React.useState();
    const [limit, setLimit] = useState(5)
    const [exp, setExp] = useState(false);
    const [audioDurations, setAudioDurations] = useState([]);
    const [audioplays, setAudioplays] = useState([]);
    const [recentisplay, setrecentisplay] = useState(false);
    const [audiosource, setAudiosource] = React.useState();
    const [values, setValues] = React.useState(true);
    const [component1, setComponent1] = useState()
    const [id, setId] = useState()
    const [playname, setPlayname] = React.useState();
    const [play, setPlay] = React.useState([]);
    const [song_id, setsongid] = React.useState();
    const [row, setRow] = useState()
    // console.log(component, recentisplay, 'isplay in recent');
    const navigate = useNavigate()
    const tokens = localStorage.getItem("iphephile");
    const audio = localStorage.getItem("audiosource");
    const tabContent = ["All", "original", "repost"];
    // console.log(name, 'name');


    const [audioSourceArr, setAudioSourceArr] = useState()
    const [tracks, setTracks] = useState(0)
    const [playingIndex, setPlayingIndex] = useState();

    const changeLimit = () => {
        if (exp === false) {
            setLimit(20);
        } else {
            setLimit(5);
        }
        setExp(!exp);
    };

    const getAudioDuration = async (audioSrc) => {
        try {
            const audio = new Audio(audioSrc);
            await audio.addEventListener("loadedmetadata", () => {
                const duration = audio.duration;
                const durationInSeconds = audio.duration;
                const durationInMinutes = (durationInSeconds / 60).toFixed(2);
                setAudioDurations((prev) => ({
                    ...prev,
                    [audioSrc]: durationInMinutes,
                }));
            });
        } catch (error) {
            console.error(error);
            setAudioDurations((prev) => ({
                ...prev,
                [audioSrc]: 0, // Default duration or handle error accordingly
            }));
        }
    };

    const getaudioPlays = async (audioSrc, index) => {
        // console.log(audioSrc, index, 'getaudio');

        try {
            if (audioSrc === localStorage.getItem("songName")) {
                setAudioplays((prev) => ({
                    ...prev,
                    [index]: 1,
                }));
            } else {
                setAudioplays((prev) => ({
                    ...prev,
                    [index]: 0,
                }));
            }
        } catch (error) {
            console.error(error);
            setAudioplays((prev) => ({
                ...prev,
                [index]: 0,
            }));
        }
    };

    // console.log(audioplays, 'audioplays');


    const setAudio = (data) => {
        localStorage.setItem("audiosource", data.audio);
        localStorage.setItem("songName", data.song_name);
        localStorage.setItem("Image", data.image);
        localStorage.setItem("current", 0);
        localStorage.setItem("tracks", 0);
        localStorage.setItem("isPause", false);
        setAudiosource(data.audio);
        localStorage.setItem("playsong", "false");
        setValues(!values);
        setrecentisplay(true);
        setId(data._id)
        setComponent1(props?.setComponent ? props?.setComponent('recent') : 'recent')
        // props.setComponent('recent')
    };

    const createMusic = async (data) => {
        try {
            const create_data = {
                song_id: data,
            };

            await Axios.post("/users/add_history", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        // getMusic()
                    } else {
                        toast.error(res?.data?.message);
                    }
                })
                .catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message);
                });
        } catch (error) {
            // console.log(error, "error");
        }
    };

    const getMusic = async () => {
        try {
            const datas = await Axios.post(
                "/users/get_user_music",
                {
                    status: tabContent[value],
                    // limit: limit,
                    name: props?.search ? props?.search : name,
                },
                {
                    headers: {
                        Authorization: tokens,
                    },
                }
            )
                .then((res) => {
                    if (res?.data?.success) {
                        setTopartist(res?.data?.topartist)
                        setRecentPlays(res?.data?.recentPlays)
                        const audioArray = res?.data?.recentPlays?.map(item => ({
                            _id: item._id,
                            audio: item.audio,
                            song_name: item.song_name,
                            name: item.createUser.name,
                            image: item.image,
                            repeat: item.repeat,
                            shuffle: item?.shuffle,
                            fav: item?.userFav
                        }));
                        setAudioSourceArr(audioArray)
                    } else {
                        setTopartist([])
                        setRecentPlays([])
                    }
                })
                .catch((err) => {
                    // console.log(err,"err")
                });
        } catch (error) {
            console.log(error, "error");
        }
    };

    // console.log(topartist, 'topartist');

    const getMusics = async () => {
        try {
            const datas = await Axios.post(
                "/users/get_user_musics",
                {
                    status: tabContent[value],
                    // limit: limit,
                    name: props?.search,
                },
            )
                .then((res) => {
                    if (res?.data?.success) {
                        setTopartist(res?.data?.topartist)
                        setRecentPlays(res?.data?.recentPlays)
                        const audioArray = res?.data?.recentPlays?.map(item => ({
                            _id: item._id,
                            audio: item.audio,
                            song_name: item.song_name,
                            name: item.createUser.name,
                            image: item.image,
                            repeat: item.repeat,
                            shuffle: item?.shuffle,
                            fav: item?.userFav
                        }));
                        setAudioSourceArr(audioArray)
                    } else {
                        setTopartist([])
                        setRecentPlays([])
                    }
                })
                .catch((err) => {
                    // console.log(err,"err")
                });
        } catch (error) {
            console.log(error, "error");
        }
    };

    useEffect(() => {
        setTracks(tracks !== undefined ? tracks : Number(localStorage.getItem("tracks")))
        if (tokens) {
            getMusic()
        }
        else {
            getMusics()
        }
    }, [props?.search, props?.start, props?.topMusicStart, props?.recentPlayStart, name])



    useEffect(() => {
        if (recentPlays?.length > 0) {
            recentPlays?.forEach((row) => {
                getAudioDuration(row?.audio);
            });
        }
        if (recentPlays?.length > 0) {
            recentPlays?.forEach((row, index) => {
                getaudioPlays(row?.song_name, index);
            });
        }
    }, [recentPlays]);

    useEffect(() => {
        if (recentPlays?.length > 0) {
            recentPlays?.forEach((row, index) => {
                getaudioPlays(row?.song_name, index);
            });
        }
    }, [values]);

    // ])

    const location = useLocation()

    const path = location.pathname

    const [anchorEl2, setAnchorEl2] = useState(null);

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose3 = () => {
        setAnchorEl2(null);
    };

    const open2 = Boolean(anchorEl2);
    const id2 = open2 ? 'simple-popover' : undefined;

    const [anchorEl3, setAnchorEl3] = useState(null);

    const handlePopoverOpen3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handlePopoverClose3 = () => {
        setAnchorEl3(null);
    };

    const openSi = Boolean(anchorEl3);


    const getplayLists = async () => {
        try {
            const datas = await Axios.post(
                "/users/get_my_playlist",
                {
                    playname: playname,
                },
                {
                    headers: {
                        Authorization: tokens,
                    },
                }
            )
                .then((res) => {
                    // console.log(res?.data?.result?.musicList,"res")
                    if (res?.data?.success) {
                        setPlay(res?.data?.result);
                    } else {
                        // setMusics([])
                        // setAlbum([])
                        setPlay([]);
                    }
                })
                .catch((err) => {
                    // console.log(err,"err")
                });
        } catch (error) {
            // console.log(error,"error");
        }
    };

    const handleAdd = async (data) => {
        try {
            const create_data = {
                playlistId: data,
                songId: song_id,
            };
            await Axios.post("/users/edit_playlist", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        toast.success(res?.data?.message);
                        getMusic();
                        handlePopoverClose3();
                        handleClose3();
                        setTimeout(() => {
                            navigate('/playlistnew')
                        }, 2000)
                    } else {
                        toast.error(res?.data?.message);
                    }
                })
                .catch((err) => {
                    console.log(err, "err");
                    toast.error(err?.response?.data?.message);
                });
        } catch (error) {
            console.log(error, "error");
        }
    };

    const handlePlayPause = (index) => {
        setPlayingIndex(index)

    };

    useEffect(() => {
        getplayLists();
    }, [playname]);

    return (
        <div className='recent'>
            <Box
                component="main"
                className="explore-box"
                sx={{
                    // flexGrow: 1,
                    // py: 1,
                    // width: { sm: `calc(100% - ${drawerWidth}px)` },
                    // marginTop: "80px",
                    // marginBottom: "100px",
                }}
            >
                <SidebarNew name={name} setName={setName} audiosource={audioSourceArr} />
                <Grid container>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} >

                        <div className='topartist-recent'>
                            Top Artist
                        </div>
                        {path === '/livechat' ? props?.grubChat : <div className='height-prop'>
                            {
                                topartist?.length > 0 ?
                                    topartist.map((row, i) => {
                                        return (
                                            <div className='display-2 recente-head-div beta'>
                                                <div className='display-1 '>
                                                    <Avatar
                                                        alt="Remy Sharp"
                                                        // src="https://s.france24.com/media/display/451ed2b8-eed6-11ea-afdd-005056bf87d6/w:1280/p:4x3/messi-1805.jpg"
                                                        src={row?.image &&
                                                            !row?.image?.includes("localhost")
                                                            ? row?.image
                                                            : ava1}
                                                        sx={{ width: 40, height: 40 }}
                                                    />
                                                    <div>
                                                        <div className='recent-head-artist beta'>
                                                            {row?.song_name.length > 10 ? `${row?.song_name.slice(0, 10)}...` : row?.song_name}
                                                        </div>
                                                        <div className='recent-head-domainbeta'>
                                                            {row?.createUser?.name}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <MoreVertIcon onClick={(e) => { handleClick2(e); setsongid(row._id); setRow(row) }} />
                                                </div>
                                            </div>
                                        )
                                    }) :

                                    <div className='nodta text-center'>
                                        <img src={nodta} alt="nodta" />
                                    </div>

                            }

                        </div>}


                        <div className='topartist-recent'>
                            Recently Played
                        </div>
                        <div className='height-prop'>
                            {
                                recentPlays?.length > 0 ?
                                    recentPlays?.map((row, i) => {
                                        return (
                                            <div className='display-2 recente-head-div beta'>
                                                <div className='display-1 '>
                                                    <Avatar
                                                        alt="Remy Sharp"
                                                        // src="https://s.france24.com/media/display/451ed2b8-eed6-11ea-afdd-005056bf87d6/w:1280/p:4x3/messi-1805.jpg"
                                                        src={row?.image &&
                                                            !row?.image?.includes("localhost")
                                                            ? row?.image
                                                            : ava1}
                                                        sx={{ width: 40, height: 40 }}
                                                    />
                                                    <div>
                                                        <div className='recent-head-artist beta'>
                                                            {row?.song_name.length > 10 ? `${row?.song_name.slice(0, 10)}...` : row?.song_name}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='time-recent'>
                                                    {new Date(row?.updatedAt).getMinutes()} Min Ago
                                                </div>
                                                <div>
                                                    {/* <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.5 7.13397C14.1667 7.51887 14.1667 8.48112 13.5 8.86602L1.5 15.7942C0.833334 16.1791 2.11047e-07 15.698 2.01867e-07 14.9282L3.66308e-08 1.0718C2.7451e-08 0.301997 0.833333 -0.179129 1.5 0.205771L13.5 7.13397Z" fill="#B8B8B8" />
                                            </svg> */}
                                                    {(audioDurations[row?.audio] !==
                                                        undefined &&
                                                        <>
                                                            {
                                                                // audioplays[i] === 0 
                                                                playingIndex !== i ||
                                                                    recentisplay === false || props?.topMusicStart || props?.start ? (
                                                                    <Tooltip
                                                                        title="Play Song"
                                                                        placement="top"
                                                                    >
                                                                        <img
                                                                            src={playnew}
                                                                            onClick={() => {
                                                                                setAudio(row);
                                                                                createMusic(row?._id);
                                                                                setAudiosource(row?.audio);
                                                                                setTracks(i)
                                                                                handlePlayPause(i)
                                                                                if (props?.setTopMusicStart) {
                                                                                    props?.setTopMusicStart(false)
                                                                                }
                                                                                if (props?.setRecentPlayStart) {
                                                                                    props?.setRecentPlayStart(false)
                                                                                }
                                                                                if (props?.setStart) {
                                                                                    props?.setStart(false)
                                                                                }
                                                                                // props?.setTopMusicStart ? props?.setTopMusicStart(false) : null
                                                                                // props?.setStart ? props?.setStart(false) : null
                                                                                // props?.setRecentPlayStart ? props?.setRecentPlayStart(true) : null
                                                                            }}
                                                                            style={{ cursor: "pointer" }}
                                                                            alt="nr"
                                                                        />
                                                                    </Tooltip>
                                                                ) : (
                                                                    <img
                                                                        src={Companylogo1}
                                                                        id="pauseImage"
                                                                        onClick={() => {
                                                                            setrecentisplay(false);
                                                                            handlePlayPause(i)
                                                                        }}
                                                                        alt={Companylogo1}
                                                                    />
                                                                )}
                                                        </>
                                                    )}
                                                </div>

                                            </div>
                                        )
                                    }) :
                                    <div className='nodta text-center'>
                                        <img src={nodta} alt="nodta" />
                                    </div>

                            }

                        </div>

                    </Grid>
                    {(localStorage.getItem("playsong") === "false" && audiosource && props?.component === "recent" || component1 == 'recent') ? (
                        <Musiccontrol
                            // audiosource={audiosource}
                            audiosource={audioSourceArr}
                            isplay={recentisplay}
                            setisplay={setrecentisplay}
                            data={id}
                            tracks={tracks}
                            handlePlayPause={handlePlayPause}
                            component={props?.component ? props?.component : component1}
                            getMusic={tokens != null ? getMusic : getMusics}
                        />
                    ) : (
                        <>
                            {(localStorage.getItem("playsong") === "false" && audio !== null && props?.component === "recent" || component1 == 'recent') ? (
                                <Musiccontrol
                                    audiosource={audio}
                                    isplay={recentisplay}
                                    setisplay={setrecentisplay}
                                    data={id}
                                    tracks={tracks}
                                    handlePlayPause={handlePlayPause}
                                    component={props?.component ? props?.component : component1}
                                    getMusic={tokens != null ? getMusic : getMusics}
                                />
                            ) : (
                                <></>
                            )}{" "}
                        </>
                    )}
                </Grid>
            </Box>
            <Popover
                id={id2}
                open={open2}
                anchorEl={anchorEl2}
                onClose={handleClose3}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}

            >
                <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }}>
                    <div className=' display-1 cursor' onClick={handlePopoverOpen3}
                    // onMouseLeave={handlePopoverClose3}
                    >
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_4446_2802)">
                                    <path d="M3.125 10H16.875" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 3.125V16.875" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4446_2802">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                        <div>
                            <div className='frstname' >
                                Add to Playlist
                            </div>

                        </div>

                    </div>
                    {/* <div className=' display-1 cursor' >
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_4446_2782)">
                                    <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M4.98438 15.5757C5.45462 14.6494 6.17216 13.8715 7.05745 13.3281C7.94275 12.7847 8.96123 12.4971 10 12.4971C11.0388 12.4971 12.0572 12.7847 12.9425 13.3281C13.8278 13.8715 14.5454 14.6494 15.0156 15.5757" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4446_2782">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div>
                            <div className='frstname' >
                                Remove from profile
                            </div>

                        </div>

                    </div> */}
                    {/* 
                    <hr style={{ borderColor: '#ffffff5c' }} />
                    <div className=' display-1 cursor' >
                        <div className=''>
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 15.8751H1.75C1.58424 15.8751 1.42527 15.8093 1.30806 15.692C1.19085 15.5748 1.125 15.4159 1.125 15.2501V11.7587C1.12508 11.5932 1.19082 11.4344 1.30781 11.3173L10.9422 1.68291C11.0594 1.56579 11.2183 1.5 11.384 1.5C11.5497 1.5 11.7086 1.56579 11.8258 1.68291L15.3172 5.17198C15.4343 5.28917 15.5001 5.44808 15.5001 5.61377C15.5001 5.77946 15.4343 5.93837 15.3172 6.05557L5.5 15.8751Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14.875 15.875H5.5" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.625 4L13 8.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>



                        </div>
                        <div>
                            <div className='frstname'  >
                                Edit details
                            </div>

                        </div>

                    </div>
                    <div className=' display-1 cursor' >
                        <div className=''>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.875 3.375H1.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.125 7.125V12.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M9.875 7.125V12.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.625 3.375V15.25C13.625 15.4158 13.5592 15.5747 13.4419 15.6919C13.3247 15.8092 13.1658 15.875 13 15.875H3C2.83424 15.875 2.67527 15.8092 2.55806 15.6919C2.44085 15.5747 2.375 15.4158 2.375 15.25V3.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11.125 3.375V2.125C11.125 1.79348 10.9933 1.47554 10.7589 1.24112C10.5245 1.0067 10.2065 0.875 9.875 0.875H6.125C5.79348 0.875 5.47554 1.0067 5.24112 1.24112C5.0067 1.47554 4.875 1.79348 4.875 2.125V3.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>


                        </div>
                        <div>
                            <div className='frstname' >
                                Delete
                            </div>

                        </div>

                    </div>
                    <hr style={{ borderColor: '#ffffff5c' }} />
                    <div className=' display-1 cursor' >
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.66602 1.66699H18.3327V10.8337H16.666V3.33366H3.33268V16.667H10.8327V18.3337H1.66602V1.66699ZM9.99935 5.83366H13.3327V7.50033H11.666V11.667C11.6657 12.1976 11.4966 12.7144 11.1832 13.1426C10.8697 13.5707 10.4281 13.888 9.92234 14.0485C9.41657 14.2091 8.87284 14.2045 8.36984 14.0355C7.86683 13.8665 7.43064 13.5419 7.1244 13.1085C6.81815 12.6752 6.65773 12.1556 6.66635 11.6251C6.67497 11.0945 6.85218 10.5804 7.17234 10.1573C7.49251 9.73412 7.93901 9.4238 8.44724 9.27124C8.95547 9.11867 9.49906 9.13178 9.99935 9.30866V5.83366ZM9.99935 11.667C9.99935 11.446 9.91155 11.234 9.75527 11.0777C9.59899 10.9215 9.38703 10.8337 9.16602 10.8337C8.945 10.8337 8.73304 10.9215 8.57676 11.0777C8.42048 11.234 8.33268 11.446 8.33268 11.667C8.33268 11.888 8.42048 12.1 8.57676 12.2562C8.73304 12.4125 8.945 12.5003 9.16602 12.5003C9.38703 12.5003 9.59899 12.4125 9.75527 12.2562C9.91155 12.1 9.99935 11.888 9.99935 11.667ZM16.666 12.5003V15.0003H19.166V16.667H16.666V19.167H14.9993V16.667H12.4993V15.0003H14.9993V12.5003H16.666Z" fill="#636363" />
                            </svg>

                        </div>
                        <div>
                            <div className='frstname' >
                                Create PlayList
                            </div>

                        </div>

                    </div>

                    <div className=' display-1 cursor' >
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.8227 10.3539L12.6275 12.1587L12.1596 12.6266L10.3549 10.8218L10.0013 10.4682L9.64775 10.8218L7.84297 12.6266L7.37508 12.1587L9.17986 10.3539L9.53341 10.0003L9.17986 9.64677L7.37508 7.84199L7.84297 7.3741L9.64775 9.17888L10.0013 9.53243L10.3549 9.17888L12.1596 7.3741L12.6275 7.84199L10.8227 9.64677L10.4692 10.0003L10.8227 10.3539ZM2.83464 10.0003C2.83464 13.9515 6.05016 17.167 10.0013 17.167C13.9524 17.167 17.168 13.9515 17.168 10.0003C17.168 6.04918 13.9524 2.83366 10.0013 2.83366C6.05016 2.83366 2.83464 6.04918 2.83464 10.0003ZM2.16797 10.0003C2.16797 5.66813 5.66911 2.16699 10.0013 2.16699C14.3335 2.16699 17.8346 5.66813 17.8346 10.0003C17.8346 14.3325 14.3335 17.8337 10.0013 17.8337C5.66911 17.8337 2.16797 14.3325 2.16797 10.0003Z" stroke="#636363" />
                            </svg>


                        </div>
                        <div>
                            <div className='frstname' >
                                Exclude from your taste profile
                            </div>

                        </div>

                    </div>
                    <div className=' display-1 cursor'  >
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_4446_2807)">
                                    <path d="M17.5 6.875V15.6945C17.5 15.8419 17.4415 15.9831 17.3373 16.0873C17.2331 16.1915 17.0919 16.25 16.9445 16.25H3.125C2.95924 16.25 2.80027 16.1842 2.68306 16.0669C2.56585 15.9497 2.5 15.7908 2.5 15.625V5C2.5 4.83424 2.56585 4.67527 2.68306 4.55806C2.80027 4.44085 2.95924 4.375 3.125 4.375H7.29141C7.42664 4.375 7.55822 4.41886 7.66641 4.5L10 6.25H16.875C17.0408 6.25 17.1997 6.31585 17.3169 6.43306C17.4342 6.55027 17.5 6.70924 17.5 6.875Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4446_2807">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div>
                            <div className='frstname' >
                                Move to folder
                            </div>

                        </div>

                    </div>
                    <div className=' display-1 cursor' >
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_4446_2811)">
                                    <path d="M17.9422 6.68291C18.0594 6.56571 18.1252 6.40681 18.1252 6.24112C18.1252 6.07543 18.0594 5.91652 17.9422 5.79932L13.2032 1.05791C13.086 0.940792 12.9271 0.875 12.7614 0.875C12.5957 0.875 12.4368 0.940792 12.3196 1.05791L7.84303 5.54698C7.84303 5.54698 5.67506 4.46338 3.35943 6.33213C3.29077 6.3871 3.23447 6.45593 3.19424 6.53415C3.154 6.61236 3.13072 6.69818 3.12593 6.78601C3.12114 6.87383 3.13493 6.96168 3.16642 7.04381C3.19791 7.12593 3.24637 7.20049 3.30865 7.2626L11.7383 15.6915C11.8015 15.7542 11.8773 15.8027 11.9606 15.8337C12.044 15.8647 12.133 15.8776 12.2218 15.8715C12.3105 15.8653 12.3969 15.8403 12.4752 15.7981C12.5535 15.7559 12.6219 15.6974 12.6758 15.6267C13.3313 14.7548 14.361 12.9634 13.4657 11.1735L17.9422 6.68291Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.52266 11.4775L3.75 15.2502" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4446_2811">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                        <div>
                            <div className='frstname' >
                                Pin playlist
                            </div>

                        </div>

                    </div>
                    <hr style={{ borderColor: '#ffffff5c' }} />
                    <div className=' display-1 cursor' >
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_4446_2816)">
                                    <path d="M2.41876 14.525C3.70469 13.1555 7.08048 10.25 11.8773 10.25V14L18.1273 7.75L11.8773 1.5V5.25C7.75235 5.25 2.46407 9.19141 1.87735 14.2766C1.86923 14.3424 1.88228 14.4091 1.9146 14.4671C1.94693 14.525 1.99686 14.5712 2.05717 14.5988C2.11747 14.6265 2.18502 14.6343 2.25003 14.621C2.31504 14.6077 2.37413 14.5741 2.41876 14.525Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4446_2816">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>


                        </div>
                        <div>
                            <div className='frstname' >
                                Share
                            </div>

                        </div>

                    </div> */}
                </div>
            </Popover>
            <Popover
                id="mouse-over-popover"

                open={openSi}
                anchorEl={anchorEl3}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handlePopoverClose3}
                disableRestoreFocus
                className='siPopover'
            >
                <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }}>


                    <div className=' display-1 cursor' >
                        <div className=''>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_4446_2802)">
                                    <path d="M3.125 10H16.875" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 3.125V16.875" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4446_2802">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                        {/* <NavLink to='/playlistnew' state={song_id}> */}
                        <div className='frstname cursor' onClick={() => { navigate('/playlistnew', { state: { song_id: song_id, value: "recent" } }) }}>
                            New Playlist
                        </div>
                        {/* </NavLink> */}

                    </div>

                    <hr style={{ borderColor: '#ffffff5c' }} />
                    {
                        play.length > 0 ?
                            play?.map((row, ind) => (
                                <div className=' display-1 cursor' onClick={() => {
                                    handleAdd(row?._id);
                                }}>
                                    <div className=''>
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.5 15.8751H1.75C1.58424 15.8751 1.42527 15.8093 1.30806 15.692C1.19085 15.5748 1.125 15.4159 1.125 15.2501V11.7587C1.12508 11.5932 1.19082 11.4344 1.30781 11.3173L10.9422 1.68291C11.0594 1.56579 11.2183 1.5 11.384 1.5C11.5497 1.5 11.7086 1.56579 11.8258 1.68291L15.3172 5.17198C15.4343 5.28917 15.5001 5.44808 15.5001 5.61377C15.5001 5.77946 15.4343 5.93837 15.3172 6.05557L5.5 15.8751Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M14.875 15.875H5.5" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8.625 4L13 8.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        {/* <img src={row?.image} /> */}
                                    </div>
                                    <div>
                                        <div className='frstname'  >
                                            {row?.playlist}
                                        </div>
                                    </div>
                                </div>
                            )) :
                            <><p>You Don't have playlists</p></>
                    }

                    {/* <div className=' display-1 cursor' >
                        <div className=''>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.875 3.375H1.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.125 7.125V12.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M9.875 7.125V12.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.625 3.375V15.25C13.625 15.4158 13.5592 15.5747 13.4419 15.6919C13.3247 15.8092 13.1658 15.875 13 15.875H3C2.83424 15.875 2.67527 15.8092 2.55806 15.6919C2.44085 15.5747 2.375 15.4158 2.375 15.25V3.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11.125 3.375V2.125C11.125 1.79348 10.9933 1.47554 10.7589 1.24112C10.5245 1.0067 10.2065 0.875 9.875 0.875H6.125C5.79348 0.875 5.47554 1.0067 5.24112 1.24112C5.0067 1.47554 4.875 1.79348 4.875 2.125V3.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>


                        </div>
                        <div>
                            <div className='frstname' >
                                Delete
                            </div>

                        </div>

                    </div> */}
                    <hr style={{ borderColor: '#ffffff5c' }} />
                </div>
            </Popover>
        </div>
    )
}

export default Recent
