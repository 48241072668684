import React, { useState, useRef } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Web3 from 'web3';
import BalanceABI from '../../Web3/balanceAbi.json'
import balanceAddress from '../../Web3/balanceAddress'
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import './Header.css'
import './Header2.css'
import dwhite from '../../img/dwhite.png'
import ddark from '../../img/d.dark.png'
import { NavLink, Navigate, useNavigate, Link, useLocation } from 'react-router-dom'
import Avatar from '@mui/material/Avatar';
import ava from "../../img/ava1.webp";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Stack from '@mui/material/Stack';
import { useEffect } from "react";
import Axios from "../../Axios";
import DarkMode from "../DarkMode/DarkMode";
import Popover from '@mui/material/Popover';
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import Album2 from '../../img/bs4.png';
import { useTheme, useMediaQuery } from '@mui/material';
import beta from '../../img/beta.webp'

function Header(props) {

    const artistID = window.localStorage.getItem('use')
    const requestAcc = useRef(true)

    const navigate = useNavigate();
    const [btnColor1, setBtnColor1] = React.useState(true)
    const [btnColor, setBtnColor] = React.useState(false)

    const [btnColor2, setBtnColor2] = React.useState(false)
    const [state, setState] = React.useState({ left: false });
    const [mobileMenuOpen, setMobileMenuOpen] = useState(true)
    const [metaAccount, setMetaAccount] = useState("")
    const [profileData, setProfileData] = useState({});
    const tokens = localStorage.getItem("iphephile");

    const tokenExist = localStorage.getItem("iphephile");
    const wallet = localStorage.getItem("wallet");

    function handleScroll() {
        var body = document.body;

        if (document.documentElement.scrollTop > 100 || window.scrollY > 100) {
            // Add the class when scrolled more than 100 pixels
            body.classList.add('scrolled');
        } else {
            // Remove the class when scrolled back to the top
            body.classList.remove('scrolled');
        }
    }

    // Attach the handleScroll function to the scroll event
    window.addEventListener('scroll', handleScroll);

    const mobileOpenMenu = () => {
        setMobileMenuOpen(false)
    }
    const mobileCloseMenu = () => {
        setMobileMenuOpen(true)
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handlebtn1 = () => {
        setBtnColor1(true)
        setBtnColor2(false)
    }
    const handlebtn2 = () => {
        setBtnColor1(false)
        setBtnColor2(true)
    }

    const clickFileInput = useRef(null);


    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const [balance, setBalance] = useState(false)


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const location = useLocation()

    useEffect(() => {
        if (location.pathname == '/backstagepass' || location.pathname == '/supportpage') {
            setBtnColor(true)
        }
        else if (location.pathname == '/create' || location.pathname == '/mintnft' || location.pathname == '/upload') {
            setBtnColor2(true)
            setBtnColor(false)
        }
        else {
            setBtnColor(false)
            setBtnColor2(false)

        }
    }, []);
    const isMount = useRef(false)
    useEffect(() => {
        if (!isMount.current && tokens !== null) {

            getProfile();
            isMount.current = true
        }
        else {
        }
    }, [props?.isimage]);
    // useEffect(() => {
    //     getProfile();
    //     console.log(props,"props")
    // }, [props?.isimage]);

    const getProfile = async () => {
        try {
            await Axios.get(`/profile/getprofile`, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        setProfileData(res?.data?.result);
                        // setUpdateName(res?.data?.result?.name);
                    } else {
                        // toast.error("Can't Fetch Detail");
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
        } catch (error) {
            // console.log(error);
        }
    };

    const requestAccount = async () => {

        if (window.ethereum) {

            try {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });

                setMetaAccount(accounts[0])



                await Axios.post("/profile/update_account", {
                    account: accounts[0]
                },
                    {
                        headers: {
                            Authorization: tokens,
                        },
                    }).then((res) => {
                        // console.log(res,"res")
                        if (res?.data?.success) {

                            //  console.log(res?.data?.message)

                        }
                        else {

                            //  console.log(res?.data?.message)
                        }
                    }).catch((err) => {

                        console.log(err)

                    })

                localStorage.setItem('accounts', accounts[0])

            } catch (error) {
                console.log(error, 'Error connecting...');
            }

        } else {
            alert('Please Add Metamask Extension in Your Browser');
        }
    }
    useEffect(() => {
        // if (requestAcc.current) {

        if (!metaAccount && tokenExist) {
            // requestAccount()
        }
        // }


        requestAcc.current = false

    }, [])
    const navigateToBack = async () => {
        try {

            if (window.ethereum) {
                const web3Instance = new Web3(window.ethereum);
                const browserChainId = await web3Instance.eth.getChainId()

                await window.ethereum.enable();
                // setWeb3(web3Instance);

                await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                const accounts = await web3Instance.eth.getAccounts();
                if (Number(browserChainId) === 56) {
                    const contractInstance = new web3Instance.eth.Contract(
                        BalanceABI,
                        balanceAddress
                    );
                    // walletbalance - >  const balance = await contractInstance.methods.balanceOf(accounts[0]).call()
                    const balances = await contractInstance.methods.balanceOf(accounts[0]).call()
                    var bal = Number(balances)
                    if (bal >= 0) {
                        navigate('/backstagepass')
                    }
                    else {
                        toast.error("Insufficient Fund In Dster Token(BSC Network)")
                        setBalance(false)
                    }



                    //  return bal
                }
                else {
                    onChangeNetwork(56)
                }


            }
            else {
                toast.error("Add Metamask Extension")
            }


        } catch (err) {
            console.log(err)
        }
    };

    const navigateToLogin = () => {
        const stateData = { login: 3 };
        navigate(0)
    };

    const onChangeNetwork = async (chainIds) => {
        try {
            const WEB = new Web3(window.ethereum);

            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: WEB.utils.toHex(chainIds) }]
            });
            navigateToBack()

        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                var data = {
                    "name": "BNB Smart Chain Mainnet",
                    "chain": "BSC",
                    "rpc": [
                        "https://bsc-dataseed1.bnbchain.org",
                        "https://bsc-dataseed2.bnbchain.org",
                        "https://bsc-dataseed3.bnbchain.org",
                        "https://bsc-dataseed4.bnbchain.org",
                        "https://bsc-dataseed1.defibit.io",
                        "https://bsc-dataseed2.defibit.io",
                        "https://bsc-dataseed3.defibit.io",
                        "https://bsc-dataseed4.defibit.io",
                        "https://bsc-dataseed1.ninicoin.io",
                        "https://bsc-dataseed2.ninicoin.io",
                        "https://bsc-dataseed3.ninicoin.io",
                        "https://bsc-dataseed4.ninicoin.io",
                        "https://bsc.publicnode.com",
                        "wss://bsc.publicnode.com",
                        "wss://bsc-ws-node.nariox.org"
                    ],
                    "faucets": [],
                    "nativeCurrency": {
                        "name": "BNB Chain Native Token",
                        "symbol": "BNB",
                        "decimals": 18
                    },
                    "infoURL": "https://www.bnbchain.org/en",
                    "shortName": "bnb",
                    "chainId": 56,
                    "networkId": 56,
                    "slip44": 714,
                    "explorers": [
                        {
                            "name": "bscscan",
                            "url": "https://bscscan.com",
                            "standard": "EIP3091"
                        }
                    ]
                }


                // console.log(chainLists1, "chainlist")
                const WEB = new Web3(window.ethereum);
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: data?.chain,
                            chainId: WEB.utils.toHex(chainIds),
                            nativeCurrency: data?.nativeCurrency,
                            rpcUrls: [data?.rpc[0]]
                        }
                    ]
                });
            }
            else if (err.code === 4001) {
                navigate(0)
            }
            else {
                console.log(err)
            }
        }
    }


    const logout = () => {
        handleClosePopover()
        localStorage.setItem("NewUser", "false")
        if (localStorage.getItem("iphephile") != null) {
            localStorage.clear();
            toast.success('Logged out Successfully', {
                duration: 3000, // Set the duration for which the toast is visible
            })

            setTimeout(() => {
                navigate('/')

            }, 1000)
        }
        else {
            navigate('/')
        }

    }

    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('md'));




    const initLogin = localStorage.getItem("NewUser")

    const handlebackStage = () => {
        localStorage.setItem("NewUser", "true")
    }
    return (<>
        <div className="header">
            {/* <ToastContainer
          
        autoClose={3000}
          position="top-right"
        /> */}
            <AppBar position="fixed" className='bg-appbar'>
                <Container maxWidth="xl" >
                    <Toolbar disableGutters>
                        {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                        <Box sx={{ width: '100%', flexGrow: 1, display: { xs: 'flex', md: 'none' } }} >
                            <Stack direction="row" sx={{ width: '100%' }} justifyContent="space-between">
                                <Link to='/'>

                                    <div className="header-logo">
                                        <img src={localStorage.getItem("theme") === 'true' ? dwhite : ddark} alt='logo' />
                                    </div>

                                </Link>



                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={mobileOpenMenu}
                                    color="inherit"
                                    className="menuOpenBtn"
                                >
                                    <MenuIcon className='toggle-btn' />
                                </IconButton>
                            </Stack>
                        </Box>


                        <div className="class-for-mobile-header" id={mobileMenuOpen ? '' : 'openmenuid'}>
                            <div onClick={mobileCloseMenu} className="menuCloseBtn"><HighlightOffIcon /></div>

                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                href="#app-bar-with-responsive-menu"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'monospace',
                                    fontWeight: 600,
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                <Link to="/" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>

                                    <div className="header-logo">
                                        <img src={localStorage.getItem("theme") === 'true' ? ddark : dwhite} alt='logo' />
                                    </div>

                                </Link>

                            </Typography>

                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} id="header-middle-text">

                                {!isLgUp && <div className="profile-img-inner" style={{ cursor: "pointer" }}


                                >
                                    <Avatar alt="Remy Sharp" style={{ border: '3px solid #FC5AFF' }} src={profileData.profilePicture && !profileData?.profilePicture?.includes("localhost") ? profileData.profilePicture : Album2} sx={{ width: 45, height: 45 }} onClick={handleClickPopover} />

                                </div>}

                                <NavLink to='https://dreamster.world/' target="blank"  >Home</NavLink>

                                {tokens ? <NavLink
                                    // onClick={() => { navigateToBack() }}
                                    // to='/supportpage'
                                    to='/bckpassnew'
                                    className={({ isActive }) => (btnColor === true ? 'active' : '')} >Backstage pass</NavLink> :
                                    <NavLink to='/bckpassnew'
                                        className={({ isActive }) => (btnColor === true ? 'active' : '')} onClick={handlebackStage} >Backstage pass</NavLink>}

                                {/* <NavLink to='/staking' className={({ isActive }) => (isActive ? 'active' : '')} >Staking</NavLink> */}

                                <NavLink to='/creatorsnew' className={({ isActive }) => (btnColor2 ? 'active' : '')} >Creators</NavLink>

                                {/* {window.location.pathname != "/supportpage" ? <NavLink to='https://tokenpresale.dreamster.io' target="_blank" className={({ isActive }) => (isActive ? 'active' : '')} >Buy Crypto</NavLink>
                                :
                                <></>
                                } */}
                                {/* {!tokens ? :
                               <></>
                                } */}



                            </Box>
                            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                                <div className="dark-mode">

                                    <DarkMode />
                                </div>
                            </Box>
                            {tokenExist && metaAccount ?

                                <div className="avatar-cont2" sx={{ marginTop: '0ppx' }}>
                                    <div className="profile-img-inner" style={{ cursor: "pointer" }}


                                    >
                                        <Avatar alt="Remy Sharp" style={{ border: '3px solid #FC5AFF' }} src={profileData.profilePicture && !profileData?.profilePicture?.includes("localhost") ? profileData.profilePicture : Album2} sx={{ width: 45, height: 45 }} onClick={handleClickPopover} />

                                    </div>

                                </div>


                                : tokenExist && !metaAccount ?

                                    isLgUp && <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                                        <div className="login-btn">
                                            <Link className={btnColor1 ? 'active' : null} onClick={() => { requestAccount() }} >Connect Wallet</Link>
                                        </div>
                                    </Box>

                                    : !tokenExist ?

                                        <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                                            <div className="login-btn">
                                                <Link to="/login" className={btnColor1 ? 'active' : null} onClick={handlebtn1} >Sign up / Login</Link>
                                                {/* <Link to="/register" className={btnColor2 ? 'active' : null} onClick={handlebtn2} >Sign up</Link> */}
                                            </div>

                                            <div className="avatar-cont2" sx={{ marginTop: '0ppx' }}>
                                                <div className="profile-img-inner" style={{ cursor: "pointer" }} >

                                                </div>

                                            </div>
                                        </Box> : ""}

                        </div>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            className="profile-pop-compo"
                        >
                            <div className="profile-popup">
                                <div className="profile-img-inner inner-dp" style={{ cursor: "pointer" }}
                                    onClick={() => { navigate(`/artistpage/${profileData?._id}`) }}

                                >

                                    <Avatar alt="Remy Sharp" src={profileData.profilePicture && !profileData?.profilePicture?.includes("localhost") ? profileData.profilePicture : Album2} sx={{ width: 56, height: 56 }} onClick={handleClickPopover} />

                                </div>
                                <div className="popup-content-profile">
                                    <div onClick={() => { navigate(`/artistpage/${profileData?._id}`) }}>
                                        <PersonIcon />  Profile
                                    </div>

                                    <div onClick={() => { navigate('/staking') }}>
                                        <AlternateEmailIcon />  Staking
                                    </div>
                                    <div onClick={() => { navigate('/activity') }}>
                                        <LocalActivityIcon /> Activity
                                    </div>
                                    <div onClick={(() => { logout() })}>
                                        <LogoutIcon />  Logout
                                    </div>
                                </div>

                            </div>
                        </Popover>

                    </Toolbar>
                </Container>
            </AppBar>

        </div>
    </>);
}

export default Header;