import '../AlbumPage/AlbumPage.css'
import React, { useEffect, useState, useRef } from 'react'
import Header2 from "../Header/Header2";
import Box from '@mui/material/Box';
import UndoIcon from '@mui/icons-material/Undo';

import Tooltip from '@mui/material/Tooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid';
import DownloadIcon from '@mui/icons-material/Download';
import '../Feed/Feed.css'
import './CreatePlayList.css'
import '../TrackPage/TrackPage.css'
import Axios from "../../Axios";
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { ToastContainer, toast } from 'react-toastify';
import Mbadge from '../../img/badge.svg';
import Companylogo from '../../img/company-logo.png';
import Track from '../NewMusicTrack/NewMusicTrack';
import Tracks from "../Music-traxk/Tracks";
import VerifiedIcon from '@mui/icons-material/Verified';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import s6 from "../../img/pass-play.png"
import Popover from '@mui/material/Popover';
import bg from "../../img/dup.webp"
import bg2 from "../../img/ava.jpg"
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Avatar } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import ava1 from "../../img/ava1.webp";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { sendData, sendplayData } from '../../Redux/action'
import { indexData } from '../../Redux/action'
import HeaderNew from '../HeaderNew/HeaderNew';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';


function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'var(--clr-bcg)',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    '@media all and (max-width: 768.98px)': {
        width: '85%'
    },
    '@media all and (max-width: 575.98px)': {
        width: '95%'
    },
    '@media all and (max-width: 1199.98px)': {
        height: '700px',
        overflow: 'scroll !important'

    }
};

const mood = [
    'Peaceful',
    'Romantic',
    'Sentimental',
    'Tender',
    'Easygoing',
    'Yearning',
    'Sophisticated',
    'Sensual',
    'Cool',
    'Gritty',
    'Meancholy',
    'Serious',
    'Brooding',
    'Fiery',
    'Defiant',
    'Aggressive',
    'Rowdy',
    'Exicted',
    'Energizing',
    'Empowering',
    'Stirring',
    'Upbeat',
    'Other'
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}



const drawerWidth = 280;

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const CreatePlayList = () => {
    const [value, setValue] = React.useState(0);
    const [music, setMusic] = React.useState([]);
    const [usermusic, setUsermusic] = React.useState([]);
    const [tracksongs, settracksongs] = useState([])
    const [add, setAdd] = useState(false)
    const [name, setName] = React.useState();
    const [ids, setIds] = useState()
    const [audiosource, setAudiosource] = React.useState();
    const [count, setCount] = React.useState()
    const tokens = localStorage.getItem("iphephile");
    const dispatch = useDispatch();
    const songlist = useSelector(state => state.songs);
    const [audiosources1, setAudiosources1] = React.useState(songlist);
    const [tracks, setTracks] = useState(0)
    const play_mood = useSelector(state => state.play_mood);
    const [audiosources, setAudiosources] = React.useState(songlist);
    const index = useSelector(state => state.index);
    const [plays, setPlays] = React.useState("false");
    const navigate = useNavigate()
    const [audioDurations, setAudioDurations] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [imageerr, setImageerr] = useState(null)
    const [image, setImage] = useState(null);
    const [filetype, setFileType] = useState('sasd', true)
    const [display, setDisplay] = useState(false)
    const [songhistory, setSonghistory] = useState([])
    const [openModel, setOpenModel] = React.useState(false);
    const handleOpen = () => setOpenModel(true);
    const handleModelClose = () => setOpenModel(false);
    const theme = useTheme();
    const [mode, setMode] = useState();
    const [modeerr, setModeerr] = useState(null);
    const [personName, setPersonName] = React.useState('');

    const [values, setValues] = React.useState(true);
    const [audioplays, setAudioplays] = useState([]);
    const [isplay, setisplay] = useState(0)
    const [playlist, setplaylist] = React.useState();
    const [playlisterr, setplaylisterr] = React.useState(null);


    var Array = []
    const handleAdds = (_id) => {
        Array.push(_id)
        setSonghistory((data) => [...data, ..._id])

    }
    const editPlay = async () => {
        try {
            if (!playlist) {
                setplaylisterr(" Playlist Name is Required")
            }
            // else if (!mode) {
            //     setModeerr("Please Enter Song Mood")
            // }
            // else if (!image) {
            //     setImageerr("Please Select Image File")
            // }
            else {
                var music = []
                if (location?.state?.song_id !== undefined) {
                    music.push(location?.state?.song_id)
                }

                const create_data = {
                    song_history: music,
                    playlist: playlist,
                    image: image,
                    song_moods: mode,
                }
                await Axios.post("/users/create_playlist", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            toast.success('PlayList Created Successfully')
                            getMusics(res?.data?.result?._id)
                            setIds(res?.data?.result?._id)
                            const row = res?.data?.result
                            navigate('/createplaylist', { state: { row } })
                            handleModelClose()
                        }
                        else {
                            toast.error(res?.data?.message)

                        }
                    }).catch((err) => {
                        toast.error(err?.response?.data?.message)

                    })
            }
        } catch (error) {
            console.log(error, "error");
        }

    }
    const changePlays = (data) => {
        setPlays(data)
        localStorage.setItem("playsong", "true")
        dispatch(sendplayData(data))
        dispatch(sendData(audiosources))
        dispatch(indexData(0))
        setAudiosources1(songlist)
    }
    const changeTracks = () => {
        setPlays("true")
        localStorage.setItem("playsong", "true")
        dispatch(sendplayData("true"))
        dispatch(sendData(audiosources))
        setAudiosources1(audiosources)
    }

    const editAlbums = async (data) => {
        try {

            const create_data = {
                album_id: data.id,
                status: data.status
            }

            await Axios.post("/users/edit_playlist_details", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        //toast.success(res?.data?.message)
                        getMusic()
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }


    const handlePickMood = (e) => {

        setMode(e.target.value)
        setModeerr(null)


    };
    const audio = localStorage.getItem("audiosource")
    const audios = localStorage.getItem("audiosources")
    const setAudio = (data) => {
        localStorage.setItem("audiosource", data.audio)
        localStorage.setItem("songName", data.song_name)
        localStorage.setItem("Image", data.image)
        localStorage.setItem("current", 0)
        localStorage.setItem("isPause", false)
        setAudiosource(data.audio)
        localStorage.setItem("tracks", 0)
        localStorage.setItem("playsong", "false")
    }
    const removeAudio = () => {
        localStorage.removeItem("audiosource")
        localStorage.removeItem("current")
        localStorage.removeItem("isPause")
        // setAudiosource(data)

    }

    const handleFileClick = (event) => {
        hiddenFileInput.current.click();
    };


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const editSong = async (data) => {
        try {

            const create_data = {
                songId: data.id,
                status: data.status
            }

            await Axios.post("/users/edit_song_details", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        //toast.success(res?.data?.message)
                        getMusic()
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }
    const hiddenFileInput = useRef(null);


    const handleClose = () => {
        setAnchorEl(null);
    };


    const location = useLocation()
    let obj = (location?.state?.row)


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const getAudioDuration = async (audioSrc) => {
        try {
            const audio = new Audio(audioSrc);
            await audio.addEventListener('loadedmetadata', () => {
                const duration = audio.duration;
                const durationInSeconds = audio.duration;
                const durationInMinutes = (durationInSeconds / 60).toFixed(2);
                setAudioDurations((prev) => ({
                    ...prev,
                    [audioSrc]: durationInMinutes,
                }));
            });
        } catch (error) {
            setAudioDurations((prev) => ({
                ...prev,
                [audioSrc]: 0, // Default duration or handle error accordingly
            }));
        }
    };

    const getaudioPlays = async (audioSrc, index) => {
        try {
            if (audioSrc === localStorage.getItem("songName")) {
                setAudioplays((prev) => ({
                    ...prev,
                    [index]: 1,
                }));
            }
            else {
                setAudioplays((prev) => ({
                    ...prev,
                    [index]: 0,
                }));
            }

        } catch (error) {
            setAudioplays((prev) => ({
                ...prev,
                [index]: 0
            }));
        }
    };


    useEffect(() => {
        if (music?.songDetails?.length > 0) {
            music?.songDetails.forEach((row) => {
                getAudioDuration(row?.audio);
            });

        }
        if (music?.songDetails?.length > 0) {
            music?.songDetails?.forEach((row, index) => {
                getaudioPlays(row?.song_name, index);
            });

        }
    }, [music?.songDetails]);
    useEffect(() => {

        if (music?.songDetails?.length > 0) {
            music?.songDetails?.forEach((row, index) => {
                getaudioPlays(row?.song_name, index);
            });

        }
    }, [values]);

    useEffect(() => {
        console.log(location?.state?.speed, "spped")
        if (location?.state?.speed) {
            setValue(location?.state?.speed)
        }
        else {
            setValue(1)
        }

    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setTracks(index !== undefined ? index : Number(localStorage.getItem("tracks")))
        getTrackMusic()
        if (location?.state?.row?._id || location?.state?.row?.album_id || location?.state?.row?.PlaylistDetails?._id) {
            getMusic()
        }


    }, [])
    useEffect(() => {
        getTrackMusic()
        getMusic()
    }, [location.state?.row?.album_id])

    const getMusics = async (data) => {
        try {

            var findData = {
                album_id: data
            }

            const datas = await Axios.post("/users/get_one_playlist", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        if (res?.data?.result.length == 0) {
                            setAdd(true)

                        }
                        else {
                            setAdd(false)
                            setOpenModel(false)
                            setMusic(res?.data?.result[0])
                            var source = []

                            if (res?.data?.result[0].songDetails.length > 0) {
                                for (var i = 0; i < res?.data?.result[0].songDetails.length; i++) {
                                    var song = {}
                                    song.name = res?.data?.result[0].songDetails[i].song_name
                                    song.audio = res?.data?.result[0].songDetails[i]?.audio
                                    source.push(song)
                                }
                            }
                            setAudiosources(source)
                            settracksongs(res?.data?.musics)
                            // setAudiosources(res?.data?.result[0]?.songDetails)
                            if (res?.data?.result[0].songDetails.length > 0) {
                                setUsermusic(res?.data?.result[0].songDetails)
                            } else {
                                setUsermusic(res?.data?.result[0].songDetails)
                            }
                            setCount(res?.data?.count)
                        }
                    }
                    else {
                        setAdd(true)
                        //toast.error(err?.response?.data?.message)
                        setMusic([])
                        settracksongs([])

                    }
                }).catch((err) => {
                    // console.log(err,"err")
                    setAdd(true)

                })

        } catch (error) {
            // console.log(error,"error");
        }
    }
    const getMusic = async () => {
        try {

            var findData = {}
            if (location?.state?.row?.PlaylistDetails?._id) {
                findData = {
                    album_id: location?.state?.row?.PlaylistDetails?._id
                }
            }
            else if (location?.state?.row?.album_id) {
                findData = {
                    album_id: location?.state?.row?.album_id
                }
            }
            else if (location?.state?.row?._id) {
                findData = {
                    album_id: location?.state?.row?._id
                }
            } else {
                findData = {
                    album_id: null
                }
                setAdd(true)
                setOpenModel(true)

            }


            const datas = await Axios.post("/users/get_one_playlist", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        if (res?.data?.result.length == 0) {
                            setAdd(true)

                        }
                        else {
                            setAdd(false)
                            setMusic(res?.data?.result[0])
                            settracksongs(res?.data?.musics)
                            var source = []

                            if (res?.data?.result[0].songDetails.length > 0) {
                                for (var i = 0; i < res?.data?.result[0].songDetails.length; i++) {
                                    var song = {}
                                    song.name = res?.data?.result[0].songDetails[i].song_name
                                    song.audio = res?.data?.result[0].songDetails[i]?.audio
                                    source.push(song)
                                }
                            }
                            setAudiosources(source)
                            if (res?.data?.result[0].songDetails.length > 0) {
                                setUsermusic(res?.data?.result[0].songDetails)
                            } else {
                                setUsermusic(res?.data?.result[0].songDetails)
                            }
                            setCount(res?.data?.count)
                        }
                    }
                    else {
                        setAdd(true)

                        //toast.error(err?.response?.data?.message)
                        setMusic([])
                        settracksongs([])

                    }
                }).catch((err) => {
                    setAdd(true)

                })

        } catch (error) {
            console.log(error, "error");
        }
    }
    const [progress, setProgress] = React.useState(20);

    React.useEffect(() => {
        //getTrackMusic()

    }, [name]);


    const getTrackMusic = async () => {
        try {


            const datas = await Axios.post("/users/get_user_music", { music_type: "track", name: name }, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {

                        // settracksongs(res?.data?.trackList)
                        setCount(res?.data?.count)

                    }
                    else {
                        //toast.error(err?.response?.data?.message)
                        //settracksongs([])
                    }
                }).catch((err) => {
                    // console.log(err,"err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }


    const createMusic = async (data) => {
        try {

            const create_data = {
                song_id: data
            }

            await Axios.post("/users/add_history", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        //   toast.success(res?.data?.message)
                        getMusic()
                        // console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addalbumLibrary = async (data) => {
        try {

            const create_data = {
                play_id: data
            }

            await Axios.post("/users/add_playlist_to_library", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getMusic()
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addFavorite = async (data) => {
        try {

            const create_data = {
                song_id: data
            }

            await Axios.post("/users/add_to_favourite", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        //toast.success(res?.data?.message)
                        getMusic()
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addalbumFavorite = async (data) => {
        try {

            const create_data = {
                album_id: data
            }

            await Axios.post("/users/add_fav_playlist", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        // toast.success(res?.data?.message)
                        getMusic()
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const handleImageChange = async (event) => {
        const files = event.target.files[0];


        setFileType(files.name, true)
        try {
            let formData = await new FormData();
            formData.append("image", files);

            const { data } = await Axios.post("/updateDp", formData);
            if (data.result) {

                setImage(data.result);
                setImageerr(null)


            }
        } catch (error) {
            console.log(error, "err");
        }
    };

    const handleAdd = async (data) => {

        try {

            var create_data
            if (location?.state?.row?.PlaylistDetails?._id) {
                create_data = {
                    playlistId: location?.state?.row?.PlaylistDetails?._id ? location?.state?.row?.PlaylistDetails?._id : ids,
                    songId: data
                }
            }
            create_data = {
                playlistId: location?.state?.row?._id ? location?.state?.row?._id : ids,
                songId: data
            }

            await Axios.post("/users/edit_playlist", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getMusic()
                        getTrackMusic()
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }


    }

    const [showMessage, setShowMessage] = useState(true);


    useEffect(() => {

        const timeout = setTimeout(() => {
            setShowMessage(false);
        }, 3000);

        return () => clearTimeout(timeout);
    }, [showMessage]);

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });


    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, height: 300 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}

        >
            <List >
                {tracksongs?.length > 0 ? <>

                    {tracksongs && tracksongs.map((obj, index) => {
                        return (
                            <>  <div className='add-track-createplaylist'>
                                <div className='tracks-createplaylist'>
                                    {obj?.image ? <img src={obj?.image} alt='bg2' /> : <img src={Companylogo} alt='bg2' />}<div>{obj.song_name} by {obj.createUser?.name}</div>
                                </div>
                                <div className='add-btn-add-track'>

                                    {add ? <Button onClick={() => { handleAdds(obj?._id) }}>
                                        ADD
                                    </Button> :
                                        <Button onClick={() => { handleAdd(obj?._id) }}>
                                            ADD
                                        </Button>}
                                </div>

                            </div>
                            </>)
                    })}

                </> :
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='feed-music-box'>
                            <div className='music-detail'>


                                <div className='album-detail'>

                                    <div className='no-music'>
                                        <div className='time-music'>
                                            <div className='music-name'>
                                                <h4>No Data Found</h4>
                                            </div>

                                        </div>

                                    </div>



                                </div>
                            </div>
                        </div>
                    </Grid>}
            </List>
        </Box>
    );



    return (
        <div className='feed trackPage'>
            <ToastContainer

                autoClose={3000}
                position="top-right"

            />
            <Box sx={{ display: 'flex' }}>
                {/* <Header2 />           */}
                <HeaderNew name={name} setName={setName} />

                <Box component="main"
                    className={plays === true ? "feed-bg our-assigned-height" : "feed-bg"}
                    style={(audiosource || audios) ? { flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '100px', paddingBottom: '150px' } : { flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '50px', marginBottom: '50px' }}>
                    <div className='feeds'>
                        {/* <div className="back-svg-div">
                            {value === 0 ? <ArrowBackIcon className="back-svg" onClick={(() => { navigate(-1) })} /> : <ArrowBackIcon className="back-svg" onClick={((e) => { navigate(`/library`, { state: { value } }) })} />}
                        </div> */}

                    </div>




                    {add ? <Grid container spacing={0} className='CreatePlayList-grid' sx={{ marginTop: '100px' }}>
                        <Grid xs={10} sm={10} md={10} lg={11} xl={10}>
                            <div className='Trackpage-title-div'>
                                <Grid container spacing={0} sx={{ marginTop: '50px', justifyContent: 'center ' }}>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={10}>

                                        {showMessage ?
                                            <div className='track-avatar'><Stack spacing={1}>
                                                <Skeleton animation='wave' variant="circular" width={200} height={200} sx={{ bgcolor: 'var(--clr-skel)' }} />
                                            </Stack></div> :
                                            <div className='track-avatar'>
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    src={music?.image ? music?.image : ava1}
                                                    sx={{ width: 200, height: 200 }}
                                                />
                                            </div>}
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                                        <div className='track-title-content-div'>
                                            <div className='Track-genre'>
                                                Play List
                                            </div>
                                            <div className='Track-title'>
                                                {music?.playlist}
                                            </div>

                                            <div className='track-artist' >

                                            </div>
                                            <div className='discription-trackpage2'>
                                                <div>Create New Play List</div>

                                            </div>

                                            <div className='trackspage-tags'>

                                            </div>
                                            <div className='follow-us-div2'>
                                                <div className='play-btn-albumpage'>

                                                </div>

                                                <div onClick={handleOpen}>
                                                    <EditIcon /> Create
                                                </div>

                                            </div>
                                        </div>

                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className='table-div-feed'>

                                            {usermusic.length > 0 ? (<>
                                                <TableContainer >
                                                    <Table aria-label="simple table" sx={{ minWidth: 650 }} >
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell></TableCell>
                                                                <TableCell align="left">Track Name</TableCell>
                                                                <TableCell align="left">Artist</TableCell>
                                                                <TableCell align="left">Date</TableCell>
                                                                <TableCell align="left">Length</TableCell>
                                                                <TableCell align="left">Plays</TableCell>
                                                                <TableCell align="center"></TableCell>

                                                            </TableRow>
                                                        </TableHead>

                                                        {usermusic && usermusic.map((obj, index) => {
                                                            return (
                                                                <>
                                                                    <TableBody>
                                                                        <TableRow>


                                                                            <TableCell>
                                                                                {audioDurations[obj?.audio] !== undefined ? <>
                                                                                    <Tooltip title="Play Song" placement="top">
                                                                                        <img src={s6} className='music-play2'
                                                                                            onClick={() => { dispatch(indexData(index)); changeTracks(); setTracks(index); createMusic(obj?._id); setPlays(false); createMusic(obj?._id); setAudio(obj) }} />

                                                                                    </Tooltip>

                                                                                </> : <>
                                                                                    <Tooltip title="Can't Play" placement="top">
                                                                                        <img src={s6} className='music-play2' />

                                                                                    </Tooltip>
                                                                                </>}

                                                                            </TableCell>
                                                                            <TableCell >
                                                                                <div className='inside-div'>

                                                                                    <div className='more-tracks-inner-div pad'>
                                                                                        <div className='music-play-main-div' onClick={((e) => { navigate(`/trackpage/${obj._id}`) })}>
                                                                                            <span>#{index + 1}</span>{obj?.song_name}-{obj?.ISRC}
                                                                                            {obj?.nft_type === "per" ? <><VerifiedIcon className="premium" /></> : <></>}

                                                                                        </div>


                                                                                    </div>
                                                                                </div>
                                                                            </TableCell>



                                                                            <TableCell>{obj?.categorey}</TableCell>
                                                                            <TableCell>{new Date(obj?.createdAt).toLocaleDateString()}</TableCell>
                                                                            <TableCell>{audioDurations[obj?.audio]}</TableCell>
                                                                            {/* <TableCell><span>{audioDurations[obj?.audio]}</span> </TableCell> */}
                                                                            <TableCell>{obj?.players?.length ? obj?.players?.length : 0}</TableCell>
                                                                            <TableCell>

                                                                                <Tooltip title={obj.isRepost === true ? "Unrepost" : "Repost"} placement="top"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 11" fill="var(--clr-font)" className={obj.isRepost === true ? "repeat" : "Repost"} onClick={() => { editSong({ id: obj?._id, status: "repost" }) }}>
                                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6992 0.375C15.1067 0.375 15.4375 0.653048 15.4375 0.99574V5.82692H16.7394C16.9284 5.82692 17.0862 5.96322 17.1191 6.14313L17.125 6.21245C17.125 6.28722 17.1031 6.36043 17.0626 6.42274L14.517 10.317C14.4031 10.4392 14.2883 10.5 14.1719 10.5C14.0554 10.5 13.9407 10.4392 13.8259 10.317L11.2812 6.42274C11.2249 6.3368 11.2052 6.23462 11.226 6.13603C11.2467 6.03745 11.3064 5.94949 11.3934 5.88923C11.455 5.84877 11.5287 5.82699 11.6043 5.82692H12.9062L12.9054 2.71076L6.757 2.71154C6.56969 2.71154 6.39419 2.59938 6.28956 2.41324L5.34456 0.720807C5.32347 0.682709 5.31248 0.640533 5.3125 0.59775C5.3125 0.474692 5.39687 0.375 5.49981 0.375H14.6992ZM2.67578 10.5C2.26825 10.5 1.9375 10.222 1.9375 9.87926L1.93666 5.04808H0.635594C0.542009 5.04535 0.452502 5.01209 0.382983 4.95419C0.313464 4.89629 0.268437 4.81752 0.255906 4.73187L0.25 4.66255C0.25 4.58778 0.271937 4.51457 0.312437 4.45226L2.85803 0.558029C2.97194 0.43575 3.08669 0.375 3.20312 0.375C3.31956 0.375 3.43431 0.43575 3.54906 0.558029L6.09381 4.45226C6.15009 4.5382 6.16978 4.64038 6.14904 4.73897C6.1283 4.83755 6.06863 4.92551 5.98159 4.98577C5.91998 5.02623 5.84627 5.04801 5.77066 5.04808H4.46875V8.16346H10.618C10.8053 8.16346 10.9808 8.27561 11.0854 8.46176L12.0304 10.1542C12.0515 10.1923 12.0625 10.2345 12.0625 10.2773C12.0625 10.4003 11.9781 10.5 11.8752 10.5H2.67578Z" />
                                                                                </svg></Tooltip>


                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Tooltip title={obj.userFav === false ? "Add to Favourite" : "Remove From Favouite"} placement="top"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 20" fill="var(--clr-font)" className={obj.userFav === false ? "fav" : "unfav"} onClick={() => { addFavorite(obj._id) }}>
                                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.08938 0.875C3.15975 0.875 0.75 4.25876 0.75 6.75321C0.75 12.1451 7.67475 16.813 12.5625 20C17.4503 16.8116 24.375 12.1465 24.375 6.75321C24.375 4.25876 21.9653 0.875 18.0356 0.875C15.8438 0.875 13.9669 2.42413 12.5625 3.89402C11.1555 2.42413 9.28125 0.875 7.08938 0.875Z" />
                                                                                </svg></Tooltip>

                                                                            </TableCell>

                                                                        </TableRow >
                                                                    </TableBody>

                                                                </>

                                                            )
                                                        })

                                                        }

                                                    </Table>
                                                </TableContainer>


                                            </>) : (<>

                                                <div className='add-false-content'>
                                                    This playlist is empty. Start adding tracks to share it or make it public.
                                                </div>
                                            </>)}

                                        </div>
                                    </Grid>
                                </Grid>

                            </div>
                        </Grid>
                    </Grid>
                        : <Grid container spacing={0} className='CreatePlayList-grid'>
                            <Grid xs={10} sm={10} md={10} lg={11} xl={10}>
                                <div className='Trackpage-title-div'>
                                    <Grid container spacing={0} sx={{ marginTop: '50px', justifyContent: 'center ' }}>
                                        <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                                            {showMessage ?
                                                <div className='track-avatar'><Stack spacing={1}>
                                                    <Skeleton animation='wave' variant="circular" width={200} height={200} sx={{ bgcolor: 'var(--clr-skel)' }} />
                                                </Stack></div> :
                                                <div className='track-avatar'>
                                                    <Avatar
                                                        alt="Remy Sharp"
                                                        src={music?.image ? music?.image : ava1}
                                                        sx={{ width: 200, height: 200 }}
                                                    />
                                                </div>}
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                                            <div className='track-title-content-div'>
                                                <div className='Track-genre'>
                                                    Play List
                                                </div>
                                                <div className='Track-title'>
                                                    {music?.playlist}
                                                </div>

                                                <div className='track-artist' onClick={((e) => { navigate(`/artistpage/${music.createUser?._id}`) })}>
                                                    {/* {artist} */}
                                                    <span>By</span>
                                                    {music?.createUser?.name}
                                                </div>
                                                <div className='discription-trackpage2'>
                                                    <div>Released<span>{new Date(music?.createdAt).toLocaleDateString()}    </span></div>

                                                </div>

                                                <div className='trackspage-tags'>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="11" viewBox="0 0 18 11" className='repeat'>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6992 0.375C15.1067 0.375 15.4375 0.653048 15.4375 0.99574V5.82692H16.7394C16.9284 5.82692 17.0862 5.96322 17.1191 6.14313L17.125 6.21245C17.125 6.28722 17.1031 6.36043 17.0626 6.42274L14.517 10.317C14.4031 10.4392 14.2883 10.5 14.1719 10.5C14.0554 10.5 13.9407 10.4392 13.8259 10.317L11.2812 6.42274C11.2249 6.3368 11.2052 6.23462 11.226 6.13603C11.2467 6.03745 11.3064 5.94949 11.3934 5.88923C11.455 5.84877 11.5287 5.82699 11.6043 5.82692H12.9062L12.9054 2.71076L6.757 2.71154C6.56969 2.71154 6.39419 2.59938 6.28956 2.41324L5.34456 0.720807C5.32347 0.682709 5.31248 0.640533 5.3125 0.59775C5.3125 0.474692 5.39687 0.375 5.49981 0.375H14.6992ZM2.67578 10.5C2.26825 10.5 1.9375 10.222 1.9375 9.87926L1.93666 5.04808H0.635594C0.542009 5.04535 0.452502 5.01209 0.382983 4.95419C0.313464 4.89629 0.268437 4.81752 0.255906 4.73187L0.25 4.66255C0.25 4.58778 0.271937 4.51457 0.312437 4.45226L2.85803 0.558029C2.97194 0.43575 3.08669 0.375 3.20312 0.375C3.31956 0.375 3.43431 0.43575 3.54906 0.558029L6.09381 4.45226C6.15009 4.5382 6.16978 4.64038 6.14904 4.73897C6.1283 4.83755 6.06863 4.92551 5.98159 4.98577C5.91998 5.02623 5.84627 5.04801 5.77066 5.04808H4.46875V8.16346H10.618C10.8053 8.16346 10.9808 8.27561 11.0854 8.46176L12.0304 10.1542C12.0515 10.1923 12.0625 10.2345 12.0625 10.2773C12.0625 10.4003 11.9781 10.5 11.8752 10.5H2.67578Z" />
                                                        </svg>
                                                        <span> {music?.reposts?.length ? music?.reposts?.length : 0} Repost</span>
                                                    </div>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 20" fill="var(--clr-font)" className='unfav'>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.08938 0.875C3.15975 0.875 0.75 4.25876 0.75 6.75321C0.75 12.1451 7.67475 16.813 12.5625 20C17.4503 16.8116 24.375 12.1465 24.375 6.75321C24.375 4.25876 21.9653 0.875 18.0356 0.875C15.8438 0.875 13.9669 2.42413 12.5625 3.89402C11.1555 2.42413 9.28125 0.875 7.08938 0.875Z" />
                                                        </svg>
                                                        <span> {music?.fav_count} Favourite</span>
                                                    </div>
                                                </div>
                                                <div className='follow-us-div2'>
                                                    <div className='play-btn-albumpage'>
                                                        <Button onClick={() => { removeAudio(); setPlays(true); setAudiosource() }} ><PlayArrowIcon />Play</Button>


                                                    </div>
                                                    <div>


                                                        {music?.players?.length ? music?.players?.length : 0} Plays
                                                    </div>

                                                    <Tooltip title={music?.isRepost === true ? "Unrepost" : "Repost"} placement="top"><div onClick={() => { editAlbums({ id: music?._id, status: "repost" }) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 11" fill="var(--clr-font)" className={music?.isRepost === true ? "repeat" : "Repost"}>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6992 0.375C15.1067 0.375 15.4375 0.653048 15.4375 0.99574V5.82692H16.7394C16.9284 5.82692 17.0862 5.96322 17.1191 6.14313L17.125 6.21245C17.125 6.28722 17.1031 6.36043 17.0626 6.42274L14.517 10.317C14.4031 10.4392 14.2883 10.5 14.1719 10.5C14.0554 10.5 13.9407 10.4392 13.8259 10.317L11.2812 6.42274C11.2249 6.3368 11.2052 6.23462 11.226 6.13603C11.2467 6.03745 11.3064 5.94949 11.3934 5.88923C11.455 5.84877 11.5287 5.82699 11.6043 5.82692H12.9062L12.9054 2.71076L6.757 2.71154C6.56969 2.71154 6.39419 2.59938 6.28956 2.41324L5.34456 0.720807C5.32347 0.682709 5.31248 0.640533 5.3125 0.59775C5.3125 0.474692 5.39687 0.375 5.49981 0.375H14.6992ZM2.67578 10.5C2.26825 10.5 1.9375 10.222 1.9375 9.87926L1.93666 5.04808H0.635594C0.542009 5.04535 0.452502 5.01209 0.382983 4.95419C0.313464 4.89629 0.268437 4.81752 0.255906 4.73187L0.25 4.66255C0.25 4.58778 0.271937 4.51457 0.312437 4.45226L2.85803 0.558029C2.97194 0.43575 3.08669 0.375 3.20312 0.375C3.31956 0.375 3.43431 0.43575 3.54906 0.558029L6.09381 4.45226C6.15009 4.5382 6.16978 4.64038 6.14904 4.73897C6.1283 4.83755 6.06863 4.92551 5.98159 4.98577C5.91998 5.02623 5.84627 5.04801 5.77066 5.04808H4.46875V8.16346H10.618C10.8053 8.16346 10.9808 8.27561 11.0854 8.46176L12.0304 10.1542C12.0515 10.1923 12.0625 10.2345 12.0625 10.2773C12.0625 10.4003 11.9781 10.5 11.8752 10.5H2.67578Z" />
                                                        </svg>


                                                    </div></Tooltip>

                                                    <Tooltip title={music?.userFav === false ? "Add to Favourite" : "Remove from Favourite"} placement="top">
                                                        <div onClick={() => { addalbumFavorite(music._id) }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 20" fill="var(--clr-font)" className={music?.userFav === false ? "fav" : "unfav"}>
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.08938 0.875C3.15975 0.875 0.75 4.25876 0.75 6.75321C0.75 12.1451 7.67475 16.813 12.5625 20C17.4503 16.8116 24.375 12.1465 24.375 6.75321C24.375 4.25876 21.9653 0.875 18.0356 0.875C15.8438 0.875 13.9669 2.42413 12.5625 3.89402C11.1555 2.42413 9.28125 0.875 7.08938 0.875Z" />
                                                            </svg>

                                                        </div>
                                                    </Tooltip>

                                                    {music?.userFound === false && music?.TotalCounts !== 0 ? <Tooltip title="Add to Library" placement="top"> <div onClick={(e) => addalbumLibrary(music._id)}>
                                                        <DownloadIcon />
                                                    </div>
                                                    </Tooltip> : <></>}
                                                    <div>
                                                        {['bottom'].map((anchor) => (
                                                            <React.Fragment key={anchor}>
                                                                <Button onClick={toggleDrawer(anchor, true)} className='bot-drawer-btn'>Add More</Button>
                                                                <Drawer
                                                                    anchor={anchor}
                                                                    open={state[anchor]}
                                                                    onClose={toggleDrawer(anchor, false)}
                                                                    className='add-track-list'
                                                                >
                                                                    {list(anchor)}
                                                                </Drawer>
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                    {music?.userFound === true ?
                                                        <Tooltip title="Remove from Library" placement="top">
                                                            <UndoIcon onClick={() => { addalbumLibrary(music?._id) }} />
                                                        </Tooltip>
                                                        : <></>}
                                                </div>

                                            </div>

                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='table-div-feed'>

                                                {usermusic.length > 0 ? (<>
                                                    <TableContainer >
                                                        <Table aria-label="simple table" sx={{ minWidth: 650 }} >
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell></TableCell>
                                                                    <TableCell align="left">Track Name</TableCell>
                                                                    <TableCell align="left">Artist</TableCell>
                                                                    <TableCell align="left">Date</TableCell>
                                                                    <TableCell align="left">Length</TableCell>
                                                                    <TableCell align="left">Plays</TableCell>
                                                                    <TableCell align="center"></TableCell>

                                                                </TableRow>
                                                            </TableHead>

                                                            {usermusic && usermusic.map((obj, index) => {
                                                                return (
                                                                    <>
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                <TableCell><img src={s6} className='music-play2'
                                                                                    onClick={() => { dispatch(indexData(index)); changeTracks(); setTracks(index); createMusic(obj?._id); setPlays(false); createMusic(obj?._id); setAudio(obj) }}
                                                                                /></TableCell>
                                                                                <TableCell >
                                                                                    <div className='inside-div'>

                                                                                        <div className='more-tracks-inner-div pad'>
                                                                                            <div className='music-play-main-div' onClick={((e) => { navigate(`/trackpage/${obj._id}`) })}>
                                                                                                <span >#{index + 1}</span>{obj?.song_name}-{obj?.ISRC}

                                                                                            </div>
                                                                                            <div>


                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </TableCell>

                                                                                <TableCell>{obj?.categorey}</TableCell>
                                                                                <TableCell>{new Date(obj?.createdAt).toLocaleDateString()}</TableCell>
                                                                                <TableCell>{audioDurations[obj?.audio]}</TableCell>
                                                                                <TableCell>{obj?.players?.length}</TableCell>
                                                                                <TableCell>

                                                                                    <Tooltip title={obj.isRepost === true ? "Unrepost" : "Repost"} placement="top"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 11" fill="var(--clr-font)" className={obj.isRepost === true ? "repeat" : "Repost"} onClick={() => { editSong({ id: obj?._id, status: "repost" }) }}>
                                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6992 0.375C15.1067 0.375 15.4375 0.653048 15.4375 0.99574V5.82692H16.7394C16.9284 5.82692 17.0862 5.96322 17.1191 6.14313L17.125 6.21245C17.125 6.28722 17.1031 6.36043 17.0626 6.42274L14.517 10.317C14.4031 10.4392 14.2883 10.5 14.1719 10.5C14.0554 10.5 13.9407 10.4392 13.8259 10.317L11.2812 6.42274C11.2249 6.3368 11.2052 6.23462 11.226 6.13603C11.2467 6.03745 11.3064 5.94949 11.3934 5.88923C11.455 5.84877 11.5287 5.82699 11.6043 5.82692H12.9062L12.9054 2.71076L6.757 2.71154C6.56969 2.71154 6.39419 2.59938 6.28956 2.41324L5.34456 0.720807C5.32347 0.682709 5.31248 0.640533 5.3125 0.59775C5.3125 0.474692 5.39687 0.375 5.49981 0.375H14.6992ZM2.67578 10.5C2.26825 10.5 1.9375 10.222 1.9375 9.87926L1.93666 5.04808H0.635594C0.542009 5.04535 0.452502 5.01209 0.382983 4.95419C0.313464 4.89629 0.268437 4.81752 0.255906 4.73187L0.25 4.66255C0.25 4.58778 0.271937 4.51457 0.312437 4.45226L2.85803 0.558029C2.97194 0.43575 3.08669 0.375 3.20312 0.375C3.31956 0.375 3.43431 0.43575 3.54906 0.558029L6.09381 4.45226C6.15009 4.5382 6.16978 4.64038 6.14904 4.73897C6.1283 4.83755 6.06863 4.92551 5.98159 4.98577C5.91998 5.02623 5.84627 5.04801 5.77066 5.04808H4.46875V8.16346H10.618C10.8053 8.16346 10.9808 8.27561 11.0854 8.46176L12.0304 10.1542C12.0515 10.1923 12.0625 10.2345 12.0625 10.2773C12.0625 10.4003 11.9781 10.5 11.8752 10.5H2.67578Z" />
                                                                                    </svg></Tooltip>


                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Tooltip title={obj.userFav === false ? "Add to Favourite" : "Remove From Favouite"} placement="top"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 20" fill="var(--clr-font)" className={obj.userFav === false ? "fav" : "unfav"} onClick={() => { addFavorite(obj._id) }}>
                                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.08938 0.875C3.15975 0.875 0.75 4.25876 0.75 6.75321C0.75 12.1451 7.67475 16.813 12.5625 20C17.4503 16.8116 24.375 12.1465 24.375 6.75321C24.375 4.25876 21.9653 0.875 18.0356 0.875C15.8438 0.875 13.9669 2.42413 12.5625 3.89402C11.1555 2.42413 9.28125 0.875 7.08938 0.875Z" />
                                                                                    </svg></Tooltip>

                                                                                </TableCell>
                                                                            </TableRow >
                                                                        </TableBody>

                                                                    </>

                                                                )
                                                            })

                                                            }

                                                        </Table>
                                                    </TableContainer>
                                                </>) : (<>
                                                    <div className='add-false-content'>
                                                        This playlist is empty. Start adding tracks to share it or make it public.
                                                    </div>
                                                </>)}
                                            </div>
                                        </Grid>
                                    </Grid>

                                </div>
                            </Grid>
                        </Grid>
                    }

                    <Grid container spacing={0} sx={{ marginTop: '50px', justifyContent: 'center ' }}>
                        {/* {add ? <></> : <Grid xs={10} sm={10} md={10} lg={11} xl={10}>
                            <Accordion className='accord-feed-page accord-style'>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header-feed-album-list"
                                >
                                    Add More tracks
                                </AccordionSummary>
                                <AccordionDetails className='accord-summary'>
                                 
                                </AccordionDetails>


                            </Accordion>
                        </Grid>} */}


                        <Modal
                            open={openModel}

                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Grid container spacing={0}>
                                    <Grid xs={1} sm={1} md={1} lg={1} xl={1} >
                                        <div>
                                            <CloseIcon onClick={handleModelClose} />
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={11} xl={11}>
                                        <div className='edit-playlist-title'>
                                            <QueueMusicIcon />
                                            Create PlayList
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={5} xl={4} className='imgae-div-edit-grid'>
                                        <div className="profile-img-outer2 minnft">
                                            <div className="image-upload-container">
                                                <div className="box-decoration">
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div
                                                            className="profile-img"
                                                            onClick={handleFileClick}
                                                            style={{ cursor: "pointer" }}
                                                        >


                                                            <div className="preview-img profile-banner-size">
                                                                {image ? (
                                                                    <Avatar
                                                                        alt="Remy Sharp"
                                                                        src={image}
                                                                        sx={{
                                                                            width: 250,
                                                                            height: 250,
                                                                            border: "3px solid #fff",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <Avatar
                                                                        alt="Remy Sharp"
                                                                        src={ava1}
                                                                        sx={{
                                                                            width: 250,
                                                                            height: 250,
                                                                            border: "3px solid #fff",
                                                                        }}
                                                                    />
                                                                )}
                                                                <div class="edit1">
                                                                    <CloudUploadIcon />
                                                                    <div>Upload Image</div>
                                                                </div>

                                                            </div>

                                                            <input
                                                                id="image-upload-input"
                                                                type="file"
                                                                onChange={handleImageChange}
                                                                ref={hiddenFileInput}
                                                                style={{ display: "none" }}
                                                            />
                                                            {imageerr !== null ? (
                                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{imageerr}</div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </Grid>

                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={7} xl={8} className='create-play-grid'>
                                        <div className='model-edit-playlist'>
                                            <TextField
                                                required
                                                id="filled-required"
                                                label="PlayList Name"
                                                // defaultValue="Hip-hop"
                                                value={playlist}
                                                onChange={(e) => { setplaylist(e.target.value); setplaylisterr(null) }}
                                                variant="outlined"
                                            />
                                            {playlisterr !== null ? (
                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{playlisterr}</div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <div>
                                            <FormControl fullWidth>
                                                <h5 className="h5-edit">Pick a Mood</h5>
                                                <Select
                                                    // multiple
                                                    displayEmpty

                                                    value={mode}
                                                    onChange={handlePickMood}
                                                    input={<OutlinedInput />}
                                                    renderValue={(selected) => {
                                                        if (selected && selected.length === 0) {
                                                            return <MenuItem >Pick a Mood</MenuItem>;
                                                        }

                                                        return selected;
                                                    }}
                                                    className='pick-mood'

                                                    MenuProps={MenuProps}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >

                                                    {/* <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem> */}
                                                    {mood.map((name) => (
                                                        <MenuItem
                                                            key={name}
                                                            value={name}

                                                            id='pick-mood'
                                                            style={getStyles(name, personName, theme)}
                                                        >
                                                            {name}
                                                        </MenuItem>
                                                    ))}

                                                </Select>
                                                {modeerr !== null ? (
                                                    <div className="errordiv">{modeerr}</div>
                                                ) : (
                                                    <></>
                                                )}
                                            </FormControl>
                                        </div>
                                        <div className='model-edit-playlist'>
                                            <TextField
                                                id="filled-multiline-static"
                                                label="Description"
                                                required
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                            />
                                        </div>

                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: '25px' }}>
                                        <div className='allButton-div'>
                                            <div>

                                            </div>
                                            <div className='savechanges'>
                                                <Button className='btn2' onClick={handleModelClose}>
                                                    Cancel
                                                </Button>
                                                <Button className='btn3' onClick={() => editPlay()}>
                                                    Save Changes
                                                </Button>
                                            </div>
                                        </div>

                                    </Grid>
                                </Grid>
                            </Box>
                        </Modal>
                    </Grid>

                    {localStorage.getItem("playsong") === "true" ? <>{audiosources.length > 0 ? <Tracks audiosources={audiosources} tracks={tracks} /> : <></>} </> : <></>}
                    {(localStorage.getItem("playsong") === "false" && audiosource) === true ? <Track audiosource={audiosource} /> : <>{(localStorage.getItem("playsong") === "false" && audio !== null) === true ? <Track audiosource={audio} /> : <></>} </>}


                </Box>
            </Box >
        </div >
    )
}

export default CreatePlayList
