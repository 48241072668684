import React from "react";
import Header2 from "../Header/Header2";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'
import Track from "../Music-traxk/Track";
import '../Upload-Music/UploadMusic.css'
import Container from '@mui/material/Container'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { useTheme } from '@mui/material/styles';
import { Button } from "@mui/material";
import cart from '../../img/cart.png'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import SnackBar from "../SnackBar/SnackBar";
import '../Hoisting/Hoisting.css'
import TextField from '@mui/material/TextField';
import { useState, useEffect, useRef } from "react";
import { error } from "jquery";
import './CreateVirtualMeet.css'
import { trim } from "jquery";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import HeaderNew from "../HeaderNew/HeaderNew";



const drawerWidth = 280;

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});




function CreateVirtualMeet() {
    const theme = useTheme();
    const [validate, setValidate] = useState('')
    const [err, setErr] = useState(null)

    // console.log(validate);

    const inputRef = useRef(null)

    useEffect(() => {

        if (validate.length >= 3) {
            console.log('yes');
            setErr(false)
        }
        //  else if (validate.trim() === '') {
        //     setErr(false)
        // }
        else {
            setErr(true)
        }

    }, [validate])

const[title,setTitle]=useState('create virtual meet')
    return (

        <div className="Hoisting">
            {/* <SnackBar /> */}
            <Box sx={{ display: 'flex' }} className='display-block'>

                <HeaderNew title={title}/>
                <Box component="main"
                    className="Hoisting-box"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
                   

                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: 0 }}
                        className="UploadMusic-box3 create-virtualmeet"
                    >
                        <Container maxWidth="xl" >
                            <Grid container spacing={2} >
                                <Grid xs={12} sm={12} md={5} lg={7} xl={8} className="upload-grid" sx={{ p: 1 }}>
                                    <div className="description-UploadMusic create-virtual">
                                        <Button component="label" variant="contained" className="upload-btn" >
                                            <CloudUploadIcon />
                                            <div>
                                                Drag-and-drop a track here,<span className="go-violet size">or browse to upload</span>
                                            </div>
                                            <VisuallyHiddenInput type="file" />
                                        </Button>
                                        <Button className="set-tumb" >
                                            Set Tumbnail
                                        </Button>
                                    </div>


                                </Grid>
                                <Grid xs={12} sm={12} md={7} lg={5} xl={4} className="upload-grid2" >
                                    <div className="hoidting-form">
                                        <div>
                                            <h5>Title</h5>
                                            {err ? (<>
                                                <TextField
                                                    error
                                                    id="outlined-error-helper-text"
                                                    ref={inputRef}
                                                    // defaultValue="Hello World"
                                                    // helperText="Incorrect entry."
                                                    onChange={((e) => { setValidate(e.target.value) })}
                                                    placeholder="Your title here"
                                                />
                                            </>) : (<>
                                                <TextField

                                                    id="outlined-error-helper-text"
                                                    ref={inputRef}
                                                    // defaultValue="Hello World"
                                                    // helperText="Incorrect entry."
                                                    onChange={((e) => { setValidate(e.target.value) })}
                                                />
                                            </>)}
                                        </div>
                                        <div>
                                            <h5>Set Token To Participate</h5>
                                            {err ? (<>
                                                <TextField
                                                    error
                                                    id="outlined-error-helper-text"
                                                    ref={inputRef}
                                                    // defaultValue="Hello World"
                                                    // helperText="Incorrect entry."
                                                    placeholder="Amount"
                                                    onChange={((e) => { setValidate(e.target.value) })}
                                                />
                                            </>) : (<>
                                                <TextField

                                                    id="outlined-error-helper-text"
                                                    ref={inputRef}
                                                    placeholder="Amount"
                                                    // defaultValue="Hello World"
                                                    // helperText="Incorrect entry."
                                                    onChange={((e) => { setValidate(e.target.value) })}
                                                />
                                            </>)}
                                        </div>

                                        <div>
                                            <h5>Description</h5>
                                            {err ? (<>
                                                <TextField
                                                    error
                                                    id="outlined-error-helper-text"
                                                    ref={inputRef}
                                                    // defaultValue="Hello World"
                                                    // helperText="Incorrect entry."
                                                    onChange={((e) => { setValidate(e.target.value) })}
                                                />
                                            </>) : (<>
                                                <TextField

                                                    id="outlined-error-helper-text"
                                                    ref={inputRef}
                                                    // defaultValue="Hello World"
                                                    // helperText="Incorrect entry."
                                                    onChange={((e) => { setValidate(e.target.value) })}
                                                />
                                            </>)}
                                        </div>
                                        <div>
                                            <h5>Event Date And Time <span className="red">(Enter numbers only)</span></h5>
                                            <div className="start-end-time-main">
                                                <div className="start-time">
                                                    Start Time:
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                        <DemoContainer components={['DateTimeField']} className='start-time-date'>
                                                            <DateTimeField />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                                <div className="start-time">
                                                    End Time:
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} className='start-time-date'>
                                                        <DemoContainer components={['DateTimeField']}>
                                                            <DateTimeField />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="create-room">
                                            <Button>
                                                Create
                                            </Button>
                                        </div>
                                    </div>
                                </Grid>


                            </Grid>
                        </Container>


                        {/* 
                        <div>
                            <Track />
                        </div> */}
                    </Box>
                </Box>

            </Box>
        </div>
    );
}

export default CreateVirtualMeet;