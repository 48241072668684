import React, { useEffect, useState, useRef } from 'react'
import './LoginNew.css'
import dmlogo from '../../img/dwhite.png'
// import dmlogo from '../../img/d.dark.png'
import lognew from '../../img/lognew.png'
import { Button, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SmartScreen } from '@mui/icons-material'
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Axios from '../../Axios'
import consts from '../../Constant'
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function RestPaswd() {

    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));
    const lgScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const smScreen = useMediaQuery(theme.breakpoints.up('sm'));


    const navigate = useNavigate()
    const [select, setSelect] = useState(null)
    const handleSelect = (val) => {
        setSelect(val)
        const timer = setTimeout(() => {
            setScreen(!screen)
        }, 400);

        return () => clearTimeout(timer);

    }


    const [chk, setChk] = useState(false)

    const handleChk = () => {
        setChk(!chk)
    }

    const [screen, setScreen] = useState(false)

    const handleScreen = () => {
        setScreen(!screen)
    }

    const [otp, setOtp] = useState(false)

    const handleOtp = () => {
        setOtp(!otp)
    }

    //new pass integration

    const params = useParams();
    const tokens = params.token;
    const path = useLocation();

    const [password, setPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordone, setShowPasswordone] = useState(false);
    const [passworderr, setpassworderr] = useState(null);
    const [cpassworderr, setcpassworderr] = useState(null);
    const [checkBoxerror, setCheckBoxerror] = useState("")
    const inpassref = useRef(null);
    const inputconfrimRef = useRef(null);
    const isChecked = useRef(false);
    const captchaRef = useRef(null)
    const [load, setLoad] = useState(false)

    const handleClickPassword = () => setPassword((show) => !show);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPasswordone = () => setShowPasswordone((show) => !show);

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePasswordChange = () => {
        const passwordregex = new RegExp(
            /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/,
            "gm"
        );
        const inputValue = inpassref.current.value;

        if (inputValue.trim() === "") {
            setpassworderr("");
        } else if (!passwordregex.test(inputValue)) {
            setpassworderr("*Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abc@1234");
        } else {
            setpassworderr("");
        }
    };

    const handleConfirmPasswordChange = () => {
        if (inputconfrimRef.current.value !== inpassref.current.value) {
            setcpassworderr("*Password do not match");
        } else if (inputconfrimRef === inpassref) {
            setcpassworderr("");
        } else {
            setcpassworderr("");
        }
    };

    useEffect(() => {
        inpassref?.current?.focus();
    }, []);

    const resetPassword = async () => {
        try {
            const passwordregex = new RegExp(
                /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/,
                "gm"
            );
            if (inpassref.current.value.trim() === "") {
                setpassworderr("*Please Enter a Password");
            } else if (!passwordregex.test(inpassref.current.value)) {
                setpassworderr("*Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abc@1234");
            } else if (inputconfrimRef.current.value.trim() === "") {
                setcpassworderr("*Please Confirm Your Password");
            } else if (inputconfrimRef.current.value !== inpassref.current.value) {
                setcpassworderr("*Password & ConfirmPassword not match");
            }
            // else if (!isChecked.current.checked){
            //   setCheckBoxerror("Accept Our Privacy Policy By Clicking The Check Box")
            // }
            else if (!captchaRef.current.getValue()) {
                setCheckBoxerror("Please Verify Your A Human")
            }
            else {
                setLoad(true)
                const newPassword = {
                    newPassword: inpassref.current.value,
                };

                await Axios.post(`/users/updateuserpassword`, newPassword, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            toast.success(res?.data?.message)
                            setTimeout(() => {
                                navigate(`/`, { state: { passwordUpdated: 0 } });
                            }, 2000)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoad(false)
                        navigate(`/`, { state: { passwordUpdated: 1 } });
                    });
            }
        } catch (error) {
            setLoad(false)
            console.log(error, "error");
        }
    };


    const handleCheckBox = () => {
        setCheckBoxerror(" ")
    }

    return (
        <>
            <ToastContainer />
            <div className='registerNew'>
                <Grid container style={{ justifyContent: 'center' }}>
                    <Grid xs={12} sm={12} md={12} lg={10} xl={7} className={`${!mdScreen && 'grd-bcg'} grd2`}>
                        <Grid container style={{ justifyContent: 'center', height: !mdScreen && '100%', alignItems: !mdScreen && 'center', backdropFilter: !mdScreen && 'blur(10px)' }}>
                            <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
                                <div className={!mdScreen ? "dmlogo text-center" : "dmlogo"}>
                                    <img src={dmlogo} alt="dmlogo" />
                                </div>

                                <div className={!mdScreen ? "margin-top text-center" : "margin-top"}>
                                    <div className="welcm">
                                        Reset Your Password 👋
                                    </div>

                                </div>


                                <div className="margin-top" style={{ width: '80%' }}>
                                    <div className="fName">
                                        New Password
                                    </div>
                                    <div className={!mdScreen && "text-center"}>
                                        <FormControl variant="outlined" className='logenew-inp'>
                                            {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                inputRef={inpassref}
                                                onChange={() => {
                                                    handlePasswordChange();
                                                }}
                                                placeholder='Password'
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <Visibility sx={{ fill: '#49475A' }} /> : <VisibilityOff sx={{ fill: '#49475A' }} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            // label="Password"
                                            />
                                        </FormControl>
                                    </div>
                                    {passworderr && <div className="errorDiv" style={{ textAlign: "center" }}>{passworderr}</div>}
                                </div>

                                <div className="margin-top" style={{ width: '80%' }}>
                                    <div className="fName">
                                        Confirm Password
                                    </div>
                                    <div className={!mdScreen && "text-center"}>
                                        <FormControl variant="outlined" className='logenew-inp'>
                                            {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                inputRef={inputconfrimRef}
                                                onChange={() => {
                                                    handleConfirmPasswordChange();
                                                }}
                                                placeholder='Password'
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <Visibility sx={{ fill: '#49475A' }} /> : <VisibilityOff sx={{ fill: '#49475A' }} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            // label="Password"
                                            />
                                        </FormControl>
                                    </div>
                                    {cpassworderr && <div className="errorDiv" style={{ textAlign: "center" }}>{cpassworderr}</div>}
                                </div>
                                <div className="check-agree">
                                    <ReCAPTCHA sitekey={consts.sitekey} ref={captchaRef} onChange={() => { handleCheckBox() }} />
                                </div>
                                {checkBoxerror && <div className="errorDiv" style={{ textAlign: "center" }}>{checkBoxerror}</div>}
                                <div className="margin-top ">


                                    <div className="margin-top regis" >
                                        {
                                            load ?
                                                <Button>Processing...</Button> :
                                                <Button onClick={() => { resetPassword() }}>Submit</Button>
                                        }
                                    </div>


                                </div>

                            </Grid>
                            {mdScreen && <Grid xs={12} sm={12} md={6} lg={6} xl={6}>

                                <div className="lognew">
                                    <img src={lognew} alt="lognew" style={{ width: !lgScreen && '100%' }} />
                                </div>

                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </div >
        </>
    )
}

export default RestPaswd
