import { Box, Grid } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import SidebarNew from '../SidebarNew/SidebarNew'
import Recent from '../Recent/Recent';
import './PlayerNew.css'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CircularProgress from '@mui/material/CircularProgress';
import PauseIcon from '@mui/icons-material/Pause';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import pasue from '../../img/pause.png'
import playnew from '../../img/playnew.png'
import Musiccontrol from '../Music-Control/Musiccontrol';
import { useTheme, useMediaQuery } from '@mui/material';
import uploadImf from '../../img/uploadimg.png';
import mp3 from '../../img/mp3.png';
import LinearProgress from '@mui/material/LinearProgress';
import { useLocation, useNavigate } from 'react-router-dom';
import ava1 from "../../img/ava1.jpg";
import Axios from "../../Axios";
import { ToastContainer, toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import Companylogo1 from "../../img/musicwave.gif";
import FavoriteIcon from "@mui/icons-material/Favorite";
import nodta from '../../img/nodata.gif';
import BottomBar from '../BottomBar/BottomBar';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const drawerWidth = 300;

const options = {
    margin: 30,
    items: 4,
    responsiveClass: true,
    //  nav: true,
    autoplay: true,
    autoplayTimeout: 2000,
    dots: false,
    loop: true,
    smartSpeed: 2000,
    autoHeightClass: "owl-height",
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 4,
        },
    },
};



function PlayerNew() {

    const [playload, setPlayload] = useState(false)

    // const handlePlayLoad = () => {

    //     setPlayload(true)
    //     const timer = setTimeout(() => {
    //         setPlayload(false)
    //     }, 3000);

    //     return () => clearTimeout(timer);
    // }




    const [playCard, setPlaycard] = useState([
        { img: 'https://imgs.search.brave.com/gTGcFA5drHatTMbjYOP3X-CROolnU7u_bxbi4rdlrWg/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9y/ZWxheGVkLWFmcmlj/YW4tZ2lybC13aXRo/LWJpZy1leWVzLXNt/aWxpbmctYXBwZWFs/aW5nLWxhZHktbGlz/dGVuaW5nLW11c2lj/LXdoaXRlXzE5NzUz/MS0xMTI4MS5qcGc_/c2l6ZT02MjYmZXh0/PWpwZw' },
        { img: "https://imgs.search.brave.com/HvqPR3gn8GFFQ9J8-sokBi0jvSJWz364dV9mPZGoUyc/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/cHJlbWl1bS1waG90/by9hZnJpY2FuLXdv/bWFuLXNpbmdpbmct/d2hpbGUtbGlzdGVu/aW5nLW11c2ljLWhl/YWRwaG9uZXMtaXNv/bGF0ZWQtZ3JleS1i/YWNrZ3JvdW5kLWNv/cHktc3BhY2VfNjEz/NjUyLTQ0MC5qcGc_/c2l6ZT02MjYmZXh0/PWpwZw" },
        { img: 'https://imgs.search.brave.com/krYFxbma7Nk_tDb7WxmAkBP-Yq03lI-oc7LR-gmBr_Q/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9w/bGVhc2VkLWdvb2Qt/bG9va2luZy1naXJs/LXdpdGgtY3VybHkt/YWZyby1oYWlyLWNs/b3Nlcy1leWVzLWVt/YnJhY2VzLWhlcnNl/bGYtbGlzdGVucy1t/dXNpY18yNzM2MDkt/NDU3NDYuanBnP3Np/emU9NjI2JmV4dD1q/cGc' },
        { img: "https://imgs.search.brave.com/krx_xPUZGcu2ldKw1qreusemorEtlIMuaqCo4SPdnZY/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by93/aW5zb21lLWNhcmVm/cmVlLWJsYWNrLWdp/cmwtbGlzdGVuaW5n/LW11c2ljLXN0dWRp/by1zaG90LWpvY3Vu/ZC1hZnJpY2FuLWZl/bWFsZS1tb2RlbC1w/b3NpbmctaGVhZHBo/b25lc18xOTc1MzEt/MTExNTkuanBnP3Np/emU9NjI2JmV4dD1q/cGc" }
    ])
    const [custmPlay, setCustmplay] = useState([
        { img: 'https://imgs.search.brave.com/gTGcFA5drHatTMbjYOP3X-CROolnU7u_bxbi4rdlrWg/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9y/ZWxheGVkLWFmcmlj/YW4tZ2lybC13aXRo/LWJpZy1leWVzLXNt/aWxpbmctYXBwZWFs/aW5nLWxhZHktbGlz/dGVuaW5nLW11c2lj/LXdoaXRlXzE5NzUz/MS0xMTI4MS5qcGc_/c2l6ZT02MjYmZXh0/PWpwZw' },
        { img: "https://imgs.search.brave.com/HvqPR3gn8GFFQ9J8-sokBi0jvSJWz364dV9mPZGoUyc/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/cHJlbWl1bS1waG90/by9hZnJpY2FuLXdv/bWFuLXNpbmdpbmct/d2hpbGUtbGlzdGVu/aW5nLW11c2ljLWhl/YWRwaG9uZXMtaXNv/bGF0ZWQtZ3JleS1i/YWNrZ3JvdW5kLWNv/cHktc3BhY2VfNjEz/NjUyLTQ0MC5qcGc_/c2l6ZT02MjYmZXh0/PWpwZw" },
        { img: 'https://imgs.search.brave.com/krYFxbma7Nk_tDb7WxmAkBP-Yq03lI-oc7LR-gmBr_Q/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9w/bGVhc2VkLWdvb2Qt/bG9va2luZy1naXJs/LXdpdGgtY3VybHkt/YWZyby1oYWlyLWNs/b3Nlcy1leWVzLWVt/YnJhY2VzLWhlcnNl/bGYtbGlzdGVucy1t/dXNpY18yNzM2MDkt/NDU3NDYuanBnP3Np/emU9NjI2JmV4dD1q/cGc' },
        { img: "https://imgs.search.brave.com/krx_xPUZGcu2ldKw1qreusemorEtlIMuaqCo4SPdnZY/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by93/aW5zb21lLWNhcmVm/cmVlLWJsYWNrLWdp/cmwtbGlzdGVuaW5n/LW11c2ljLXN0dWRp/by1zaG90LWpvY3Vu/ZC1hZnJpY2FuLWZl/bWFsZS1tb2RlbC1w/b3NpbmctaGVhZHBo/b25lc18xOTc1MzEt/MTExNTkuanBnP3Np/emU9NjI2JmV4dD1q/cGc" },
        { img: 'https://imgs.search.brave.com/gTGcFA5drHatTMbjYOP3X-CROolnU7u_bxbi4rdlrWg/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9y/ZWxheGVkLWFmcmlj/YW4tZ2lybC13aXRo/LWJpZy1leWVzLXNt/aWxpbmctYXBwZWFs/aW5nLWxhZHktbGlz/dGVuaW5nLW11c2lj/LXdoaXRlXzE5NzUz/MS0xMTI4MS5qcGc_/c2l6ZT02MjYmZXh0/PWpwZw' },
        { img: "https://imgs.search.brave.com/krx_xPUZGcu2ldKw1qreusemorEtlIMuaqCo4SPdnZY/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by93/aW5zb21lLWNhcmVm/cmVlLWJsYWNrLWdp/cmwtbGlzdGVuaW5n/LW11c2ljLXN0dWRp/by1zaG90LWpvY3Vu/ZC1hZnJpY2FuLWZl/bWFsZS1tb2RlbC1w/b3NpbmctaGVhZHBo/b25lc18xOTc1MzEt/MTExNTkuanBnP3Np/emU9NjI2JmV4dD1q/cGc" },
        { img: 'https://imgs.search.brave.com/krYFxbma7Nk_tDb7WxmAkBP-Yq03lI-oc7LR-gmBr_Q/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9w/bGVhc2VkLWdvb2Qt/bG9va2luZy1naXJs/LXdpdGgtY3VybHkt/YWZyby1oYWlyLWNs/b3Nlcy1leWVzLWVt/YnJhY2VzLWhlcnNl/bGYtbGlzdGVucy1t/dXNpY18yNzM2MDkt/NDU3NDYuanBnP3Np/emU9NjI2JmV4dD1q/cGc' },
    ])
    const [play, setPlay] = useState(false)


    const [playingIndex, setPlayingIndex] = useState();
    const [playingIndex1, setPlayingIndex1] = useState();
    // console.log(playingIndex, 'playingindex');

    const location = useLocation()
    let result = location?.state?.data
    const [data, setData] = useState(result)
    const fileSize = location?.state?.filesize
    // console.log(location?.state?.data, 'data');
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isLgDn = useMediaQuery(theme.breakpoints.up('lg'));
    // console.log(isLgUp, 'isLgUp');
    const childRef = useRef();
    const [value, setValue] = React.useState(0);
    const [limit, setLimit] = React.useState(5);
    const [limit1, setLimit1] = React.useState(4);
    const [name, setName] = React.useState();
    const [music, setMusic] = React.useState([]);
    const [recent, setRecent] = React.useState([]);
    const [count, setCount] = React.useState();
    const [see, setSee] = useState(true);
    const [topmusic, setTopmusic] = React.useState([]);
    const [audioDurations, setAudioDurations] = useState([]);
    const [audioplays, setAudioplays] = useState([]);
    const [audiosource, setAudiosource] = React.useState();
    const [MusicData, setMusicData] = useState()
    const [isplay, setisplay] = useState(false);
    const [index, setIndex] = useState()
    const [exp, setExp] = useState(false);
    const [values, setValues] = React.useState(true);
    const [component, setComponent] = useState()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [audioSourceArr, setAudioSourceArr] = useState()
    const [audioSourceArr1, setAudioSourceArr1] = useState()
    const [type, setType] = useState()
    // console.log(audioSourceArr, 'audioSourceArr');
    const [tracks, setTracks] = useState(0)
    const [tracks1, setTracks1] = useState(0)
    const [start, setStart] = useState(false)
    const [topMusicStart, setTopMusicStart] = useState(false)
    const [recentPlayStart, setRecentPlayStart] = useState(false)
    const tokens = localStorage.getItem("iphephile");
    const audio = localStorage.getItem("audiosource");
    const date = new Date()
    const tabContent = ["All", "original", "repost"];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClick = () => {
        if (childRef.current) {
            const event = { type: 'click' };  // Example event object
            childRef.current.toggleDrawer(true)(event);
        }
    };

    const handlePlayPause = (index) => {
        setPlayingIndex(index)
        // setPlayingIndex(index === playingIndex ? null : index);
        // console.log('pause', index, playingIndex);
        if (!isLgDn === true) {
            handleClick()
        }
        // handleRenderComp()

    };

    const handlePlayPause1 = (index) => {
        setPlayingIndex1(index)
    }

    const [progress, setProgress] = useState(0);
    // console.log(progress, 'progress');

    useEffect(() => {
        if (data != null) {
            const timer = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress === 100) {
                        setTimeout(() => {
                            setData(null)
                            navigate(location.pathname, { replace: true, state: null });
                        }, 2000)

                        return 100;
                    }
                    const diff = Math.random() * 10;
                    return Math.min(Math.floor(oldProgress + diff), 100);
                });
            }, 500);

            return () => {
                clearInterval(timer);
            }
        }
    }, []);

    const getAudioDuration = async (audioSrc) => {
        try {
            const audio = new Audio(audioSrc);
            await audio.addEventListener("loadedmetadata", () => {
                const duration = audio.duration;
                const durationInSeconds = audio.duration;
                const durationInMinutes = (durationInSeconds / 60).toFixed(2);
                setAudioDurations((prev) => ({
                    ...prev,
                    [audioSrc]: durationInMinutes,
                }));
            });
        } catch (error) {
            console.error(error);
            setAudioDurations((prev) => ({
                ...prev,
                [audioSrc]: 0, // Default duration or handle error accordingly
            }));
        }
    };

    // console.log(audioDurations, 'audioDurations');
    // const fileName = result?.uploadedFiles?.[0]?.name || "No file uploaded";

    // const fileSize = (`${(result?.uploadedFile?.[0]?.size)} MB`)

    const seeMore = () => {

        if (see == false) {
            // music.slice(0,5)
            setLimit1(4);
        } else {
            setLimit1(20);
        }

        setSee(!see);
    };

    const changeLimit = () => {
        if (exp === false) {
            setLimit(34);
        } else {
            setLimit(5);
        }
        setExp(!exp);
    };

    const getMusic = async () => {
        try {
            // setLoading(true)
            const datas = await Axios.post(
                "/users/get_user_music",
                {
                    status: tabContent[value],
                    // limit: limit1,
                    name: name,
                },
                {
                    headers: {
                        Authorization: tokens,
                    },
                }
            )
                .then((res) => {
                    if (res?.data?.success) {
                        // setLoading(false)
                        setMusic(res?.data?.result);
                        setRecent(res?.data?.recentList);
                        const audioArray = res?.data?.recentList?.map(item => ({
                            _id: item._id,
                            audio: item.audio,
                            song_name: item.song_name,
                            name: item.createUser.name,
                            image: item.image,
                            repeat: item.repeat,
                            shuffle: item?.shuffle,
                            fav: item?.userFav
                        }));
                        setAudioSourceArr1(audioArray)
                        setCount(res?.data?.count);
                    } else {
                        // setLoading(false)
                        setMusic([]);
                        setRecent([]);
                    }
                })
                .catch((err) => {
                    // setLoading(false)
                    // console.log(err,"err")
                });
        } catch (error) {
            // setLoading(false)
            console.log(error, "error");
        }
    };
    // console.log(result.uploadedFiles?.[0]?.size, 'size');


    const getMusics = async () => {
        try {
            // setLoading(true)
            const datas = await Axios.post(
                "/users/get_user_musics",
                {
                    status: tabContent[value],
                    // limit: limit1,
                    name: name,
                },
            )
                .then((res) => {
                    if (res?.data?.success) {
                        // setLoading(false)
                        setMusic(res?.data?.result);
                        setRecent(res?.data?.recentList);
                        const audioArray = res?.data?.recentList?.map(item => ({
                            _id: item._id,
                            audio: item.audio,
                            song_name: item.song_name,
                            name: item.createUser.name,
                            image: item.image,
                            repeat: item.repeat,
                            shuffle: item?.shuffle,
                            fav: item?.userFav
                        }));
                        setAudioSourceArr1(audioArray)
                        setCount(res?.data?.count);
                    } else {
                        // setLoading(false)
                        setMusic([]);
                        setRecent([]);
                    }
                })
                .catch((err) => {
                    // setLoading(false)
                    // console.log(err,"err")
                });
        } catch (error) {
            console.log(error, "error");
        }
    };
    const getTopmusic = async () => {
        try {
            // setLoading(true)
            const find_data = {
                explore: "Top Stream",
                name: name,
            };
            const datas = await Axios.post("/users/get_user_favourite", find_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        // setLoading(false)
                        setTopmusic(res?.data?.result);
                        const audioArray = res?.data?.result?.map(item => ({
                            _id: item?._id,
                            audio: item?.audio,
                            song_name: item?.song_name,
                            name: item?.createUser.name,
                            image: item?.image,
                            repeat: item?.repeat,
                            shuffle: item?.shuffle,
                            fav: item?.userFav
                        }));
                        setAudioSourceArr(audioArray)
                    } else {
                        //toast.error(err?.response?.data?.message)
                        // setLoading(false)
                        setTopmusic([]);
                    }
                })
                .catch((err) => {
                    // console.log(err, "errsss")
                    setTopmusic([]);
                    // setLoading(false)
                    if (err?.response?.data?.message == "Admin Blocked You") {
                        // setLoading(false)
                        localStorage.clear();
                        navigate('/');
                        toast.error('Admin Blocked You');
                    }
                });
        } catch (error) {
            // console.log(error, "error");
        }
    };

    const getTopmusics = async () => {
        try {
            // setLoading(true)
            const find_data = {
                explore: "Top Stream",
                name: name,
            };
            const datas = await Axios.post("/users/get_user_favourites", find_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        // setLoading(false)
                        setTopmusic(res?.data?.result);
                        const audioArray = res?.data?.result?.map(item => ({
                            _id: item._id,
                            audio: item.audio,
                            song_name: item.song_name,
                            name: item.createUser.name,
                            image: item.image,
                            repeat: item.repeat,
                            shuffle: item?.shuffle,
                            fav: item?.userFav
                        }));
                        setAudioSourceArr(audioArray)
                    } else {
                        //toast.error(err?.response?.data?.message)
                        // setLoading(false)
                        setTopmusic([]);
                    }
                })
                .catch((err) => {
                    // console.log(err, "err")
                    setTopmusic([]);
                    // setLoading(false)
                    if (err?.response?.data?.message == "Admin Blocked You") {
                        // setLoading(false)
                        localStorage.clear();
                        navigate('/');
                        toast.error('Admin Blocked You');
                    }
                });
        } catch (error) {
            // setLoading(false)
            // console.log(error, "error");
        }
    };

    const getaudioPlays = async (audioSrc, index) => {
        // setComponent('topmusic')
        try {
            if (audioSrc === localStorage.getItem("songName")) {
                setAudioplays((prev) => ({
                    ...prev,
                    [index]: 1,
                }));
            } else {
                setAudioplays((prev) => ({
                    ...prev,
                    [index]: 0,
                }));
            }
        } catch (error) {
            console.error(error);
            setAudioplays((prev) => ({
                ...prev,
                [index]: 0,
            }));
        }
    };

    useEffect(() => {
        if (tokens) {
            getMusic();
            getTopmusic();
        } else {
            getMusics();
            getTopmusics();
        }
    }, [name, start, topMusicStart, recentPlayStart, limit, limit1, type]);

    useEffect(() => {
        if (music.length > 0) {
            music.forEach((row) => {
                getAudioDuration(row?.audio);
                // getPreAudioDuration(row?.pre_audio)
            });
        }
        // if (music.length > 0) {
        //     music.forEach((row, index) => {
        //         getaudioPlays1(row?.song_name, index);
        //     });
        // }
    }, [music]);
    useEffect(() => {
        setTracks(tracks !== undefined ? tracks : Number(localStorage.getItem("tracks")))
        if (topmusic.length > 0) {
            topmusic.forEach((row) => {
                getAudioDuration(row?.audio);
                // getPreAudioDuration(row?.pre_audio)
            });
        }
        if (topmusic.length > 0) {
            topmusic.forEach((row, index) => {
                getaudioPlays(row?.song_name, index);
            });
        }
    }, [topmusic]);

    useEffect(() => {
        if (topmusic.length > 0) {
            topmusic.forEach((row, index) => {
                getaudioPlays(row?.song_name, index);
            });
        }
    }, [values]);

    useEffect(() => {
        if (recent.length > 0) {
            recent.forEach((row) => {
                getAudioDuration(row?.audio);
            });
        }
    }, [recent]);

    const createMusic = async (data) => {
        try {
            const create_data = {
                song_id: data,
            };

            await Axios.post("/users/add_history", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        // getMusic()
                    } else {
                        toast.error(res?.data?.message);
                    }
                })
                .catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message);
                });
        } catch (error) {
            // console.log(error, "error");
        }
    };


    const setAudio = (data, i) => {
        // console.log(start, topMusicStart, 'start');

        localStorage.setItem("audiosource", data.audio);
        // localStorage.setItem("name", data.createUser.name);
        // localStorage.setItem("songName", data.song_name);
        // localStorage.setItem("Image", data.image);
        // localStorage.setItem("index", i);
        localStorage.setItem("songName", audioSourceArr?.[i]?.song_name);
        localStorage.setItem("name", audioSourceArr?.[i]?.name);
        localStorage.setItem("Image", audioSourceArr?.[i]?.image);
        localStorage.setItem("current", 0);
        localStorage.setItem("tracks", i);
        localStorage.setItem("isPause", false);
        setMusicData(data._id)
        setAudiosource(data.audio);
        localStorage.setItem("playsong", "false");
        setValues(!values);
        setisplay(true);
        setComponent('topmusic')
    };

    // console.log(component, 'component');


    const addFavorite = async (data) => {
        try {
            if (tokens) {
                const create_data = {
                    song_id: data,
                };

                await Axios.post("/users/add_to_favourite", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            getMusic();
                            getTopmusic();
                        } else {
                            // toast.error(res?.data?.message);
                        }
                    })
                    .catch((err) => {
                        // console.log(err, "err")
                        // toast.error(err?.response?.data?.message);
                    });
            }
            else {
                toast.error("Please Login to add this Song to your Favourite")
            }
        } catch (error) {
            // console.log(error, "error");
        }
    };

    const addRecentPlay = async (data) => {
        const create_data = {
            song_id: data,
        };
        try {
            if (tokens) {
                const response = await Axios.post('/users/addRecentPlays', create_data, {
                    headers: {
                        Authorization: tokens
                    }
                })
                if (response.data.success) {
                    getMusic()
                }
            }
            else {
                console.log('please login');
            }
        } catch (error) {
            console.log(error, 'er');
        }
    }

    const [isMobiles, setIsMobiles] = useState(false);

    // console.log(isMobiles, 'ismobiles');

    // Function to toggle isMobile state
    const toggleIsMobile = () => {
        setIsMobiles((prev) => !prev);
    };

    // const navigation = (row, id) => {
    //     console.log(row, id, 'navigation');

    //     if (row === "album") {
    //         navigate(`/albumpage/${id}`);
    //     } else if (row === "track") {
    //         navigate(`/trackpage/${id}`);
    //     } else if (row === "playlist") {
    //         navigate(`/showplaylist/${id}`);
    //     }
    // };


    useEffect(() => {
        if (location?.state?.login == 0) {
            toast.error('Login to continue')
        }
    }, [location?.state?.login])

    const sideHeight = localStorage.getItem("isPause")

    // console.log(sideHeight, 'sideheight');
    


    return (
        <>
            <ToastContainer autoClose={3000} position="top-right" />
            <div className='playernew' >
                <Box sx={{ display: isLgUp ? 'flex' : 'block' }}>
                    <SidebarNew name={name} setName={setName} audiosource={audioSourceArr} data={data} />
                    <Box
                        component="main"
                        // className="explore-box"
                        sx={{
                            flexGrow: 1,
                            py: 1,
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                            // height: '125vh',
                            marginTop: "80px",
                            marginBottom: audio === '' ? '0px' : audio === undefined ? '0px' : '0px',
                        }}
                    >

                        <Grid container spacing={0} >
                            <Grid
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={9}

                            >
                                <div style={{ padding: "15px" }}>
                                    {data != null && <>
                                        <div className='display-1'>
                                            <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.28571 23.2853C7.65265 23.2853 9.57143 21.3665 9.57143 18.9996C9.57143 16.6326 7.65265 14.7139 5.28571 14.7139C2.91878 14.7139 1 16.6326 1 18.9996C1 21.3665 2.91878 23.2853 5.28571 23.2853Z" stroke="url(#paint0_linear_3889_4513)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.57129 19V1C10.8095 1 12.0355 1.24388 13.1794 1.71771C14.3234 2.19154 15.3628 2.88604 16.2383 3.76156C17.1138 4.63709 17.8083 5.67649 18.2822 6.82041C18.756 7.96434 18.9999 9.19039 18.9999 10.4286V10.4286" stroke="url(#paint1_linear_3889_4513)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_3889_4513" x1="-0.105121" y1="13.3952" x2="12.3346" y2="14.158" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#B5179E" />
                                                        <stop offset="1" stop-color="#7209B7" />
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_3889_4513" x1="8.35566" y1="-1.76924" x2="22.0767" y2="-1.32851" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#B5179E" />
                                                        <stop offset="1" stop-color="#7209B7" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>

                                            <div className='discover-genre'>
                                                Uploading
                                            </div>

                                        </div>
                                        <div className='uploadingplayerdiv display-1'>
                                            <div className='uploadImf'>
                                                {/* <img src={result?.imageUrl ? result?.imageUrl : uploadImf} alt='add' /> */}
                                                <img src={data?.image ? data?.image : ava1} alt='add' />
                                            </div>
                                            <div style={{ width: '80%' }}>
                                                <div className='uploaded-music' >
                                                    {/* <div className='text-right closeup' >
                                            <svg width="15" height="15" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.760196 6.28427L6.28493 0.759539L7.38416 1.85876L1.85942 7.3835L0.760196 6.28427ZM0.764282 1.85876L1.86351 0.759539L7.38824 6.28427L6.28902 7.3835L0.764282 1.85876Z" fill="var(--clr-font)" />
                                            </svg>
                                        </div> */}
                                                    <div className='display-2'>
                                                        <div className='display-1'>
                                                            <img src={mp3} alt="MP3 Icon" />
                                                            <div className='upsongname'>
                                                                {/* {fileName.length > 20 ? fileName.slice(0, 20) + "..." : fileName} */}
                                                                {data?.music_type == 'track' ?
                                                                    data?.song_name > 15 ? data?.song_name.slice(0, 15) + '...' : data?.song_name ? data?.song_name : "No file uploaded" :
                                                                    data?.music_type == 'album' ?
                                                                        data?.album_name > 15 ? data?.album_name.slice(0, 15) + '...' : data?.album_name ? data?.album_name : "No file uploaded" :
                                                                        data?.playlist > 15 ? data?.playlist.slice(0, 15) + '...' : data?.playlist ? data?.playlist : "No file uploaded"
                                                                }
                                                                {/* {data?.song_name > 15 ? data?.song_name.slice(0, 15) + '...' : data?.song_name ? data?.song_name : "No file uploaded"} */}
                                                            </div>
                                                        </div>
                                                        {progress === 100 ? <div className='display-1'>
                                                            <div className='green'>
                                                                100%
                                                            </div>
                                                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="20" cy="20" r="20" fill="#00B477" />
                                                                <path d="M14 20.0332L18.7238 25.6229L29.0498 16.0006" stroke="white" stroke-width="2" stroke-linecap="round" />
                                                            </svg>

                                                        </div>
                                                            : <div className='uploaded-size'>
                                                                {/* {fileSize} */}
                                                                {data?.musci_type == 'track' ? fileSize ? `${(fileSize / 1024 / 1024).toFixed(2)} MB` : 'Unknown size' : null}
                                                            </div>

                                                        }

                                                    </div>

                                                </div>
                                                <Box sx={{ width: '100%', margin: '15px 0' }} className="uploadprogess">
                                                    <LinearProgress variant="determinate" value={progress} />
                                                    <div className="compe text-center">
                                                        {progress}%
                                                        <div>
                                                            Completed
                                                        </div>
                                                    </div>
                                                </Box>
                                            </div>
                                        </div>
                                    </>}
                                    {
                                        data == null ?
                                            <>
                                                <div className='display-1'>
                                                    <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.28571 23.2853C7.65265 23.2853 9.57143 21.3665 9.57143 18.9996C9.57143 16.6326 7.65265 14.7139 5.28571 14.7139C2.91878 14.7139 1 16.6326 1 18.9996C1 21.3665 2.91878 23.2853 5.28571 23.2853Z" stroke="url(#paint0_linear_3889_4513)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M9.57129 19V1C10.8095 1 12.0355 1.24388 13.1794 1.71771C14.3234 2.19154 15.3628 2.88604 16.2383 3.76156C17.1138 4.63709 17.8083 5.67649 18.2822 6.82041C18.756 7.96434 18.9999 9.19039 18.9999 10.4286V10.4286" stroke="url(#paint1_linear_3889_4513)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_3889_4513" x1="-0.105121" y1="13.3952" x2="12.3346" y2="14.158" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#B5179E" />
                                                                <stop offset="1" stop-color="#7209B7" />
                                                            </linearGradient>
                                                            <linearGradient id="paint1_linear_3889_4513" x1="8.35566" y1="-1.76924" x2="22.0767" y2="-1.32851" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#B5179E" />
                                                                <stop offset="1" stop-color="#7209B7" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>

                                                    <div className='discover-genre'>
                                                        {/* discover genre */} New Release
                                                    </div>
                                                    <div
                                                        className="new-release-btns"
                                                        onClick={() => {
                                                            seeMore();
                                                        }}
                                                    >
                                                        {recent?.length > 0 ? (
                                                            <>{see ? "See More" : "See Less"} </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                                <Grid container spacing={0}>
                                                    {/* {playCard.map((row, i) => { */}
                                                    {
                                                        recent?.length > 0 ?
                                                            recent?.slice(0, limit1).map((row, ind) => {
                                                                return (
                                                                    // date >= new Date(row?.publish) ?
                                                                    <Grid
                                                                        xs={12}
                                                                        sm={6}
                                                                        md={4}
                                                                        lg={4}
                                                                        xl={3}
                                                                    // sx={{ padding: "15px" }}

                                                                    >

                                                                        <div className='player-carosuel'>
                                                                            {/* <OwlCarousel className='owl-theme' loop margin={8} nav     {...options}> */}

                                                                            {/* </OwlCarousel> */}
                                                                            <div class="card"
                                                                            // style={{
                                                                            //     backgroundImage: `url(${row?.image && !row?.image.includes("localhost")
                                                                            //         ? row?.image
                                                                            //         : ava1})`,
                                                                            // }}
                                                                            // onClick={() => {
                                                                            //     navigate(`/trackpage/${row._id}`);
                                                                            // }}
                                                                            >
                                                                                <button class="mail">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                                                        class="lucide lucide-mail">
                                                                                        <rect width="20" height="16" x="2" y="4" rx="2"></rect>
                                                                                        <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
                                                                                    </svg>
                                                                                </button>
                                                                                <div class="profile-pic">

                                                                                    <img
                                                                                        src={
                                                                                            row?.image && !row?.image.includes("localhost")
                                                                                                ? row?.image
                                                                                                : ava1
                                                                                        }
                                                                                        alt='img'
                                                                                        id="svg2" width="666.66669" height="666.66669" />
                                                                                </div>
                                                                                <div class="bottom">
                                                                                    <div class="content new"
                                                                                        onClick={() => { navigate(`/albumnew/${row._id}`) }}
                                                                                    // onClick={() => { navigate('/albumnew', { state: { row: row } }) }}
                                                                                    >
                                                                                        <div class="name"> {`${row?.song_name.length >= 15 ? (row?.song_name.slice(0, 15) + "...") : row?.song_name}`}</div>
                                                                                        <div class="about-me">{row?.createUser?.name}{" "}</div>
                                                                                    </div>
                                                                                    <div class="bottom-bottom">
                                                                                        <p class="heading display-2" >
                                                                                            <div className='acost' >
                                                                                                {/* {`${row?.song_name.length >= 12 ? (row?.song_name.slice(0, 12) + "...") : row?.song_name}`} */}
                                                                                                {row?.song_name.length > 15 ? `${row?.song_name.slice(0, 15)}...` : row?.song_name}
                                                                                                {/* <div className='acosttrack'>
                                                                                                    {`${recent?.length} tracks`}
                                                                                                </div> */}
                                                                                            </div>

                                                                                        </p>
                                                                                        <Box sx={{ position: 'relative', display: 'inline-flex' }}
                                                                                            // onClick={handlePlayLoad} 
                                                                                            key={ind}>
                                                                                            {/* {console.log(playingIndex1 != ind, topMusicStart, isplay, 'log')} */}
                                                                                            {/* {playingIndex1 != ind || isplay || topMusicStart && <CircularProgress disableShrink />} */}
                                                                                            {
                                                                                                playingIndex1 !== ind || isplay == false || topMusicStart || recentPlayStart ?
                                                                                                    null
                                                                                                    :
                                                                                                    isLgUp ?
                                                                                                        <CircularProgress disableShrink />
                                                                                                        : <></>
                                                                                            }
                                                                                            <Box
                                                                                                sx={{
                                                                                                    top: isplay == true ? 0 : 0,
                                                                                                    left: isplay == true ? 0 : -40,
                                                                                                    bottom: 0,
                                                                                                    right: 0,
                                                                                                    position: 'absolute',
                                                                                                    display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                    justifyContent: 'center',

                                                                                                }
                                                                                                }

                                                                                                className='pypas'
                                                                                                key={ind}
                                                                                            >
                                                                                                {/* {playload ? <PauseIcon /> : <PlayArrowIcon />} */}

                                                                                                {isLgUp && audioDurations[row?.audio] !==
                                                                                                    undefined ?
                                                                                                    playingIndex1 != ind ||
                                                                                                        isplay === false || topMusicStart || recentPlayStart ? (
                                                                                                        <PlayArrowIcon
                                                                                                            onClick={() => {
                                                                                                                setAudio(row, ind);
                                                                                                                createMusic(row?._id);
                                                                                                                setAudiosource(row?.audio);
                                                                                                                handlePlayPause1(ind)
                                                                                                                addRecentPlay(row?._id)
                                                                                                                setTracks1(ind)
                                                                                                                setType('new release')
                                                                                                                setStart(true)
                                                                                                                setTopMusicStart(false)
                                                                                                                setRecentPlayStart(false)
                                                                                                            }}
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <PauseIcon
                                                                                                            onClick={() => {
                                                                                                                setisplay(false);
                                                                                                                setPlayingIndex1()
                                                                                                                setType('new release')
                                                                                                                // setStart(false)
                                                                                                            }}
                                                                                                        />
                                                                                                    )
                                                                                                    :
                                                                                                    <></>
                                                                                                }

                                                                                            </Box>
                                                                                        </Box>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </Grid>
                                                                    // :
                                                                    // <>
                                                                    //     <p style={{ color: "white" }}>No Data Found</p>
                                                                    // </>
                                                                )
                                                            })
                                                            :
                                                            <Grid
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            // sx={{ padding: "15px" }}

                                                            >
                                                                <div className='nodta text-center'>
                                                                    <img src={nodta} alt="nodta" />
                                                                </div>
                                                            </Grid>
                                                    }
                                                </Grid>
                                            </>
                                            :
                                            <></>
                                    }
                                    <div className='display-2 pad-10 mg-top-30'>
                                        <div className='display-1'>
                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.85728 13H6.143C5.19622 13 4.42871 13.7675 4.42871 14.7143V21.5714C4.42871 22.5182 5.19622 23.2857 6.143 23.2857H7.85728C8.80406 23.2857 9.57157 22.5182 9.57157 21.5714V14.7143C9.57157 13.7675 8.80406 13 7.85728 13Z" stroke="url(#paint0_linear_3889_4519)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M18.1424 13H16.4282C15.4814 13 14.7139 13.7675 14.7139 14.7143V21.5714C14.7139 22.5182 15.4814 23.2857 16.4282 23.2857H18.1424C19.0892 23.2857 19.8567 22.5182 19.8567 21.5714V14.7143C19.8567 13.7675 19.0892 13 18.1424 13Z" stroke="url(#paint1_linear_3889_4519)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M1 16.4286V12.1429C1 9.18759 2.17398 6.35336 4.26367 4.26367C6.35336 2.17398 9.18759 1 12.1429 1C15.0981 1 17.9324 2.17398 20.022 4.26367C22.1117 6.35336 23.2857 9.18759 23.2857 12.1429V16.4286" stroke="url(#paint2_linear_3889_4519)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_3889_4519" x1="3.76564" y1="11.4176" x2="11.2505" y2="11.6471" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#B5179E" />
                                                        <stop offset="1" stop-color="#7209B7" />
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_3889_4519" x1="14.0508" y1="11.4176" x2="21.5357" y2="11.6471" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#B5179E" />
                                                        <stop offset="1" stop-color="#7209B7" />
                                                    </linearGradient>
                                                    <linearGradient id="paint2_linear_3889_4519" x1="-1.87331" y1="-1.37363" x2="30.3389" y2="1.47955" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#B5179E" />
                                                        <stop offset="1" stop-color="#7209B7" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>

                                            <div className='discover-genre'>
                                                top musics
                                            </div>

                                        </div>
                                        <div className='showmoewnew cursor' onClick={() => { changeLimit(); }}>
                                            {topmusic?.length > 0 ? (
                                                <>
                                                    {exp ? `Show Less <<` : `Show more >>`}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            {/* Show more {">>"} */}
                                        </div>
                                    </div>
                                    <div className='mg-top-30'>
                                        {/* {custmPlay.map((row, i) => {
                                            const isPlaying = i === playingIndex; */}
                                        {
                                            topmusic.length > 0 ?
                                                topmusic.slice(0, limit)?.map((row, i) => {
                                                    const isPlaying = i === playingIndex;

                                                    // { console.log(isPlaying, 'playing'); }
                                                    return (
                                                        <div key={i} className='display-2 play-card-new' style={{ boxShadow: isPlaying ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : null }} onClick={async () => {
                                                            if (!isLgUp) {
                                                                setAudio(row);
                                                                createMusic(row?._id);
                                                                setAudiosource(row?.audio);
                                                                handlePlayPause(i);
                                                                addRecentPlay(row?._id);
                                                                setTracks(i)
                                                            }
                                                        }}
                                                        >
                                                            <Stack direction="row" spacing={2} style={{ alignItems: 'center' }}>
                                                                <div
                                                                    className={`new-play-num ${!isPlaying ? 'playing' : ''}`}
                                                                >
                                                                    {/* {0} */}
                                                                    {i + 1}
                                                                </div>
                                                                {/* {console.log(playingIndex, isplay, isLgUp, 'i')}
                                                        {console.log(!isLgUp && playingIndex != i || isplay == false, 'condition')} */}

                                                                {/* {playingIndex != i || isplay == false ?
                                                                    <div className={`${isPlaying ? 'avatar-music2' : ''}`}>
                                                                        <Avatar alt="Remy Sharp" src={
                                                                            row?.image &&
                                                                                !row?.image?.includes("localhost")
                                                                                ? row?.image
                                                                                : ava1
                                                                        }
                                                                            sx={{ width: isLgUp ? 58 : 35, height: isLgUp ? 58 : 35 }} />
                                                                    </div>
                                                                    : */}

                                                                {!isLgUp && playingIndex == i ?
                                                                    <div>
                                                                        <div className="loading-wave">
                                                                            <div className="loading-bar"></div>
                                                                            <div className="loading-bar"></div>
                                                                            <div className="loading-bar"></div>
                                                                            <div className="loading-bar"></div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className={`${isPlaying ? 'avatar-music2' : ''}`}>
                                                                        <Avatar alt="Remy Sharp" src={
                                                                            row?.image &&
                                                                                !row?.image?.includes("localhost")
                                                                                ? row?.image
                                                                                : ava1
                                                                        }
                                                                            sx={{ width: isLgUp ? 58 : 35, height: isLgUp ? 58 : 35 }} />
                                                                    </div>}
                                                                {/* } */}
                                                                <div className={isPlaying ? 'marquee-container' : null}>
                                                                    <div className={`new-play-artist ${!isPlaying || start || recentPlayStart ? 'playing' : 'marquee-ply'}`} >
                                                                        {/* maroon 5 - memories */}
                                                                        {/* {row.artist} - {row.title} */}
                                                                        {row?.createUser?.name} - {row?.song_name.length > 15 ? `${row?.song_name.slice(0, 15)}...` : row?.song_name}

                                                                    </div>
                                                                </div>

                                                            </Stack>
                                                            <Stack direction="row" spacing={!isLgUp ? 2 : 4} style={{ alignItems: 'center' }}>
                                                                {/* {isLgUp || !isPlaying ? <div div className={`play-new-time ${!isPlaying ? 'playing' : ''}`} >
                                                            04.20
                                                        </div> : null} */}
                                                                {isLgUp && audioDurations[row?.audio] !==
                                                                    undefined ? (
                                                                    <div div className={`play-new-time ${!isPlaying || start || recentPlayStart ? 'playing' : ''}`} >{playingIndex != i || isplay == false || start || recentPlayStart ? audioDurations[row?.audio] : null}</div>
                                                                ) : (
                                                                    isLgUp && <span style={{ color: "white" }}>-</span>
                                                                )}
                                                                {/* {console.log(playingIndex != i, playingIndex, i, 'index')} */}
                                                                {
                                                                    playingIndex !== i || isplay == false || start || recentPlayStart ?
                                                                        null
                                                                        :
                                                                        isLgUp ?
                                                                            <div>
                                                                                <div className="loading-wave">
                                                                                    <div className="loading-bar"></div>
                                                                                    <div className="loading-bar"></div>
                                                                                    <div className="loading-bar"></div>
                                                                                    <div className="loading-bar"></div>
                                                                                </div>
                                                                            </div>
                                                                            : <></>
                                                                }

                                                                {isLgUp && playingIndex !== i || isplay == false || start || recentPlayStart ?
                                                                    null :
                                                                    <div>
                                                                        <Tooltip
                                                                            title={
                                                                                row?.userFav === true
                                                                                    ? "Remove From Favourite"
                                                                                    : "Add to Favourite"
                                                                            }
                                                                            placement="top"
                                                                        >
                                                                            <FavoriteIcon
                                                                                onClick={() => {
                                                                                    addFavorite(row?._id);
                                                                                }}
                                                                                className={
                                                                                    row?.userFav === true
                                                                                        ? "fav-heart"
                                                                                        : "normal-svg"
                                                                                }
                                                                            />
                                                                        </Tooltip>
                                                                    </div>
                                                                }
                                                                <div className='display-1 cursor'>

                                                                    {isLgUp && (audioDurations[row?.audio] !==
                                                                        undefined &&
                                                                        <>
                                                                            {
                                                                                // audioplays[i] === 0 ||
                                                                                isplay === false || playingIndex !== i || start || recentPlayStart ? (
                                                                                    <Tooltip
                                                                                        title="Play Song"
                                                                                        placement="top"
                                                                                    >
                                                                                        <img
                                                                                            src={playnew}
                                                                                            onClick={() => {
                                                                                                setAudio(row, i);
                                                                                                createMusic(row?._id);
                                                                                                setAudiosource(row?.audio);
                                                                                                handlePlayPause(i)
                                                                                                addRecentPlay(row?._id)
                                                                                                setTracks(i)
                                                                                                setType('top music')
                                                                                                setTopMusicStart(true)
                                                                                                setStart(false)
                                                                                                setRecentPlayStart(false)
                                                                                            }}
                                                                                            style={{ cursor: "pointer" }}
                                                                                            alt="nr"
                                                                                        />
                                                                                    </Tooltip>
                                                                                ) : (
                                                                                    <img
                                                                                        src={pasue}
                                                                                        // id="pauseImage"
                                                                                        onClick={() => {
                                                                                            setisplay(false);
                                                                                            handlePlayPause(i)
                                                                                            setType('top music')
                                                                                            // setTopMusicStart(false)
                                                                                        }}
                                                                                        alt={Companylogo1}
                                                                                    />
                                                                                )}
                                                                        </>
                                                                    )}

                                                                    {/* <img
                                                                src={isPlaying ? pasue : playnew}
                                                                onClick={() => handlePlayPause(i)}
                                                                alt={isPlaying ? 'Pause' : 'Play'}
                                                            onClick={handleClick}
                                                            /> */}
                                                                </div>
                                                            </Stack>
                                                        </div>
                                                    );
                                                }) :
                                                <Grid
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                // sx={{ padding: "15px" }}

                                                >
                                                    <div className='nodta text-center'>
                                                        <img src={nodta} alt="nodta" />
                                                    </div>
                                                </Grid>
                                        }
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={3}
                            // sx={{ padding: "15px" }}
                            >
                                <Recent
                                    search={name}
                                    component={component}
                                    setComponent={setComponent}
                                    setRecentPlayStart={setRecentPlayStart}
                                    start={start}
                                    setStart={setStart}
                                    topMusicStart={topMusicStart}
                                    setTopMusicStart={setTopMusicStart}
                                    recentPlayStart={recentPlayStart}
                                />

                            </Grid>
                            {/* {console.log(localStorage.getItem("playsong") === "false" && audiosource && component === "topmusic", 'logs')} */}
                        </Grid>
                        {(localStorage.getItem("playsong") === "false" && audiosource && component === "topmusic") ? (

                            <>
                                {/* {console.log('audiosource')} */}
                                <Musiccontrol
                                    // audiosource={audiosource}
                                    audiosource={type == 'new release' ? audioSourceArr1 : audioSourceArr}
                                    tracks={type == 'new release' ? tracks1 : tracks}
                                    handlePlay={type == 'new release' ? handlePlayPause1 : handlePlayPause}
                                    isplay={isplay}
                                    setisplay={setisplay}
                                    component={component}
                                    data={MusicData}
                                    getTopmusic={tokens != null ? getTopmusic : getTopmusics}
                                    getMusic={tokens != null ? getMusic : getMusics}
                                // fav={addFavorite}
                                />
                            </>
                        ) : (
                            <>
                                {(localStorage.getItem("playsong") === "false" && audio !== null && component === "topmusic") ? (
                                    <>
                                        {/* {console.log('audio')} */}
                                        <Musiccontrol
                                            audiosource={audio}
                                            tracks={type == 'new release' ? tracks1 : tracks}
                                            handlePlay={type == 'new release' ? handlePlayPause1 : handlePlayPause}
                                            isplay={isplay}
                                            setisplay={setisplay}
                                            component={component}
                                            data={MusicData}
                                            getTopmusic={tokens != null ? getTopmusic : getTopmusics}
                                            getMusic={tokens != null ? getMusic : getMusics}
                                        // fav={addFavorite}
                                        />
                                    </>
                                ) : (
                                    <></>
                                )}{" "}
                            </>
                        )}
                        {/* <Musiccontrol ref={childRef} /> */}
                        {/* <BottomBar /> */}
                    </Box>
                </Box >


            </div >
        </>
    )
}

export default PlayerNew
