import React, { useEffect, useState, useRef } from "react";
import Header2 from "../Header/Header2";
import Box from "@mui/material/Box";
import nrplay from "../../img/nrplay.png";
import { useParams } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { BsFacebook } from "react-icons/bs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Companylogo1 from "../../img/musicwave.gif";
import "../Feed/Feed.css";
import "../TrackPage/TrackPage.css";
import "./ArtistPage.css";
import Axios from "../../Axios";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Album from "../../img/music-logo.png";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import Mbadge from "../../img/badge.svg";
import Companylogo from "../../img/company-logo.png";
import RepeatIcon from "@mui/icons-material/Repeat";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Album1 from "../../img/backstage-fanzone.png";
import Album2 from "../../img/bs4.png";
import Album3 from "../../img/music-logo5.png";
import Album4 from "../../img/music-logo6.png";
import Album5 from "../../img/music-logo7.png";
import minilogo from "../../img/company-logo.png";
import bronze from "../../img/company-logo.png";
import gold from "../../img/gold.png";
import platinum from "../../img/platinum.png";
import diamond from "../../img/diamondpng.png";
import m1 from "../../img/m1.png";
import m2 from "../../img/m2.png";
import m3 from "../../img/m3.png";
import Tracks from "../Music-traxk/Tracks";
import { useSelector } from "react-redux";
import Track from "../NewMusicTrack/NewMusicTrack";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Popover from "@mui/material/Popover";
import { useLocation } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import SendTrack from "../ShareLink/SendTrack";
import SendProfile from "../ShareLink/SendProfile";
import Avatar from "@mui/material/Avatar";
import PaidIcon from "@mui/icons-material/Paid";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ava1 from "../../img/ava1.webp";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import AvatarGroup from "@mui/material/AvatarGroup";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Groups2Icon from "@mui/icons-material/Groups2";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { FaTwitter } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { FaTiktok } from "react-icons/fa6";
import { FaLink } from "react-icons/fa";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Profile from "../Profile/Profile";
import HeaderNew from "../HeaderNew/HeaderNew";
import msg from "../../img/msg.gif";
import cnt from "../../img/cnt.gif";
import nodata2 from "../../img/newnodata.gif";
import expmore from "../../img/explore.gif";
import Skeleton from "@mui/material/Skeleton";
import polls from "../../img/poll.png";
import survey from "../../img/survey.png";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import newno from "../../img/newnodata.gif";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Fade from "@mui/material/Fade";
import { makeStyles } from '@mui/styles';
import SidebarNew from '../SidebarNew/SidebarNew'
import Musiccontrol from "../Music-Control/Musiccontrol";

const useStyles = makeStyles((theme) => ({

  image: {
    width: '100%',
    height: '400px',
    objectFit: 'cover',
    objectPosition: 'top',
    // background:
  },
}));


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "var(--clr-bcg)",
  // border: '2px solid #000',
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  "@media(max-width:575.98px)": {
    width: "80%",
  },
};

const drawerWidth = 280;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const label = { inputProps: { "aria-label": "Switch demo" } };
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "var(--clr-bcg)",
  height: "620px",
  // overflow: 'scroll',
  // border: '2px solid #000',
  // boxShadow: 'none',
  p: 4,
  "@media all and (max-width: 1199.98px)": {
    width: "55%",
  },
  "@media all and (max-width: 768.98px)": {
    width: "85%",
  },
  "@media all and (max-width: 575.98px)": {
    width: "95%",
  },
};

const ArtistPage = () => {
  const [value, setValue] = React.useState(0);
  const [track, setTrack] = React.useState([]);
  const [account, setAccount] = React.useState();
  const [amount, setamount] = React.useState();
  const [topsupporters, setTopsupporters] = React.useState([]);
  const [mutuals, setMutual] = React.useState([]);
  const [sameArtist, setSameArtist] = React.useState([]);
  const [followers, setFollowers] = React.useState([]);
  const [followings, setFollowings] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [urlerr, setUrlerr] = React.useState("");
  const [openModel, setOpenModel] = React.useState(false);
  const fburls = useRef(null);
  const handleOpen = () => setOpenModel(true);
  const handleModelClose = () => setOpenModel(false);
  const [openModel2, setOpenModel2] = React.useState(false);
  const handleOpen2 = () => setOpenModel2(true);
  const handleModelClose2 = () => setOpenModel2(false);

  const [openModelprofile, setOpenModelprofile] = React.useState(false);
  const handleOpenprofile = () => setOpenModelprofile(true);
  const handleModelCloseprofile = () => setOpenModelprofile(false);
  const [playname, setPlayname] = React.useState();
  const [song_id, setsongid] = React.useState();
  const [musics, setMusics] = React.useState([]);
  const [album, setAlbum] = React.useState([]);
  const [playlist, setPlaylist] = React.useState([]);
  const [play, setPlay] = React.useState([]);
  const params = useParams();
  const [rows, setRows] = useState({});
  const [audiosource, setAudiosource] = React.useState();
  const [counts, setCounts] = React.useState(0);
  const [fburl, setfburl] = React.useState("");
  const [yturl, setyturl] = React.useState("");
  const [instaurl, setinstaurl] = React.useState("");
  const [twitterurl, settwitterurl] = React.useState("");
  const tokens = localStorage.getItem("iphephile");
  const navigate = useNavigate();
  const [audioDurations, setAudioDurations] = useState([]);
  const [audioDurations1, setAudioDurations1] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [artistId, setArtistId] = useState("");
  const [prf, setPrf] = useState(false);
  const [tracks, setTracks] = useState(0);
  const songlist = useSelector((state) => state.songs);
  const [audiosources, setAudiosources] = React.useState(songlist);
  const audio = localStorage.getItem("audiosource");
  const isMount = useRef(false);
  const [isOwner, setIsOwner] = useState('');
  const [name, setName] = useState()
  const [component, setComponent] = useState()

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const tootltip = (
    <div>
      <div>10K - Gold</div>
      <div>1M-Diamond</div>
      <div>5M-Platinum</div>
    </div>
  );

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 280 }}
      role="presentation"
    >
      <List className="artist-sidebar-list">
        <div className="artist-stamp-main">
          <div className="tire-main-div">
            <div className="art-mini-logo">
              <img
                src={getStamp(user?.TotalFollowers)}
                alt="logo"
                className="shining"
              />
            </div>

            <div class="vl"></div>
            <div className="tire-level">
              {getTier(user?.TotalFollowers)}
              <div className="tire-medal">
                {getStampName(user?.TotalFollowers)}
              </div>
            </div>
          </div>
          <Tooltip
            title={tootltip}
            arrow
            placement="right"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            {/* <Button>Arrow</Button> */}
            <InfoOutlinedIcon />
          </Tooltip>
        </div>
        <div className="artist-description-cont">{user?.name}</div>
        <div className="artist-nft-link">
          {/* Nft:
                    <p>https://opensea.io/AkiraTheDon</p> */}
        </div>
        <div className="artist-sub-div-follow-stack">
          <Stack direction="row" spacing={3}>
            <BsTwitterX
              onClick={() => {
                setCounts(0);
              }}
            />
            <IoLogoInstagram
              onClick={() => {
                setCounts(1);
              }}
            />
            <FaYoutube
              onClick={() => {
                setCounts(2);
              }}
            />
            <BsFacebook
              onClick={() => {
                setCounts(3);
              }}
            />
          </Stack>

          <div className="inputbox art">
            <Typography variant="p" component="p" className="email">
              {getTitle(counts)}
            </Typography>
            <FormControl
              sx={{ m: 1, width: "100%" }}
              variant="outlined"
              className="input-box"
            >
              <OutlinedInput
                className="input-box-txt artist"
                inputRef={fburls}
                value={getValue(counts)}
                onChange={(e) => {
                  setuserValue(e);
                }}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
              />
            </FormControl>
            {urlerr ? (
              <div className="errorDiv" style={{ textAlign: "center" }}>
                {urlerr}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="TIP-audion-btn">
          {(String(artistId) === window.localStorage.getItem("usrId")) ===
            true ? (
            <Button
              onClick={() => {
                updateProfile();
              }}
            >
              Change Url
            </Button>
          ) : null}
        </div>
        <div className="top-support">
          <EmojiEventsIcon /> Top Supporters
        </div>
        <div className="avatar-div-stack">
          <AvatarGroup max={4}>
            {topsupporters && topsupporters.length > 0 ? (
              <>
                {topsupporters &&
                  topsupporters.map((row, index) => {
                    return (
                      <>
                        <Avatar alt="Remy Sharp" src={row?.profilePicture} />
                      </>
                    );
                  })}
              </>
            ) : (
              <>
                <div className="newno">
                  <img src={newno} />
                </div>
              </>
            )}
          </AvatarGroup>
          {topsupporters.length > 0 ? (
            <div
              onClick={() => {
                navigate(`/artist/top_supporters/${params._id}`);
              }}
              className="display-flex"
            >
              View More
              <ArrowRightAltIcon />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="top-support">
          <PersonAddAltIcon /> Mutuals
        </div>
        <div className="avatar-div-stack">
          <AvatarGroup max={4}>
            {mutuals && mutuals.length > 0 ? (
              <>
                {mutuals &&
                  mutuals.map((row, index) => {
                    return (
                      <>
                        <Avatar alt="Remy Sharp" src={row?.profilePicture} />
                      </>
                    );
                  })}
              </>
            ) : (
              <>
                <div className="newno">
                  <img src={newno} />
                </div>
              </>
            )}
          </AvatarGroup>
          {mutuals?.length > 0 ? (
            <div
              onClick={() => {
                navigate(`/artist/mutual_connects/${params._id}`);
              }}
            >
              View More
              <ArrowRightAltIcon />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="top-support">
          <Groups2Icon /> Related Artists
        </div>
        <div className="avatar-div-stack">
          <AvatarGroup max={4}>
            {sameArtist && sameArtist?.length > 0 ? (
              <>
                {sameArtist &&
                  sameArtist?.map((row, index) => {
                    return (
                      <>
                        <Avatar alt="Remy Sharp" src={row?.profilePicture} />
                      </>
                    );
                  })}
              </>
            ) : (
              <>
                <div className="newno">
                  <img src={newno} />
                </div>
              </>
            )}
          </AvatarGroup>
          {followers?.length > 0 ? (
            <div
              onClick={() => {
                navigate(`/artist/related_artists/${params._id}`);
              }}
            >
              View More
              <ArrowRightAltIcon />
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="top-support">
          <Groups2Icon /> Followers List
        </div>
        <div className="avatar-div-stack">
          <AvatarGroup max={4}>
            {followers && followers?.length > 0 ? (
              <>
                {followers &&
                  followers.map((row, index) => {
                    return (
                      <>
                        <Avatar alt="Remy Sharp" src={row?.profilePicture} />
                      </>
                    );
                  })}
              </>
            ) : (
              <>
                <div className="newno">
                  <img src={newno} />
                </div>
              </>
            )}
          </AvatarGroup>
          {followers?.length > 0 ? (
            <div
              onClick={() => {
                navigate(`/artist/followers_list/${params._id}`);
              }}
            >
              View More
              <ArrowRightAltIcon />
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="top-support">
          <Groups2Icon /> Followings List
        </div>
        <div className="avatar-div-stack">
          <AvatarGroup max={4}>
            {followings && followings?.length > 0 ? (
              <>
                {followings &&
                  followings.map((row, index) => {
                    return (
                      <>
                        <Avatar alt="Remy Sharp" src={row?.profilePicture} />
                      </>
                    );
                  })}
              </>
            ) : (
              <>
                <div className="newno">
                  <img src={newno} />
                </div>
              </>
            )}
          </AvatarGroup>
          {followers?.length > 0 ? (
            <div
              onClick={() => {
                navigate(`/artist/following_details/${params._id}`);
              }}
            >
              View More
              <ArrowRightAltIcon />
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* <div className='top-support'>
                    <Groups2Icon /> Top tags
                </div>
                <div className='top-tags-content'>
                    <div>meaningwave</div>
                    <div>akirathedon</div>
                    <div>navalravikant</div>
                    <div>kingoflofichristmas</div>
                    <div>meaningwave</div>
                </div> */}
      </List>
    </Box>
  );

  const handleClickEl2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClickEl3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleCloseEl2 = () => {
    setAnchorEl2(null);
  };
  const handleCloseEl3 = () => {
    setAnchorEl3(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMore = (event) => {
    setAnchorE2(event?.currentTarget);
    //    console.log(anchorEl2)
  };

  const handleClose2 = () => {
    setAnchorE2(null);
  };

  const history = useLocation();
  const [values, setValues] = React.useState(true);
  const [audioplays, setAudioplays] = useState([]);
  const [isplay, setisplay] = useState(0);
  const [MusicData, setMusicData] = useState()

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const open2 = Boolean(anchorE2);
  const id2 = open2 ? "simple-popover" : undefined;

  const open3 = Boolean(anchorEl2);
  const id3 = open3 ? "simple-popover3" : undefined;
  // to add song to playlist
  const handleAdd = async (data) => {
    try {
      const create_data = {
        playlistId: data,
        songId: song_id,
      };

      await Axios.post("/users/edit_playlist", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            getArtist();
            handleModelClose();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } catch (error) { }
  };

  // const setAudio = (data) => {
  //   localStorage.setItem("audiosource", data.audio);
  //   localStorage.setItem("songName", data.song_name);
  //   localStorage.setItem("Image", data.image);
  //   localStorage.setItem("current", 0);
  //   localStorage.setItem("isPause", false);
  //   setAudiosource(data.audio);
  //   setisplay(true);
  // };

  const setAudio = (data, i) => {
    localStorage.setItem("audiosource", data.audio);
    localStorage.setItem("name", data.createUser.name);
    localStorage.setItem("songName", data.song_name);
    localStorage.setItem("Image", data.image);
    localStorage.setItem("current", 0);
    localStorage.setItem("tracks", 0);
    localStorage.setItem("isPause", false);
    setAudiosource(data.audio);
    localStorage.setItem("playsong", "false");
    setisplay(true);
    setMusicData(data._id)
  };

  // to add song to history
  const createMusic = async (data) => {
    try {
      const create_data = {
        song_id: data,
      };

      await Axios.post("/users/add_history", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            //toast.success(res?.data?.message)
            getArtist();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };
  const getAudioDuration = async (audioSrc) => {
    try {
      const audio = new Audio(audioSrc);
      await audio.addEventListener("loadedmetadata", () => {
        const duration = audio.duration;
        const durationInSeconds = audio.duration;
        const durationInMinutes = (durationInSeconds / 60).toFixed(2);
        setAudioDurations((prev) => ({
          ...prev,
          [audioSrc]: durationInMinutes,
        }));
      });
    } catch (error) {
      console.error(error);
      setAudioDurations((prev) => ({
        ...prev,
        [audioSrc]: 0, // Default duration or handle error accordingly
      }));
    }
  };

  const getaudioPlays = async (audioSrc, index) => {
    try {
      if (audioSrc === localStorage.getItem("songName")) {
        setAudioplays((prev) => ({
          ...prev,
          [index]: 1,
        }));
      } else {
        setAudioplays((prev) => ({
          ...prev,
          [index]: 0,
        }));
      }
    } catch (error) {
      console.error(error);
      setAudioplays((prev) => ({
        ...prev,
        [index]: 0,
      }));
    }
  };

  useEffect(() => {
    if (track.length > 0) {
      track.forEach((row) => {
        getAudioDuration(row?.audio);
        getPreAudioDuration(row?.pre_audio)
      });
    }
    if (track.length > 0) {
      track.forEach((row, index) => {
        getaudioPlays(row?.song_name, index);
      });
    }
  }, [track]);

  useEffect(() => {
    if (track.length > 0) {
      track.forEach((row, index) => {
        getaudioPlays(row?.song_name, index);
      });
    }
  }, [values]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!isMount.current) {
      getArtist();
      getSupporters();
      getMutual();
      getFollowers();
      getFollowings();
      SameArtist();
      getValue(counts);
      if (history?.state?.emailVerify === 0) {
        toast.success("Email Updated Sucessfully");
      } else if (history?.state?.emailVerify === 1) {
        toast.error("Email Cannot Updated");
      } else if (history?.state?.emailVerify === 4) {
        toast.success("Please Update Your Profile");
      }
      isMount.current = true;
    }
  }, []);

  useEffect(() => {
    getArtist();
  }, [prf]);

  useEffect(() => {
    setUrlerr("");
  }, [counts]);

  const getTitle = (data) => {
    switch (data) {
      case 0:
        return "Twitter Url";
      case 1:
        return "Instagram Url";
      case 2:
        return "Youtube Url";
      case 3:
        return "Facebook Url";
    }
  };

  const getTier = (data) => {
    if (Number(data) > 10000) return "Tier 2";
    else if (Number(data) > 100000) return "Tier 3";
    else if (Number(data) > 500000) return "Tier 4";
    else if (Number(data) >= 1) return "Tier 1";
  };

  const getValue = (data) => {
    if (data === 0 && fburls.current) {
      fburls.current.value = twitterurl;
    } else if (data === 1 && fburls.current) {
      fburls.current.value = instaurl;
    } else if (data === 2 && fburls.current) {
      fburls.current.value = yturl;
    } else if (data === 3 && fburls.current) {
      fburls.current.value = fburl;
    }
  };

  const setuserValue = (e) => {
    const urlregex = new RegExp(
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
    );

    if (!urlregex.test(e.target.value)) {
      setUrlerr("*Enter Url Valid Url link");
    } else {
      setUrlerr("");
      if (counts === 0) {
        settwitterurl(e.target.value);
      } else if (counts === 1) {
        setinstaurl(e.target.value);
      } else if (counts === 2) {
        setyturl(e.target.value);
      } else if (counts === 3) {
        setfburl(e.target.value);
      }
    }
  };


  useEffect(() => {
    getArtist();
  }, [params._id]);

  // to get top getSupporters
  const getSupporters = async () => {
    try {
      var findData = {
        id: params._id,
        type: "show",
      };

      const datas = await Axios.post("/followers/get_my_supporters", findData, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            setTopsupporters(res.data?.result);
          } else {
            //toast.error(err?.response?.data?.message)
            setTopsupporters([]);
          }
        })
        .catch((err) => {
          // console.log(err, "err")
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to get top getMutual followers
  const getMutual = async () => {
    try {
      var findData = {
        id: params._id,
        type: "show",
      };

      const datas = await Axios.post(
        "/followers/get_my_mutualfollowers",
        findData,
        {
          headers: {
            Authorization: tokens,
          },
        }
      )
        .then((res) => {
          if (res?.data?.success) {
            setMutual(res.data?.result?.MutualFollowers);
          } else {
            //toast.error(err?.response?.data?.message)

            setMutual([]);
          }
        })
        .catch((err) => {
          // console.log(err, "err")
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to get top SameArtist
  const SameArtist = async () => {
    try {
      var findData = {
        id: params._id,
        type: "show",
      };

      const datas = await Axios.post(
        "/followers/get_relatedartists",
        findData,
        {
          headers: {
            Authorization: tokens,
          },
        }
      )
        .then((res) => {
          if (res?.data?.success) {
            setSameArtist(res?.data?.result);
          } else {
            //toast.error(err?.response?.data?.message)

            setSameArtist([]);
          }
        })
        .catch((err) => {
          // console.log(err, "err")
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to Get the list of followers list for the artist

  const getFollowers = async () => {
    try {
      var findData = {
        id: params._id,
        type: "show",
      };

      const datas = await Axios.post("/followers/get_my_followers", findData, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            setFollowers(res?.data?.result?.followerDetails);
          } else {
            setFollowers([]);
          }
        })
        .catch((err) => {
          setFollowers([]);

          // console.log(err, "err")
        });
    } catch (error) {
      setFollowers([]);

      // console.log(error, "error");
    }
  };

  const getFollowings = async () => {
    try {
      var findData = {
        id: params._id,
        type: "show",
      };

      const datas = await Axios.post("/followers/get_my_followings", findData, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            setFollowings(res?.data?.result?.followingDetails);
          } else {
            setFollowings([]);
          }
        })
        .catch((err) => {
          setFollowings([]);

          // console.log(err, "err")
        });
    } catch (error) {
      setFollowings([]);

      // console.log(error, "error");
    }
  };

  const getStamp = (data) => {
    if (Number(data) >= 10000) return gold;
    else if (Number(data) > 100000) return platinum;
    else if (Number(data) > 500000) return diamond;
    else if (Number(data) > 1) return bronze;
    else return minilogo;
  };
  const getStampName = (data) => {
    if (Number(data) >= 10000) return "Gold";
    else if (Number(data) > 100000) return "Platinum";
    else if (Number(data) > 500000) return "Diamond";
    else if (Number(data) > 1) return "Bronze";
    else return "";
  };
  const getArtist = async () => {
    try {
      var findData = { id: params._id };

      const datas = await Axios.post(
        "/followers/get_artist_details",
        findData,
        {
          headers: {
            Authorization: tokens,
          },
        }
      )
        .then((res) => {
          if (res?.data?.success) {
            setComponent('artist')
            setUser(res.data?.result?.UserDetails[0]);
            setAccount(res.data?.result?.UserDetails[0].account);
            setamount(res.data?.result?.UserDetails[0].amount);
            setfburl(
              res.data?.result?.UserDetails[0].fburl !== undefined
                ? res.data?.result?.UserDetails[0].fburl
                : ""
            );
            settwitterurl(
              res.data?.result?.UserDetails[0].twitterurl !== undefined
                ? res.data?.result?.UserDetails[0].twitterurl
                : ""
            );
            setyturl(
              res.data?.result?.UserDetails[0].yturl !== undefined
                ? res.data?.result?.UserDetails[0].yturl
                : ""
            );
            setinstaurl(
              res.data?.result?.UserDetails[0].instaurl !== undefined
                ? res.data?.result?.UserDetails[0].instaurl
                : ""
            );
            setTrack(res.data?.result?.SongDetails);
            setAlbum(res.data?.result?.AlbumDetails);
            setPlaylist(res.data?.result?.PlaylistDetails);
            setArtistId(res.data?.result?.UserDetails[0]?._id);
          } else {
            //toast.error(err?.response?.data?.message)
            setUser({});
            setTrack([]);
            setAlbum([]);
            setPlaylist([]);
          }
        })
        .catch((err) => {
          // console.log(err, "err")
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };
  // to repost/unrepost Songs
  const editSong = async (data) => {
    try {
      const create_data = {
        songId: data.id,
        status: data.status,
      };

      await Axios.post("/users/edit_song_details", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            //toast.success(res?.data?.message)
            getArtist();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };
  // to repost/unrepost albums
  const editAlbums = async (data) => {
    try {
      const create_data = {
        album_id: data.id,
        status: data.status,
      };

      await Axios.post("/users/edit_album_details", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            //   toast.success(res?.data?.message)
            getArtist();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };
  // to repost/unrepost playlist
  const editPlaylists = async (data) => {
    try {
      const create_data = {
        album_id: data.id,
        status: data.status,
      };

      await Axios.post("/users/edit_playlist_details", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            getArtist();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to add song to library

  const addLibrary = async (data) => {
    try {
      const create_data = {
        song_id: data,
      };

      await Axios.post("/users/add_library", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            getArtist();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          // console.log(err, "err")
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };
  // to favourite/unfavorite a song
  const addFavorite = async (data) => {
    try {
      const create_data = {
        song_id: data,
      };

      await Axios.post("/users/add_to_favourite", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            getArtist();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          // console.log(err, "err")
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };
  // to favourite/unfavorite a Album
  const addalbumFavorite = async (data) => {
    try {
      const create_data = {
        album_id: data,
      };

      await Axios.post("/users/add_fav_album", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            getArtist();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          // console.log(err, "err")
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };
  // to favourite/unfavorite a Playlist
  const addplayFavorite = async (data) => {
    try {
      const create_data = {
        album_id: data,
      };

      await Axios.post("/users/add_fav_playlist", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            getArtist();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          // console.log(err, "err")
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };
  // to add album to library
  const addalbumLibrary = async (data) => {
    try {
      const create_data = {
        album_id: data,
      };

      await Axios.post("/users/add_album_to_library", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            getArtist();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          // console.log(err, "err")
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };
  // to add playlist to library
  const addplayLibrary = async (data) => {
    try {
      const create_data = {
        play_id: data,
      };

      await Axios.post("/users/add_playlist_to_library", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            getArtist();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          // console.log(err, "err")
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };
  //to upload Profile
  const updateProfile = async () => {
    let reg = /^[0-9]+$/;
    try {
      var updateData = {
        name: user?.name,
        coverImage: user?.coverImage,
        profilePicture: user?.profilePicture,
        amount: user?.amount,
        account: user?.account,
        fburl: fburl,
        yturl: yturl,
        twitterurl: twitterurl,
        instaurl: instaurl,
      };

      await Axios.post("/updateProfile", updateData, {
        headers: {
          Authorization: tokens,
        },
      }).then((res) => {
        if (res?.data?.success) {
          toast.success("Profile Updated Successfully", {
            duration: 1000, // Set the duration for which the toast is visible
          });
          getArtist();
        } else {
          if (res?.data?.message == "Incorrect password") {
            toast.error(res?.data?.message);
            //setlpassworderr("Incorrect password");
          } else {
            toast.error(res?.data?.message);
          }
        }
      });
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getplayLists();
  }, [playname]);
  const getplayLists = async () => {
    try {
      const datas = await Axios.post(
        "/users/get_my_playlist",
        {
          playname: playname,
        },
        {
          headers: {
            Authorization: tokens,
          },
        }
      )
        .then((res) => {
          // console.log(res?.data?.result?.musicList,"res")
          if (res?.data?.success) {
            // toast.success(res?.data?.message)
            getArtist();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          // console.log(err, "err")
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };
  //follow the Artist
  const followUser = async (data) => {
    try {
      const follow_data = {
        id: data,
      };
      console.log(follow_data);

      await Axios.post("/followers/follow_artist", follow_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            getArtist();
          } else {
            setMusics([]);
            setAlbum([]);
            setPlay([]);
          }
        })
        .catch((err) => {
          // console.log(err,"err")
        });
    } catch (error) {
      // console.log(error,"error");
    }
  };
  const addPlay = (data) => {
    // console.log(data,"response")
    // setsongid(data)
    handleOpen();
    handleCloseEl2();
    handleClose();
  };

  useEffect(() => {
    if (track.length <= 0) {
      var appDiv = document.querySelector(".App");

      appDiv.className = "App hv";
    } else {
      var appDiv = document.querySelector(".App");
      appDiv.className = "App";
    }
  }, []);

  const navigation = () => {
    if (String(params._id) !== window.localStorage.getItem("usrId")) {
      navigate(`/message/${params._id}`, { state: { account, amount } });
    } else {
      navigate(`/mymessage`);
    }
  };

  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowMessage(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [showMessage]);

  const [poll, setPoll] = useState(false);
  const handleOpenPoll = () => setPoll(true);
  const handleClosePoll = () => setPoll(false);

  const [shining, setShining] = useState(false);

  const getPreAudioDuration = async (audioSrc) => {
    try {
      const audio = new Audio(audioSrc);
      await audio.addEventListener("loadedmetadata", () => {
        const duration = audio.duration;
        const durationInSeconds = audio.duration;
        const durationInMinutes = (durationInSeconds / 60).toFixed(2);
        setAudioDurations1((prev) => ({
          ...prev,
          [audioSrc]: durationInMinutes,
        }));
      });
    } catch (error) {
      console.error(error);
      setAudioDurations1((prev) => ({
        ...prev,
        [audioSrc]: 0, // Default duration or handle error accordingly
      }));
    }
  };

  const setAudios = (data) => {
    if (audioDurations1[data?.pre_audio] !== undefined) {
      localStorage.setItem("audiosource", data.pre_audio);
      localStorage.setItem("songName", data.song_name);
      localStorage.setItem("Image", data.image);
      localStorage.setItem("current", 0);
      localStorage.setItem("tracks", 0);
      localStorage.setItem("isPause", false);
      setAudiosource(data.pre_audio);
      localStorage.setItem("playsong", "false");
      setValues(!values);
      setisplay(true);
    }
  };

  const handleDeleteSong = async (id, key) => {

    const songsId = id;
    const colName = key
    // alert(songsId);
    // alert(colName);

    try {

      const { data } = await await Axios.post("/users/deletetrack", { key: colName, id: songsId }, {
        headers: {
          Authorization: tokens,
        },
      })

      if (data?.success) {
        toast.success(data?.message);
      } else {
        toast.success(data?.message);
      }

    } catch (error) {
      console.log(error, 'error')
    } finally {
      getArtist();
    }

  }

  useEffect(() => {
    setIsOwner(localStorage.getItem('usrId'));
  }, [])

  return (
    <div className="feed trackPage">
      <ToastContainer
        toastStyle={{ backgroundColor: "white" }}
        autoClose={3000}
        position="top-right"
        reverseOrder={false}
      />
      <Box sx={{ display: "flex" }} className="display-block">
        {/* <HeaderNew title="Profile" prf={prf} /> */}
        <SidebarNew title="Profile" prf={prf} name={name} setName={setName} />
        <Box
          component="main"
          className="feed-bg"
          sx={{
            flexGrow: 1,
            py: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            marginTop: "50px",
          }}
        >
          <Grid container spacing={0}>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="trackpage-bgimg-div">
                <img
                  src={
                    user?.coverImage && !user?.coverImage?.includes("localhost")
                      ? user?.coverImage
                      : Album1
                  }
                  alt="bg"
                />
              </div>
            </Grid>
          </Grid>


          <Container maxWidth="md" className="profile-redesign-cont ar6">
            <Grid container spacing={0} >
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="artist-page-pannel">
                  <div className="new-prf-avt">
                    <div className="tar" onClick={handleClickMore}>
                      {/* <img style={{ width: '25px', height: '20px' }} src="https://img.icons8.com/office/22/000000/usb-connector.png" alt="usb-connector" />Connect */}
                    </div>
                    {/* <Popover
                                            id={id2}
                                            open={open2}
                                            anchorEl={anchorE2}
                                            onClose={handleClose2}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            className='playlist-popover'
                                        >

                                            <div className='playlist-more-div' ><PaidIcon />Pay & Earn</div>
                                            <div className='playlist-more-div' ><ShowChartIcon />Artist Dashboard</div>
                                            <div className='playlist-more-div' ><EmojiEventsIcon />Rewards</div>
                                            <div className='playlist-more-div' ><SettingsIcon />Settings</div>
                                        </Popover> */}

                    {showMessage ? (
                      <Stack spacing={1}>
                        <Skeleton
                          variant="circular"
                          width={200}
                          height={200}
                          sx={{
                            bgcolor: "var(--clr-skel)",
                            "@media (max-width: 575.98px)": {
                              width: "90px !important",
                              height: "90px !important",
                            },
                          }}
                        />
                      </Stack>
                    ) : (
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          user?.profilePicture &&
                            !user?.profilePicture?.includes("localhost")
                            ? user?.profilePicture
                            : Album2
                        }
                        sx={{
                          width: 200,
                          height: 200,
                          border: "2px solid #fff",
                        }}
                      />
                    )}

                    {String(artistId) !==
                      window.localStorage.getItem("usrId") &&
                      user?.isFollow === true ? (
                      <div
                        className="tar"
                        onClick={() => {
                          navigation(user?.isFollow);
                        }}
                      >
                        <img src={msg} /> Message
                      </div>
                    ) : (
                      <>
                        {String(artistId) ===
                          window.localStorage.getItem("usrId") ? (
                          <div
                            className="tar"
                            onClick={() => {
                              navigation(user?.isFollow);
                            }}
                          >
                            <img src={msg} /> Message
                          </div>
                        ) : (
                          <div
                            className="tar"
                            onClick={() => {
                              toast.error(
                                `You should follow ${user?.name ? user?.name : "this Artist"} to message`
                              );
                            }}
                          >
                            <img src={msg} /> Message
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="follow-inner2">
                    <div className="artist-name-tag">{user?.name}</div>

                    {user?.role == "artist" ? (
                      <div className="artist-tag">ARTIST</div>
                    ) : user?.role == "user" ? (
                      <div className="artist-tag">FAN</div>
                    ) : (
                      <div className="artist-tag"></div>
                    )}
                    <div className="artist-name-tag">
                      <div className="art-mini-logo">
                        <img
                          src={getStamp(user?.TotalFollowers)}
                          alt="logo"
                          className="shining"
                        />
                      </div>
                      <div className="artist-user-name">@{user?.name}</div>

                      {(String(artistId) ===
                        window.localStorage.getItem("usrId")) ===
                        true ? (
                        <div className="virtualmeet-btn">
                          <Button>
                            <SendProfile
                              handleModelCloseprofile={handleModelCloseprofile}
                              handleOpenprofile={handleOpenprofile}
                              openModelprofile={openModelprofile}
                              id={`/artistpage/${params._id}`}
                            />
                          </Button>

                          {user?.role === "artist" ? (
                            <>
                              {" "}
                              <Button
                                onClick={() => {
                                  navigate("/hoisting");
                                }}
                              >
                                Virtual Meet +
                              </Button>
                              <Button
                                onClick={() => {
                                  navigate("/createexclusive");
                                }}
                              >
                                Exclusive Releases +
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}
                          <Button
                            onClick={() => {
                              navigate("/messageset");
                            }}
                          >
                            Message Settings +
                          </Button>

                          <Button
                            onClick={() => {
                              handleOpenPoll();
                            }}
                          >
                            Polls & Surveys +
                          </Button>

                          <Modal
                            open={poll}
                            onClose={handleClosePoll}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <div className="canceltwotone">
                                <CancelTwoToneIcon onClick={handleClosePoll} />
                              </div>
                              <div className="poll-nav-main">
                                <div className="poll-nav">
                                  <div>
                                    <img src={polls} />
                                  </div>
                                  {user?.role === "artist" ? (
                                    <div
                                      className="artist-track"
                                      onClick={() => {
                                        navigate("/pollssurvey");
                                      }}
                                    >
                                      Polls & Survey
                                    </div>
                                  ) : (
                                    <div
                                      className="artist-track"
                                      onClick={() => {
                                        navigate("/polls_survey");
                                      }}
                                    >
                                      Polls & Survey
                                    </div>
                                  )}
                                </div>
                                <div className="poll-nav">
                                  <div>
                                    <img src={survey} />
                                  </div>

                                  <div
                                    className="artist-track"
                                    onClick={() => {
                                      navigate("/pollshistory");
                                    }}
                                  >
                                    Polls History
                                  </div>
                                </div>
                              </div>
                            </Box>
                          </Modal>
                        </div>
                      ) : (
                        <div className="virtualmeet-btn"></div>
                      )}
                    </div>
                    <div className="artist-follow-main">
                      <div className="follow-inner-artist">
                        <div className="artist-track">
                          {track?.length}
                          <div className="artist-track-inner">TRACKS</div>
                        </div>
                        <div className="artist-track">
                          {user?.TotalFollowers}
                          <div className="artist-track-inner">PLUGGED-IN</div>
                        </div>
                        <div className="artist-track">
                          {user?.TotalFollowings}
                          <div className="artist-track-inner">
                            {String(artistId) ==
                              window.localStorage.getItem("usrId")
                              ? "MY-PLUGS"
                              : "PLUGS"}
                          </div>
                        </div>
                      </div>
                      <div className="artist-follow-more-msg-mian">
                        {String(artistId) ==
                          window.localStorage.getItem("usrId") ? (
                          <></>
                        ) : (
                          <div className="artist-follow-btn">
                            {user?.isFollow}

                            {user?.isFollow === false ? (
                              <Button
                                onClick={() => {
                                  followUser(params._id);
                                }}
                              >
                                <PersonAddAlt1Icon /> Plug-in{" "}
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  followUser(params._id);
                                }}
                              >
                                <PersonAddAlt1Icon /> Plugged{" "}
                              </Button>
                            )}
                          </div>
                        )}

                        <div className="vertiacl-stk">
                          <Stack spacing={3} className="artist-sub-div-follow-stack art">
                            {twitterurl ?
                              <Link to={twitterurl} target="_blank">
                                <BsTwitterX />
                              </Link> : <></>
                            }
                            {
                              instaurl ?
                                <Link to={instaurl} target="_blank">
                                  <IoLogoInstagram />
                                </Link> : <></>
                            }
                            <Link to="https://t.me/DreamsterNFT" target="_blank">
                              <FaYoutube />
                            </Link>
                            {
                              fburl ?
                                <Link to={fburl} target="_blank">
                                  <BsFacebook />
                                </Link> : <></>
                            }
                          </Stack>
                        </div>
                      </div>
                    </div>
                    <div className="artist-pg-tab-main-div">
                      <Box sx={{ width: "100%" }}>
                        <Box>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            className="library-tab2"
                          >
                            <Tab
                              label="TRACKS"
                              {...a11yProps(0)}
                              iconPosition="start"
                              icon={<img src={m1} />}
                            />
                            <Tab
                              label="ALBUMS"
                              {...a11yProps(1)}
                              iconPosition="start"
                              icon={<img src={m2} />}
                            />
                            <Tab
                              label="PLAYLISTS"
                              {...a11yProps(2)}
                              iconPosition="start"
                              icon={<img src={m3} />}
                            />
                            {(String(artistId) ===
                              window.localStorage.getItem("usrId")) ===
                              true ? (
                              <Tab label="Details" {...a11yProps(3)} />
                            ) : null}
                          </Tabs>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={0}>
              {/* {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Tooltip title="Explore More" placement="right">
                    <Button
                      onClick={toggleDrawer(anchor, true)}
                      className="artist-explore-more"
                    >
                      <img src={expmore} alt="exp" />
                    </Button>
                  </Tooltip>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    className="artist-side"
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))} */}

              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>

                <CustomTabPanel
                  value={value}
                  index={0}
                  style={audiosource ? { paddingBottom: "150px" } : {}}
                >
                  <Grid container spacing={2}>
                    {track?.length > 0 ? (
                      <>
                        {track &&
                          track?.map((row, index) => {
                            return (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  key={row?._id}
                                >
                                  <div className="feed-music-box">
                                    <div className="music-detail">
                                      <div className="album-img hover-play-div">
                                        <img
                                          src={
                                            row?.image &&
                                              !row?.image?.includes("localhost")
                                              ? row?.image
                                              : ava1
                                          }
                                          alt="Track"

                                        />
                                        {row?.nft_type === "per" ?
                                          <Tooltip title={row?.nft_type == "per" && audioDurations1[row?.pre_audio] == undefined ? "Can't Play" : ""} placement="top">

                                            <img
                                              src={nrplay}
                                              style={{
                                                width: "32px",
                                                height: "32px",
                                              }}
                                              onMouseOver={() => setAudios(row)}
                                              onMouseOut={() => setisplay(false)}
                                              className="hover-play3"
                                            />
                                          </Tooltip> : <></>}
                                      </div>

                                      <div className="album-detail">
                                        <div className="music-time">
                                          <div className="time-music">
                                            <div className="music-name">
                                              {row?.type}{" "}
                                              {row?.nft_type === "per" ? (
                                                <>
                                                  <VerifiedIcon className="premium" />
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              <div
                                                onClick={(e) => {
                                                  navigate(
                                                    `/trackpage/${row._id}`
                                                  );
                                                }}
                                              >
                                                {/* {row?.song_name}-{row?.ISRC} */}
                                                {row?.song_name}
                                              </div>
                                            </div>
                                            {/* <div className="link-badge">
                                              <Link to="">{row?.nft_link}</Link>
                                            </div> */}
                                          </div>
                                          <div className="music-timing">
                                            {audioDurations[row?.audio] !==
                                              undefined ? (
                                              <span>
                                                {audioDurations[row?.audio]}
                                              </span>
                                            ) : (
                                              <span>-</span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="loop-times">
                                          <div className="loop-part">
                                            <div className="company-logo">
                                              {audioDurations[row?.audio] !==
                                                undefined ? (
                                                <>
                                                  {" "}
                                                  {audioplays[index] === 0 ||
                                                    isplay === false ? (
                                                    <Tooltip
                                                      title="Play Song"
                                                      placement="top"
                                                    >
                                                      <img
                                                        src={nrplay}
                                                        onClick={() => {
                                                          setAudio(row);
                                                          setValues(!values);
                                                          createMusic(row?._id);
                                                          setAudiosource(
                                                            row?.audio
                                                          );
                                                        }}
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        alt="nr"
                                                      />
                                                    </Tooltip>
                                                  ) : (
                                                    <>
                                                      <img
                                                        src={Companylogo1}
                                                        onClick={() =>
                                                          setisplay(false)
                                                        }
                                                        id="pauseImage"
                                                        alt="Companylogo"
                                                      />
                                                    </>
                                                  )}
                                                </>
                                              ) : (

                                                <>
                                                  {row?.nft_type === "per" &&
                                                    audioDurations[row?.audio] !==
                                                    undefined ? (
                                                    <Tooltip
                                                      title="Premium Song"
                                                      placement="top"
                                                    >
                                                      {(audioplays[index] == 0 ||
                                                        isplay == false) == true ? (
                                                        <img
                                                          src={nrplay}
                                                          onClick={() => {
                                                            setAudios(row);
                                                            createMusic(row?._id);
                                                            setAudiosource(
                                                              row?.pre_audio
                                                            );
                                                          }}
                                                          style={{ cursor: "pointer" }}
                                                          alt="nr"
                                                        />
                                                      ) : (
                                                        <img
                                                          src={Companylogo1}
                                                          id="pauseImage"
                                                          onClick={() => {
                                                            setisplay(false);
                                                          }}
                                                          alt={Companylogo}
                                                        />
                                                      )}
                                                    </Tooltip>
                                                  ) : (
                                                    <Tooltip
                                                      title="Can't Play"
                                                      placement="top"
                                                    >
                                                      <img
                                                        src={nrplay}
                                                        style={{ cursor: "pointer" }}
                                                        alt="nr"
                                                      />
                                                    </Tooltip>
                                                  )}
                                                </>
                                                // <Tooltip
                                                //   title={
                                                //     row?.nft_type === "per"
                                                //       ? "Premium Song"
                                                //       : "Can't play"
                                                //   }
                                                //   placement="top"
                                                // >
                                                //   <img
                                                //     src={nrplay}
                                                //     style={{
                                                //       cursor: "pointer",
                                                //     }}
                                                //     alt="nr"
                                                //   />
                                                // </Tooltip>
                                              )}
                                            </div>
                                            <div className="repost-loop">
                                              <p>
                                                {" "}
                                                <RepeatIcon className="repeat" />
                                                {row?.reposts?.length
                                                  ? row?.reposts?.length
                                                  : 0}{" "}
                                                reposts
                                              </p>
                                            </div>
                                            <div className="repost-loop">
                                              <p>
                                                {" "}
                                                <FavoriteIcon className="unfav" />
                                                {row?.fav_count
                                                  ? row?.fav_count
                                                  : 0}{" "}
                                                Favorite
                                              </p>
                                            </div>
                                          </div>
                                          <div className="music-timing">
                                            <p>
                                              {row?.players?.length
                                                ? row?.players?.length
                                                : 0}{" "}
                                              Plays
                                            </p>
                                          </div>
                                        </div>
                                        <div className="like-dot">
                                          <div className="share-btn">
                                            <Tooltip
                                              placement="top"
                                              title={
                                                row?.isRepost === true
                                                  ? "Unrepost"
                                                  : "Repost"
                                              }
                                            >
                                              <RepeatIcon
                                                className={
                                                  row?.isRepost === true
                                                    ? "repeat"
                                                    : "Repost"
                                                }
                                                onClick={() => {
                                                  editSong({
                                                    id: row?._id,
                                                    status: "repost",
                                                  });
                                                }}
                                              />
                                            </Tooltip>

                                            <Tooltip
                                              placement="top"
                                              title={
                                                (row?.userFav === true) === true
                                                  ? "Remove from Favourite"
                                                  : "Add to Favourite"
                                              }
                                            >
                                              <FavoriteIcon
                                                className={
                                                  row?.userFav === true
                                                    ? "unfav"
                                                    : "Repost"
                                                }
                                                onClick={() => {
                                                  addFavorite(row?._id);
                                                }}
                                              />
                                            </Tooltip>

                                            <SendTrack
                                              handleModelClose2={
                                                handleModelClose2
                                              }
                                              handleOpen2={handleOpen2}
                                              openModel2={openModel2}
                                              id={`/trackpage/${row?._id}`}
                                            />
                                          </div>
                                          <div className="music-timing">
                                            {row?.isMine === true ? (
                                              <Switch
                                                {...label}
                                                checked={row?.opentoeveryone}
                                                onClick={() => {
                                                  editSong({
                                                    id: row?._id,
                                                    status: "open",
                                                  });
                                                }}
                                              />
                                            ) : (
                                              <></>
                                            )}
                                            <MoreHorizIcon
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                handleClick(e);
                                                setsongid(row?._id);
                                                setRows(row);
                                              }}
                                            />

                                            <Popover
                                              id={id}
                                              open={open}
                                              anchorEl={anchorEl}
                                              onClose={handleClose}
                                              anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                              }}
                                              transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                              }}
                                              className="playlist-popover"
                                            >
                                              <div
                                                className="playlist-more-div"
                                                onClick={() => addPlay(row._id)}
                                              >
                                                Add to Playlists
                                              </div>
                                              <div
                                                className="playlist-more-div"
                                                onClick={(e) => {
                                                  navigate(
                                                    `/trackpage/${rows._id}`
                                                  );
                                                }}
                                              >
                                                Visit Track Page
                                              </div>
                                              <div className="playlist-more-div">
                                                Visit Artist Page
                                              </div>
                                              {params._id == isOwner ?
                                                <div className="playlist-more-div" onClick={() => { handleDeleteSong(rows?._id, 'music') }}>
                                                  Delete Track
                                                </div> :
                                                null
                                              }
                                            </Popover>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </>
                            );
                          })}
                      </>
                    ) : (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="feed-music-box">
                          <div className="music-detail">
                            <div className="album-detail">
                              <div className="no-music">
                                <div className="time-music">
                                  <div className="music-name">
                                    <div className="newno">
                                      <img src={nodata2} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </CustomTabPanel>

                <CustomTabPanel
                  value={value}
                  index={1}
                  className="cust-pannel-artistpage"
                >
                  {album.length > 0 ? (
                    <>
                      {album &&
                        album.map((row, index) => {
                          return (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                key={row?._id}
                              >
                                <div className="feed-music-box">
                                  <div className="music-detail">
                                    <div className="album-img">
                                      <img
                                        src={
                                          row?.image &&
                                            !row?.image?.includes("localhost")
                                            ? row?.image
                                            : ava1
                                        }
                                        alt={Album}
                                      />
                                    </div>

                                    <div className="album-detail">
                                      <div className="music-time">
                                        <div className="time-music">
                                          <div
                                            className="music-name"
                                            style={{ textAlign: "left" }}
                                          >
                                            <div
                                              onClick={(e) => {
                                                navigate(
                                                  `/albumpage/${row._id}`,
                                                  { state: { row } }
                                                );
                                              }}
                                            >
                                              {row?.playlist}
                                            </div>
                                          </div>
                                          <div className="link-badge">
                                            Created By{" "}
                                            <Link to="">
                                              {row?.createUser?.name}
                                            </Link>
                                          </div>
                                        </div>
                                        <div className="music-timing"></div>
                                      </div>
                                      <div className="loop-times">
                                        <div className="loop-part">
                                          <div className="company-logo">
                                            {(audioDurations[row?.audio] !==
                                              undefined &&
                                              row?.nft_type === "nor") ||
                                              row?.isMine === true ? (
                                              <img
                                                onClick={() => {
                                                  createMusic(row?._id);
                                                  setAudiosource(row?.audio);
                                                }}
                                                src={Companylogo}
                                                alt={Companylogo}
                                              />
                                            ) : (
                                              <img
                                                src={Companylogo}
                                                alt={Companylogo}
                                              />
                                            )}
                                          </div>
                                          <div className="repost-loop">
                                            <p>
                                              {" "}
                                              <RepeatIcon className="repeat" />
                                              {row?.reposts?.length
                                                ? row?.reposts?.length
                                                : 0}{" "}
                                              reposts
                                            </p>
                                          </div>
                                          <div className="repost-loop">
                                            <p>
                                              {" "}
                                              <FavoriteIcon className="unfav" />
                                              {row?.fav_count
                                                ? row?.fav_count
                                                : 0}{" "}
                                              Favorite
                                            </p>
                                          </div>
                                        </div>
                                        <div className="music-timing">
                                          <p>
                                            {row?.players?.length
                                              ? row?.players?.length
                                              : 0}{" "}
                                            Plays
                                          </p>
                                        </div>
                                      </div>
                                      <div className="like-dot">
                                        <div className="share-btn">
                                          <Tooltip
                                            placement="top"
                                            title={
                                              row?.isRepost === true
                                                ? "Unrepost"
                                                : "Repost"
                                            }
                                          >
                                            <RepeatIcon
                                              className={
                                                row?.isRepost === true
                                                  ? "repeat"
                                                  : "Repost"
                                              }
                                              onClick={() => {
                                                editAlbums({
                                                  id: row?._id,
                                                  status: "repost",
                                                });
                                              }}
                                            />
                                          </Tooltip>

                                          <Tooltip
                                            placement="top"
                                            title={
                                              (row?.userFav === true) === true
                                                ? "Remove from Favourite"
                                                : "Add to Favourite"
                                            }
                                          >
                                            <FavoriteIcon
                                              className={
                                                row?.userFav === true
                                                  ? "unfav"
                                                  : "Repost"
                                              }
                                              onClick={() => {
                                                addalbumFavorite(row?._id);
                                              }}
                                            />
                                          </Tooltip>

                                          <SendTrack
                                            handleModelClose2={
                                              handleModelClose2
                                            }
                                            handleOpen2={handleOpen2}
                                            openModel2={openModel2}
                                            id={`/trackpage/${row?._id}`}
                                          />
                                        </div>
                                        <div className="music-timing">
                                          {row?.isMine === true ? (
                                            <Switch
                                              {...label}
                                              checked={row?.opentoeveryone}
                                              onClick={() => {
                                                editSong({
                                                  id: row?._id,
                                                  status: "open",
                                                });
                                              }}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                          <MoreHorizIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              handleClick(e);
                                              setsongid(row?._id);
                                              setRows(row);
                                            }}
                                          />

                                          <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "left",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "right",
                                            }}
                                            className="playlist-popover"
                                          >
                                            <div
                                              className="playlist-more-div"
                                              onClick={(e) => {
                                                navigate(
                                                  `/albumpage/${rows._id}`,
                                                  { state: { rows } }
                                                );
                                              }}
                                            >
                                              Visit Album Page
                                            </div>
                                            <div className="playlist-more-div">
                                              Visit Artist Page
                                            </div>
                                            <div className="playlist-more-div" onClick={() => { handleDeleteSong(rows?._id, 'album') }}>
                                              Delete Album
                                            </div>
                                          </Popover>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                            </>
                          );
                        })}
                    </>
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="feed-music-box">
                        <div className="music-detail">
                          <div className="album-detail">
                            <div className="no-music">
                              <div className="time-music">
                                <div className="music-name">
                                  <div className="newno">
                                    <img src={nodata2} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  )}
                </CustomTabPanel>

                <CustomTabPanel
                  value={value}
                  index={2}
                  className="cust-pannel-artistpage"
                >
                  {playlist.length > 0 ? (
                    <>
                      {playlist &&
                        playlist.map((row, index) => {
                          return (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                key={row?._id}
                              >
                                <div className="feed-music-box">
                                  <div className="music-detail">
                                    <div className="album-img">
                                      <img
                                        src={
                                          row?.image &&
                                            !row?.image?.includes("localhost")
                                            ? row?.image
                                            : ava1
                                        }
                                        alt={Album}
                                      />
                                    </div>

                                    <div className="album-detail">
                                      <div className="music-time">
                                        <div className="time-music">
                                          <div
                                            className="music-name"
                                            style={{ textAlign: "left" }}
                                          >
                                            <div
                                              onClick={(e) => {
                                                navigate(
                                                  `/showplaylist/${row._id}`,
                                                  { state: { row } }
                                                );
                                              }}
                                            >
                                              {row?.playlist}
                                            </div>
                                          </div>
                                          <div className="link-badge">
                                            Created By{" "}
                                            <Link to="">
                                              {row?.createUser?.name}
                                            </Link>
                                          </div>
                                        </div>
                                        <div className="music-timing"></div>
                                      </div>
                                      <div className="loop-times">
                                        <div className="loop-part">
                                          <div className="company-logo">
                                            {(audioDurations[row?.audio] !==
                                              undefined &&
                                              row?.nft_type === "nor") ||
                                              row?.isMine === true ? (
                                              <img
                                                onClick={() => {
                                                  createMusic(row?._id);
                                                  setAudiosource(row?.audio);
                                                }}
                                                src={Companylogo}
                                                alt={Companylogo}
                                              />
                                            ) : (
                                              <img
                                                src={Companylogo}
                                                alt={Companylogo}
                                              />
                                            )}
                                          </div>
                                          <div className="repost-loop">
                                            <p>
                                              {" "}
                                              <RepeatIcon className="repeat" />
                                              {row?.reposts?.length
                                                ? row?.reposts?.length
                                                : 0}{" "}
                                              reposts
                                            </p>
                                          </div>
                                          <div className="repost-loop">
                                            <p>
                                              {" "}
                                              <FavoriteIcon className="unfav" />
                                              {row?.fav_count
                                                ? row?.fav_count
                                                : 0}{" "}
                                              Favorite
                                            </p>
                                          </div>
                                        </div>
                                        <div className="music-timing">
                                          <p>
                                            {row?.players?.length
                                              ? row?.players?.length
                                              : 0}{" "}
                                            Plays
                                          </p>
                                        </div>
                                      </div>
                                      <div className="like-dot">
                                        <div className="share-btn">
                                          <Tooltip
                                            placement="top"
                                            title={
                                              row?.isRepost === true
                                                ? "Unrepost"
                                                : "Repost"
                                            }
                                          >
                                            <RepeatIcon
                                              className={
                                                row?.isRepost === true
                                                  ? "repeat"
                                                  : "Repost"
                                              }
                                              onClick={() => {
                                                editPlaylists({
                                                  id: row?._id,
                                                  status: "repost",
                                                });
                                              }}
                                            />
                                          </Tooltip>

                                          <Tooltip
                                            placement="top"
                                            title={
                                              (row?.userFav === true) === true
                                                ? "Remove from Favourite"
                                                : "Add to Favourite"
                                            }
                                          >
                                            <FavoriteIcon
                                              className={
                                                row?.userFav === true
                                                  ? "unfav"
                                                  : "Repost"
                                              }
                                              onClick={() => {
                                                addplayFavorite(row?._id);
                                              }}
                                            />
                                          </Tooltip>
                                        </div>
                                        <div className="music-timing">
                                          {row?.isMine === true ? (
                                            <Switch
                                              {...label}
                                              checked={row?.opentoeveryone}
                                              onClick={() => {
                                                editSong({
                                                  id: row?._id,
                                                  status: "open",
                                                });
                                              }}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                          <MoreHorizIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              handleClick(e);
                                              setsongid(row?._id);
                                              setRows(row);
                                            }}
                                          />

                                          <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "left",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "right",
                                            }}
                                            className="playlist-popover"
                                          >
                                            <div
                                              className="playlist-more-div"
                                              onClick={(e) => {
                                                navigate(
                                                  `/showplaylist/${rows._id}`,
                                                  { state: { rows } }
                                                );
                                              }}
                                            >
                                              Visit Playlist Page
                                            </div>
                                            <div className="playlist-more-div">
                                              Visit Artist Page
                                            </div>
                                            <div className="playlist-more-div" onClick={() => { handleDeleteSong(rows?._id, 'playlist') }}>
                                              Delete Playlist
                                            </div>
                                          </Popover>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                            </>
                          );
                        })}
                    </>
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="feed-music-box">
                        <div className="music-detail">
                          <div className="album-detail">
                            <div className="no-music">
                              <div className="time-music">
                                <div className="music-name">
                                  <div className="newno">
                                    <img src={nodata2} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  )}
                </CustomTabPanel>

                <CustomTabPanel
                  value={value}
                  index={3}
                  className="cust-pannel-artistpage"
                >
                  <Profile prf={prf} setPrf={setPrf} />
                </CustomTabPanel>

              </Grid>
            </Grid>

            <Modal
              keepMounted
              open={openModel}
              onClose={handleModelClose}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <Box sx={style2}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    color: "var(--clr-font)",
                  }}
                >
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      borderBottom: "1px solid var(--clr-search-border)",
                      display: "flex",
                    }}
                  >
                    <Grid xs={1} sm={1} md={1} lg={1} xl={1}>
                      <div>
                        <CloseIcon onClick={handleModelClose} />
                      </div>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={11} xl={11}>
                      <div className="edit-playlist-title">Add to Playlist</div>
                    </Grid>
                  </Grid>

                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div id="search-wrap2">
                      <InputBase
                        id="search-input"
                        sx={{ ml: 1, flex: 1 }}
                        value={playname ? playname : ""}
                        onChange={(e) => setPlayname(e.target.value)}
                        placeholder="Search your music"
                        inputProps={{ "aria-label": "search google maps" }}
                      />
                      <IconButton
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        id="search-input"
                      >
                        <SearchIcon />
                      </IconButton>
                    </div>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div
                      className="new-playlist"
                      onClick={(e) => {
                        navigate("/createplaylist", { state: { song_id } });
                      }}
                    >
                      <AddCircleIcon />
                      <div>New Playlist</div>
                    </div>
                  </Grid>
                  {play.length > 0 ? (
                    <>
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="grid-height"
                      >
                        {play &&
                          play.map((row, ind) => {
                            return (
                              <Grid
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                key={row?._id}
                                onClick={() => {
                                  handleAdd(row?._id);
                                }}
                              >
                                <div className="new-playlist created">
                                  <div className="d-flex">
                                    {row?.image &&
                                      row?.image &&
                                      !row?.image?.profilePicture?.includes(
                                        "localhost"
                                      ) ? (
                                      <img src={row?.image} alt="bg2" />
                                    ) : (
                                      <img src={ava1} alt="bg2" />
                                    )}
                                    {/* <img src={row?.image} alt='img' /> */}
                                  </div>
                                  <div>{row?.playlist}</div>
                                </div>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <div className="newno">
                        <img src={nodata2} />
                      </div>
                    </>
                  )}
                </Grid>
              </Box>
            </Modal>
          </Container>

          {localStorage.getItem("playsong") === "true" ? (
            <>
              {audiosources.length > 0 ? (
                <Tracks audiosources={audiosources} tracks={tracks} />
              ) : (
                <></>
              )}{" "}
            </>
          ) : (
            <></>
          )}
          {/* {(localStorage.getItem("playsong") === "false" && audiosource) ===
            true ? (
            <Track
              audiosource={audiosource}
              isplay={isplay}
              setisplay={setisplay}
            />
          ) : (
            <>
              {(localStorage.getItem("playsong") === "false" &&
                audio !== null) === true ? (
                <Track
                  audiosource={audio}
                  isplay={isplay}
                  setisplay={setisplay}
                />
              ) : (
                <></>
              )}{" "}
            </>
          )} */}

          {(localStorage.getItem("playsong") === "false" && audiosource) ===
            true ? (
            <Musiccontrol
              audiosource={audiosource}
              isplay={isplay}
              setisplay={setisplay}
              data={MusicData}
              getArtist={getArtist}
              component={component}
            />
          ) : (
            <>
              {(localStorage.getItem("playsong") === "false" &&
                audio !== null) === true ? (
                <Musiccontrol
                  audiosource={audio}
                  isplay={isplay}
                  setisplay={setisplay}
                  data={MusicData}
                  getArtist={getArtist}
                  component={component}

                />
              ) : (
                <></>
              )}{" "}
            </>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default ArtistPage;
