import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Track from "../Music-traxk/Track";
import "./Profile.css";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import mainBg from "../../img/profile-main-bg.png";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Axios from "../../Axios";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import Visibility1 from "@mui/icons-material/Visibility";
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
import VisibilityOff from "../../img/eyelash.svg";
import VisibilityOff1 from "../../img/eyelash.svg";
import Avatar from "@mui/material/Avatar";
// import ava from "../../img/ava.jpg";
import ava from "../../img/ava1.webp";
import EditIcon from "@mui/icons-material/Edit";
import Header from '../Header/Header';
import { makeStyles } from "@mui/styles";
// import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { X } from "@mui/icons-material";
import { BsTwitterX } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa";
import Stack from '@mui/material/Stack';
import { IoLogoInstagram } from "react-icons/io5";
import { BsFacebook } from "react-icons/bs";
import { FaGlobeAmericas } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
const drawerWidth = 280;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  cardbg: {
    borderRadius: "12px !important",
    background: "rgba(255, 255, 255, 0.10)",
    boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
    backdropFilter: "blur(37.5px)",
    padding: "0 !important",

  },

  pwrback: {
    borderRadius: "12px !important",
    border: "1px solid rgba(255, 255, 255, 0.20)",
    background: "#000000 !important",
  },

  learn: {
    borderRadius: "12px !important",
    background: 'transparent !important',
    boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
  },

  containWidth: {
    maxWidth: '1440px',
    margin: 'auto',
    background: 'none !important',
    padding: '0 !important',
    boxShadow: 'none !important',

    "@media (max-width: 1199.98px)": {
      padding: '20px !important',
    },
  }

});



function Profile({ prf, setPrf }) {

  console.log(prf, 'prf');


  const classes = useStyles();
  const theme = useTheme();

  const [login, setLogin] = useState(false);

  const [openpass, setOpenPass] = React.useState(false);
  const handleOpenTime = () => setOpenPass(true);
  const handleCloseTime = () => setOpenPass(false);

  const navigate = useNavigate();
  const email = useRef(null);
  const name = useRef(null);
  const lemail = useRef(null);
  const lpassword = useRef(null);
  const lpassword1 = useRef(null);
  const inpassref = useRef(null);
  const [nameerr, setnameerr] = useState(null);
  const [counts, setCounts] = React.useState(0)
  const [fburl, setfburl] = React.useState()
  const [yturl, setyturl] = React.useState()
  const [urlerr, setUrlerr] = React.useState('');
  const [instaurl, setinstaurl] = React.useState()
  const [twitterurl, settwitterurl] = React.useState()
  const [lemailerr, setlemailerr] = useState(null);
  const [lpassworderr, setlpassworderr] = useState(null);
  const [lpassworderr1, setlpassworderr1] = useState(null);
  const [islogin, setIslogin] = useState(false);
  //const token = window.localStorage.getItem("iphephile")
  const tokens = localStorage.getItem("iphephile");
  const [profileData, setProfileData] = useState({});
  // console.log(lpassworderr1, 'lpassworderr1');
  const [load, setLoad] = useState()

  useEffect(() => {
    const store = localStorage.setItem("nickname", "mynameglad");
    if (localStorage.getItem("nickname")) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, []);

  const [update, setupdate] = useState(false);
  const fburls = useRef(null);
  const [updateemail, setupdateemail] = useState(false);
  const [emailerr, setemailerr] = useState(null);
  const [passworderr, setpassworderr] = useState(null);
  const [cpassworderr, setcpassworderr] = useState(null);
  const [image, setImage] = useState(null);
  const [isimage, setisImage] = useState(false);
  const [profiledetail, setprofileDetail] = useState({});
  const [verify, setverify] = useState(false)

  useEffect(() => {
    setUrlerr("")
  }, [counts])

  const lemailhandleChange = () => {
    const emailRegex = new RegExp(
      /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
      "gm"
    );
    const inputValue = lemail.current.value;
    setupdateemail(inputValue)
    if (inputValue.trim() === "") {
      setemailerr("");
    } else if (!emailRegex.test(inputValue)) {
      setemailerr("*Invalid Email");
    } else {
      setemailerr("");
      setverify(true)
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const [showPassword1, setShowPassword1] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const [password, setPassword] = React.useState(false);

  const handleClickPassword = () => setPassword((show) => !show);

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const emailhandleChange = () => {
    const emailRegex = new RegExp(
      /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
      "gm"
    );
    const inputValue = email.current.value;

    if (inputValue.trim() === "") {
      setemailerr("");
    } else if (!emailRegex.test(inputValue)) {
      setemailerr("*Invalid Email");
    } else {
      setemailerr("");
    }
  };

  const handleChange1 = () => {
    setcpassworderr("");
    setpassworderr("");
    setlpassworderr1("");
    setlpassworderr("");
    setemailerr("");
    setnameerr("");
  };

  const handleChange2 = () => {
    setlpassworderr("");
    setlpassworderr1("");
    setlemailerr("");
  };




  const getProfile = async () => {
    try {

      await Axios.get(`/profile/getprofile`, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            setupdate(true)
            setProfileData(res?.data?.result);
            setUpdateName(res?.data?.result?.name);
            setupdateemail(res?.data?.result?.email);
            setImage(res?.data?.result?.profilePicture)
            setfburl(res.data?.result?.fburl)
            settwitterurl(res.data?.result?.twitterurl)
            setyturl(res.data?.result?.yturl)
            setinstaurl(res.data?.result?.instaurl)
          } else {
            toast.error("Can't Fetch Detail");
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    } catch (error) {
      // console.log(error);
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const hiddenFileInput = useRef(null);


  const handleImageChange = async (event) => {
    const files = event.target.files[0];

    try {
      let formData = await new FormData();
      formData.append("image", files);
      const fileName = files.name
      var idxDot = fileName.lastIndexOf(".") + 1;
      var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        const { data } = await Axios.post("/updateDp", formData);
        if (data.result) {
          // setLogo(data.result)

          setisImage(true)

          setImage(data.result);
          const updateData = {
            name: profileData.name,
            coverImage: profileData.coverImage,
            amount: profileData?.amount,
            account: profileData?.account,
            profilePicture: data.result,
            fburl: fburl,
            yturl: yturl,
            twitterurl: twitterurl,
            instaurl: instaurl
          };
          await Axios.post("/updateProfile", updateData, {
            headers: {
              Authorization: tokens,
            },
          }).then((res) => {

            if (res.data.success) {
              toast.success("Image uploaded Successfully", {
                duration: 500, // Set the duration for which the toast is visible
              });
              getProfile();
              // setPrf(!prf)
            }
          });
        }
      } else {
        setImage(null)
        toast.error("Only jpg, jpeg, png and gif files are allowed!");
      }

    } catch (error) {
      setisImage(false)

      // console.log(error, "err");
    }
  };


  const history = useLocation();
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //   upload image

  const hiddenBannerInput = useRef(null);
  const [imageBanner, setImageBanner] = useState(null);
  const [updateName, setUpdateName] = useState();
  const isMount = useRef(false)

  const handleImageBannerChange = async (event) => {
    const files = event.target.files[0];

    try {
      let formData = await new FormData();
      formData.append("image", files);
      const fileName = files.name
      var idxDot = fileName.lastIndexOf(".") + 1;
      var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        const { data } = await Axios.post("/updateDp", formData);
        if (data.result) {
          // setLogo(data.result)
          setisImage(true)

          setImage(data.result);
          const updateData = {
            name: profileData.name,
            // phone: profileData?.result?.phone,
            coverImage: data.result,
            profilePicture: profileData.profilePicture,
            amount: profileData?.amount,
            account: profileData?.account,
            fburl: fburl,
            yturl: yturl,
            twitterurl: twitterurl,
            instaurl: instaurl
          };
          await Axios.post("/updateProfile", updateData, {
            headers: {
              Authorization: tokens,
            },
          }).then((res) => {

            if (res.data.success) {
              toast.success("Image uploaded Successfully");
              getProfile();
              // setPrf(!prf)
            }
          });
        }
      } else {
        setImage(null)
        toast.error("Only jpg, jpeg, png and gif files are allowed!");
      }

    } catch (error) {
      setisImage(false)
      // console.log(error, "err");
    }
  };



  const handleBannerClick = (event) => {
    hiddenBannerInput.current.click();
  };

  const updateProfile = async () => {
    var updateData = {};
    console.log(updateData, 'updateData');

    let reg = /^[0-9]+$/;
    try {
      if (name?.current?.value === "") {
        setnameerr("*Name Required");
      } else if (lpassword?.current?.value !== "" && lpassword1?.current?.value === "") {
        setlpassworderr1("*New Password Required");
        console.log('new password required');
        // handlePasswordChange()
      }
      else if (urlerr !== '') {
        setUrlerr(urlerr)
      }
      else {
        setLoad(true)
        if (lpassword1?.current?.value != "") {
          const newPassword = lpassword1?.current?.value;
          updateData.name = name?.current?.value;
          updateData.newpassword = lpassword1?.current?.value;
          updateData.profilePicture = profileData.profilePicture;
          updateData.amount = profileData?.amount;
          updateData.account = profileData?.account;
          updateData.coverImage = profileData.coverImage;
          updateData.oldpassword = lpassword?.current?.value;
          updateData.fburl = fburl;
          updateData.yturl = yturl;
          updateData.twitterurl = twitterurl;
          updateData.instaurl = instaurl
        } else {
          updateData = {
            name: name?.current?.value,
            // phone : updateInputPhone
            amount: profileData?.amount,
            account: profileData?.account,
            coverImage: profileData.coverImage,
            profilePicture: profileData.profilePicture,
            fburl: fburl,
            yturl: yturl,
            twitterurl: twitterurl,
            instaurl: instaurl
          };
        }

        console.log(updateData, "updateData");
        await Axios.post("/updateProfile", updateData,
          {
            headers: {
              Authorization: tokens,
            },
          }).then((res) => {

            if (res?.data?.success) {
              setupdate(false);
              // setPrf(!prf)
              toast.success("Profile Updated Successfully", {
                duration: 1000, // Set the duration for which the toast is visible
              });
              if (history?.state?.emailVerify === 4) {
                setLoad(false)
                setTimeout(() => {
                  navigate('/feed');
                }, 1000);
              }
              else {
                setLoad(false)
                lpassword1.current.value = ''
                lpassword.current.value = ''
                getProfile();
                // setPrf(!prf)
              }

            } else {
              if (res?.data?.message == "Incorrect password") {
                setLoad(false)
                setlpassworderr("Incorrect old password");
              }
              else {
                setLoad(false)
                toast.error(res?.data?.message);
              }
              setupdate(false);

            }
          });
      }
    } catch (error) {
      setLoad(false)
      // console.log(error);
    }
  };
  useEffect(() => {

    if (!isMount.current) {
      getProfile()
      if (history?.state?.emailVerify === 0) {

        toast.success("Email Updated Sucessfully", {
          duration: 1000, // Set the duration for which the toast is visible
        })
        navigate('/profile')

      } else if (history?.state?.emailVerify === 1) {
        toast.error("Email Cannot Updated")
      }
      else if (history?.state?.emailVerify === 4) {
        toast.success("Please Update Your Profile", {
          duration: 1000, // Set the duration for which the toast is visible
        })
      }
      isMount.current = true
    }

  }, []);



  const changeEmail = async () => {
    var updateData = {};
    let reg = /^[0-9]+$/;
    try {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!lemail.current.value) {
        setemailerr("*Email is Required");
      } else if (!emailRegex.test(lemail.current.value)) {
        setemailerr("*Invalid Email");
      }
      else {
        updateData = {
          amount: profileData?.amount,
          account: profileData?.account,
          email: updateemail
        }

        console.log(updateData, "updateData");
        await Axios.post("/change_email", updateData).then((res) => {

          if (res?.data?.success) {
            setupdate(false);
            toast.success(res?.data?.message, {
              duration: 1000, // Set the duration for which the toast is visible
            });
            getProfile();
            if (history?.state?.emailVerify === 4) {
              setTimeout(() => {
                navigate('/feed');
              }, 1000);
            }
            else {
              getProfile();
            }
          } else {
            if (res?.data?.message == "Incorrect password") {
              setlpassworderr("Incorrect password");
            }
            else {
              toast.error(res?.data?.message);
            }
            setupdate(false);

          }
        });
      }

    }
    catch (error) {
      // console.log(error);
    }
  };
  const getTitle = (data) => {
    switch (data) {
      case 0:
        return "Twitter Url";
      case 1:
        return "Instagram Url";
      case 2:
        return "Youtube Url";
      case 3:
        return "Facebook Url";
    }
  }



  const getValue = (data) => {
    if (counts === 0 && fburls.current) {
      fburls.current.value = twitterurl !== undefined ? twitterurl : ''
    }
    else if (counts === 1 && fburls.current) {
      fburls.current.value = instaurl !== undefined ? instaurl : ''
    }
    else if (counts === 2 && fburls.current) {
      fburls.current.value = yturl !== undefined ? yturl : ""
    }
    else if (counts === 3 && fburls.current) {
      fburls.current.value = fburl !== undefined ? fburl : ""
    }
    else {
      return twitterurl !== undefined ? twitterurl : ''
    }
  }

  const setuserValue = (e) => {
    const urlregex = new RegExp(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig)
    const twitterUrlRegex = new RegExp(/^https?:\/\/(www\.)?twitter\.com\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i);
    const ytUrlRegex = new RegExp(/^https?:\/\/(www\.)?youtube\.com\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i);
    const instaUrlRegex = new RegExp(/^https?:\/\/(www\.)?instagram\.com\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i);
    const fbUrlRegex = new RegExp(/^https?:\/\/(www\.)?facebook\.com\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i);

    if (counts === 0) {
      settwitterurl(e.target.value)
      if (e.target.value && !twitterUrlRegex.test(e.target.value)) {
        setUrlerr('*Enter Url Valid Url link')
      }
      else {
        setUrlerr('')
      }
    }
    else if (counts === 1) {
      setinstaurl(e.target.value)
      if (e.target.value && !instaUrlRegex.test(e.target.value)) {
        setUrlerr('*Enter Url Valid Url link')
      }
      else {
        setUrlerr('')
      }
    }
    else if (counts === 2) {
      setyturl(e.target.value)
      if (e.target.value && !ytUrlRegex.test(e.target.value)) {
        setUrlerr('*Enter Url Valid Url link')
      }
      else {
        setUrlerr('')
      }
    }
    else if (counts === 3) {
      setfburl(e.target.value)
      if (e.target.value && !fbUrlRegex.test(e.target.value)) {
        setUrlerr('*Enter Url Valid Url link')
      }
      else {
        setUrlerr('')
      }
    }

  }

  const handlePasswordChange = () => {
    const passwordregex = new RegExp(
      /(^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])(?=.*\d).{8,16}$)+/,
      "gm"
    );
    const inputValue = lpassword1.current.value;
    // setlpassworderr1(null)
    if (inputValue.trim() === "") {
      setlpassworderr1("")
    } else if (!(passwordregex.test(inputValue))) {
      setlpassworderr1("Password must be at least 8 characters and It contains Minimum one uppercase and Special Characters")
    } else {
      setlpassworderr1("")
    }
  }

  const HandleNameUpdate = (e) => {
    setUpdateName(e.target.value);
    setnameerr("");
  };

  //   upload image


  return (
    <>
      <ToastContainer position="top-right" />
      <div className="UploadMusic profile">
        <Grid container spacing={2} className="profile-pg-grid">
          <Grid
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={8}
            className="upload-grid  profile-setting"
          >
            <div className="profile-setting">

              <div className="inner-box2">
                <div className="inner-popup-pad">
                  <div className="border-bg2"></div>
                  <div className="description-UploadMusic">
                    <div
                      className="profile-banner"
                      onClick={() => { handleBannerClick() }}
                      style={{ cursor: "pointer" }}
                    >

                      <div className="profile-banner-size">
                        {profileData.coverImage && !profileData?.coverImage?.includes("localhost") ? (
                          <Avatar
                            alt="Remy Sharp"
                            src={profileData?.coverImage}
                            sx={{
                              width: "100%",
                              height: 208,
                              borderRadius: "unset",
                            }}
                          />
                        ) : (
                          <Avatar
                            alt="Remy Sharp"
                            src={mainBg}
                            sx={{
                              width: '100%',
                              height: 208,
                              borderRadius: "none",
                            }}
                          />
                        )}
                      </div>

                      <input
                        id="image-upload-input"
                        type="file"
                        accept="image/*"
                        onChange={handleImageBannerChange}
                        ref={hiddenBannerInput}
                        style={{ display: "none" }}
                      />
                      <Button
                        className="image-upload-button"
                        id="image-upload-button-banner"
                      >
                        <CloudUploadIcon />
                      </Button>
                    </div>

                    <div className="profile-img-outer">
                      <div className="image-upload-container">
                        <div className="box-decoration">
                          <div
                            className="profile-img"
                            onClick={() => { handleClick() }}
                            style={{ cursor: "pointer" }}
                          >
                            {/* <div className='profile-img-inner'>
                                {image ? (
                                <img src={URL.createObjectURL(image)} alt="upload-profile-image" className="img-display-after" />
                                ) : (
                                <img src={subBg} alt="upload-profile-image" className="img-display-before" />
                                )}
                                </div> */}
                            <div className="profile-img">
                              {/* {profileData.profilePicture && !profileData?.profilePicture?.includes("localhost") ? ( */}
                              {profileData.profilePicture ? (
                                <Avatar
                                  alt="Remy Sharp"
                                  src={profileData.profilePicture}
                                  sx={{
                                    width: 156,
                                    height: 156,
                                    border: "3px solid #fff",
                                  }}
                                />
                              ) : (
                                <Avatar
                                  alt="Remy Sharp"
                                  src={ava}
                                  sx={{
                                    width: 156,
                                    height: 156,
                                    border: "3px solid #fff",
                                  }}
                                />
                              )}
                              <div class="edit">
                                <EditIcon />
                              </div>
                              <input
                                id="image-upload-input"
                                type="file"
                                accept="image/*"
                                // onChange={handleinfo}

                                // ref={clickFileInput}
                                style={{ display: "none" }}
                              />
                            </div>

                            <input
                              id="image-upload-input"
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              ref={hiddenFileInput}
                              style={{ display: "none" }}
                            />
                            {/* <Button
                                className="image-upload-button"
                                id="image-upload-button"
                                onClick={handleUploadButtonClick}
                                >
                                <CloudUploadIcon/>
                                </Button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="inner-popup">
                    <div className="inputbox">
                      <Typography
                        variant="p"
                        component="p"
                        className="email"
                      >
                        Name :
                      </Typography>
                      <FormControl
                        sx={{ m: 1, width: "100%" }}
                        variant="outlined"
                        className="input-box"
                      >
                        <OutlinedInput
                          className="input-box-txt"
                          inputRef={name}
                          value={updateName}
                          onChange={HandleNameUpdate}
                          id="outlined-adornment-weight"
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            "aria-label": "weight",
                          }}
                        />
                      </FormControl>
                    </div>
                    {nameerr ? (
                      <div
                        className="errorDiv"
                        style={{ textAlign: "center" }}
                      >
                        {nameerr}
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="inputbox">
                      <Typography
                        variant="p"
                        component="p"
                        className="email"
                      >
                        Email :
                      </Typography>

                      <FormControl
                        sx={{ m: 1, width: "100%" }}
                        variant="outlined"
                        className="input-box"
                      >
                        <OutlinedInput
                          className="input-box-txt"
                          inputRef={lemail}
                          value={updateemail ? updateemail : ''}
                          disabled={profileData.signup_type === "wallet" && profileData.email === "" ? false : true}
                          onChange={() => {
                            lemailhandleChange();
                          }}
                          id="outlined-adornment-weight"
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            "aria-label": "weight",
                          }}
                        />

                      </FormControl>

                      {lemailerr ? (
                        <div className="errorDiv">{lemailerr}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {emailerr ? (
                      <div className="errorDiv">{emailerr}</div>
                    ) : (
                      <>
                      </>
                    )}
                    <div className="verify-email">
                      {profileData.signup_type === "wallet" && profileData.email === "" ? <div className="submit-btn2">
                        <Link onClick={() => { changeEmail() }}>Verify Email</Link>
                      </div> : <></>}
                    </div>


                    {profileData?.signup_type === "gmail" ? <><div className="inputbox">
                      <div className="pwd-spc">
                        <Typography
                          variant="p"
                          component="p"
                          className="email"
                        >
                          Old Password :
                        </Typography>

                      </div>
                      <FormControl
                        sx={{ m: 1, width: "100%" }}
                        variant="outlined"
                        className="input-box"
                      >
                        <OutlinedInput
                          className="input-box-txt"
                          inputRef={lpassword}
                          onChange={() => {
                            handleChange1();
                          }}
                          id="outlined-adornment-password"
                          type={showPassword1 ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => { handleClickShowPassword1() }}
                                onMouseDown={() => { handleMouseDownPassword1() }}
                                edge="end"
                              >
                                {showPassword1 ? (
                                  <Visibility1 />
                                ) : (
                                  <img
                                    src={VisibilityOff1}
                                    alt={VisibilityOff1}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                        />
                      </FormControl>
                    </div>
                      {lpassworderr ? (
                        <div
                          className="errorDiv"
                          style={{ textAlign: "center" }}
                        >
                          {lpassworderr}
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="inputbox">
                        <div className="pwd-spc">
                          <Typography
                            variant="p"
                            component="p"
                            className="email"
                          >
                            New Password :
                          </Typography>

                        </div>
                        <FormControl
                          sx={{ m: 1, width: "100%" }}
                          variant="outlined"
                          className="input-box"
                        >
                          <OutlinedInput
                            className="input-box-txt"
                            inputRef={lpassword1}
                            onChange={() => {
                              handlePasswordChange()
                            }}
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => { handleClickShowPassword() }}
                                  onMouseDown={() => { handleMouseDownPassword() }}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <img
                                      src={VisibilityOff}
                                      alt={VisibilityOff}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                        </FormControl>
                      </div>
                      {lpassworderr1 ? (
                        <div
                          className="errorDiv"
                          style={{ textAlign: "center" }}
                        >
                          {lpassworderr1}
                        </div>
                      ) : (
                        <></>
                      )}</> : <></>}

                    {profileData?.signup_type === "wallet" ? <div className="inputbox">
                      <Typography
                        variant="p"
                        component="p"
                        className="email"
                      >
                        Wallet Address :
                      </Typography>
                      <FormControl
                        sx={{ m: 1, width: "100%" }}
                        variant="outlined"
                        className="input-box"
                      >
                        <OutlinedInput
                          className="input-box-txt"
                          value={profileData?.account ? profileData?.account : '-'}
                          id="outlined-adornment-weight"
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            "aria-label": "weight",
                          }}
                        />
                      </FormControl>
                    </div>
                      : <></>}
                    <Typography
                      variant="p"
                      component="p"
                      className="email"
                    >
                      {getTitle(counts)}
                    </Typography>
                    <FormControl
                      sx={{ m: 1, width: "100%" }}
                      variant="outlined"
                      className="input-box"
                    >
                      <OutlinedInput
                        className="input-box-txt"
                        inputRef={fburls}
                        value={getValue(counts)}
                        onChange={(e) => { setuserValue(e) }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                    {urlerr ? <div className='errorDiv' style={{ textAlign: 'center' }}>{urlerr}</div> : <></>}
                    <div className="profile-follow-stack-div">
                      <Stack direction="row" spacing={4}>

                        <BsTwitterX onClick={() => { setCounts(0) }} />
                        <IoLogoInstagram onClick={() => { setCounts(1) }} />
                        <FaYoutube onClick={() => { setCounts(2) }} />
                        <BsFacebook onClick={() => { setCounts(3) }} />
                      </Stack>
                    </div>
                    <div className="submit-btn">
                      {
                        load ? <Button>Processing...</Button>
                          :
                          <Button onClick={() => { updateProfile() }}>update</Button>
                      }
                    </div>


                    {profileData.signup_type === "wallet" && profileData.email === "" ? <div className="submit-btn">
                      <Button onClick={() => { changeEmail() }}>Update Email</Button>
                    </div> : <></>}
                  </div>

                  <div className="border-bg"></div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Profile;
