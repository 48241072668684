import storage from 'redux-persist/lib/storage'; // Local storage for web
const persistConfig = {
  key: 'root', // Root key for the stored data
  storage
};



export default persistConfig

