import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import consts from "../../Constant";
import { ToastContainer, toast } from "react-toastify";

function PrivateRoute({ children }) {
  const location = useLocation()
  // console.log(location,"locwtion")


  const navigateToLogin = () => {

    let result = location.pathname.includes("/trackpage");
    let resultArtist = location.pathname.includes("/artist");
    let joinresult = location.pathname.includes("/join");
    let createresult = location.pathname.includes("/upload") || location.pathname.includes("/mintnft");

    if (result === true || resultArtist === true || joinresult === true || createresult === true) {

      const message = `${location.pathname}`

      localStorage.setItem("URL", message)

    }

    const stateData = { login: 0 };
    return (
      <>
        <Navigate
          to={'/'}
          state={stateData}
        />
      </>
    );
  };

  return localStorage.getItem("iphephile") !== null ? children : navigateToLogin();
}

export default PrivateRoute;
