import React from "react";
import Header2 from "../Header/Header2";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'
import Track from "../Music-traxk/Track";
import './HotNew.css'
import Container from '@mui/material/Container'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { useTheme } from '@mui/material/styles';
import cart from '../../img/cart.png'
import music from '../../img/music-exp.png'
import exp3 from '../../img/exp3.png'
import exp4 from '../../img/exp4.png'
import exp5 from '../../img/exp5.png'
import exp6 from '../../img/exp6.png'
import exp7 from '../../img/exp7.png'
import exp8 from '../../img/exp8.png'
import exp9 from '../../img/exp9.png'
import exp10 from '../../img/exp10.png'
import exp11 from '../../img/exp11.png'
import p1 from '../../img/p1.png'
import p2 from '../../img/p2.png'
import p3 from '../../img/p3.png'
import p4 from '../../img/p4.png'
import p5 from '../../img/p5.png'
import c1 from '../../img/c1.png'
import c2 from '../../img/c2.png'
import c3 from '../../img/c3.png'
import c4 from '../../img/c4.png'
import i1 from '../../img/i1.png'
import i2 from '../../img/i2.png'
import hotnew_img_profile from '../../img/hotnew-img-profile.png'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import PagesIcon from '@mui/icons-material/Pages';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

/*** tabele **/

function createData(track, artist, added, length, plays) {
  return {
    track,
    artist,
    added,
    length,
    plays,
  };
}

const rows = [
  createData('LONE VOID', 'voltra', '12/18/23', '6:31', '15.1K'),
  createData('Arcando - Mr. Brightside (with Livi…', 'voltra', '12/18/23', '6:31', '15.1K'),
  createData('Skeeyee - Sexyy Red (Love Langu…', 'voltra', '12/18/23', '6:31', '15.1K'),
  createData('Ian Ewing - and thats okay', 'voltra', '12/18/23', '6:31', '15.1K'),
  createData('AMANDA TOVALIN - EN EL CIELO…', 'voltra', '12/18/23', '6:31', '15.1K'),
  createData('Jalaya - Balance', 'voltra', '12/18/23', '6:31', '15.1K'),
  createData('Sublab & Azaleh - Blue Hour', 'voltra', '12/18/23', '6:31', '15.1K'),
  createData('Jelly Bean', 'voltra', '12/18/23', '6:31', '15.1K'),
  createData('PLS&TY - New Color (Oliverse Re…', 'voltra', '12/18/23', '6:31', '15.1K'),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'track',
    numeric: false,
    disablePadding: true,
    label: 'Track Name',
  },
  {
    id: 'artist',
    numeric: true,
    disablePadding: false,
    label: 'Artist',
  },
  {
    id: 'added',
    numeric: true,
    disablePadding: false,
    label: 'Added',
  },
  {
    id: 'length',
    numeric: true,
    disablePadding: false,
    label: 'Length',
  },
  {
    id: 'plays',
    numeric: true,
    disablePadding: false,
    label: 'Plays',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

/** table end */

const content = [

  { clsName: 'explore-card2', heading: 'Trending Playlists', para: 'Explore premium music available to purchase.', img: `${music}` },
  { clsName: 'explore-card3', heading: 'Underground Trending', para: 'Explore premium music available to purchase.', img: `${exp3}` },
  { clsName: 'explore-card4', heading: 'Trending Playlists', para: 'Explore premium music available to purchase.', img: `${exp4}` },
  { clsName: 'explore-card5', heading: 'Best New Releases', para: 'Explore premium music available to purchase.', img: `${exp5}` },
  { clsName: 'explore-card6', heading: 'Under The Radar', para: 'Explore premium music available to purchase.', img: `${exp6}` },
  { clsName: 'explore-card7', heading: 'Top Albums', para: 'Explore premium music available to purchase.', img: `${exp7}` },
  { clsName: 'explore-card8', heading: 'Remixables', para: 'Explore premium music available to purchase.', img: `${exp8}` },
  { clsName: 'explore-card9', heading: 'Most Loved', para: 'Explore premium music available to purchase.', img: `${exp9}` },
  { clsName: 'explore-card10', heading: 'Feeling Lucky?', para: 'Explore premium music available to purchase.', img: `${exp10}` },
  { clsName: 'explore-card11', heading: 'heart break', para: 'Explore premium music available to purchase.', img: `${exp11}` },
]


const content2 = [
  { claNme: 'playlist-card1', text: 'Chill', img: `${p1}` },
  { claNme: 'playlist-card2', text: 'Upbeat', img: `${p2}` },
  { claNme: 'playlist-card3', text: 'Intense', img: `${p3}` },
  { claNme: 'playlist-card4', text: 'Provoking', img: `${p4}` },
  { claNme: 'playlist-card5', text: 'Intimate', img: `${p5}` },
]

const content3 = [
  { img: `${c1}`, txt1: 'Haan808s Time Turners', txt2: 'Haan808 / ماهان اسدی' },
  { img: `${c2}`, txt1: 'Critical Music Selects', txt2: 'Critical Music' },
  { img: `${c3}`, txt1: 'Lofi Xmas', txt2: 'Boarderella' },
  { img: `${c4}`, txt1: 'FUXWITHIT Finds', txt2: 'FUXWITHIT' }
]


const drawerWidth = 280;


function HotNew() {

  const theme = useTheme();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [name, setName] = React.useState();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );

  return (
    <div className="explore hot-and-new-music">
      <Box sx={{ display: 'flex' }}>

      <Header2 name={name} setName={setName} />

        <Box
          className="explore-box hot-and-new-music-box"
          sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '50px', marginBottom: '100px' }}>

          <Box
            sx={{ flexGrow: 1, p: 3 }}
            className="explore-box2"
          >
            <Container maxWidth="md">
              <Grid container>
                <Grid xs={12} sm={12} md={12} lg={4} xl={4}>
                  <div className="hotnew_img_profile-img-outer">
                    <img src={hotnew_img_profile} alt="hotnew_img_profile" />
                  </div>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={8} xl={8}>
                  <div className="hotnew_img_profile_details">
                    <label className="playlist-label">playlist</label>
                    <h2>Hot & New🔥</h2>
                    <p className="by-Audius">By <span>Dreamster</span></p>
                    <div className="mdt-flex mdt-flex-outer">
                      <div className="mdt-flex-inner"><label>modified</label> <span>12/18/23</span></div>
                      <div className="mdt-flex-inner"><label>duration</label> <span>1h 5m</span></div>
                      <div className="mdt-flex-inner"><label>tracks</label> <span>20</span></div>
                    </div>
                    <p className="description-para-music">All the latest hot & new tracks on Dreamster! Prepare to buckle in for a trip through various genres featuring artists you love and new artists you are about to discover. 🔥</p>


                    <div className="mdt-flex rf-flex-block">
                      <div className="mdt-flex-inner rf-flex-block-inner"><PagesIcon /><label>1.87K </label> <span>Reposts</span></div>
                      <div className="mdt-flex-inner rf-flex-block-inner"><FavoriteIcon /><label>1.35M</label> <span>Favorites</span></div>
                    </div>

                    <Stack className="stack-buttons-row" spacing={1} direction="row">
                      <Button className="button-color-1" startIcon={<PlayArrowIcon />} variant="contained">Play</Button>
                      <Button className="button-color-2" startIcon={<ShareIcon />} variant="outlined">Share</Button>
                      <Button className="button-color-3" startIcon={<PagesIcon />} variant="outlined">Repost</Button>
                      <Button className="button-color-4" startIcon={<FavoriteIcon />} variant="outlined">Favorited</Button>
                      <Button className="button-color-5" variant="outlined"><MoreHorizIcon /></Button>
                    </Stack>

                  </div>
                </Grid>
              </Grid>

              <Grid>

                <Box sx={{ width: '100%' }}>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={dense ? 'small' : 'medium'}
                      className="new-hot-table"
                    >
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                      />
                      <TableBody>
                        {visibleRows.map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              onClick={(event) => handleClick(event, row.id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                              sx={{ cursor: 'pointer' }}
                            >

                              <TableCell
                                id={labelId}
                                scope="row"
                              >
                                {row.track}
                              </TableCell>
                              <TableCell align="right">{row.artist}</TableCell>
                              <TableCell align="right">{row.added}</TableCell>
                              <TableCell align="right">{row.length}</TableCell>
                              <TableCell align="right">{row.plays}</TableCell>
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow
                            style={{
                              height: (dense ? 33 : 53) * emptyRows,
                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

              </Grid>
            </Container>

            <div>
              <Track />
            </div>

          </Box>
        </Box>

      </Box>
      {/* <div>
                <Track/>
            </div> */}
    </div>
  );
}

export default HotNew;