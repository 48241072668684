import React, { Component, useEffect, useState, useRef } from "react";
import logo from "../../img/d.dark.png";
import Web3 from "web3";
import consts from "../../Constant";
import {
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../../fireBase/firebase";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
import VisibilityOff from "../../img/eyelash.svg";
import Axios from "../../Axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import "../Login/Login.css";
import "./Register.css";
import "../../Media.css";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import constant from "../../Constant";
import ReCAPTCHA from "react-google-recaptcha";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const Register = () => {
  const history = useLocation();
  const isMounted = useRef(false);

  const [recent, setResent] = useState(false);
  const [recent1, setResent1] = useState(false);



  useEffect(() => {
    email.current.focus();
    if (!isMounted.current) {
      if (history?.state?.emailVerify === 0) {
        toast.success("Email Verified Successfully Please Login", {
          icon: "👏",
        });
        navigate(`/`, { state: { emailVerify: 2 } });
      } else if (history?.state?.emailVerify === 1) {
        toast.error("Email Already Verified");
        navigate(`/`, { state: { emailVerify: 2 } });
      } else if (history?.state?.login === 0) {
        toast.error("Please Login");

        navigate({ state: { login: 2 } });

        setTimeout(() => {
          setOpen(true);
        }, 1500);
      } else if (history?.state?.email) {
        // toast.error(history?.state?.email)
        email.current.value = history?.state?.email;
      }
      isMounted.current = true;
    } else {
      // console.log("fetched", history?.state)
    }
  }, []);

  const [openpass, setOpenPass] = React.useState(false);
  const handleOpenTime = () => setOpenPass(true);
  const handleCloseTime = () => setOpenPass(false);

  const [showPasswordone, setShowPasswordone] = React.useState(false);
  const handleClickShowPasswordone = () => setShowPasswordone((show) => !show);

  const [today, setDate] = useState(new Date());
  const [login, setLogin] = useState(false);
  const [otp, setOtp] = useState('');
  const [otperr, setOtpErr] = useState('');

  const navigate = useNavigate();
  const email = useRef(null);
  const fname = useRef(null);
  const lname = useRef(null);
  const captchaRef = useRef(null);

  // const phone = useRef(null);
  const remail = useRef(null);
  const inpassref = useRef(null);
  const inputconfrimRef = useRef(null);

  const [lnameerr, setLnameerr] = useState(null);
  const [wnameerr, setWnameerr] = useState(null);
  const [fnameerr, setFnameerr] = useState(null);
  const [checkBoxError, setCheckBoxError] = useState(null);
  const [usertypeError, setUsertypeError] = useState(null);
  const [islogin, setIslogin] = useState(false);
  const [islogin2, setIslogin2] = useState(false);

  //const token = window.localStorage.getItem("iphephile")
  const tokens = localStorage.getItem("iphephile");

  const current = new Date();
  const date = current.toLocaleString("en-us", {
    month: "long",
    year: "numeric",
    day: "numeric",
  });

  useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 60);
    // return () => {
    //   clearInterval(timer);
    // }
  }, []);

  const time = today.toLocaleTimeString({
    hour: "numeric",
    hour12: true,
    minute: "numeric",
  });

  useEffect(() => {
    const store = localStorage.setItem("nickname", "mynameglad");
    if (localStorage.getItem("nickname")) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [emailerr, setemailerr] = useState(null);
  const [passworderr, setpassworderr] = useState(null);
  const [cpassworderr, setcpassworderr] = useState(null);
  const [error, setError] = useState("");
  const [userType, setUserType] = useState("user");
  const [regMail, setRegmail] = useState('');
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [verify, setVerify] = useState(false)
  const [remailerr, setremailerr] = useState(null);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [password, setPassword] = React.useState(false);

  const handleClickPassword = () => setPassword((show) => !show);

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const emailhandleChange = () => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const inputValue = email.current.value;
    setLnameerr(null);
    setWnameerr(null);
    if (inputValue.trim() === "") {
      setemailerr("");
    } else if (!emailRegex.test(inputValue)) {
      setemailerr("*Invalid Email");
    } else {
      setemailerr("");
    }
  };
  const handlePasswordChange = () => {
    const passwordregex = new RegExp(
      /(^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])(?=.*\d).{8,16}$)+/,
      "gm"
    );
    const inputValue = inpassref.current.value;
    setLnameerr(null);
    if (inputValue.trim() === "") {
      setpassworderr("");
    } else if (!passwordregex.test(inputValue)) {
      setpassworderr(
        "Password must be at least 8 characters and It contains Minimum one uppercase and Special Characters"
      );
    } else {
      setpassworderr("");
    }
  };

  const handleConfirmPasswordChange = () => {
    setLnameerr(null);
    // setcpassworderr(inputconfrimRef);
    if (inputconfrimRef.current.value !== inpassref.current.value) {
      setcpassworderr("Password do not match");
    } else if (inputconfrimRef === inpassref) {
      setcpassworderr("");
    } else {
      setcpassworderr("");
    }
  };

  const handleChange6 = () => {
    setFnameerr("");
    setLnameerr("");
  };

  var element = document.getElementById("box");
  const register = async () => {

    try {
      const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const passwordregex = new RegExp(
        /(^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])(?=.*\d).{8,16}$)+/,
        "gm"
      );

      if (!email.current.value) {
        setemailerr("*Email is Required");
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      } else if (!emailRegex.test(email.current.value)) {
        setemailerr("*Invalid Email");
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      } else if (!inpassref.current.value) {
        setpassworderr("*Password is Required");
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      } else if (!passwordregex.test(inpassref.current.value)) {
        setpassworderr(
          "*Password must be a minimum 8 characters & Maximum 16 characters.Eg: Abc@123"
        );
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      } else if (!inputconfrimRef.current.value) {
        setcpassworderr("*Confirm Password is Required");
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      } else if (inpassref.current.value !== inputconfrimRef.current.value) {
        setcpassworderr("*Password and Confirm Password must be Equal");
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      } else if (!fname.current.value) {
        setFnameerr("*FirstName is Required");
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      } else if (!lname.current.value) {
        setLnameerr("*LastName is Required");
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      } else if (userType == "") {
        setUsertypeError("*Please Select User Type");
        element.scrollIntoView({ behavior: "smooth", block: "end" });
      } else if (!captchaRef.current.getValue()) {
        setCheckBoxError("Please Verify Your A Human");
      } else {
        let token = captchaRef.current.getValue();
        console.log(token, "RECAPTCHAAA");
        if (token) {
          setError("");
        } else {
          setError("You must confirm you are not a robot");
        }
        setIslogin(true);
        const name = `${fname.current.value} ${lname.current.value}`;
        const registerdata = {
          name: name,
          firstname: fname.current.value,
          lastname: lname.current.value,
          email: email.current.value,
          signup_type: "gmail",
          // phone: phone.current.value,
          typeUser: userType,
          password: inpassref.current.value,
          repassword: inputconfrimRef.current.value,
        };

        const { data } = await Axios.post("/register", registerdata);

        if (data.result) {

          toast.success("Registred Successfully, Please Verify Your Email");
          localStorage.clear();
          await localStorage.setItem("regMail", email.current.value);
          await setRegmail(localStorage.getItem("regMail"));
          setResent(true);

          setTimeout(() => {
            setIslogin(false);
            // navigate("/");

          }, 2000);
        }
      }
    } catch (error) {
      // console.log(error, "err")
      if (error?.response?.data?.message === "EMAIL ALREADY EXISTS") {
        toast.error("Email already Exist");
      } else {
        setemailerr(error?.response?.data?.message);
        toast.error(error?.response?.data?.message);
      }
      setTimeout(() => {
        setIslogin(false);
      }, 2000);
    }

    // setResent(true)
  };
  const wallerRegister = async (account) => {
    try {
      console.log(account.length);
      if (account.length < 1) {
        setWnameerr("*Connect To your Wallet");
        // loadWeb3()
      }
      if (userType == "") {
        setUsertypeError("*Please Select User Type");
        element.scrollIntoView({ behavior: "smooth", block: "end" });
      } else {
        const registerdata = {
          signup_type: "wallet",
          typeUser: userType,
          account: account[0],
        };
        console.log(registerdata, "datas");
        const { data } = await Axios.post("/register", registerdata);
        console.log(data, "detassss");
        if (data.success === true) {
          setLnameerr(null);
          setWnameerr(null);
          toast.success("User Registered Sucessfully");
          window.localStorage.setItem("iphephile", data?.result?.token);
          setTimeout(() => {
            //navigate('/feed')
            navigate(`/login`, { state: { emailVerify: 4 } });
          }, 2000);
        } else {
          if (data.message === "User Already registered") {
            toast.error("This User Already Registered");
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          } else {
            toast.error(data.message);
            setTimeout(() => {
              navigate("/");
            }, 2000);
          }
        }
      }
    } catch (error) {
      // console.log(error, "err")
      if (error?.response?.data?.message === "EMAIL ALREADY EXISTS") {
        setemailerr(error?.response?.data?.message);
      } else {
        setWnameerr(error?.response?.data?.message);
      }
    }
  };

  const signinwithGoogle = async (e) => {
    try {
      if (userType == "") {
        setUsertypeError("*Please Select User Type");
        element.scrollIntoView({ behavior: "smooth", block: "end" });
      } else if (!captchaRef.current.getValue()) {
        setCheckBoxError("Please Verify Your A Human");
      } else {
        const provider = await new GoogleAuthProvider();
        return signInWithPopup(auth, provider)
          .then((res) => {
            onAuthStateChanged(auth, async (user) => {
              console.log(user, 'user');

              if (user) {
                const registerdata = {
                  name: user.displayName,
                  firstname: user.displayName,
                  lastname: user.displayName,
                  email: user.email,
                  signup_type: "google",
                  typeUser: userType,
                };
                setIslogin2(true);

                await Axios.post("/register", registerdata)
                  .then((res) => {
                    if (res.data.success) {
                      toast.success(res.data.message);
                      window.localStorage.setItem(
                        "iphephile",
                        res?.data?.result?.token
                      );
                      window.localStorage.setItem(
                        "usrId",
                        res?.data?.result?.user?._id
                      );
                      window.localStorage.setItem(
                        "role",
                        res?.data?.result?.user?.role
                      );
                      window.localStorage.setItem("isPauses", false);
                      window.localStorage.setItem("currents", 0);

                      setTimeout(() => {
                        setIslogin2(false);
                        navigate("/");
                      }, 2000);
                    } else {
                      toast.error(res.data.message);
                      setTimeout(() => {
                        setIslogin2(false);
                      }, 2000);
                    }
                  })
                  .catch((error) => {
                    console.log(error);

                    toast.error(error?.response?.data?.message);

                    setTimeout(() => {
                      setIslogin2(false);
                    }, 2000);
                    // toast.error("Error while Register to dreamster")
                  });
              } else {
                console.log("user");
              }
            });
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
            setTimeout(() => {
              setIslogin2(false);
            }, 1000);
          });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setTimeout(() => {
        setIslogin2(false);
      }, 1000);
    }
  };
  useEffect(() => {
    const unsubscripe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;

      } else {
        console.log("user");
      }
    });
  }, []);

  const handlecheckBox = () => {
    setCheckBoxError("");
  };

  const loadWeb3 = async () => {
    if (userType == "") {
      setUsertypeError("*Please Select User Type");
    } else {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        console.log(web3Instance);
        try {
          await window.ethereum.enable();
          setWeb3(web3Instance);

          await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          const accounts = await web3Instance.eth.getAccounts();
          console.log(accounts);
          setAccounts(accounts);
          setWnameerr(null);
          setIsLoading(false);
          wallerRegister(accounts);
        } catch (error) {
          console.error("Error connecting to MetaMask:", error.message);
          setWnameerr("Please Connect to Your Wallet");
          setIsLoading(false);
        }
      } else {
        console.error("MetaMask not detected. Please install it.");
        setWnameerr("MetaMask not detected. Please install it.");
        setIsLoading(false);
      }
    }
  };

  const [response, setResponse] = useState("");
  const handleCaptchaSuccess = (value) => {
    setResponse(value);
    Axios.post("/verify-recaptcha", { response: value })
      .then((res) => {
        if (res.data.success) {
          // Perform desired action on successful verification
        } else {
          // Display error message to the user
        }
      })
      .catch((err) => console.error(err));
  };

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
    setUsertypeError("");
  };

  


  const handleNewVerify = async () => {
    const payload = {
      otpcode: otp,
      email: regMail
    };

    const { data } = await Axios.post("/verify", payload);

    if (data?.success) {
      toast.success(data?.message);
      setTimeout(() => {
        toast.success('Please Login');
      }, 2500)
      setTimeout(() => {
        navigate("/login");
      }, 1500);
    } else {
      toast.error(data?.message);
      setOtpErr(data?.message);
    }

  }


  const handleresendOpen = () => {
    setOpen(false);
    setResent1(true);
    setremailerr("");
  };
  const handleresendClose = () => {
    setResent1(false);
    setOpenPass(false);
    setremailerr("");
  };

  const ressendEmail = async () => {
    try {
      const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!remail.current.value) {
        setremailerr("*Email is Required");
      } else if (!emailRegex.test(remail.current.value)) {
        setremailerr("*Invalid Email");
      } else {
        // console.log("Login")
        const mailData = {
          email: remail.current.value,
        };
        window.localStorage.removeItem("iphephile");
        setIslogin(true);

        const { data } = await Axios.post("/re_send", mailData)
          .then(async (res) => {
            // console.log(res,"res")
            if (res?.data?.success) {
              setVerify(true)
              await setRegmail(localStorage.getItem("regMail"));

              toast.success(
                `Email send to you mail,Please Verify Your ${remail.current.value}`,
                {
                  duration: 1000, // Set the duration for which the toast is visible
                }
              );

              setremailerr("");

              setIslogin(false);
            } else {
              setVerify(false)
              setIslogin(false);

              // console.log(res,"daa")
              if (res?.data.message === "User Does Not Exist") {
                toast.error(res?.data?.message);
              } else if (res?.data.message === "Email already Verified") {
                //setIslogin(false)

                toast.error(res?.data?.message);
                setTimeout(() => {
                  navigate('/login')
                }, 1000);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setVerify(false)
            if (
              err?.response?.data?.message === "User Does Not Exist" ||
              err?.response?.data?.message === "BLOCKED_USER" ||
              err?.response?.data?.message === "Please Verify Email" ||
              err?.response?.data?.message === "USER DOES NOT EXIST"
            ) {
              toast.error(err?.response?.data?.message);
            }
            setIslogin(false);
          });
      }
    } catch (error) {
      setIslogin(false);

      //   toast.error("Something Went Wrong")
    }
  };

  return (
    <>
      <ToastContainer autoClose={3000} position="top-right" />
      <div className="register-page">
        <div className="outer-box">
          <div className="inner-box">
            <div className="dream-logo">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="inner-popup-pad register" id="box">
              <h1 className="log-head">Register to Dreamster</h1>
              <div className="inner-popup">
                <div className="inputbox">
                  <Typography variant="p" component="p" className="email">
                    Firstname
                  </Typography>

                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant="outlined"
                    className="input-box"
                  >
                    <OutlinedInput
                      className="input-box-txt"
                      placeholder="Firstname"
                      inputRef={fname}
                      onChange={() => {
                        handleChange6();
                      }}
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                  </FormControl>
                  {fnameerr ? (
                    <div className="errorDiv" style={{ textAlign: "center" }}>
                      {fnameerr}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="inputbox">
                  <Typography variant="p" component="p" className="email">
                    Lastname
                  </Typography>

                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant="outlined"
                    className="input-box"
                  >
                    <OutlinedInput
                      className="input-box-txt"
                      placeholder="Lastname"
                      inputRef={lname}
                      onChange={() => {
                        handleChange6();
                      }}
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                  </FormControl>
                  {lnameerr ? (
                    <div className="errorDiv" style={{ textAlign: "center" }}>
                      {lnameerr}
                    </div>
                  ) : (
                    <></>
                  )}
                  {wnameerr ? (
                    <div className="errorDiv" style={{ textAlign: "center" }}>
                      {wnameerr}
                    </div>
                  ) : (
                    <></>
                  )}


                </div>
                <div className="inputbox">
                  <Typography variant="p" component="p" className="email">
                    Email Address
                  </Typography>

                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant="outlined"
                    className="input-box"
                  >
                    <OutlinedInput
                      className="input-box-txt"
                      placeholder="Email"
                      inputRef={email}
                      onChange={() => {
                        emailhandleChange();
                      }}
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                  </FormControl>
                  {emailerr ? (
                    <div className="errorDiv" style={{ textAlign: "center" }}>
                      {emailerr}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="inputbox">
                  <div className="pwd-spc">
                    <Typography variant="p" component="p" className="email">
                      Password
                    </Typography>
                  </div>
                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant="outlined"
                    className="input-box"
                  >
                    <OutlinedInput
                      className="input-box-txt"
                      placeholder="Password"
                      id="outlined-adornment-password"
                      inputRef={inpassref}
                      onChange={() => {
                        handlePasswordChange();
                      }}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Visibility style={{ fill: "#000" }} />
                            ) : (
                              <img src={VisibilityOff} alt={VisibilityOff} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  {passworderr ? (
                    <div className="errorDiv" style={{ textAlign: "center" }}>
                      {passworderr}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="inputbox">
                  <div className="pwd-spc">
                    <Typography variant="p" component="p" className="email">
                      Confirm Password
                    </Typography>
                  </div>
                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant="outlined"
                    className="input-box"
                  >
                    <OutlinedInput
                      className="input-box-txt"
                      placeholder=" Confirm Password"
                      id="outlined-adornment-password"
                      inputRef={inputconfrimRef}
                      onChange={() => {
                        handleConfirmPasswordChange();
                      }}
                      type={password ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickPassword}
                            onMouseDown={handleMouseUpPassword}
                            edge="end"
                          >
                            {password ? (
                              <Visibility style={{ fill: "#000" }} />
                            ) : (
                              <img src={VisibilityOff} alt={VisibilityOff} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  {cpassworderr ? (
                    <div className="errorDiv" style={{ textAlign: "center" }}>
                      {cpassworderr}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>


                <Typography variant="p" component="p" className="email">
                  Usertype
                </Typography>
                <FormControl fullWidth className="input-box fan-art">
                  <Select value={userType} onChange={handleUserTypeChange} defaultValue={""}>
                    <MenuItem value={"user"}>Fan</MenuItem>
                    <MenuItem value={"artist"}>Artist</MenuItem>
                  </Select>
                </FormControl>
                {usertypeError ? (
                  <div className="errorDiv" style={{ textAlign: "center" }}>
                    {" "}
                    {usertypeError}{" "}
                  </div>
                ) : (
                  <></>
                )}
                <div className="check-agree">
                  <ReCAPTCHA
                    sitekey={consts.sitekey}
                    ref={captchaRef}
                    onChange={() => {
                      handlecheckBox();
                    }}
                  />
                  {/* <p>This site is protected by reCAPTCHA and the Google. <span>Privacy Policy</span> and <span>Terms of Service</span> apply.</p> */}
                </div>
                {checkBoxError ? (
                  <div className="errorDiv" style={{ textAlign: "center" }}>
                    {checkBoxError}
                  </div>
                ) : (
                  <></>
                )}

                <div className="submit-btn">
                  {islogin === false ? (
                    <Button
                      onClick={() => {
                        register();
                      }}
                    >
                      Create
                    </Button>
                  ) : (
                    <Button>Processing ...</Button>
                  )}
                </div>

                <div className="meta-google">
                  <Button
                    onClick={() => {
                      loadWeb3();
                    }}
                  >
                    <img
                      width="30"
                      height="30"
                      src="https://img.icons8.com/color/30/metamask-logo.png"
                      alt="metamask-logo"
                    />
                  </Button>
                  {islogin2 === false ? (
                    <Button
                      onClick={() => {
                        signinwithGoogle();
                      }}
                    >
                      <img
                        width="30"
                        height="30"
                        src="https://img.icons8.com/fluency/30/google-logo.png"
                        alt="google-logo"
                      />
                    </Button>
                  ) : (
                    <Button>Processing ...</Button>
                  )}
                </div>

                <div className="already-acct">
                  <span>Already A Member?</span>{" "}
                  <Link to="/login">Sign in</Link>
                </div>
                <div className="already-acct">
                  <div className="verify">
                    <span>Don't have verify Email? </span>
                    <Link to="" onClick={() => { handleresendOpen() }}>
                      Resend Verification Mail
                    </Link>
                  </div>
                </div>
                <div className="copyright">
                  <p>© 2023 Dreamster - All rights reserved</p>
                </div>
              </div>
            </div>
          </div>
          <Modal
            open={recent}
            className="login-page"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="withdraw-popup new">
              <div className="dream-logo">
                {/* <div className='popup' >
                  <CloseIcon onClick={handleresendClose} style={{ color: "red" }} />
                </div> */}
                <Link to="/">
                  <img src={logo} alt="logo" className="img-fluid" />
                </Link>
              </div>
              <h6 className="new-act-head">Please Verify Your Email?</h6>
              <div className="text-center"> Enter the verification code we've sent to your email:</div>
              {/* <div className="text-center">Sankar.alpharive@gmail.com</div> */}
              <div className="text-center">{regMail}</div>
              <div className="inner-popup">
                <FormControl
                  sx={{ m: 1, width: "100%" }}
                  variant="outlined"
                  className="input-box"
                >
                  <OutlinedInput
                    className="input-box-txt"
                    placeholder="Enter OTP code"
                    inputRef={remail}
                    onChange={(e) => { setOtpErr(''); setOtp(e.target.value) }}
                    id="outlined-adornment-weight"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </div>
              <div style={{ textAlign: 'center', color: 'red' }}>{otperr ? otperr : null}</div>
              <div className="submit-btn">
                <Button onClick={handleNewVerify}>
                  Submit
                </Button>
              </div>

            </Box>
          </Modal>

          <Modal
            open={recent1}
            className="login-page"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="withdraw-popup verify">
              <div className="dream-logo">
                {/* <div className='popup' >
            <CloseIcon onClick={handleresendClose} style={{color:"red"}}/>
          </div> */}
                <Link to="/">
                  <img src={logo} alt="logo" className="img-fluid" />
                </Link>
              </div>
              <h6 className="new-act-head">Resend Verification Mail?</h6>

              <div className="inner-popup">
                {
                  verify == false ?
                    <FormControl
                      sx={{ m: 1, width: "100%" }}
                      variant="outlined"
                      className="input-box"
                    >
                      <OutlinedInput
                        className="input-box-txt"
                        placeholder="Email"
                        inputRef={remail}
                        onChange={() => {
                          setremailerr(null);
                        }} //remailhandleChange()
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl> : <></>
                }
                {remailerr ? <div className="errorDiv">{remailerr}</div> : <></>}
                {
                  verify == true ?
                    <>
                      <FormControl
                        sx={{ m: 1, width: "100%" }}
                        variant="outlined"
                        className="input-box"
                      >
                        <OutlinedInput
                          className="input-box-txt"
                          placeholder="Enter OTP code"
                          // inputRef={remail}
                          onChange={(e) => { setOtpErr(''); setOtp(e.target.value) }}
                          id="outlined-adornment-weight"
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            "aria-label": "weight",
                          }}
                        />
                      </FormControl>
                      <div style={{ textAlign: 'center', color: 'red' }}>{otperr ? otperr : null}</div>
                      <div className="submit-btn">
                        <Button onClick={() => { handleNewVerify() }}>
                          Submit
                        </Button>
                      </div>
                    </> :
                    <></>
                }
                {
                  verify == false ?

                    <div className="submit-btn">
                      {islogin === false ? (
                        <Button
                          className="binance-btn"
                          onClick={() => {
                            ressendEmail();
                          }}
                        >
                          {" "}
                          Resend Mail
                        </Button>
                      ) : (
                        <Button className="binance-btn"> Processing ...</Button>
                      )}
                    </div> : <></>
                }
                <div className="verify-bck" onClick={handleresendClose}>
                  <KeyboardBackspaceIcon /> Back
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Register;
