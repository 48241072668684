import React, { useEffect, useState,useRef } from "react";
import Axios from "../../Axios"
import Constant from "../../Constant";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EmailTokenpage() {
  const path = useLocation();
  const navigate = useNavigate();
  const token = path.pathname.split("/")[2]
  const email = path.pathname.split("/")[3]
  const isMounted = useRef(false)
 const _id = localStorage.getItem("usrId")

  // console.log(token,'token');

  useEffect(() => {
    if (!isMounted.current) {
      console.log(token,email)
  
      getVerify()
      isMounted.current = true
      }
      else {
        console.log("Run")
    }
  }, []);

  const getVerify = async () => {
    console.log("Verify")
  try {
    await Axios.post(`/verify`,{email:email},
    {
      headers: {
        Authorization: token,
      },
    }
  ).then((res) => {
    console.log(res,'res')
        navigate(`/artistpage/${_id}`, { state: { emailVerify: 0 } })
  }).catch((err) => {
     console.log(err,'err')
    navigate(`/artistpage/${_id}`, { state: { emailVerify: 1 } })
  })
  } catch (error) {
    console.log(error,'error')
    navigate(`/artistpage/${_id}`, { state: { emailVerify: 1 } })
  }
  }
  return <></>;
}
