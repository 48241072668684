import React, { useState } from "react";
import Header2 from "../Header/Header2";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import "./Creater.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Banner from "../../img/creator-banner.png.jpg";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import { Navigate, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Header from "../Header/Header";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderNew from "../HeaderNew/HeaderNew";
const drawerWidth = 280;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  cardbg: {
    borderRadius: "12px !important",
    background: "rgba(255, 255, 255, 0.10)",
    boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
    backdropFilter: "blur(37.5px)",
    padding: "0 !important",
  },

  pwrback: {
    borderRadius: "12px !important",
    border: "1px solid rgba(255, 255, 255, 0.20)",
    background: "#000000 !important",
  },

  learn: {
    borderRadius: "12px !important",
    background: "transparent !important",
    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important",
  },

  containWidth: {
    maxWidth: "1440px",
    margin: "auto",
    background: "none !important",
    padding: "0 !important",
    boxShadow: "none !important",

    "@media (max-width: 1199.98px)": {
      padding: "20px !important",
    },
  },
});

const Creater = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState();
  const classes = useStyles();

  // React.useEffect(() => {
  //   if (location?.state?.speed) {
  //     console.log(location?.state, "state");
  //     setValue(location?.state?.speed - 1);
  //   } else if (location?.state?.value) {
  //     setValue(location?.state?.value);
  //   } else {
  //     console.log(location?.state, "state");
  //     setValue(0);
  //   }
  // }, []);

  const uroleaction = () =>{
   const role =  localStorage.getItem("role")
   try {
    if(role === "artist"){
      navigate("/upload" )
    }else{
      toast.error("Become An Artist to Create")
    }
   } catch (error) {
    console.log(error,"err");
   }
  }

  const uroleactionmint = () =>{
    const role =  localStorage.getItem("role")
    try {
     if(role === "artist"){
       navigate("/mintnft" )
     }else{
       toast.error("Become An Artist to Create")
     }
    } catch (error) {
     console.log(error,"err");
    }
   }
  return (
    <div className="creator">
      <ToastContainer autoClose={3000} position="top-right" />
      <Box sx={{ display: "flex" }}>
        <HeaderNew title={"Upload"} />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: {
              sm: `calc(100% - ${drawerWidth}px)`,
              overflowY: "scroll",
              height: "100vh",
            },
          }}
          className="creator-box"
        >
          <Grid container spacing={0} className="whole-cnt">
            <Grid>
              <div class="page-cnt-center">
                <div class="card_cus-file">
                  <div class="gallery col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h2 class="text-white new">
                      This Create section empowers musicians with flexibility
                      and control over how they share and monetize their music,
                      catering to both casual listeners and dedicated fans
                      looking to own a piece of their favorite artist’s work.
                      See details Below:
                    </h2>
                    {/* <p class="txt-sucess"></p> */}
                  </div>
                  <div class="card border-0">
                    <div class="upload-det">
                      <div class="upload-card-box">
                        <Grid container spacing={0} className="whole-cnt">
                          <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div class="music-up border-end">
                              <div className="library-button">
                                <Button
                                  onClick={() => uroleaction()}
                                  class="btn btn-outline-success mb-3"
                                >
                                  <div className="upload-cloud start-music t1">
                                    <MusicNoteIcon /> Upload Free Music
                                  </div>
                                </Button>
                              </div>


                              <p class="text-wrap-file">
                                This tab allows you as a musicians to upload your music without attaching it to a smart contract
                                on the blockchain. Here’s what happens
                              </p>
                              <ul className="ui-desc">
                                <li>
                                  <strong>Purpose:</strong> As a Musicians you
                                  can freely share your music with fans, making
                                  it accessible to anyone who signs up on
                                  Dreamster.
                                </li>
                                <li>
                                  <strong>User Experience:</strong> Users can
                                  download the music to their library for free,
                                  create personalized playlists, and enjoy their
                                  favorite tracks without any cost.
                                </li>
                                <li>
                                  <strong>Beneﬁts for Artists:</strong> This
                                  feature helps artists build a fanbase, increase
                                  their visibility, and attract more listeners by
                                  oﬀering free content.
                                </li>
                              </ul>
                            </div>
                          </Grid>
                          <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div>
                              <div className="library-button">
                                <Button
                                  onClick={() => uroleactionmint()}
                                  class="btn btn-outline-success mb-3"
                                >
                                  <div className="upload-cloud start-music t1">
                                    <MusicNoteIcon /> Create Digital Music Asset
                                  </div>
                                </Button>
                              </div>
                              <div className="music-up border-end">

                                <p class="text-wrap-file">
                                  This tab is designed for musicians to
                                  transform their music into digital assets
                                  using blockchain technology. Here’s the
                                  process:
                                </p>
                                <ul className="ui-desc">
                                  <li>
                                    <strong>Purpose:</strong> Musicians create a
                                    smart contract that attaches their music to
                                    the blockchain, enabling the creation of
                                    digital tokens that fans can purchase.
                                  </li>
                                  <li>
                                    <strong>Process:</strong>
                                    <ul>
                                      <li>
                                        <strong>
                                          Smart Contract Creation:
                                        </strong>
                                        Musicians upload their music and create
                                        a smart contract, which secures their
                                        music on the blockchain.
                                      </li>
                                      <li>
                                        <strong>Instant Distribution:</strong>
                                        The smart contract allows for the
                                        instant distribution of digital tokens,
                                        representing ownership of the music. So
                                        for example if you create• 10,000
                                        tokens, in short you have just created
                                        10,000 copies of your album and you can
                                        set the price or allow them to bid until
                                        they sell out.
                                      </li>
                                      <li>
                                        <strong>Token Purchase:</strong>
                                        Fans can purchase these tokens to own
                                        and listen to the music.
                                      </li>
                                      <li>
                                        <strong>Collaborator Splits: </strong>
                                        Musicians can set up the smart contract
                                        to split royalties with collaborators,
                                        ensuring everyone receives their fair
                                        share each time the music is sold or
                                        traded.
                                      </li>
                                    </ul>
                                    <li>
                                      <strong>Beneﬁts for Artists:</strong>
                                      This feature provides a new revenue
                                      stream, enables you as an artists to
                                      directly monetize your music, and ensures
                                      fair and transparent royalty distribution
                                      among collaborators.
                                    </li>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="music-banner mb-3">
                  <img src={Banner} alt={Banner} />
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Creater;
