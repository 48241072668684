import React, { useEffect, useState, useRef } from "react";
import exp from "../../img/exp.gif";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { BsFacebook } from "react-icons/bs";
import "../Feed/Feed.css";
import "../TrackPage/TrackPage.css";
import "../ArtistPage/ArtistPage.css";
import Axios from "../../Axios";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { ToastContainer, toast } from "react-toastify";
import minilogo from "../../img/company-logo.png";
import bronze from "../../img/company-logo.png";
import gold from "../../img/gold.png";
import platinum from "../../img/platinum.png";
import diamond from "../../img/diamondpng.png";
import { useLocation } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Tooltip from "@mui/material/Tooltip";
import AvatarGroup from "@mui/material/AvatarGroup";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Groups2Icon from "@mui/icons-material/Groups2";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { IoLogoInstagram } from "react-icons/io5";
import { FaTiktok } from "react-icons/fa6";
import { FaLink } from "react-icons/fa";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import newno from "../../img/newnodata.gif";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Fade from "@mui/material/Fade";

const drawerWidth = 280;


const label = { inputProps: { "aria-label": "Switch demo" } };

const ExploreList = (props) => {

    const [topsupporters, setTopsupporters] = React.useState([]);
    const [mutuals, setMutual] = React.useState([]);
    const [sameArtist, setSameArtist] = React.useState([]);
    const [followers, setFollowers] = React.useState([]);
    const [followings, setFollowings] = React.useState([]);
    const [user, setUser] = React.useState({});
    const [urlerr, setUrlerr] = React.useState("");
    const fburls = useRef(null);
    const params = useParams();
    const [counts, setCounts] = React.useState(0);
    const [fburl, setfburl] = React.useState("");
    const [yturl, setyturl] = React.useState("");
    const [instaurl, setinstaurl] = React.useState("");
    const [twitterurl, settwitterurl] = React.useState("");
    const tokens = localStorage.getItem("iphephile");
    const navigate = useNavigate();
    const [anchorE2, setAnchorE2] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [artistId, setArtistId] = useState("");
    const [prf, setPrf] = useState(false);
    const isMount = useRef(false);
    const [userListId, setUserListId] = useState('');

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const tootltip = (
        <div>
            <div>10K - Gold</div>
            <div>1M-Diamond</div>
            <div>5M-Platinum</div>
        </div>
    );

    useEffect(() => {
        setUserListId(localStorage.getItem('usrId'));
    }, [])

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 280 }}
            role="presentation"
        >
            <List className="artist-sidebar-list">
                <div className="artist-stamp-main">
                    <div className="tire-main-div">
                        <div className="art-mini-logo">
                            <img
                                src={getStamp(user?.TotalFollowers)}
                                alt="logo"
                                className="shining"
                            />
                        </div>

                        <div class="vl"></div>
                        <div className="tire-level">
                            {getTier(user?.TotalFollowers)}
                            <div className="tire-medal">
                                {getStampName(user?.TotalFollowers)}
                            </div>
                        </div>
                    </div>
                    <Tooltip
                        title={tootltip}
                        arrow
                        placement="right"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                    >
                        {/* <Button>Arrow</Button> */}
                        <InfoOutlinedIcon />
                    </Tooltip>
                </div>
                <div className="artist-description-cont">{user?.name}</div>
                <div className="artist-nft-link">
                    {/* Nft:
                    <p>https://opensea.io/AkiraTheDon</p> */}
                </div>
                <div className="artist-sub-div-follow-stack">
                    <Stack direction="row" spacing={3}>
                        <BsTwitterX
                            onClick={() => {
                                setCounts(0);
                            }}
                        />
                        <IoLogoInstagram
                            onClick={() => {
                                setCounts(1);
                            }}
                        />
                        <FaYoutube
                            onClick={() => {
                                setCounts(2);
                            }}
                        />
                        <BsFacebook
                            onClick={() => {
                                setCounts(3);
                            }}
                        />
                    </Stack>

                    <div className="inputbox art">
                        <Typography variant="p" component="p" className="email">
                            {getTitle(counts)}
                        </Typography>
                        <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                            className="input-box"
                        >
                            <OutlinedInput
                                className="input-box-txt artist"
                                inputRef={fburls}
                                value={getValue(counts)}
                                onChange={(e) => {
                                    setuserValue(e);
                                }}
                                id="outlined-adornment-weight"
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                    "aria-label": "weight",
                                }}
                            />
                        </FormControl>
                        {urlerr ? (
                            <div className="errorDiv" style={{ textAlign: "center" }}>
                                {urlerr}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <div className="TIP-audion-btn">
                    {(String(artistId) === window.localStorage.getItem("usrId")) ===
                        true ? (
                        <Button
                            onClick={() => {
                                updateProfile();
                            }}
                        >
                            Change Url
                        </Button>
                    ) : null}
                </div>
                <div className="top-support">
                    <EmojiEventsIcon /> Top Supporters
                </div>
                <div className="avatar-div-stack">
                    <AvatarGroup max={4}>
                        {topsupporters && topsupporters.length > 0 ? (
                            <>
                                {topsupporters &&
                                    topsupporters.map((row, index) => {
                                        return (
                                            <>
                                                <Avatar alt="Remy Sharp" src={row?.profilePicture} />
                                            </>
                                        );
                                    })}
                            </>
                        ) : (
                            <>
                                <div className="newno">
                                    <img src={newno} />
                                </div>
                            </>
                        )}
                    </AvatarGroup>
                    {topsupporters.length > 0 ? (
                        <div
                            onClick={() => {
                                navigate(`/artist/top_supporters/${params._id}`);
                            }}
                            className="display-flex"
                        >
                            View More
                            <ArrowRightAltIcon />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="top-support">
                    <PersonAddAltIcon /> Mutuals
                </div>
                <div className="avatar-div-stack">
                    <AvatarGroup max={4}>
                        {mutuals && mutuals.length > 0 ? (
                            <>
                                {mutuals &&
                                    mutuals.map((row, index) => {
                                        return (
                                            <>
                                                <Avatar alt="Remy Sharp" src={row?.profilePicture} />
                                            </>
                                        );
                                    })}
                            </>
                        ) : (
                            <>
                                <div className="newno">
                                    <img src={newno} />
                                </div>
                            </>
                        )}
                    </AvatarGroup>
                    {mutuals?.length > 0 ? (
                        <div
                            onClick={() => {
                                navigate(`/artist/mutual_connects/${params._id ? params._id : userListId}`);
                            }}
                        >
                            View More
                            <ArrowRightAltIcon />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="top-support">
                    <Groups2Icon /> Related Artists
                </div>
                <div className="avatar-div-stack">
                    <AvatarGroup max={4}>
                        {sameArtist && sameArtist?.length > 0 ? (
                            <>
                                {sameArtist &&
                                    sameArtist?.map((row, index) => {
                                        return (
                                            <>
                                                <Avatar alt="Remy Sharp" src={row?.profilePicture} />
                                            </>
                                        );
                                    })}
                            </>
                        ) : (
                            <>
                                <div className="newno">
                                    <img src={newno} />
                                </div>
                            </>
                        )}
                    </AvatarGroup>
                    {followers?.length > 0 ? (
                        <div
                            onClick={() => {
                                navigate(`/artist/related_artists/${params._id}`);
                            }}
                        >
                            View More
                            <ArrowRightAltIcon />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div className="top-support">
                    <Groups2Icon /> Plugins
                </div>
                <div className="avatar-div-stack">
                    <AvatarGroup max={4}>
                        {followers && followers?.length > 0 ? (
                            <>
                                {followers &&
                                    followers.map((row, index) => {
                                        return (
                                            <>
                                                <Avatar alt="Remy Sharp" src={row?.profilePicture} />
                                            </>
                                        );
                                    })}
                            </>
                        ) : (
                            <>
                                <div className="newno">
                                    <img src={newno} />
                                </div>
                            </>
                        )}
                    </AvatarGroup>
                    {followers?.length > 0 ? (
                        <div
                            onClick={() => {
                                navigate(`/artist/plugin_list/${params._id}`);
                            }}
                        >
                            View More
                            <ArrowRightAltIcon />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <div className="top-support">
                    <Groups2Icon /> Plugged-In List
                </div>
                <div className="avatar-div-stack">
                    <AvatarGroup max={4}>
                        {followings && followings?.length > 0 ? (
                            <>
                                {followings &&
                                    followings.map((row, index) => {
                                        return (
                                            <>
                                                <Avatar alt="Remy Sharp" src={row?.profilePicture} />
                                            </>
                                        );
                                    })}
                            </>
                        ) : (
                            <>
                                <div className="newno">
                                    <img src={newno} />
                                </div>
                            </>
                        )}
                    </AvatarGroup>
                    {followers?.length > 0 ? (
                        <div
                            onClick={() => {
                                navigate(`/artist/pluggedin_details/${params._id}`);
                            }}
                        >
                            View More
                            <ArrowRightAltIcon />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

            </List>
        </Box>
    );
    const history = useLocation();

    const open2 = Boolean(anchorE2);
    const id2 = open2 ? "simple-popover" : undefined;

    const open3 = Boolean(anchorEl2);
    const id3 = open3 ? "simple-popover3" : undefined;
    // to add song to playlist


    useEffect(() => {
        if (!isMount.current && tokens !== null) {
            getSupporters();
            getMutual();
            getFollowers();
            getFollowings();
            SameArtist();
            getValue(counts);
            if (history?.state?.emailVerify === 0) {
                toast.success("Email Updated Sucessfully");
            } else if (history?.state?.emailVerify === 1) {
                toast.error("Email Cannot Updated");
            } else if (history?.state?.emailVerify === 4) {
                toast.success("Please Update Your Profile");
            }
            isMount.current = true;
        }
    }, []);

    useEffect(() => {
        if (tokens !== null) {
            getArtist();
        }
    }, [prf]);

    useEffect(() => {
        setUrlerr("");
    }, [counts]);

    const getTitle = (data) => {
        switch (data) {
            case 0:
                return "Twitter Url";
            case 1:
                return "Instagram Url";
            case 2:
                return "Youtube Url";
            case 3:
                return "Facebook Url";
        }
    };

    const getTier = (data) => {
        if (Number(data) > 10000) return "Tier 2";
        else if (Number(data) > 100000) return "Tier 3";
        else if (Number(data) > 500000) return "Tier 4";
        else if (Number(data) >= 1) return "Tier 1";
    };

    const getValue = (data) => {
        if (data === 0 && fburls.current) {
            fburls.current.value = twitterurl;
        } else if (data === 1 && fburls.current) {
            fburls.current.value = instaurl;
        } else if (data === 2 && fburls.current) {
            fburls.current.value = yturl;
        } else if (data === 3 && fburls.current) {
            fburls.current.value = fburl;
        }
    };

    const setuserValue = (e) => {
        const urlregex = new RegExp(
            /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
        );

        if (!urlregex.test(e.target.value)) {
            setUrlerr("*Enter Url Valid Url link");
        } else {
            setUrlerr("");
            if (counts === 0) {
                settwitterurl(e.target.value);
            } else if (counts === 1) {
                setinstaurl(e.target.value);
            } else if (counts === 2) {
                setyturl(e.target.value);
            } else if (counts === 3) {
                setfburl(e.target.value);
            }
        }
    };

    useEffect(() => {
        getArtist();
    }, [params._id]);

    // to get top getSupporters
    const getSupporters = async () => {
        try {
            var findData = {
                id: params._id,
                type: "show",
            };

            const datas = await Axios.post("/followers/get_my_supporters", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        setTopsupporters(res.data?.result);
                    } else {
                        //toast.error(err?.response?.data?.message)
                        setTopsupporters([]);
                    }
                })
                .catch((err) => {
                    // console.log(err, "err")
                });
        } catch (error) {
            // console.log(error, "error");
        }
    };

    // to get top getMutual followers
    const getMutual = async () => {
        try {
            var findData = {
                id: params._id,
                type: "show",
            };

            const datas = await Axios.post(
                "/followers/get_my_mutualfollowers",
                findData,
                {
                    headers: {
                        Authorization: tokens,
                    },
                }
            )
                .then((res) => {
                    if (res?.data?.success) {
                        setMutual(res.data?.result?.MutualFollowers);
                    } else {
                        //toast.error(err?.response?.data?.message)

                        setMutual([]);
                    }
                })
                .catch((err) => {
                    // console.log(err, "err")
                });
        } catch (error) {
            // console.log(error, "error");
        }
    };

    // to get top SameArtist
    const SameArtist = async () => {
        try {
            var findData = {
                id: params._id,
                type: "show",
            };

            const datas = await Axios.post(
                "/followers/get_relatedartists",
                findData,
                {
                    headers: {
                        Authorization: tokens,
                    },
                }
            )
                .then((res) => {
                    if (res?.data?.success) {
                        setSameArtist(res?.data?.result);
                    } else {
                        //toast.error(err?.response?.data?.message)

                        setSameArtist([]);
                    }
                })
                .catch((err) => {
                    // console.log(err, "err")
                });
        } catch (error) {
            // console.log(error, "error");
        }
    };

    // to Get the list of followers list for the artist

    const getFollowers = async () => {
        try {
            var findData = {
                id: params._id,
                type: "show",
            };

            const datas = await Axios.post("/followers/get_my_followers", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        setFollowers(res?.data?.result?.followerDetails);
                    } else {
                        setFollowers([]);
                    }
                })
                .catch((err) => {
                    setFollowers([]);

                    // console.log(err, "err")
                });
        } catch (error) {
            setFollowers([]);

            // console.log(error, "error");
        }
    };

    const getFollowings = async () => {
        try {
            var findData = {
                id: params._id,
                type: "show",
            };

            const datas = await Axios.post("/followers/get_my_followings", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        setFollowings(res?.data?.result?.followingDetails);
                    } else {
                        setFollowings([]);
                    }
                })
                .catch((err) => {
                    setFollowings([]);

                    // console.log(err, "err")
                });
        } catch (error) {
            setFollowings([]);

            // console.log(error, "error");
        }
    };

    const getStamp = (data) => {
        if (Number(data) >= 10000) return gold;
        else if (Number(data) > 100000) return platinum;
        else if (Number(data) > 500000) return diamond;
        else if (Number(data) > 1) return bronze;
        else return minilogo;
    };
    const getStampName = (data) => {
        if (Number(data) >= 10000) return "Gold";
        else if (Number(data) > 100000) return "Platinum";
        else if (Number(data) > 500000) return "Diamond";
        else if (Number(data) > 1) return "Bronze";
        else return "";
    };
    const updateProfile = async () => {
        let reg = /^[0-9]+$/;
        try {
            var updateData = {
                name: user?.name,
                coverImage: user?.coverImage,
                profilePicture: user?.profilePicture,
                amount: user?.amount,
                account: user?.account,
                fburl: fburl,
                yturl: yturl,
                twitterurl: twitterurl,
                instaurl: instaurl,
            };

            await Axios.post("/updateProfile", updateData, {
                headers: {
                    Authorization: tokens,
                },
            }).then((res) => {
                if (res?.data?.success) {
                    toast.success("Profile Updated Successfully", {
                        duration: 1000, // Set the duration for which the toast is visible
                    });
                    getArtist();
                } else {
                    if (res?.data?.message == "Incorrect password") {
                        toast.error(res?.data?.message);
                        //setlpassworderr("Incorrect password");
                    } else {
                        toast.error(res?.data?.message);
                    }
                }
            });
        } catch (error) {
            // console.log(error);
        }
    };
    const getArtist = async () => {
        try {
            var findData = { id: params._id };

            const datas = await Axios.post(
                "/followers/get_artist_details",
                findData,
                {
                    headers: {
                        Authorization: tokens,
                    },
                }
            )
                .then((res) => {
                    if (res?.data?.success) {
                        setUser(res.data?.result?.UserDetails[0]);
                        setfburl(
                            res.data?.result?.UserDetails[0].fburl !== undefined
                                ? res.data?.result?.UserDetails[0].fburl
                                : ""
                        );
                        settwitterurl(
                            res.data?.result?.UserDetails[0].twitterurl !== undefined
                                ? res.data?.result?.UserDetails[0].twitterurl
                                : ""
                        );
                        setyturl(
                            res.data?.result?.UserDetails[0].yturl !== undefined
                                ? res.data?.result?.UserDetails[0].yturl
                                : ""
                        );
                        setinstaurl(
                            res.data?.result?.UserDetails[0].instaurl !== undefined
                                ? res.data?.result?.UserDetails[0].instaurl
                                : ""
                        );

                        setArtistId(res.data?.result?.UserDetails[0]?._id);
                    } else {
                        //toast.error(err?.response?.data?.message)

                    }
                })
                .catch((err) => {
                    // console.log(err, "err")
                });
        } catch (error) {
            // console.log(error, "error");
        }
    };





    const [showMessage, setShowMessage] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowMessage(false);
        }, 3000);

        return () => clearTimeout(timeout);
    }, [showMessage]);



    return (
        <div >

            <Grid container spacing={0}>
                {["left"].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Tooltip title="Explore More" placement="right">
                            <Button
                                onClick={toggleDrawer(anchor, true)}
                                className="artist-explore-more"
                            >
                                <img src={exp} alt="exp" />
                            </Button>
                        </Tooltip>
                        <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            className="artist-side"
                        >
                            {list(anchor)}
                        </Drawer>
                    </React.Fragment>
                ))}


            </Grid>

        </div>
    );
};

export default ExploreList;
