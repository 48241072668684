import React, { useCallback, useState, useRef } from 'react';
import './CreatorsNew.css';
import SidebarNew from '../SidebarNew/SidebarNew';
import { Box, Grid, Button, useTheme, useMediaQuery, styled, Snackbar, Tabs, Tab, Typography } from '@mui/material';
import Recent from '../Recent/Recent';
import Musiccontrol from '../Music-Control/Musiccontrol';
import { useDropzone } from 'react-dropzone';
import mp3 from '../../img/mp3.png';
import PropTypes from 'prop-types';
import upload from '../../img/upload.png';
import uploadImf from '../../img/uploadimg.png';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import tc1 from '../../img/tc1.png'
import tc2 from '../../img/tc2.png'
import sonstr from '../../img/songtrst.png'
import spin from '../../img/spin.png'
import dster from '../../img/dster.png'
import free from '../../img/free.png'
import 'react-datepicker/dist/react-datepicker.css';
import date from '../../img/date.png';
import Modal from '@mui/material/Modal';
import { ToastContainer, toast } from 'react-toastify';
// import moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import Axios from '../../Axios';
import dayjs from 'dayjs';
import Cookies from "js-cookie";
import Playlist from '../Playlist/Playlist';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    // bgcolor: 'var(--clr-bcg)',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 4,
};

const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30%",
    '@media(max-width:767.98px)': {
        width: '80%',
    },
    bgcolor: '#373737',
    border: '2px solid #175AFF',
    // boxShadow: 24,
    borderRadius: '12px',

    p: 4,
};

const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "400px",
    // height: '460px',
    '@media(max-width:991.98px)': {
        width: '80%',
    },
    background: 'linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%)',
    // border: '2px solid #175AFF',
    // boxShadow: 24,xdfcfv bnm,
    borderRadius: '12px',

    p: '1px',
};


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const drawerWidth = 300;

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const CreatorsNew = () => {
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const mobileView = useMediaQuery(theme.breakpoints.up('sm'));
    const [personName, setPersonName] = React.useState('');
    const [names, setNames] = React.useState();

    const location = useLocation()
    const searchParams = new URLSearchParams(location?.search);
    const numberParam = searchParams.get('number');
    const [number, setNumber] = useState(location.search ? parseInt(numberParam, 10) : 1);
    const [value, setValue] = useState(0);
    const [upLoading, setUpLoading] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [openModel, setOpenModel] = useState(false);
    const [loading, setLoading] = useState(false)

    const handleOpen = () => { setOpenModel(true); setIsOpen(true); }
    const handleClose2 = () => setOpenModel(false);

    const [openModel2, setOpenModel2] = useState(false);

    const handleOpen2 = () => { setOpenModel2(true) };
    const handleClose3 = () => setOpenModel2(false);

    const [openModel3, setOpenModel3] = useState(false);

    const handleOpen3 = () => { setOpenModel3(true) };
    const handleClose4 = () => {
        setOpenModel3(false);
    }

    const navigate = useNavigate()

    const [open, setOpen] = useState(false);

    const [age, setAge] = useState(10);
    const [category, setCategory] = useState('Pick a Genre');
    const [mode, setMode] = useState('Kind of Mode');
    const [audio, setAudio] = useState([])
    const [audioerr, setAudioerr] = useState()
    const [audioerr1, setAudioerr1] = useState()

    const [music_type, setmusictype] = useState("track");
    const [description, setDescription] = useState()
    const [type, setType] = useState('')
    const [image, setImage] = useState(null);
    const [imageerr, setImageerr] = useState(null)
    const [filetype, setFileType] = useState(true)

    const uroleaction = () => {
        const role = localStorage.getItem("role")
        try {
            if (role === "artist") {
                // console.log('if');
                // handleFileChange()
            } else {
                toast.error("Become An Artist to Create")
            }
        } catch (error) {
            console.log(error, "err");
        }
    }

    const handleChange2 = (event) => {
        // setAge(event.target.value);
        setCategory(event.target.value)
    };
    const [am, setAM] = useState();
    // const [date, setDate] = useState();
    const [time, setTime] = useState()

    const handleChange4 = (event) => {
        // console.log(event.target.value, 'event.target.value');
        setAM(event.target.value);
    };


    const handleChange1 = (e) => {
        setMode(e.target.value)
        // setModeerr(null)
    };

    // console.log(category, mode, 'logs');
    // console.log('Selected file:', uploadedFiles);

    const inputRef = useRef(null)

    // const handleClick = () => {
    //     setOpen(true);
    // };
    const [values, setValues] = useState(0);

    const handleChange3 = (event, newValue) => {
        setValues(newValue);
        // if (newValue === 0) {
        //     setmusictype("track")
        // } else if (newValue === 1) {
        //     setmusictype("album")
        // } else if (newValue === 2) {
        //     setmusictype("playlist")
        // }
    };

    // console.log(music_type, 'music_type');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const handleTypechange = async (value) => {
        console.log(value, 'value');
        setType(value)


    }
    // console.log(type, 'handleTypechange');

    const handleChange = (event, newValue) => {
        setOpen(false)
        // console.log(newValue, 'value');

        setValue(newValue);
        if (newValue === 0) {
            setmusictype("track")
            uploadedFiles.length = 1
            audio.length = 1
        } else if (newValue === 1) {
            setmusictype("album")
        } else if (newValue === 2) {
            setmusictype("playlist")
        }
        // console.log(music_type, 'newvalue');

    };
    // console.log(uploadedFiles, 'uploadedfiles');
    // console.log(value, 'value');



    const UploadMultipleFile = useCallback(async (files) => {
        const role = localStorage.getItem("role")
        if (role === 'artist') {
            try {
                if (files && files.length > 0) {
                    const newFile = files[0];

                    if (value == 0) {
                        if (files && uploadedFiles.length == 0) {
                            setUpLoading(true)
                            let formData = await new FormData();
                            formData.append("image", newFile);

                            var fileName = newFile?.name;
                            var idxDot = fileName.lastIndexOf(".") + 1;
                            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                            // console.log(fileName, idxDot, extFile, 'extFile');
                            if (extFile == "mp3" || extFile == "MP3") {
                                setUploadedFiles(prevFiles => [...prevFiles, newFile]);
                                setEditedValue(newFile ? newFile.name.slice(0, -4) : "")
                                setName(newFile ? newFile.name.slice(0, -4) : "")
                                const { data } = await Axios.post("/updateDp", formData);
                                if (data.result) {
                                    setUpLoading(false)
                                    setAudio(prevFiles => [...prevFiles, { audio: data.result, song_name: newFile ? newFile.name.slice(0, -4) : "" }]);
                                    if (number == 1) {
                                        setAudioerr('')
                                        setAudioerr1('')
                                    }
                                    else {
                                        setAudioerr1('')
                                    }
                                    // getAudioDuration(data.result)
                                }
                                else {
                                    setUpLoading(false)
                                    setAudio([]);
                                    if (number == 1) {
                                        setAudioerr("Audio file did not upload")
                                    }
                                    else {
                                        setAudioerr1("Audio file did not upload")
                                    }
                                }
                            }
                            else {
                                setUpLoading(false)
                                setAudio([]);
                                if (number == 1) {
                                    setAudioerr("Only mp3 files are allowed!")
                                }
                                else {
                                    setAudioerr1("Only mp3 files are allowed!")
                                }
                            }
                        }
                        else {
                            setOpen(true)
                        }
                    }
                    else if (value == 1 || value == 2) {
                        const isFileAlreadyUploaded = uploadedFiles.some(file => file.name === newFile.name);

                        if (!isFileAlreadyUploaded) {
                            // console.log('Selected file:', newFile);
                            setUpLoading(true)
                            let formData = await new FormData();
                            formData.append("image", newFile);

                            var fileName = newFile?.name;
                            var idxDot = fileName.lastIndexOf(".") + 1;
                            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                            // console.log(fileName, idxDot, extFile, 'extFile');
                            if (extFile == "mp3" || extFile == "MP3") {
                                setUploadedFiles(prevFiles => [...prevFiles, newFile]);
                                setEditedValue(newFile ? newFile.name.slice(0, -4) : "")
                                setName(newFile ? newFile.name.slice(0, -4) : "")
                                const { data } = await Axios.post("/updateDp", formData);
                                if (data.result) {
                                    setUpLoading(false)
                                    setAudio(prevFiles => [...prevFiles, { audio: data.result, song_name: newFile ? newFile.name.slice(0, -4) : "" }]);
                                    if (number == 1) {
                                        setAudioerr('')
                                    }
                                    else {
                                        setAudioerr1('')
                                    }
                                    // getAudioDuration(data.result)
                                }
                                else {
                                    setUpLoading(false)
                                    setAudio([]);
                                    if (number == 1) {
                                        setAudioerr("Audio file did not upload")
                                    }
                                    else {
                                        setAudioerr1("Audio file did not upload")
                                    }
                                }
                            }
                            else {
                                setUpLoading(false)
                                setAudio([]);
                                if (number == 1) {
                                    setAudioerr("Only mp3 files are allowed!")
                                }
                                else {
                                    setAudioerr1("Only mp3 files are allowed!")
                                }
                            }
                        } else {
                            setOpen(true);
                        }
                    }
                }
                else {
                    // setUploadedFiles(prevFiles => [...prevFiles, newFile]);
                }
            } catch (error) {

            }
        }
        else {
            toast.error("Become An Artist to Create")
        }

        // handleUpload();
        // handleFileUpload();
    }, [number, value, uploadedFiles]);

    // console.log(audio, 'audio');


    // const handleFileChange = async (files) => {
    //     console.log(files, 'files');
    //     const role = localStorage.getItem("role")
    //     if (role === "artist") {
    //         if (files) {
    //             try {
    //                 setUpLoading(true)
    //                 const newFile = files[0];
    //                 if (newFile) {
    //                     // console.log('Selected file:', newFile);
    //                     let formData = await new FormData();
    //                     formData.append("image", newFile);

    //                     var fileName = newFile?.name;
    //                     var idxDot = fileName.lastIndexOf(".") + 1;
    //                     var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    //                     // console.log(fileName, idxDot, extFile, 'extFile');
    //                     if (extFile == "mp3" || extFile == "MP3") {
    //                         setUploadedFiles(prevFiles => [...prevFiles, newFile]);
    //                         setEditedValue(newFile ? newFile.name.slice(0, -4) : "")
    //                         const { data } = await Axios.post("/updateDp", formData);
    //                         if (data.result) {
    //                             setUpLoading(false)
    //                             setAudio(data.result);
    //                             setAudioerr(null)
    //                             // getAudioDuration(data.result)
    //                         }
    //                         else {
    //                             setUpLoading(false)
    //                             setAudio();
    //                             setAudioerr("Audio file did not upload")
    //                         }
    //                     }
    //                     else {
    //                         setUpLoading(false)
    //                         setAudio();
    //                         setAudioerr("Only mp3 files are allowed!")
    //                     }
    //                 }
    //                 else if (value === 0) {
    //                     setOpen(true);
    //                     return;
    //                 }
    //                 else {
    //                     // alert('File already uploaded');
    //                     setOpen(true);
    //                     return;
    //                 }
    //             } catch (error) {
    //                 console.log(error, "file upload error");
    //             }
    //         }
    //     }
    //     else {
    //         toast.error("Become An Artist to Create")
    //     }


    // }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'audio/*',
        // onDrop: handleFileChange,
        onDrop: UploadMultipleFile,
        multiple: true, // Ensure only one file is accepted at a time
    });

    // const handleUpload = () => {
    //     setUpLoading(true);
    //     const timer = setTimeout(() => {
    //         setUpLoading(false);
    //     }, 3000);
    // }

    const incrementNumber = () => {
        setNumber(prevNumber => prevNumber + 1);
    };

    // console.log(uploadedFiles, 'uploadedFiles');

    const decreaseNum = () => {
        setNumber(prevNumber => prevNumber > 1 ? prevNumber - 1 : 1);
        setUploadedFiles([])
        setEditedValue('')
        setName('')
        setAudio([])
        setAudioerr('')
        setAudioerr1('')
    }

    const handleValue = () => {
        if (number === 2 && value === 0) {
            return;
        }
    }
    // console.log(number, 'number');
    const [editingIndex, setEditingIndex] = useState(-1);
    const [editedValue, setEditedValue] = useState();
    const [name, setName] = useState()
    const [isEditing, setIsEditing] = useState(false);
    const [amount, setAmount] = useState()
    const [amountErr, setAmountErr] = useState()


    console.log(amount, 'amount');

    const handleEditButtonClick = (index) => {
        setEditingIndex(index);
        setEditedValue(uploadedFiles[index].name.slice(0, -4));
        setTimeout(() => {
            inputRef.current.focus();
        }, 0);
        setIsEditing(true);
    };

    const handleSaveButtonClick = () => {
        setUploadedFiles(prevFiles => {
            const updatedFiles = prevFiles.map((file, i) => {
                if (i === editingIndex) {
                    return { ...file, name: `${editedValue}.mp3` };
                }
                return file;
            });
            return updatedFiles;
        });
        setEditingIndex(-1)
    };

    const handleInputChange = (e) => {
        // setEditedValue(e.target.value);
        if (value == 0) {
            const newSongName = e.target.value;
            setName(newSongName)
            setAudio(prevFiles =>
                prevFiles.map(file =>
                    file.song_name === name ? { ...file, song_name: newSongName } : file
                )
            );
        }
        else {
            setName(e.target.value)
        }
    };

    const removeTag = (index) => {
        setTags(prevFiles => prevFiles.filter((_, i) => i !== index));
    };
    const removeFile = (index) => {
        setUploadedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
        setAudio(prevFiles => prevFiles.filter((_, i) => i !== index));
        if (number == 1) {
            setAudioerr('')
        }
        else {
            setAudioerr1('')
        }
    };
    // const removeFile = () => {
    //     setUploadedFiles([]);
    // };
    const [inputValue, setInputValue] = useState('');
    const [tags, setTags] = useState([]);

    const handleInputTag = (event) => {
        setInputValue(event.target.value);
    };

    const handleAddTag = () => {
        if (inputValue.trim() !== '') {
            setTags([...tags, inputValue.trim()]);
            setInputValue('');
        }
    };
    // console.log(tags, 'tags');
    const [select, setselect] = useState(1)
    const [publish, setPublish] = useState()

    const handleSelect = (val, value) => {
        // console.log(val, value, 'logs');
        setselect(val)
        setPublish(value)
    }
    // console.log(publish, 'publish');

    const [startDate, setStartDate] = useState(null);
    const [startDate1, setStartDate1] = useState(null);
    const [dateerr, setdateerr] = useState()
    const [isOpen, setIsOpen] = useState(false);



    // const handleDateChange = (inputDate) => {
    //     console.log(inputDate, 'date');
    //     // var oriDate = `${Number(e?.$M) + 1}/${e?.$D}/${e?.$y} ${e?.$H}:${e?.$m}`
    //     // console.log(oriDate, 'oriDate');
    //     var todaysDate = new Date();
    //     if (inputDate.setHours(0, 0, 0, 0) >= todaysDate.setHours(0, 0, 0, 0)) {
    //         setStartDate(inputDate);
    //         setdateerr(null)
    //     }
    //     else {
    //         setdateerr("Select Future Date")
    //         setStartDate(inputDate)
    //     }

    //     // const data = (new Date(date).toISOString())
    //     // console.log(data, 'data');
    //     // setStartDate(date);
    //     setIsOpen(false);
    //     setOpenModel(false) // Close the date picker after selecting a date
    // };

    // const getTimestamp = () => {
    //     // Combine the date and time
    //     const dateStr = moment(startDate).format("YYYY-MM-DD");
    //     const timeStr = `${time} ${am}`;
    //     const combinedDateTime = moment(`${dateStr} ${timeStr}`, "YYYY-MM-DD hh:mm A");
    //     console.log(combinedDateTime, 'date');

    //     if (!combinedDateTime.isValid()) {
    //         setdateerr("Invalid date or time");
    //         return;
    //     }

    //     // Convert to timestamp
    //     const timestamp = combinedDateTime.unix();
    //     const currentTimestamp = Math.floor(new Date().getTime() / 1000);
    //     if (timestamp >= currentTimestamp) {
    //         console.log("Select Future Date and Time");
    //         setdateerr("Select Future Date and Time");
    //         return
    //     }
    //     else {
    //         console.log("Timestamp:", timestamp);
    //         return timestamp;
    //     }

    // };

    const handleDateChange = (e) => {
        // console.log(e, 'e');
        var oriDate = `${Number(e?.$M) + 1}/${e?.$D}/${e?.$y} ${e?.$H}:${e?.$m}`

        if (new Date(oriDate).getTime() > new Date().getTime()) {
            setStartDate1(dayjs(new Date(oriDate).getTime()))
            setStartDate(new Date(oriDate).getTime());
            setdateerr(null)
        }
        else {
            setdateerr("Select Future Date and Time")
            setStartDate(new Date(oriDate).getTime())
        }
    };

    // console.log(startDate, 'startDate');
    const [imageUrl, setImageUrl] = useState(null);

    // const handleImageUpload = (event) => {
    //     const file = event.target.files[0];
    //     const reader = new FileReader();

    //     console.log(reader, "result of file");

    //     reader.onloadend = () => {
    //         setImageUrl(reader.result);
    //     };

    //     if (file) {
    //         reader.readAsDataURL(file);
    //     }
    // };

    const handleImageUpload = async (event) => {
        const files = event.target.files[0];
        // console.log(files, 'files');
        try {
            let formData = await new FormData();
            formData.append('image', files)
            var fileName = files?.name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                const { data } = await Axios.post("/updateDp", formData);
                if (data.result) {
                    // setImageUrl(data.result)
                    setFileType(files?.name, true)
                    setImage(data.result);
                    setImageerr(null)
                }
            } else {
                setImage(null)
                setImageerr("Only jpg, jpeg, png and gif files are allowed!");
            }
        } catch (error) {
            console.log(error, "err");
        }
    };

    const mood = [
        'Peaceful',
        'Romantic',
        'Sentimental',
        'Tender',
        'Easygoing',
        'Yearning',
        'Sophisticated',
        'Sensual',
        'Cool',
        'Gritty',
        'Meancholy',
        'Serious',
        'Brooding',
        'Fiery',
        'Defiant',
        'Aggressive',
        'Rowdy',
        'Exicted',
        'Energizing',
        'Empowering',
        'Stirring',
        'Upbeat',
        'Other'
    ]

    const northamerica = [
        'Jazz',
        'Blues',
        'Country',
        'Hip-Hop',
        'Rock n Roll',
        'R&B (Rhythm and Blues)',
        'Gospel',
        'Bluegrass',
        'Folk',
        'Electronic',
        'Indie',
        'Reggae ',
        'Metal',
        'Reggae ',
        'Soul',

    ];

    const latinamerica = [
        'Salsa',
        'Reggaeton',
        'Bossa Nova',
        'Tango',
        'Mariachi',

    ];


    const europe = [
        'Classical',
        'Techno',
        'Bossa Nova',
        'EDM (Electronic Dance Music)',
        'Flamenco',
        'Balkan Folk',
    ]

    const africa = [
        'Afrobeat',
        'Highlife',
        'Kwaito',
        'Mbalax',
        'Taarab',
    ]

    const asia = [
        'Bollywood Music',
        'K-Pop',
        'J-Pop',
        'Qawwali',
        'Peking Opera',
    ]

    const middleeast = [
        'Arabic Pop',
        'Dabke',
        'Turkish Folk Music',
        'Raï',
        'Fado',
    ]

    const oceania = [
        'Aboriginal Folk Music',
        'Pasifika',
        'Contemporary Australian Rock',
        'Maori Music',
        'Hawaiian Music',
    ]

    const createMusic = async () => {
        // console.log(select, 'select');
        try {
            // setLoading(true)
            let payload
            if (type == 'free') {
                payload = {
                    image: image,
                    audio: audio,
                    music_type: music_type,
                    // song_name: editedValue,
                    name: name,
                    categorey: category,
                    song_moods: mode === "Other" ? "other" : mode,
                    description: description,
                    tags: tags,
                    opentoeveryone: type == 'free' ? true : false,
                    publish: select === 1 ? new Date().getTime() : startDate
                }
            }
            else {
                const arr = Cookies.get('audio')
                payload = {
                    image: Cookies.get('image'),
                    audio: JSON.parse(arr),
                    music_type: Cookies.get('musictype'),
                    // song_name: Cookies.get('songname'),
                    name: Cookies.get('name'),
                    categorey: Cookies.get('category'),
                    song_moods: Cookies.get('songmoods'),
                    description: Cookies.get('description'),
                    tags: Cookies.get('tags'),
                    opentoeveryone: type == 'free' ? true : false,
                    publish: select === 1 ? new Date().getTime() : startDate
                }
            }
            // console.log(payload, 'payllad');
            // console.log(typeof payload.audio, '&&&');

            await Axios.post("/users/uploadmusic", payload, {
                headers: {
                    Authorization: localStorage.getItem("iphephile"),
                },
            })
                .then((res) => {
                    const data = res.data.result
                    if (res?.data?.success) {
                        navigate('/', { state: { data: data } })
                        removeData()
                        // toast.success('Music Uploaded Successfully', {
                        //     duration: 1000,
                        // })
                        // setTimeout(() => {
                        // setLoading(false)
                        // navigate('/playernew')
                        // const datas = Cookies.get('files')
                        // const file = JSON.parse(datas)
                        // navigate('/', { state: { data: data, filesize: uploadedFiles ? uploadedFiles?.size : file.size } })
                        // }, 2000);
                    }
                    else {
                        toast.error(res?.data?.message)
                        // setLoading(false)

                    }
                }).catch((err) => {
                    if (err?.response?.data?.message == "Admin Blocked You") {
                        localStorage.clear();
                        navigate('/');
                        // setLoading(false)
                        toast.error('Admin Blocked You');
                    } else {
                        toast.error(err?.response?.data?.message)
                    }
                })
        } catch (error) {
            console.log(error, 'err');
        }
    }

    const uploadData = (
        <>
            {upLoading ? (
                <div className='hover-img'>
                    <div className='zoom-text'>
                        Uploading!
                    </div>
                    <div className="spinner">
                        <div className="spinner1">
                        </div>
                    </div>
                </div>
            ) : (
                uploadedFiles.map((file, index) => (
                    <div className='uploaded-music' style={{ width: number === 2 ? '100%' : !mobileView ? '100%' : '60%' }}>
                        <div className='text-right closeup' onClick={() => removeFile(index)}>
                            <svg width="15" height="15" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.760196 6.28427L6.28493 0.759539L7.38416 1.85876L1.85942 7.3835L0.760196 6.28427ZM0.764282 1.85876L1.86351 0.759539L7.38824 6.28427L6.28902 7.3835L0.764282 1.85876Z" fill="var(--clr-font)" />
                            </svg>
                        </div>
                        <div className='display-2'>
                            <div className='display-1'>
                                <img src={mp3} alt="MP3 Icon" />
                                <div className='upsongname'>
                                    {/* {file?.name?.slice(0, 14) + "..." + 'mp3'} */}
                                    {file?.name?.slice(0, 14) + "..." + 'mp3'}
                                </div>
                            </div>
                            <div className='uploaded-size'>
                                {file?.size ? `${(file?.size / 1024 / 1024).toFixed(2)} MB` : 'Unknown size'}
                            </div>
                        </div>
                    </div>
                ))
            )}
        </>
    )

    const [pg, setPg] = useState(0)

    const handlePg = async (val) => {
        setPg(val)
    }

    const [selectAge, setSelectAge] = useState(10);

    const handleSelectAge = (event) => {
        setSelectAge(event.target.value);
    };

    const setDatas = () => {
        Cookies.set('image', image)
        Cookies.set('audio', JSON.stringify(audio))
        Cookies.set('musictype', music_type)
        Cookies.set('songname', editedValue)
        Cookies.set('name', name)
        Cookies.set('category', category)
        Cookies.set('songmoods', mode === "Other" ? "other" : mode)
        Cookies.set('description', description)
        Cookies.set('tags', tags)
        Cookies.set('files', JSON.stringify(uploadedFiles))
    }

    const removeData = () => {
        Cookies.remove('image')
        Cookies.remove('audio')
        Cookies.remove('musictype')
        Cookies.remove('songname')
        Cookies.remove('category')
        Cookies.remove('songmoods')
        Cookies.remove('description')
        Cookies.remove('tags')
    }

    const makePayment = async () => {
        // console.log('paymnet');
        try {
            if (amount === undefined) {
                setAmountErr('Please Enter Amount')
            }
            else {
                const payload = {
                    amount: amount
                }
                const response = await Axios.post('/users/makePayment', payload,
                    {
                        headers: {
                            Authorization: localStorage.getItem("iphephile"),
                        },
                    }
                )
                // console.log(response?.result?.url, 'response');
                // console.log(response, 'response');

                if (response.data.success) {
                    window.location.replace(response?.data?.result?.url);
                }
                else {
                    toast.error(response.data.message)
                }
            }


        } catch (error) {
            console.log(error, 'err');
        }
    }


    return (
        <>
            <ToastContainer />
            <div className='creatorsnew'>
                <Box sx={{ display: isLgUp ? 'flex' : 'block' }}>
                    <SidebarNew name={names} setName={setNames} />
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            py: 1,
                            // width: { sm: `calc(100% - ${drawerWidth}px)` },
                            marginTop: "80px",
                        }}
                    >
                        <Grid container spacing={0}>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={9}
                                style={{ padding: '20px' }}
                            >
                                <div className='display-1'>
                                    <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.28571 23.2853C7.65265 23.2853 9.57143 21.3665 9.57143 18.9996C9.57143 16.6326 7.65265 14.7139 5.28571 14.7139C2.91878 14.7139 1 16.6326 1 18.9996C1 21.3665 2.91878 23.2853 5.28571 23.2853Z" stroke="url(#paint0_linear_3889_4513)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9.57129 19V1C10.8095 1 12.0355 1.24388 13.1794 1.71771C14.3234 2.19154 15.3628 2.88604 16.2383 3.76156C17.1138 4.63709 17.8083 5.67649 18.2822 6.82041C18.756 7.96434 18.9999 9.19039 18.9999 10.4286V10.4286" stroke="url(#paint1_linear_3889_4513)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <defs>
                                            <linearGradient id="paint0_linear_3889_4513" x1="-0.105121" y1="13.3952" x2="12.3346" y2="14.158" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B5179E" />
                                                <stop offset="1" stopColor="#7209B7" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_3889_4513" x1="8.35566" y1="-1.76924" x2="22.0767" y2="-1.32851" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B5179E" />
                                                <stop offset="1" stopColor="#7209B7" />
                                            </linearGradient>
                                        </defs>
                                    </svg>

                                    <div className='discover-genre' onClick={decreaseNum}>
                                        {number === 4 ? 'Access & Sale' : (number === 5 || number === 7) ? "License Type" : number === 6 ? 'Track Access' : number === 8 ? "Publish" : 'Upload Your Music'}
                                    </div>
                                </div>
                                {number === 1 || number === 2 ? (
                                    <div className='uploadnew' style={{ height: number == 2 ? 'auto' : ' 250px' }}>
                                        {/* {(uploadedFiles?.length > 0 && number === 1) ? ( */}
                                        {(uploadedFiles.length > 0 && number === 1) ? (

                                            <>
                                                {uploadData}
                                            </>
                                        ) : (
                                            <div className={isDragActive ? 'text-center dropzone active' : 'text-center dropzone'} {...getRootProps({ className: 'dropzone' })} >
                                                <div className='crUpload'>
                                                    {number === 2 ? "More to Upload?" : "Upload"}
                                                </div>
                                                <Button
                                                    component="label"
                                                    role={undefined}
                                                    sx={{ flexDirection: 'column' }}
                                                    tabIndex={-1}
                                                    startIcon={isDragActive ? null : <img src={upload} alt="Upload Icon" />}
                                                >
                                                    {/* <VisuallyHiddenInput type="file" accept="audio/*" onChange={(e) => { handleFileChange(e.target.files); setAudioerr('') }} /> */}

                                                    <div className='dragndrop'>
                                                        {isDragActive ?
                                                            <div className='hover-img'>
                                                                <img src={upload} alt="Upload Icon" />
                                                                <div className="spinner">
                                                                    <div className="spinner1">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : 'Drag-and-drop your files here, or'}
                                                    </div>
                                                    <div className='browseupload'>
                                                        browse to upload
                                                    </div>
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                ) : null}
                                {number == 1 ? audioerr !== null ?
                                    <div className="errordiv" style={{ "marginTop": "10px" }}>{audioerr}</div> :
                                    <></> :
                                    number == 2 ? audioerr1 != null ?
                                        <div className="errordiv" style={{ "marginTop": "10px" }}>{audioerr1}</div> : <></>
                                        : <></>
                                }
                                {number === 1 && <div className='text-center dragndrop cond'>
                                    Provide FLAC, WAV, ALAC, or AIFF for highest audio quality.<span className='browseupload'>Learn more about lossless HD.</span>
                                </div>}
                                {number === 2 && <div className='uploadnew' style={{ height: isLgUp ? 'auto' : 'auto' }}>
                                    {(uploadedFiles && number === 2) ? (
                                        <Grid container style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                                                <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                                    <Tabs
                                                        orientation={isLgUp ? "vertical" : null}
                                                        variant="scrollable"
                                                        value={value}
                                                        className='vertical-tab'
                                                        onChange={handleChange}
                                                        aria-label="Vertical tabs example"
                                                        sx={{ borderRight: 1, borderColor: '#175AFF', width: '100%', }}
                                                    >
                                                        <Tab label="Track" {...a11yProps(0)} />
                                                        <Tab label="Album" {...a11yProps(1)} />
                                                        <Tab label="Playlist" {...a11yProps(2)} />
                                                    </Tabs>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={12} lg={3} xl={6}>
                                                <TabPanel value={value} index={0}>
                                                    <div className='playlist-r-alnum' style={{ height: !mobileView ? 'auto' : null }}>
                                                        {uploadData}
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value={value} index={1}>
                                                    <div className='playlist-r-alnum'>
                                                        {uploadData}
                                                    </div>
                                                </TabPanel>
                                                <TabPanel value={value} index={2}>
                                                    {uploadData}
                                                </TabPanel>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                                                <div className='uploadImf'>
                                                    <img src={uploadImf} alt='add' />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </div>}
                                {number === 3 && <div className='uploadnew' style={{ height: number === 3 ? 'auto' : '250px' }}>
                                    {(uploadedFiles && number === 3) ? (
                                        <Grid container style={{ alignItems: 'center' }}>
                                            <Grid xs={12} sm={12} md={12} lg={3} xl={9}>
                                                <div className='pick-genr'>


                                                    <div className='track-name'>
                                                        {value == 0 ? 'Track Name *' : value == 1 ? ' Album Name*' : "Playlist Name*"}
                                                        {/* Track Name * */}
                                                    </div>
                                                    {/* {uploadedFiles.map((file, index) => ( */}
                                                    <>
                                                        {/* <div className='text-right'>
                                                        {editingIndex === index ?
                                                        <button class="bookmarkBtn" onClick={handleSaveButtonClick}>
                                                            <span class="IconContainer">
                                                                <svg viewBox="0 0 384 512" height="0.9em" class="icon">
                                                                    <path
                                                                        d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"
                                                                    ></path>
                                                                </svg>
                                                            </span>
                                                            <p class="text">Save</p>
                                                        </button>
                                                        :
                                                        <button class="edit-button" onClick={() => handleEditButtonClick()}>

                                                            <svg class="edit-svgIcon" viewBox="0 0 512 512">
                                                                <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
                                                            </svg>
                                                        </button>

                                                        }
                                                    </div> */}
                                                        <div className='ttckname-ch'>
                                                            <TextField
                                                                id="standard-basic"
                                                                // value={uploadedFiles ? uploadedFiles.name.slice(0, -4) : ""}
                                                                value={name}
                                                                onChange={handleInputChange}
                                                                variant="standard"
                                                                // disabled={editingIndex !== index}
                                                                inputRef={inputRef}
                                                            />
                                                        </div>
                                                    </>

                                                    {/* ))} */}
                                                </div>
                                                <div className='display-2' style={{ flexDirection: !mobileView ? 'column' : null }}>
                                                    <div className='pick-genr ne'>
                                                        <FormControl fullWidth>
                                                            {/* <InputLabel id="demo-simple-select-label" className='track-name'>Pick a Genre*</InputLabel> */}
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={category}
                                                                label="category"
                                                                placeholder='Pick a Genre'
                                                                className='select-genre'
                                                                onChange={handleChange2}
                                                                renderValue={(selected) => {
                                                                    if (selected && selected.length === 0) {
                                                                        return <MenuItem disabled>*Pick a Genre</MenuItem>;
                                                                    }

                                                                    return selected;
                                                                }}
                                                            >

                                                                <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem>
                                                                {northamerica.map((name) => (
                                                                    <MenuItem
                                                                        key={name}
                                                                        value={name}
                                                                    // style={getStyles(name, personName, theme)}
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                ))}
                                                                <MenuItem className="select-genre" disabled><strong>Latin America</strong></MenuItem>
                                                                {latinamerica.map((name) => (
                                                                    <MenuItem
                                                                        key={name}
                                                                        value={name}
                                                                    // style={getStyles(name, personName, theme)}
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                ))}
                                                                <MenuItem className="select-genre" disabled><strong>Europe</strong></MenuItem>
                                                                {europe.map((name) => (
                                                                    <MenuItem
                                                                        key={name}
                                                                        value={name}
                                                                    // style={getStyles(name, personName, theme)}
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                ))}
                                                                <MenuItem className="select-genre" disabled><strong>Africa</strong></MenuItem>
                                                                {africa.map((name) => (
                                                                    <MenuItem
                                                                        key={name}
                                                                        value={name}
                                                                    // style={getStyles(name, personName, theme)}
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                ))}
                                                                <MenuItem className="select-genre" disabled ><strong>Asia</strong></MenuItem>
                                                                {asia.map((name) => (
                                                                    <MenuItem
                                                                        key={name}
                                                                        value={name}
                                                                    // style={getStyles(name, personName, theme)}
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                ))}
                                                                <MenuItem className="select-genre" disabled ><strong>Middle East</strong></MenuItem>
                                                                {middleeast.map((name) => (
                                                                    <MenuItem
                                                                        key={name}
                                                                        value={name}
                                                                    // style={getStyles(name, personName, theme)}
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                ))}
                                                                <MenuItem className="select-genre" disabled ><strong>Oceania</strong></MenuItem>
                                                                {oceania.map((name) => (
                                                                    <MenuItem
                                                                        key={name}
                                                                        value={name}
                                                                    // style={getStyles(name, personName, theme)}
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className='pick-genr ne'>
                                                        <FormControl fullWidth>
                                                            {/* <InputLabel id="demo-simple-select-label" className='track-name'>Pick a Genre*</InputLabel> */}
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={mode}
                                                                label="Age"
                                                                className='select-genre'
                                                                onChange={handleChange1}
                                                                renderValue={(selected) => {
                                                                    if (selected && selected.length === 0) {
                                                                        return <MenuItem disabled>*Kind of Mode</MenuItem>;
                                                                    }

                                                                    return selected;
                                                                }}
                                                            >
                                                                {mood.map((name) => (
                                                                    <MenuItem
                                                                        key={name}
                                                                        value={name}
                                                                    // style={getStyles(name, personName, theme)}
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                <div className='pick-genr'>
                                                    <TextField
                                                        id="standard-multiline-static"
                                                        label="Descriptions"
                                                        multiline
                                                        rows={4}
                                                        value={description}
                                                        sx={{ width: '100%' }}
                                                        // defaultValue="Default Value"
                                                        variant="standard"
                                                        onChange={(e) => { setDescription(e.target.value) }}
                                                    />
                                                </div>
                                                <div className='pick-genr tag'>
                                                    {/* <div class="input-container">
                                                    <input type="text" name="text" class="input" placeholder="Search something..." />
                                                    <Button className='search-btn icon'>
                                                        New taq +
                                                    </Button>
                                                </div> */}
                                                    <div className="containers">
                                                        <input
                                                            type="text"
                                                            name="text"
                                                            className="inputs"
                                                            required
                                                            placeholder="Type to search..."
                                                            value={inputValue}
                                                            onChange={handleInputTag}
                                                        // onKeyDown={(e) => {
                                                        //     if (e.key === 'Enter') {
                                                        //         handleAddTag();
                                                        //     }
                                                        // }}
                                                        />
                                                        <div className="icon"
                                                            // onClick={() => { inputValue.trim() !== '' ? handleAddTag() : '' }}
                                                            onClick={handleAddTag}
                                                        >
                                                            {inputValue.trim() !== '' ? 'Save' : 'New Tag +'}
                                                        </div>

                                                        <div className='tags'>

                                                            {tags.map((tag, index) => (
                                                                <div key={index} className='addegtag'>
                                                                    {`#${tag}`}
                                                                    {/* <span onClick={() => handleDeleteTag(index)}>x</span> */}
                                                                    <DeleteIcon style={{ fontSize: '18px' }} onClick={() => { removeTag(index) }} />
                                                                </div>
                                                            ))}

                                                        </div>
                                                    </div>



                                                </div>
                                            </Grid>

                                            <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                                                <div className='uploadImf'>
                                                    {/* <img src={imageUrl ? imageUrl : uploadImf} alt='add' /> */}
                                                    <img src={image ? image : uploadImf} alt='add' />
                                                    {/* {console.log(image)} */}
                                                    <Button
                                                        component="label"
                                                        role={undefined}
                                                        tabIndex={-1}
                                                        className='uploadart'
                                                    // startIcon={isDragActive ? null : <img src={upload} alt="Upload Icon" />}
                                                    >
                                                        Add Artwork
                                                        <div className='anyone up'>
                                                            Upload From
                                                        </div>
                                                        <VisuallyHiddenInput type="file"
                                                            //  accept="audio/*"
                                                            {...getInputProps()} onChange={handleImageUpload} />
                                                    </Button>
                                                </div>
                                                {imageerr !== null ? (
                                                    <div className="errordiv" style={{ "marginTop": "10px" }}>{imageerr}</div>
                                                ) : (
                                                    <></>
                                                )}
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </div>}

                                {number === 4 && <div className='pick-genr' style={{ background: number === 4 && '#1D1D1D' }}>
                                    <div className='controlswho'>
                                        Control who has access to listen. Create gated experiences or require users pay to unlock your music.
                                    </div>
                                    <div className='display-3 tc'>
                                        <img src={tc2} alt='ad' onClick={() => { handleTypechange('free') }} />
                                        <img src={tc1} alt='sa' onClick={() => { handleTypechange('pay'); handleOpen3() }} />
                                        <Modal
                                            open={openModel3}
                                            // onClose={handleClose4}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box sx={style3}>
                                                <div style={{ background: 'var(--clr-bcg3)', borderRadius: '12px', padding: '20px 50px', height: '100%' }}>
                                                    <div className='text-left' style={{
                                                        position: 'relative',
                                                        left: '-35px'
                                                    }}>
                                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => { handleClose4(); setPg(0) }}>
                                                            < path d="M0.806643 14.4092C0.299284 13.9018 0.299285 13.0792 0.806644 12.5719L12.5719 0.806641C13.0792 0.299282 13.9018 0.299282 14.4092 0.806641C14.9165 1.314 14.9165 2.13659 14.4092 2.64395L2.64395 14.4092C2.13659 14.9165 1.314 14.9165 0.806643 14.4092ZM0.801271 2.62783C0.296879 2.12344 0.296879 1.30566 0.801271 0.801269C1.30566 0.296877 2.12344 0.296877 2.62783 0.801269L14.4146 12.588C14.9189 13.0924 14.9189 13.9102 14.4146 14.4145C13.9102 14.9189 13.0924 14.9189 12.588 14.4145L0.801271 2.62783Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                    {pg === 1 ? <>

                                                        <div className='puntext'>
                                                            Add Dster Price
                                                        </div>

                                                        <div className="layerdiv">
                                                            <div className="comment2 display-1">
                                                                <div className='commentinput'>
                                                                    <TextField id="outlined-basic" variant="outlined" placeholder='$ 99' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='pricedrp'>
                                                            Prices are in USD, but payouts will be in your local currency.
                                                        </div>
                                                        <div className="layerdiv">
                                                            <div className="comment2 display-1">
                                                                <div className='commentinput'>
                                                                    <FormControl fullWidth>
                                                                        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            value={selectAge}
                                                                            label="Age"
                                                                            onChange={handleSelectAge}
                                                                        >
                                                                            <MenuItem value={10}>What country is your bank account in?</MenuItem>
                                                                            <MenuItem value={20}>Twenty</MenuItem>
                                                                            <MenuItem value={30}>Thirty</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="setval text-center">
                                                            <Button>
                                                                PAY
                                                            </Button>
                                                        </div>
                                                    </> : pg === 2 ? <>

                                                        <div className='puntext'>
                                                            Pay via stripe
                                                        </div>

                                                        <div className="layerdiv">
                                                            <div className="comment2 display-1">
                                                                <div className='commentinput'>
                                                                    <TextField id="outlined-basic" variant="outlined" placeholder='$ 99' value={amount} onChange={(e) => { setAmount(e.target.value); setAmountErr(null) }} />
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='pricedrp'>
                                                            Prices are in USD, but payouts will be in your local currency.
                                                        </div>
                                                        {amountErr && <p style={{ color: 'red' }}>{amountErr}</p>}
                                                        <div className="setval text-center">
                                                            <Button onClick={() => { setDatas(); makePayment() }}>
                                                                PAYS
                                                            </Button>
                                                        </div>
                                                    </> : <>

                                                        <div className='puntext'>
                                                            Select Payment method
                                                        </div>

                                                        <div className='border-lye' >

                                                            <Button onClick={(() => { handlePg(1) })}>
                                                                Dster Token
                                                            </Button>

                                                        </div>
                                                        <div className='border-lye'>
                                                            <Button className='' onClick={() => { handlePg(2) }}>
                                                                Via strip
                                                            </Button>
                                                        </div>
                                                    </>}
                                                </div>

                                            </Box>
                                        </Modal>
                                    </div>
                                </div>}

                                {number === 5 && <div className='pick-genr' style={{ background: number === 5 && '#1D1D1D' }}>
                                    <Box sx={{ width: '100%', marginBottom: '100px' }}>
                                        <Box >
                                            <Tabs value={values} onChange={handleChange3} aria-label="basic tabs example" className='accesstab'>
                                                <Tab label="No Attribution" {...a11yProps(0)} />
                                                <Tab label="Allow Attribution" {...a11yProps(1)} />

                                            </Tabs>
                                        </Box>
                                        <TabPanel value={values} index={0}>
                                            <div className='allrights'>
                                                All rights reserved
                                            </div>

                                        </TabPanel>
                                        <TabPanel value={values} index={1}>
                                        </TabPanel>

                                    </Box>
                                </div>}
                                {(number === 5 || number === 6) && <div className="songtrst">

                                    <img src={sonstr} alt="ase" />
                                    <div className='whatsongtrst'>
                                        Songtrust empowers creators to own their music with song monitoring, song registration, and global publishing royalty collection, as well as music publishing education for a successful career.
                                    </div>

                                </div>}
                                {(number === 7) && <div className="pick-genr" style={{
                                    background: '#1D1D1D'
                                }}>

                                    <div style={{ height: number === 7 ? 'auto' : '250px' }}>
                                        <div>
                                            <div className='controlswho'>
                                                Upload your stems and source files to allow fans to remix your track. This does not affect users ability to listen offline.
                                            </div>
                                            <div className='display-3 tc'>
                                                <div className='licenseaccess'>
                                                    <div className="licinner">
                                                        <img src={dster} alt='fsf' />
                                                        <div className='text-center txt-c'>
                                                            You're uploading a Special Access track. By default, users who unlock your track will be able to download your available files. If you'd like to sell your files, set your track to Public or Hidden in the
                                                        </div>
                                                        <div className="pricemint">
                                                            <Button>
                                                                Price & Minting
                                                            </Button>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='licenseaccess border-radius-effect'>
                                                    <div className="licinner">
                                                        <div className='free'>
                                                            <div className='anyone'>
                                                                Free
                                                            </div>
                                                        </div>

                                                        <div className='text-center txt-c'>
                                                            Allow your fans to download a lossless copy of your full track.
                                                        </div>
                                                        <div className='anyone cursor'>
                                                            Any one can download
                                                        </div>
                                                    </div>

                                                    {/* <div className="pricemint">
                                                    <Button>
                                                        Price & Minting
                                                    </Button>
                                                </div> */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>}


                                {(number === 8) && <div className="pick-genr" style={{
                                    background: '#1D1D1D', padding: !mobileView ? '25px 5px' : '25px 50px'
                                }}>

                                    <div style={{ height: number === 8 ? '400px' : 'auto' }}>
                                        <div>
                                            <div className='controlswho'>
                                                Specify a release date for your music or schedule it to be released in the future. Release date affects sorting on your profile and is visible in track details.
                                            </div>
                                            <div>
                                                <div className='publishtype cursor' style={{ border: select === 1 && "1px solid", borderColor: select === 1 && '#175AFF' }} onClick={() => handleSelect(1, 'publish now')}>
                                                    {select === 1 && <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="16.5" cy="16.5" r="15" fill="url(#paint0_linear_4071_1871)" fill-opacity="0.2" stroke="url(#paint1_linear_4071_1871)" stroke-width="3" />
                                                        <path d="M11 16.1934L14.7417 20.6208L22.9208 12.9992" stroke="white" stroke-width="2" stroke-linecap="round" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_4071_1871" x1="32.4107" y1="29.6484" x2="-1.12525" y2="27.0679" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#175AFF" />
                                                                <stop offset="1" stop-color="#FC5AFF" />
                                                            </linearGradient>
                                                            <linearGradient id="paint1_linear_4071_1871" x1="32.4107" y1="29.6484" x2="-1.12525" y2="27.0679" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#175AFF" />
                                                                <stop offset="1" stop-color="#FC5AFF" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>}

                                                    Publish Public Now
                                                </div>
                                                <div className='publishtype cursor' style={{ border: select === 2 && "1px solid", borderColor: select === 2 && '#175AFF' }} onClick={() => handleSelect(2, startDate)}>
                                                    {select === 2 && <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="16.5" cy="16.5" r="15" fill="url(#paint0_linear_4071_1871)" fill-opacity="0.2" stroke="url(#paint1_linear_4071_1871)" stroke-width="3" />
                                                        <path d="M11 16.1934L14.7417 20.6208L22.9208 12.9992" stroke="white" stroke-width="2" stroke-linecap="round" />
                                                        <defs>
                                                            <linearGradient id="paint0_linear_4071_1871" x1="32.4107" y1="29.6484" x2="-1.12525" y2="27.0679" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#175AFF" />
                                                                <stop offset="1" stop-color="#FC5AFF" />
                                                            </linearGradient>
                                                            <linearGradient id="paint1_linear_4071_1871" x1="32.4107" y1="29.6484" x2="-1.12525" y2="27.0679" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#175AFF" />
                                                                <stop offset="1" stop-color="#FC5AFF" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                    }
                                                    {/* <div className='shedulpick'>
                                                        <img
                                                            src={date}
                                                            alt="Open Date Picker"
                                                            onClick={handleOpen}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                        <Modal
                                                            open={openModel}
                                                            onClose={handleClose2}
                                                            aria-labelledby="modal-modal-title"
                                                            aria-describedby="modal-modal-description"
                                                        >
                                                            <Box sx={style}>
                                                                {isOpen && (
                                                                    <DatePicker
                                                                        selected={startDate}
                                                                        onChange={handleDateChange}
                                                                        inline
                                                                    />
                                                                )}
                                                            </Box>
                                                        </Modal>

                                                    </div> */}
                                                    Schedule your track post
                                                    {select === 2 &&
                                                        <>
                                                            <div className='display-1 margin-top' style={{ justifyContent: 'center' }}>
                                                                <div className='sheduletext in1'>
                                                                    {/* <TextField id="outlined-basic"
                                                                    label="Date"
                                                                    variant="outlined"
                                                                    value={startDate ? moment(startDate).format("DD/MM/YYYY") : null}
                                                                 onChange={(date) => { handleDateChange(date) }}
                                                                /> */}
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                                        <DemoContainer components={['DateTimePicker']} >
                                                                            <DateTimePicker label="Date" className='datemui-picker' value={startDate1} onChange={(date) => { handleDateChange(date) }} />
                                                                        </DemoContainer>
                                                                    </LocalizationProvider>
                                                                </div>


                                                                <br />

                                                            </div>
                                                            {dateerr && <p style={{ color: 'red' }}>{dateerr}</p>}
                                                        </>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <Modal
                                            open={openModel2}
                                            onClose={handleClose3}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box sx={style2}>
                                                <div className='text-left'>
                                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClose3}>
                                                        <path d="M0.806643 14.4092C0.299284 13.9018 0.299285 13.0792 0.806644 12.5719L12.5719 0.806641C13.0792 0.299282 13.9018 0.299282 14.4092 0.806641C14.9165 1.314 14.9165 2.13659 14.4092 2.64395L2.64395 14.4092C2.13659 14.9165 1.314 14.9165 0.806643 14.4092ZM0.801271 2.62783C0.296879 2.12344 0.296879 1.30566 0.801271 0.801269C1.30566 0.296877 2.12344 0.296877 2.62783 0.801269L14.4146 12.588C14.9189 13.0924 14.9189 13.9102 14.4146 14.4145C13.9102 14.9189 13.0924 14.9189 12.588 14.4145L0.801271 2.62783Z" fill="white" />
                                                    </svg>

                                                </div>
                                                <div className='puntext'>
                                                    Publish
                                                </div>
                                                <div className='pundesc'>
                                                    Ready to begin uploading? Your followers will be notified once your upload is complete.
                                                </div>
                                                <div className='upload-next-btn pun text-center' >
                                                    {/* {
                                                        loading ?
                                                            <Button>
                                                                Processing....
                                                            </Button> : */}
                                                    <Button onClick={() => { handleClose3(); createMusic() }} >
                                                        Publish
                                                    </Button>
                                                    {/* } */}
                                                    <div>
                                                        <Button className='cancel' onClick={() => { handleClose3() }}>
                                                            Cancel
                                                        </Button>
                                                    </div>

                                                </div>
                                            </Box>
                                        </Modal>

                                    </div>

                                </div>}
                                <div className='upload-next-btn text-center' style={{ marginTop: number === 5 && '25px' }}>
                                    {
                                        number === 7 && <div className='curre' style={{ display: 'flex' }}>
                                            <div style={{ marginRight: '5px', marginBottom: '15px' }}>
                                                <svg width="15" height="15" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="5" cy="5" r="4.5" stroke="url(#paint0_linear_4068_1533)" />
                                                    <path d="M4.77226 7.66732V3.68288H5.22088V7.66732H4.77226ZM5.00033 2.99806C4.90843 2.99806 4.8299 2.96606 4.76474 2.90208C4.69957 2.83636 4.66699 2.75768 4.66699 2.66602C4.66699 2.57437 4.69957 2.49654 4.76474 2.43256C4.8299 2.36684 4.90843 2.33398 5.00033 2.33398C5.09222 2.33398 5.17075 2.36684 5.23591 2.43256C5.30108 2.49654 5.33366 2.57437 5.33366 2.66602C5.33366 2.75768 5.30108 2.83636 5.23591 2.90208C5.17075 2.96606 5.09222 2.99806 5.00033 2.99806Z" fill="url(#paint1_linear_4068_1533)" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear_4068_1533" x1="9.82143" y1="8.98438" x2="-0.340986" y2="8.20239" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#175AFF" />
                                                            <stop offset="1" stop-color="#FC5AFF" />
                                                        </linearGradient>
                                                        <linearGradient id="paint1_linear_4068_1533" x1="5.32175" y1="7.12565" x2="4.64031" y2="7.1191" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#175AFF" />
                                                            <stop offset="1" stop-color="#FC5AFF" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                            Currently you select the pricing option, After complete the pricing process you save and continue to publish

                                        </div>
                                    }

                                    {/* <Button style={{ opacity: (uploadedFiles && !upLoading) ? '100%' : '50%', width: number === 6 && '80%' }} onClick={number === 8 ? handleOpen2 : uploadedFiles && !upLoading ? incrementNumber : null} >
                                        {number === 6 && <img src={spin} alt="dad" className='spin-image' />}    {number === 1 ? 'Next' : (number === 5 || number === 6) ? 'View Rights' : (number === 7) ? "save & Continue" : number === 8 ? "Publish" : 'Continue'}
                                    </Button> */}
                                    {/* {console.log(uploadedFiles, upLoading, audioerr, audioerr1)} */}
                                    {number == 1 ? <Button style={{ opacity: (uploadedFiles.length > 0 && !upLoading && audioerr == '') ? '100%' : '50%' }}
                                        onClick={uploadedFiles.length > 0 && !upLoading && audioerr == '' ? incrementNumber : null} >
                                        {/* {number === 2 ? 'Continue' : 'Next'} */}
                                        Next
                                    </Button> : <></>}

                                    {number == 2 ? <Button style={{ opacity: (uploadedFiles.length > 0 && !upLoading && audioerr1 == '') ? '100%' : '50%' }}
                                        onClick={uploadedFiles.length > 0 && !upLoading && audioerr1 == '' ? incrementNumber : null} >
                                        Continue
                                    </Button> : <></>}

                                    {number == 3 ? <Button style={{
                                        opacity: (editedValue != '' && name !== '' && category != 'Pick a Genre' && mode != 'Kind of Mode' && tags.length > 0 && description != '' && image != null) ? '100% ' : '50% '
                                    }}
                                        onClick={editedValue != '' && name !== '' && category != 'Pick a Genre' && mode != 'Kind of Mode' && tags.length > 0 && description != '' && image != null ? incrementNumber : null
                                        } >
                                        Continue
                                    </Button> : <></>}

                                    {number == 4 ? <Button style={{ opacity: type != '' ? '100%' : '50%' }}
                                        onClick={type != '' ? incrementNumber : null} >
                                        Continue
                                    </Button> : <></>}
                                    {/* {
                                        number == 5 ? loading == true ? <Button className="btn btn-upload">Processing ...</Button> :
                                            <Button className="btn btn-upload" onClick={() => createMusic()}>Submit</Button> : <></>
                                    } */}

                                    {
                                        number == 5 || number === 6 ? <Button
                                            //  style={{ opacity: (uploadedFiles && !upLoading) ? '100%' : '50%', width: number === 6 && '80%' }}
                                            // onClick={uploadedFiles && !upLoading ? incrementNumber : null}
                                            onClick={incrementNumber}

                                        >
                                            {number == 5 ? <></> : ''
                                                // <img src={spin} alt="dad" className='spin-image' />
                                            }View Rights
                                        </Button> : <></>
                                    }
                                    {
                                        number == 7 ? <Button
                                            //  style={{ opacity: (uploadedFiles && !upLoading) ? '100%' : '50%' }}
                                            // onClick={uploadedFiles && !upLoading ? incrementNumber : null}
                                            onClick={incrementNumber}

                                        >
                                            Save and Continue
                                        </Button> : <></>
                                    }

                                    {
                                        number == 8 ? <Button style={{ opacity: (dateerr == null) ? '100%' : '50%' }}
                                            onClick={() => { dateerr == null ? handleOpen2() : handleClose2() }} >
                                            Publish
                                        </Button> : <></>
                                    }

                                </div >
                                <Snackbar
                                    open={open}
                                    autoHideDuration={5000}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                    onClose={handleClose}
                                    message={value === 0 ? "You can't upload more than one track" : "File already uploaded"}
                                // sx={{ width: '60%' }}
                                />
                            </Grid >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={3}
                            >
                                <Recent />
                            </Grid>
                            {/* <Musiccontrol /> */}

                        </Grid >
                    </Box >
                </Box >
            </div >
        </>
    );
};

export default CreatorsNew;
