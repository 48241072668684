import React, { useEffect, useState } from "react";
import Axios from "../../Axios";
import Header2 from "../Header/Header2";
// import Companylogo1 from '../../img/phause.png';
import Companylogo1 from "../../img/musicwave.gif";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import ava1 from "../../img/ava1.webp";
import VerifiedIcon from "@mui/icons-material/Verified";
import SendTrack from "../ShareLink/SendTrack";
import UndoIcon from "@mui/icons-material/Undo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import Grid from "@mui/material/Grid";
import Track from "../NewMusicTrack/NewMusicTrack";
import "./Library.css";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Tracks from "../Music-traxk/Tracks";
import { useSelector } from "react-redux";
import Companylogo from "../../img/nr-play.png";
import RepeatIcon from "@mui/icons-material/Repeat";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import f1 from "../../img/filter.png";
import { Popover } from "@mui/material";
import lib from "../../img/lib.png";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import HeaderNew from "../HeaderNew/HeaderNew";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import mtab from "../../img/mtab.gif";
import Skeleton from "@mui/material/Skeleton";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import nrplay from "../../img/nrplay.png";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const label = { inputProps: { "aria-label": "Switch demo" } };

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const drawerWidth = 280;

function Library() {
  const navigate = useNavigate();

  const [btnColor, setBtnColor] = React.useState("1");
  const [value, setValue] = React.useState(0);
  const [name, setName] = React.useState();
  const [status, setstatus] = React.useState("All");
  const [music, setMusic] = React.useState([]);
  const [album, setAlbum] = React.useState([]);
  const [play, setPlay] = React.useState([]);
  const [openModel2, setOpenModel2] = React.useState(false);
  const handleOpen2 = () => setOpenModel2(true);
  const handleModelClose2 = () => setOpenModel2(false);
  const [speed, setSpeed] = useState(1);
  const [audiosource, setAudiosource] = React.useState();
  const [count, setCount] = React.useState();
  const tokens = localStorage.getItem("iphephile");
  const [tracks, setTracks] = useState(0);
  const songlist = useSelector((state) => state.songs);
  const play_mood = useSelector((state) => state.play_mood);
  const index = useSelector((state) => state.index);
  const [audiosources, setAudiosources] = React.useState(songlist);
  const [audioDurations, setAudioDurations] = useState([]);
  const [audioDurations1, setAudioDurations1] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [values, setValues] = React.useState(true);
  const [audioplays, setAudioplays] = useState([]);
  const [isplay, setisplay] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // console.log(obj,'library');

  var a = 2;
  // to get Song Duration
  const getAudioDuration = async (audioSrc) => {
    try {
      const audio = new Audio(audioSrc);
      await audio.addEventListener("loadedmetadata", () => {
        const duration = audio.duration;
        const durationInSeconds = audio.duration;
        const durationInMinutes = (durationInSeconds / 60).toFixed(2);
        setAudioDurations((prev) => ({
          ...prev,
          [audioSrc]: durationInMinutes,
        }));
      });
    } catch (error) {
      console.error(error);
      setAudioDurations((prev) => ({
        ...prev,
        [audioSrc]: 0, // Default duration or handle error accordingly
      }));
    }
  };
  const getPreAudioDuration = async (audioSrc) => {
    try {
      const audio = new Audio(audioSrc);
      await audio.addEventListener("loadedmetadata", () => {
        const duration = audio.duration;
        const durationInSeconds = audio.duration;
        const durationInMinutes = (durationInSeconds / 60).toFixed(2);
        setAudioDurations1((prev) => ({
          ...prev,
          [audioSrc]: durationInMinutes,
        }));
      });
    } catch (error) {
      console.error(error);
      setAudioDurations1((prev) => ({
        ...prev,
        [audioSrc]: 0, // Default duration or handle error accordingly
      }));
    }
  };
  // to get song detail which is currently playing
  const getaudioPlays = async (audioSrc, index) => {
    try {
      if (audioSrc === localStorage.getItem("songName")) {
        setAudioplays((prev) => ({
          ...prev,
          [index]: 1,
        }));
      } else {
        setAudioplays((prev) => ({
          ...prev,
          [index]: 0,
        }));
      }
    } catch (error) {
      console.error(error);
      setAudioplays((prev) => ({
        ...prev,
        [index]: 0,
      }));
    }
  };
  const location = useLocation();
  useEffect(() => {
    setTracks(
      index !== undefined ? index : Number(localStorage.getItem("tracks"))
    );
    console.log(localStorage.getItem("tracks"), location, "tracksss");

    if (location?.state) {
      setSpeed(location?.state?.value);
    } else {
      setSpeed(1);
    }
  }, []);

  useEffect(() => {
    if (music?.length > 0) {
      music.forEach((row) => {
        getAudioDuration(row?.audio);
        getPreAudioDuration(row?.pre_audio);
      });
    }
    if (music.length > 0) {
      music.forEach((row, index) => {
        getaudioPlays(row?.song_name, index);
      });
    }
  }, [music]);

  useEffect(() => {
    if (music.length > 0) {
      music.forEach((row, index) => {
        getaudioPlays(row?.song_name, index);
      });
    }
  }, [values]);

  useEffect(() => {
    getMusic();
  }, []);
  useEffect(() => {
    getMusic();
  }, [name, status]);

  const reset = () => {
    setstatus("All");
    setName();
    getMusic();
  };
  // to get All songs,
  const getMusic = async () => {
    try {
      const datas = await Axios.post(
        "/users/get_music_library",
        {
          name: name,
          status: status,
        },
        {
          headers: {
            Authorization: tokens,
          },
        }
      )
        .then((res) => {
          // console.log(res?.data?.result?.musicList,"res")
          if (res?.data?.success) {
            setMusic(res?.data?.result?.musicList);
            setAlbum(res?.data?.result?.musicAlbum);
            setPlay(res?.data?.result?.musicPlay);
            setCount(res?.data?.result.length);
          } else {
            setMusic([]);
            setAlbum([]);
            setPlay([]);
          }
        })
        .catch((err) => {
          // console.log(err,"err")
          if (err?.response?.data?.message == "Admin Blocked You") {
            localStorage.clear();
            navigate('/');
            toast.error('Admin Blocked You');
          }
        });
    } catch (error) {
      // console.log(error,"error");
    }
  };
  // to add song to history
  const createMusic = async (data) => {
    try {
      const create_data = {
        song_id: data,
      };
      console.log(create_data);

      await Axios.post("/users/add_history", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          console.log(res, "res");
          if (res?.data?.success) {
            //   toast.success(res?.data?.message)

            console.log(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };
  // to repost/unrepost Songs or make open to everyone
  const editSong = async (data) => {
    try {
      const create_data = {
        songId: data.id,
        status: data.status,
      };
      console.log(create_data);

      await Axios.post("/users/edit_song_details", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          console.log(res, "res");
          if (res?.data?.success) {
            //toast.success(res?.data?.message)
            getMusic();
            // console.log(res?.data?.message)
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const audio = localStorage.getItem("audiosource");

  // to get song detail which is currently playing

  const setAudio = (data) => {
    localStorage.setItem("audiosource", data.audio);
    localStorage.setItem("songName", data.song_name);
    localStorage.setItem("Image", data.image);
    localStorage.setItem("current", 0);
    localStorage.setItem("isPause", false);
    setAudiosource(data.audio);
    localStorage.setItem("tracks", 0);
    localStorage.setItem("playsong", "false");
    setisplay(true);
    setValues(!values);
  };
  const setAudios = (data) => {
    if (audioDurations1[data?.pre_audio] !== undefined) {
      localStorage.setItem("audiosource", data.pre_audio);
      localStorage.setItem("songName", data.song_name);
      localStorage.setItem("Image", data.image);
      localStorage.setItem("current", 0);
      localStorage.setItem("tracks", 0);
      localStorage.setItem("isPause", false);
      setAudiosource(data.pre_audio);
      localStorage.setItem("playsong", "false");
      setValues(!values);
      setisplay(true);
    }
  };

  // to add song to library

  const addLibrary = async (data) => {
    try {
      const create_data = {
        song_id: data,
      };
      console.log(create_data);

      await Axios.post("/users/add_library", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          console.log(res, "res");
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            getMusic();
            console.log(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };

  // to favourite/unfavorite a song

  const addFavorite = async (data) => {
    try {
      const create_data = {
        song_id: data,
      };
      console.log(create_data);

      await Axios.post("/users/add_to_favourite", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          console.log(res, "res");
          if (res?.data?.success) {
            // toast.success(res?.data?.message)
            getMusic();
            console.log(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };
  const [send, setSend] = useState();
  const sendTracks = (data) => {
    handleOpen2();
    setSend(data);
  };
  // to favourite/unfavorite a Album

  const addalbumFavorite = async (data) => {
    try {
      const create_data = {
        album_id: data,
      };
      console.log(create_data);

      await Axios.post("/users/add_fav_album", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          console.log(res, "res");
          if (res?.data?.success) {
            //toast.success(res?.data?.message)
            getMusic();
            console.log(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };
  // to favourite/unfavorite a Playlist

  const addplayFavorite = async (data) => {
    try {
      const create_data = {
        album_id: data,
      };
      console.log(create_data);

      await Axios.post("/users/add_fav_playlist", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          console.log(res, "res");
          if (res?.data?.success) {
            // toast.success(res?.data?.message)
            getMusic();
            console.log(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };

  // to add album to library

  const addalbumLibrary = async (data) => {
    try {
      const create_data = {
        album_id: data,
      };

      await Axios.post("/users/add_album_to_library", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            getMusic();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          // console.log(err, "err")
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to add/remove playlist to library

  const addplayLibrary = async (data) => {
    try {
      const create_data = {
        play_id: data,
      };

      await Axios.post("/users/add_playlist_to_library", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            console.log(res?.data?.message);

            toast.success(res?.data?.message);
            getMusic();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          // console.log(err, "err")
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };
  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleplaylist = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popover" : undefined;

  const handleBtncolor = async (e) => {
    setBtnColor(e.target.id);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setName();
  };

  const theme = useTheme();

  const [title, setTitle] = useState("library");

  const [direction, setDirection] = React.useState("up");
  const [hidden, setHidden] = React.useState(false);

  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const handleHiddenChange = (event) => {
    setHidden(event.target.checked);
  };

  const handleSpeedChange = (newSpeed) => {
    setSpeed(newSpeed);
  };

  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowMessage(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [showMessage]);

  return (
    <div className="library">
      <Box sx={{ display: "flex" }}>
        <HeaderNew name={name} setName={setName} title={title} />

        <ToastContainer autoClose={3000} position="top-right" />
        <Box
          component="main"
          className="library-box"
          sx={{
            flexGrow: 1,
            py: 1,

            "@media (max-width:991.68px)": {
              width: `calc(100% - ${drawerWidth}px)`,
            },

            marginTop: "80px",
          }}
        >
          <Grid container spacing={0}>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="library-head-div">
                <SpeedDial
                  ariaLabel="SpeedDial basic example"
                  icon={
                    <img
                      src={mtab}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "30px",
                      }}
                    />
                  }
                  direction="right"
                  value={value}
                  className="speed-dail"
                >
                  <SpeedDialAction
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="23"
                        viewBox="0 0 19 23"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.4 17.1517V4.89721C4.4 4.31029 4.76953 3.79147 5.31224 3.61641L17.0122 0.261725C17.8494 -0.00829849 18.7 0.637235 18.7 1.54252V16.9741C18.7 16.993 18.6996 17.0118 18.6989 17.0305C18.6996 17.0535 18.7 17.0766 18.7 17.0997C18.7 18.5356 17.2449 19.6997 15.45 19.6997C13.6551 19.6997 12.2 18.5356 12.2 17.0997C12.2 15.6638 13.6551 14.4997 15.45 14.4997C15.6726 14.4997 15.89 14.5176 16.1 14.5517V4.89721L7 7.41322V19.6578C7 19.6641 6.99996 19.6704 6.99988 19.6766C6.99996 19.6843 7 19.692 7 19.6997C7 21.1356 5.54493 22.2997 3.75 22.2997C1.95507 22.2997 0.5 21.1356 0.5 19.6997C0.5 18.2638 1.95507 17.0997 3.75 17.0997C3.9726 17.0997 4.18998 17.1176 4.4 17.1517Z"
                          fill="white"
                        />
                      </svg>
                    }
                    tooltipTitle="TRACKS"
                    onClick={() => {
                      handleSpeedChange(1);
                    }}
                  />
                  <SpeedDialAction
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="21"
                        viewBox="0 0 22 21"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.7262 20.8C4.98241 20.8 0.326172 16.1438 0.326172 10.4C0.326172 4.65624 4.98241 0 10.7262 0C16.4699 0 21.1262 4.65624 21.1262 10.4C21.1262 16.1438 16.4699 20.8 10.7262 20.8ZM10.7262 15.6C13.5981 15.6 15.9262 13.2719 15.9262 10.4C15.9262 7.52812 13.5981 5.2 10.7262 5.2C7.85429 5.2 5.52617 7.52812 5.52617 10.4C5.52617 13.2719 7.85429 15.6 10.7262 15.6ZM10.7262 11.7C10.0082 11.7 9.42617 11.118 9.42617 10.4C9.42617 9.68203 10.0082 9.1 10.7262 9.1C11.4441 9.1 12.0262 9.68203 12.0262 10.4C12.0262 11.118 11.4441 11.7 10.7262 11.7Z"
                          fill="white"
                        />
                      </svg>
                    }
                    tooltipTitle="ALBUMS"
                    onClick={() => {
                      handleSpeedChange(2);
                    }}
                  />
                  <SpeedDialAction
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.44322 22.0019C6.73894 22.8468 5.56593 23.4 4.23789 23.4C2.08398 23.4 0.337891 21.9449 0.337891 20.15C0.337891 18.3551 2.08398 16.9 4.23789 16.9C4.69372 16.9 5.13128 16.9652 5.53789 17.0849V3.92672C5.53789 3.23991 5.95361 2.6328 6.56416 2.42794L14.0392 0.0725725C14.9809 -0.243406 15.9379 0.51199 15.9379 1.57134C15.9379 2.61818 15.9379 3.4033 15.9379 3.92672C15.9379 4.71184 15.2244 5.21326 14.6379 5.35758C13.794 5.56521 11.6273 6.01168 8.13789 6.69697V20.8292C8.13789 21.4513 7.88457 21.8348 7.44322 22.0019ZM17.2379 7.8H22.4379C23.1559 7.8 23.7379 8.38203 23.7379 9.1C23.7379 9.81797 23.1559 10.4 22.4379 10.4H17.2379C16.5199 10.4 15.9379 9.81797 15.9379 9.1C15.9379 8.38203 16.5199 7.8 17.2379 7.8ZM14.6379 13H22.4379C23.1559 13 23.7379 13.582 23.7379 14.3C23.7379 15.018 23.1559 15.6 22.4379 15.6H14.6379C13.9199 15.6 13.3379 15.018 13.3379 14.3C13.3379 13.582 13.9199 13 14.6379 13ZM14.6379 18.2H22.4379C23.1559 18.2 23.7379 18.782 23.7379 19.5C23.7379 20.218 23.1559 20.8 22.4379 20.8H14.6379C13.9199 20.8 13.3379 20.218 13.3379 19.5C13.3379 18.782 13.9199 18.2 14.6379 18.2Z"
                          fill="white"
                        />
                      </svg>
                    }
                    tooltipTitle="PLAYLISTS"
                    onClick={() => {
                      handleSpeedChange(3);
                    }}
                  />
                </SpeedDial>

                <div className="library-left-div">
                  <div
                    className="filter-div"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      reset();
                    }}
                  >
                    <RotateLeftIcon />
                  </div>
                  <div>
                    <div
                      className="filter-div"
                      style={{ cursor: "pointer" }}
                      onClick={handleClick}
                    >
                      <img src={f1} alt="filter" />
                    </div>

                    <Popover
                      id={id}
                      open={open}
                      className="pop-over-mui"
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <div className="pop-over">
                        <div className="non-act">
                          <div
                            onClick={() => {
                              setstatus("All");
                            }}
                          >
                            All
                          </div>
                        </div>
                        <div className="non-act">
                          <div
                            className={({ isActive }) =>
                              isActive ? "active1" : ""
                            }
                            onClick={() => {
                              setstatus("Favourite");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="20"
                              viewBox="0 0 25 20"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.08938 0.875C3.15975 0.875 0.75 4.25876 0.75 6.75321C0.75 12.1451 7.67475 16.813 12.5625 20C17.4503 16.8116 24.375 12.1465 24.375 6.75321C24.375 4.25876 21.9653 0.875 18.0356 0.875C15.8438 0.875 13.9669 2.42413 12.5625 3.89402C11.1555 2.42413 9.28125 0.875 7.08938 0.875Z"
                              />
                            </svg>
                            Favourites
                          </div>
                        </div>
                      </div>
                    </Popover>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <Box
            component="main"
            sx={{ flexGrow: 1, p: 0 }}
            className="library-box2"
          >
            {speed === 1 && (
              <div>
                <Container
                  maxWidth="lg"
                  style={audiosource ? { paddingBottom: "0px" } : {}} /*150px*/
                  className="library-play"
                >
                  {music.length > 0 ? (
                    <>
                      {music &&
                        music.map((row, index) => {
                          return (
                            <>
                              {showMessage ? (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <div className="feed-music-main-div">
                                    <Stack
                                      spacing={1}
                                      className="lib-stack"
                                      direction="row"
                                    >
                                      <Stack
                                        spacing={1}
                                        className="lib2"
                                        direction="row"
                                      >
                                        <Skeleton
                                          variant="rectangular"
                                          width={105}
                                          height={125}
                                          sx={{ bgcolor: "var(--clr-skel)" }}
                                        />
                                        <Stack spacing={2} className="lib2">
                                          <Skeleton
                                            variant="rounded"
                                            sx={{
                                              bgcolor: "var(--clr-skel)",
                                              width: "180px",
                                              height: "10px",
                                              "@media (max-width: 575.98px)": {
                                                width: "120px",
                                              },
                                            }}
                                          />
                                          <Skeleton
                                            variant="rounded"
                                            sx={{
                                              bgcolor: "var(--clr-skel)",
                                              width: "150px",
                                              height: "10px",
                                              "@media (max-width: 575.98px)": {
                                                width: "100px",
                                              },
                                            }}
                                          />
                                          <Skeleton
                                            variant="rounded"
                                            sx={{
                                              bgcolor: "var(--clr-skel)",
                                              width: "130px",
                                              height: "10px",
                                              "@media (max-width: 575.98px)": {
                                                width: "80px",
                                              },
                                            }}
                                          />
                                        </Stack>
                                      </Stack>
                                      <Stack spacing={2}>
                                        <Skeleton
                                          variant="rounded"
                                          width={30}
                                          height={5}
                                          sx={{
                                            bgcolor: "var(--clr-skel)",
                                            "@media (max-width: 575.98px)": {
                                              display: "none",
                                            },
                                          }}
                                        />
                                        <Skeleton
                                          variant="rounded"
                                          width={20}
                                          height={5}
                                          sx={{
                                            bgcolor: "var(--clr-skel)",
                                            "@media (max-width: 575.98px)": {
                                              display: "none",
                                            },
                                          }}
                                        />
                                        <Skeleton
                                          variant="rounded"
                                          width={15}
                                          height={5}
                                          sx={{
                                            bgcolor: "var(--clr-skel)",
                                            "@media (max-width: 575.98px)": {
                                              display: "none",
                                            },
                                          }}
                                        />
                                      </Stack>
                                    </Stack>
                                  </div>
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <div className="feed-music-main-div">
                                    <div
                                      className="feed-music-box cursor"
                                      key={row?._id}
                                    >
                                      <div
                                        className="music-detail"

                                      >
                                        <div className="album-img hover-play-div">
                                          <img
                                            src={row?.image && !row?.image.includes("localhost") ? row?.image : ava1}
                                            alt={ava1}
                                            onClick={(e) => {
                                              Navigate(`/trackpage/${row._id}`);
                                            }}
                                          />
                                          {row?.nft_type === "per" ?
                                            <Tooltip title={row?.nft_type == "per" && audioDurations1[row?.pre_audio] == undefined ? "Can't Play" : ""} placement="top">
                                              <img
                                                onMouseOver={() => setAudios(row)}
                                                // onMouseOut={() => setisplay(false)}
                                                src={nrplay}
                                                style={{
                                                  width: "32px",
                                                  height: "32px",
                                                }}
                                                className="hover-play3"
                                              />
                                            </Tooltip> : <></>}
                                        </div>

                                        <div className="album-detail">
                                          <div className="music-time">
                                            <div className="time-music">
                                              <div
                                                className="music-name"
                                                onClick={(e) => {
                                                  Navigate(
                                                    `/trackpage/${row._id}`
                                                  );
                                                }}
                                              >
                                                <Link to="">
                                                  {row?.song_name}-{row?.ISRC}{" "}
                                                  {row?.nft_type === "per" ? (
                                                    <>
                                                      <VerifiedIcon className="premium" />
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </Link>
                                              </div>
                                            </div>
                                            <div className="music-timing">
                                              <p>
                                                {audioDurations[row?.audio]}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="loop-times">
                                            <div className="loop-part">
                                              <div className="company-logo">
                                                {(audioDurations[row?.audio] !==
                                                  undefined &&
                                                  row?.nft_type === "nor") ||
                                                  row?.isMine === true ? (
                                                  <>
                                                    {audioplays[index] === 0 ||
                                                      isplay === false ? (
                                                      <Tooltip
                                                        title="Play Song"
                                                        placement="top"
                                                      >
                                                        <img
                                                          onClick={() => {
                                                            setAudio(row);
                                                            createMusic(
                                                              row?._id
                                                            );
                                                            setAudiosource(
                                                              row?.audio
                                                            );
                                                          }}
                                                          src={Companylogo}
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          alt="Companylogo"
                                                        />
                                                      </Tooltip>
                                                    ) : (
                                                      <img
                                                        src={Companylogo1}
                                                        id="pauseImage"
                                                        alt={Companylogo}
                                                        onClick={() =>
                                                          setisplay(false)
                                                        }
                                                      />
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    {row?.nft_type === "per" &&
                                                      audioDurations[
                                                      row?.audio
                                                      ] !== undefined ? (
                                                      <Tooltip
                                                        title="Premium Song"
                                                        placement="top"
                                                      >
                                                        <>
                                                          {(audioplays[index] ==
                                                            0 ||
                                                            isplay == false) ==
                                                            true ? (
                                                            <img
                                                              src={Companylogo}
                                                              onClick={() => {
                                                                setAudios(row);
                                                                createMusic(
                                                                  row?._id
                                                                );
                                                                setAudiosource(
                                                                  row?.pre_audio
                                                                );
                                                              }}
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              alt="nr"
                                                            />
                                                          ) : (
                                                            <img
                                                              src={Companylogo1}
                                                              id="pauseImage"
                                                              onClick={() => {
                                                                setisplay(
                                                                  false
                                                                );
                                                              }}
                                                              alt={Companylogo}
                                                            />
                                                          )}
                                                        </>
                                                      </Tooltip>
                                                    ) : (
                                                      <Tooltip
                                                        title="Can't Play"
                                                        placement="top"
                                                      >
                                                        <img
                                                          src={Companylogo}
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          alt="nr"
                                                        />
                                                      </Tooltip>
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                              <div className="repost-loop">
                                                <p>
                                                  {" "}
                                                  <RepeatIcon className="repeat" />
                                                  {row?.reposts?.length
                                                    ? row?.reposts?.length
                                                    : 0}{" "}
                                                  reposts
                                                </p>
                                              </div>
                                              <div className="repost-loop">
                                                <p>
                                                  {" "}
                                                  <FavoriteIcon className="unfav" />
                                                  {row?.fav_count
                                                    ? row?.fav_count
                                                    : 0}{" "}
                                                  Favorite
                                                </p>
                                              </div>
                                            </div>
                                            <div className="music-timing">
                                              <p>
                                                {row?.players?.length
                                                  ? row?.players?.length
                                                  : 0}{" "}
                                                Plays
                                              </p>
                                            </div>
                                          </div>
                                          <div className="like-dot">
                                            <div className="share-btn">
                                              {row.isRepost === true ? (
                                                <Tooltip
                                                  title="Unrepost"
                                                  placement="top"
                                                >
                                                  <RepeatIcon
                                                    onClick={() => {
                                                      editSong({
                                                        id: row?._id,
                                                        status: "repost",
                                                      });
                                                    }}
                                                    className="repeat"
                                                  />
                                                </Tooltip>
                                              ) : (
                                                <Tooltip
                                                  title="Repost"
                                                  placement="top"
                                                >
                                                  <RepeatIcon
                                                    onClick={() => {
                                                      editSong({
                                                        id: row?._id,
                                                        status: "repost",
                                                      });
                                                    }}
                                                  />
                                                </Tooltip>
                                              )}
                                              {row?.userFav === true ? (
                                                <Tooltip
                                                  title="Unfavourite"
                                                  placement="top"
                                                >
                                                  <FavoriteIcon
                                                    className="unfav"
                                                    onClick={() => {
                                                      addFavorite(row?._id);
                                                    }}
                                                  />
                                                </Tooltip>
                                              ) : (
                                                <Tooltip
                                                  title="Favourite"
                                                  placement="top"
                                                >
                                                  <FavoriteIcon
                                                    onClick={() => {
                                                      addFavorite(row?._id);
                                                    }}
                                                  />
                                                </Tooltip>
                                              )}

                                              <div
                                                onClick={() => {
                                                  sendTracks(row?._id);
                                                }}
                                              >
                                                <ShortcutIcon />
                                              </div>
                                              <SendTrack
                                                handleModelClose2={
                                                  handleModelClose2
                                                }
                                                handleOpen2={handleOpen2}
                                                openModel2={openModel2}
                                                id={`/trackpage/${send}`}
                                              />

                                              {row?.userFound === true ? (
                                                <Tooltip
                                                  title="Remove from Library"
                                                  placement="top"
                                                >
                                                  <UndoIcon
                                                    onClick={() => {
                                                      addLibrary(row?._id);
                                                    }}
                                                  />
                                                </Tooltip>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                            {row?.isMine === true ? (
                                              <Switch
                                                {...label}
                                                checked={row?.opentoeveryone}
                                                onClick={() => {
                                                  editSong({
                                                    id: row?._id,
                                                    status: "open",
                                                  });
                                                }}
                                              />
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              )}
                            </>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      <Grid container spacing={2}>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div className="description-library">
                            You haven’t added any tracks yet.
                          </div>
                          <div className="description-library2">
                            Once you have, this is where you’ll find them!
                          </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div className="start-listen-btn1">
                            <Button onClick={() => Navigate("/feed")}>
                              Go to Trending
                            </Button>
                          </div>
                        </Grid>
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="lib-grid"
                        >
                          <div className="lib">
                            <img src={lib} alt="lib" />
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Container>
              </div>
            )}
            {speed === 2 && (
              <div>
                <Container maxWidth="lg" className="favourite-album-container">
                  <Grid container spacing={0} sx={{ justifyContent: "center" }}>
                    {album.length > 0 ? (
                      <>
                        {album &&
                          album.map((row, index) => {
                            return (
                              <>
                                <Grid xs={12} sm={12} md={6} lg={4} xl={3}>
                                  <Card
                                    sx={{ maxWidth: 232 }}
                                    className="card-setting"
                                  >
                                    <CardMedia
                                      sx={{
                                        height: 200,
                                        width: 200,
                                        cursor: "pointer",
                                      }}
                                      image={row?.image && !row?.image.includes("localhost") ? row?.image : ava1}
                                      title="green iguana"
                                      className="lib-album-img"
                                      onClick={(e) => {
                                        Navigate(`/albumpage/${row?._id}`, {
                                          state: { value },
                                        });
                                      }}
                                    />
                                    <CardContent
                                      className="album-lib-typo"
                                      sx={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        Navigate(`/albumpage/${row?._id}`, {
                                          state: { value },
                                        });
                                      }}
                                    >
                                      <Typography
                                        gutterBottom
                                        className="library-album-card-title"
                                        component="div"
                                      >
                                        {row?.album_name}
                                      </Typography>
                                      <p className="lib-album-para">
                                        {row?.fav_count} Favourites .
                                        <span>
                                          {" "}
                                          {row?.songDetails?.length} Tracks
                                        </span>
                                      </p>
                                    </CardContent>
                                    <CardActions className="album-card-follow">
                                      <Stack
                                        direction="row"
                                        divider={
                                          <Divider
                                            orientation="vertical"
                                            flexItem
                                          />
                                        }
                                        spacing={4}
                                      >
                                        <span>
                                          <Tooltip
                                            title={
                                              row?.userFav === true
                                                ? "Remove from Favourite"
                                                : "Add to Favourite"
                                            }
                                            placement="top"
                                          >
                                            <FavoriteIcon
                                              className={
                                                row?.userFav === true
                                                  ? "unfav"
                                                  : "unfavs"
                                              }
                                              onClick={() => {
                                                addalbumFavorite(row?._id);
                                              }}
                                            />
                                          </Tooltip>
                                          {row?.userFound === false ? (
                                            <></>
                                          ) : (
                                            <Tooltip
                                              title="Remove from Library"
                                              placement="top"
                                            >
                                              <div
                                                className="playtime more-histore"
                                                onClick={() => {
                                                  addalbumLibrary(row?._id);
                                                }}
                                              >
                                                <UndoIcon />
                                              </div>
                                            </Tooltip>
                                          )}
                                        </span>
                                      </Stack>
                                    </CardActions>
                                  </Card>
                                </Grid>
                              </>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        <Grid container spacing={2}>
                          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="description-library">
                              You haven’t favorited any tracks yet.
                            </div>
                            <div className="description-library2">
                              Once you have, this is where you’ll find them!
                            </div>
                          </Grid>
                          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="start-listen-btn1">
                              <Button onClick={() => Navigate("/feed")}>
                                Go to Trending
                              </Button>
                            </div>
                          </Grid>
                          <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="lib-grid"
                          >
                            <div className="lib">
                              <img src={lib} alt="lib" />
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Container>
              </div>
            )}

            {speed === 3 && (
              <div>
                <Container
                  maxWidth="lg"
                  className="favourite-album-container library-play"
                >
                  <Grid container spacing={0} sx={{ justifyContent: "center" }}>
                    <Grid xs={12} sm={12} md={6} lg={4} xl={3}>
                      <Card
                        sx={{ maxWidth: 232, height: 332, cursor: "pointer" }}
                        className="create-playlist-card card-setting"
                        onClick={handleplaylist}
                      >
                        <CardContent className="album-lib-typo">
                          <Typography
                            gutterBottom
                            className="library-album-card-title2"
                            component="div"
                          >
                            <AddIcon />
                            <div>Create New Playlist</div>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <div>
                      <Popover
                        id={id2}
                        open={open2}
                        anchorEl={anchorEl2}
                        onClose={handleClose2}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        className="playlist-popover"
                      >
                        <div
                          className="playlist-more-div"
                          onClick={(e) => {
                            Navigate("/create", { state: { speed } });
                          }}
                        >
                          <CloudUploadIcon />
                          Upload Playlist
                        </div>
                        <div
                          className="playlist-more-div"
                          onClick={() => {
                            Navigate("/createplaylist", { state: { speed } });
                          }}
                        >
                          <QueueMusicIcon />
                          Create Playlist
                        </div>
                      </Popover>
                    </div>
                    {play.length > 0 ? (
                      <>
                        {play &&
                          play.map((row, index) => {
                            return (
                              <>
                                <Grid xs={12} sm={12} md={6} lg={4} xl={3}>
                                  <Card
                                    sx={{ maxWidth: 232 }}
                                    className="card-setting"
                                  >
                                    <CardMedia
                                      sx={{
                                        height: 200,
                                        width: 200,
                                        cursor: "pointer",
                                      }}
                                      image={row?.image && !row?.image.includes("localhost") ? row?.image : ava1}
                                      title="green iguana"
                                      className="lib-album-img"
                                      onClick={(e) => {
                                        Navigate(`/showplaylist/${row._id}`, {
                                          state: { row, speed },
                                        });
                                      }}
                                    />
                                    <CardContent
                                      className="album-lib-typo"
                                      sx={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        Navigate(`/showplaylist/${row._id}`, {
                                          state: { row, speed },
                                        });
                                      }}
                                    >
                                      <Typography
                                        gutterBottom
                                        className="library-album-card-title"
                                        component="div"
                                      >
                                        {row?.playlist}
                                      </Typography>
                                      <p className="lib-album-para">
                                        {row?.fav_count} Favourites .
                                        <span>
                                          {" "}
                                          {row?.songDetails?.length} Tracks
                                        </span>
                                      </p>
                                    </CardContent>
                                    <CardActions className="album-card-follow">
                                      <Stack
                                        direction="row"
                                        divider={
                                          <Divider
                                            orientation="vertical"
                                            flexItem
                                          />
                                        }
                                        spacing={4}
                                      >
                                        <span>
                                          <Tooltip
                                            title={
                                              row?.userFav === true
                                                ? "Remove from Favourite"
                                                : "Add to Favourite"
                                            }
                                            placement="top"
                                          >
                                            <FavoriteIcon
                                              className={
                                                row?.userFav === true
                                                  ? "unfav"
                                                  : "unfavs"
                                              }
                                              onClick={() => {
                                                addplayFavorite(row?._id);
                                              }}
                                            />
                                          </Tooltip>
                                        </span>

                                        {row?.userFound === false ? (
                                          <></>
                                        ) : (
                                          <Tooltip
                                            title="Remove from Library"
                                            placement="top"
                                          >
                                            <div
                                              className="playtime more-histore"
                                              onClick={() => {
                                                addplayLibrary(row?._id);
                                              }}
                                            >
                                              <UndoIcon />
                                            </div>
                                          </Tooltip>
                                        )}
                                      </Stack>
                                    </CardActions>
                                  </Card>
                                </Grid>
                              </>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        <Grid container spacing={2}>
                          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="description-library">
                              You haven’t favorited any tracks yet.
                            </div>
                            <div className="description-library2">
                              Once you have, this is where you’ll find them!
                            </div>
                          </Grid>
                          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="start-listen-btn1">
                              <Button onClick={() => Navigate("/feed")}>
                                Go to Trending
                              </Button>
                            </div>
                          </Grid>
                          <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="lib-grid"
                          >
                            <div className="lib">
                              <img src={lib} alt="lib" />
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Container>
              </div>
            )}
            {localStorage.getItem("playsong") === "true" ? (
              <>
                {audiosources.length > 0 ? (
                  <Tracks audiosources={audiosources} tracks={tracks} />
                ) : (
                  <></>
                )}{" "}
              </>
            ) : (
              <></>
            )}
            {(localStorage.getItem("playsong") === "false" && audiosource) ===
              true ? (
              <Track
                audiosource={audiosource}
                isplay={isplay}
                setisplay={setisplay}
              />
            ) : (
              <>
                {(localStorage.getItem("playsong") === "false" &&
                  audio !== null) === true ? (
                  <Track
                    audiosource={audio}
                    isplay={isplay}
                    setisplay={setisplay}
                  />
                ) : (
                  <></>
                )}{" "}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default Library;
