import React, { useEffect, useState, useRef } from "react";
import Header2 from "../Header/Header2";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Track from "../Music-traxk/Track";
import "../Profile/Profile.css";
import "./ActivityDetailPage.css";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import cart from "../../img/cart.png";
import mainBg from "../../img/profile-main-bg.png";
import subBg from "../../img/profile-sub-bg.png";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Axios from "../../Axios";
import { ToastContainer, toast } from 'react-toastify';
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import Visibility1 from "@mui/icons-material/Visibility";
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
import VisibilityOff from "../../img/eyelash.svg";
import VisibilityOff1 from "../../img/eyelash.svg";
import Avatar from "@mui/material/Avatar";
// import ava from "../../img/ava.jpg";
import ava from "../../img/ava1.webp";
import EditIcon from "@mui/icons-material/Edit";
import Header from '../Header/Header';
import { makeStyles } from "@mui/styles";
// import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import VerifiedIcon from '@mui/icons-material/Verified';
import Cards from '../../img/card-1.png';
import Cards1 from '../../img/card-2.png';
import Cards2 from '../../img/card-3.png';
import Cards3 from '../../img/card-3.png';
import Cards4 from '../../img/card-4.png';
import Cards5 from '../../img/card-5.png';
import Cards6 from '../../img/card-6.png';
import Cards7 from '../../img/card-7.png';
import Cards8 from '../../img/card-8.png';
import Cards9 from '../../img/card-9.png';
import Cards10 from '../../img/card-10.png';
import Cards11 from '../../img/card-11.png';
import Cards12 from '../../img/card-12.png';
import join from '../../img/join-icon.svg';
import ListIcon from '@mui/icons-material/List';
import icon from '../../img/c-icon.png';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ad from "../../img/activitydetail.avif"
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DescriptionIcon from '@mui/icons-material/Description';
import NotesIcon from '@mui/icons-material/Notes';
import HeaderNew from "../HeaderNew/HeaderNew";




const drawerWidth = 280;

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    cardbg: {
        borderRadius: "12px !important",
        background: "rgba(255, 255, 255, 0.10)",
        boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
        backdropFilter: "blur(37.5px)",
        padding: "0 !important",

    },

    pwrback: {
        borderRadius: "12px !important",
        border: "1px solid rgba(255, 255, 255, 0.20)",
        background: "#000000 !important",
    },

    learn: {
        borderRadius: "12px !important",
        background: 'transparent !important',
        boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
    },

    containWidth: {
        maxWidth: '1440px',
        margin: 'auto',
        background: 'none !important',
        padding: '0 !important',
        boxShadow: 'none !important',

        "@media (max-width: 1199.98px)": {
            padding: '20px !important',
        },
    }

});


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const card = [
    { img: `${Cards}`, name: 'Jump' },
    { img: `${Cards1}`, name: 'ManTra' },
    { img: `${Cards2}`, name: 'Opera Odiosa ao Demônio da Putrefação' },
    { img: `${Cards3}`, name: 'Blues-corn' },
    { img: `${Cards4}`, name: 'Jump' },
    { img: `${Cards5}`, name: 'Afro KingKong vs Godzilla' },
    { img: `${Cards6}`, name: 'Jump' },
    { img: `${Cards7}`, name: 'HOSTAGE Feat. Tshaka' },
    { img: `${Cards8}`, name: 'Jump' },
    { img: `${Cards9}`, name: 'ManTra' },
    { img: `${Cards10}`, name: 'Jump' },
    { img: `${Cards11}`, name: 'HOSTAGE Feat. Tshaka' },
    { img: `${Cards12}`, name: 'Jump' },
]


function ActivityDetailPage() {

    const classes = useStyles();
    const theme = useTheme();
    const currTime = new Date().toLocaleTimeString;
    console.log(currTime, "time");

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className="UploadMusic profile">
            <ToastContainer position="top-right" />
            <Box sx={{ display: "flex" }}>
                <HeaderNew title="Activity"/>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, marginTop: '60px' }}
                    className="profile-outer-layer-activity-detail"
                >



                    <Grid container spacing={0}>

                        <Grid xs={12} sm={12} md={5} lg={5} xl={5}>
                            <div className="activity-detail-img">
                                <img src={ad} alt='ad' />
                            </div>

                        </Grid>
                        <Grid xs={12} sm={12} md={7} lg={7} xl={7}>
                            <div>
                                <div className="ac-detail-title-verify">
                                    Sugartown Oras by Zynga <VerifiedIcon />
                                </div>
                                <div className="ac-detail-title">
                                    Sugartown Oras #597
                                </div>
                                <div className="owned-by">
                                    Owned by
                                    <span>CA12B4</span>
                                </div>
                                <div className="follow-ac-detail">
                                    <div className="count-ac-detail">
                                        #2,848
                                    </div>
                                    <div>
                                        <RemoveRedEyeIcon /> 113 Views
                                    </div>
                                    <div>
                                        <FavoriteBorderIcon />2 favorites
                                    </div>
                                    <div>
                                        <SportsEsportsIcon /> Gaming
                                    </div>
                                </div>
                                <div className="sales-on">
                                    Sale ends February 7, 2024 at 7:09 AM
                                </div>
                                <div className="time-n-date-ac-detail">
                                    <div>
                                        01
                                        <div>Days</div>
                                    </div>
                                    <div>
                                        17
                                        <div>Hours</div>
                                    </div>
                                    <div>
                                        22
                                        <div>Minutes</div>
                                    </div>
                                    <div>
                                        45
                                        <div>Seconds</div>
                                    </div>
                                </div>
                                <div className="current-price">
                                    Current price
                                </div>
                                <div className="value-act-detail">
                                    0.308 ETH<span>$709.10</span>
                                </div>
                                <div className="buy-now-act-detail">
                                    <Button className="b1"><ShoppingCartIcon />BuyNow </Button>
                                    <Button className="b2"><LocalOfferIcon />MakeOffer</Button>
                                </div>

                            </div>

                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="description-act-detail">
                                <NotesIcon /> Description
                            </div>
                            <div className="description-content-act-detail">
                                <div className="by"> By Zynga <VerifiedIcon /></div>
                                <div>
                                    Oras are a collection of 9,999 unique beings from another dimension. They entered Sugartown through a rift in the space-time continuum that was accidentally created when the trio of farm friends were developing games.
                                </div>
                            </div>

                        </Grid>
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="description-act-detail">
                                <DescriptionIcon />Details
                            </div>
                            <div className="description-content-act-detail">
                                <div>
                                    <div className="details-inner-details-text">
                                        <div>
                                            Contract Address
                                        </div>
                                        <div>
                                            0xd564...7b38
                                        </div>
                                    </div>
                                    <div className="details-inner-details-text">
                                        <div>
                                            Token ID
                                        </div>
                                        <div>
                                            5994
                                        </div>
                                    </div>
                                    <div className="details-inner-details-text">
                                        <div>
                                            Token Standard
                                        </div>
                                        <div>
                                            ERC-721
                                        </div>
                                    </div>
                                    <div className="details-inner-details-text">
                                        <div>
                                            Chain
                                        </div>
                                        <div>
                                            Ethereum
                                        </div>
                                    </div>
                                    <div className="details-inner-details-text">
                                        <div>
                                            Last Updated
                                        </div>
                                        <div>
                                            1 month ago
                                        </div>
                                    </div>
                                    <div className="details-inner-details-text">
                                        <div>
                                            Creator Earnings
                                        </div>
                                        <div>
                                            5%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>



                    {/* <div>
              <Track />
            </div> */}
                </Box>

            </Box>
        </div>
    );
}

export default ActivityDetailPage;
