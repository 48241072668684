import React, { useEffect, useState } from "react";
import HeaderNew from "../HeaderNew/HeaderNew";
import Box from "@mui/material/Box";
import ava1 from "../../img/ava1.webp";
import UndoIcon from "@mui/icons-material/Undo";
import { useParams } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Tracks from "../Music-traxk/Tracks";
import { useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import SendMessages from "../ShareLink/SendMessages";
import DownloadIcon from "@mui/icons-material/Download";
import "./TrackPage.css";
import Axios from "../../Axios";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Album from "../../img/music-logo.png";
import { Link } from "react-router-dom";
import Companylogo1 from "../../img/musicwave.gif";
import Companylogo from "../../img/nr-play.png";
import RepeatIcon from "@mui/icons-material/Repeat";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import Track from "../NewMusicTrack/NewMusicTrack";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Popover from "@mui/material/Popover";
import bg from "../../img/dup.webp";
import { useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SendTrack from "../ShareLink/SendTrack";
import Avatar from "@mui/material/Avatar";
import { ToastContainer, toast } from "react-toastify";
import nodata from "../../img/newnodata.gif";
import nrplay from "../../img/nrplay.png";
// import usePageSEO from "../../Hooks/OgConte";
import constant from "../../Constant"
const drawerWidth = 280;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "var(--clr-bcg)",
  height: "620px",
  // overflow: 'scroll',
  // border: '2px solid #000',
  // boxShadow: 'none',
  p: 4,
  "@media all and (max-width: 1199.98px)": {
    width: "55%",
  },
  "@media all and (max-width: 768.98px)": {
    width: "85%",
  },
  "@media all and (max-width: 575.98px)": {
    width: "95%",
  },
};

const Trackpage = () => {
  const [value, setValue] = React.useState(0);
  const [values, setValues] = React.useState(true);
  const [music, setMusic] = React.useState([]);
  const [usermusic, setUsermusic] = React.useState([]);
  const [name, setName] = React.useState();
  const [openModel, setOpenModel] = React.useState(false);
  const [isplay, setisplay] = useState(false);
  const handleOpen = () => setOpenModel(true);
  const handleModelClose = () => setOpenModel(false);
  const [openModel1, setOpenModel1] = React.useState(false);
  const handleOpen1 = () => setOpenModel1(true);
  const handleModelClose1 = () => setOpenModel1(false);
  const [openModel2, setOpenModel2] = React.useState(false);
  const handleOpen2 = () => setOpenModel2(true);
  const handleModelClose2 = () => setOpenModel2(false);
  const [playname, setPlayname] = React.useState();
  const [song_id, setsongid] = React.useState();
  const [play, setPlay] = React.useState([]);
  const params = useParams();
  const [audioplays, setAudioplays] = useState([]);
  const [audiosource, setAudiosource] = React.useState();
  const [count, setCount] = React.useState();
  const tokens = localStorage.getItem("iphephile");
  const navigate = useNavigate();
  const [audioDurations, setAudioDurations] = useState([]);
  const [audioDurations1, setAudioDurations1] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tracks, setTracks] = useState(0);
  const songlist = useSelector((state) => state.songs);
  const play_mood = useSelector((state) => state.play_mood);
  const index = useSelector((state) => state.index);
  const [audiosources, setAudiosources] = React.useState(songlist);
  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleAdd = async (data) => {
    try {
      const create_data = {
        playlistId: data,
        songId: song_id,
      };
      await Axios.post("/users/edit_playlist", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            getMusic();
            handleModelClose();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const addPlay = (data) => {
    // setsongid(data)
    handleOpen();
    //handleCloseEl2()
    handleClose();
  };
  useEffect(() => {
    getplayLists();
  }, [playname]);
  const getplayLists = async () => {
    try {
      const datas = await Axios.post(
        "/users/get_my_playlist",
        {
          playname: playname,
        },
        {
          headers: {
            Authorization: tokens,
          },
        }
      )
        .then((res) => {
          // console.log(res?.data?.result?.musicList,"res")
          if (res?.data?.success) {
            setPlay(res?.data?.result);
          } else {
            // setMusics([])
            // setAlbum([])
            setPlay([]);
          }
        })
        .catch((err) => {
          // console.log(err,"err")
        });
    } catch (error) {
      // console.log(error,"error");
    }
  };
  const editSong = async (data) => {
    try {
      const create_data = {
        songId: data.id,
        status: data.status,
      };
      await Axios.post("/users/edit_song_details", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            //toast.success(res?.data?.message)
            getMusic();
            // console.log(res?.data?.message)
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };
  const getAudioDuration = async (audioSrc) => {
    try {
      const audio = new Audio(audioSrc);
      await audio.addEventListener("loadedmetadata", () => {
        const duration = audio.duration;
        const durationInSeconds = audio.duration;
        const durationInMinutes = (durationInSeconds / 60).toFixed(2);
        setAudioDurations((prev) => ({
          ...prev,
          [audioSrc]: durationInMinutes,
        }));
      });
    } catch (error) {
      setAudioDurations((prev) => ({
        ...prev,
        [audioSrc]: 0, // Default duration or handle error accordingly
      }));
    }
  };

  const getPreAudioDuration = async (audioSrc) => {
    try {
      const audio = new Audio(audioSrc);
      await audio.addEventListener("loadedmetadata", () => {
        const duration = audio.duration;
        const durationInSeconds = audio.duration;
        const durationInMinutes = (durationInSeconds / 60).toFixed(2);
        setAudioDurations1((prev) => ({
          ...prev,
          [audioSrc]: durationInMinutes,
        }));
      });
    } catch (error) {
      console.error(error);
      setAudioDurations1((prev) => ({
        ...prev,
        [audioSrc]: 0, // Default duration or handle error accordingly
      }));
    }
  };
  const getaudioPlays = async (audioSrc, index) => {
    try {
      if (audioSrc === localStorage.getItem("songName")) {
        setAudioplays((prev) => ({
          ...prev,
          [index]: 1,
        }));
        setisplay(true);
      } else {
        setAudioplays((prev) => ({
          ...prev,
          [index]: 0,
        }));
      }
    } catch (error) {
      console.error(error);
      setAudioplays((prev) => ({
        ...prev,
        [index]: 0,
      }));
    }
  };

  useEffect(() => {
    if (usermusic) {
      usermusic.forEach((row, index) => {
        getaudioPlays(row?.song_name, index);
      });
    }
  }, [values]);

  useEffect(() => {
    if (usermusic?.length > 0) {
      usermusic.forEach((row) => {
        getPreAudioDuration(row?.pre_audio);
        getAudioDuration(row?.audio);
      });
    }

    if (usermusic) {
      usermusic.forEach((row, index) => {
        getaudioPlays(row?.song_name, index);
      });
    }
  }, [usermusic]);

  useEffect(() => {
    if (music) {
      getPreAudioDuration(music?.pre_audio);
      getAudioDuration(music?.audio);
    }
  }, [music]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // console.log(location?.state?.row?._id,"row")
    setTracks(
      index !== undefined ? index : Number(localStorage.getItem("tracks"))
    );

    getMusic();
    getplayLists();
  }, []);
  useEffect(() => {
    getMusic();
  }, [params._id]);
  
  const getMusic = async () => {
    try {
      var findData = { song_id: params._id };

      if(tokens != null){
        const datas = await Axios.post("/users/get_one_music", findData, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            setMusic(res?.data?.result?.musicList[0]);
            if (res?.data?.result?.musicList[0].SongDetails.length > 1) {
              setUsermusic(res?.data?.result?.musicuserList);
            } else {
              setUsermusic(res?.data?.result?.musicuserList);
            }
            setCount(res?.data?.count);
          } else {
            //toast.error(err?.response?.data?.message)
            setMusic([]);
          }
        })
        .catch((err) => {
          // console.log(err,"err")
        });
      }else{
        const datas = await Axios.post("/users/get_one_mus", findData, {})
        .then((res) => {
          if (res?.data?.success) {
            setMusic(res?.data?.result?.musicList[0]);
            getAudioDuration(res?.data?.result?.musicList[0]?.audio);
            
            if (res?.data?.result?.musicList[0].SongDetails.length > 1) {
              setUsermusic(res?.data?.result?.musicuserList);
            } else {
              setUsermusic(res?.data?.result?.musicuserList);
            }
            setCount(res?.data?.count);
          } else {
            //toast.error(err?.response?.data?.message)
            setMusic([]);
          }
        })
        .catch((err) => {
          // console.log(err,"err")
        });
        }
    } catch (error) {
      // console.log(error,"error");
    }
  };

  useEffect(()=>{
   
    localStorage.setItem("songName",music.song_name)
    if(tokens != "null"){ 
    const message = `${location.pathname}`
    localStorage.setItem("URL", message)
  }
   
    if(music.song_name != undefined){
      document.title = music.song_name
    }
    setMetaTag('name','description',music.song_name)
    setMetaTag('name','keyword',"dreammusic")
    setMetaTag('property','og:title',music.song_name || "ShareTrack")
    setMetaTag('property','og:description',music.description || "ShareTrack")
    setMetaTag('property','og:description',music.description || "ShareTrack")
    setMetaTag('property','og:image',music.image)
    setMetaTag('property','og:url ',`${constant.MainUrl}/trackpage/${music._id}` || window.location.href)
    return()=>{

    }

 },[music ])

    const setMetaTag = (attr,key,content) => {
        if(content){
          
            let element = document.querySelector(`meta[${attr}="${key}"]`)
            if(!element){
                element = document.createElement('meta')
                element.setAttribute(attr,key)
                document.head.appendChild(element)
            }
            element.setAttribute('content',content)
        }
    }





  // const se = () => {}
  //   usePageSEO({
  //     title: music.song_name,
  //     description : music.description,
  //     Kewords : [ "MUSIC-NFT", "DreansterMusic"] , 
  //     ogTitle : music.song_name ,
  //     ogImage : music.image, 
  //     ogUrl : `${constant.MainUrl}${music.id}`
  //   })
  

  const createMusic = async (data) => {
    try {
      const create_data = {
        song_id: data,
      };

      await Axios.post("/users/add_history", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            //   toast.success(res?.data?.message)
            getMusic();
            // console.log(res?.data?.message)
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };
  const audio = localStorage.getItem("audiosource");
  const setAudio = (data) => {
    localStorage.setItem("audiosource", data.audio);
    localStorage.setItem("songName", data.song_name);
    localStorage.setItem("Image", data.image);
    localStorage.setItem("current", 0);
    localStorage.setItem("isPause", false);
    localStorage.setItem("tracks", 0);
    setAudiosource(data.audio);
    setValues(!values);
    localStorage.setItem("playsong", "false");
    setisplay(true);
  };

  const setAudios = (data) => {
    if (audioDurations1[data?.pre_audio] !== undefined) {
      localStorage.setItem("audiosource", data.pre_audio);
      localStorage.setItem("songName", data.song_name);
      localStorage.setItem("Image", data.image);
      localStorage.setItem("current", 0);
      localStorage.setItem("tracks", 0);
      localStorage.setItem("isPause", false);
      setAudiosource(data.pre_audio);
      localStorage.setItem("playsong", "false");
      setValues(!values);
      setisplay(true);
    }
  };

  const addLibrary = async (data) => {
    try {
      const create_data = {
        song_id: data,
      };

      await Axios.post("/users/add_library", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            getMusic();
            // console.log(res?.data?.message)
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
    }
  };

  const addFavorite = async (data) => {
    try {
      const create_data = {
        song_id: data,
      };

      await Axios.post("/users/add_to_favourite", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            // toast.success(res?.data?.message)
            getMusic();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };
  const [send, setSend] = useState();
  const sendTracks = (data) => {
    handleOpen2();
    setSend(data);
  };
  return (
    <div className="feed trackPage">
      <ToastContainer position="top-right" reverseOrder={true} />
      <Box sx={{ display: "flex" }} className="display-block">
        {/* <Header2 /> */}
        <HeaderNew name={name} setName={setName} title="Track" />

        <Box
          component="main"
          className="feed-bg"
          sx={{
            flexGrow: 1,
            py: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            marginTop: "50px",
          }}
        >
          <Grid container spacing={0}>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="trackpage-bgimg-div">
                <img src={bg} alt="bg" />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={0} className="trackpage-grid">
            <Grid xs={11} sm={12} md={10} lg={11} xl={10}>
              <div className="Trackpage-title-div">
                <Grid container spacing={0} sx={{ justifyContent: "center" }}>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                    <div className="track-avatar">
                      <Avatar
                        alt="Remy Sharp"
                        src={music?.image &&
                          !music?.image?.includes(
                            "localhost"
                          ) ? music?.image : ava1}
                        sx={{ width: 200, height: 200 }}
                      />
                    </div>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                    <div className="track-title-content-div">
                      <div className="Track-genre">Track</div>
                      <div className="Track-title">
                        {music?.song_name}-{music?.ISRC}{" "}
                        {music?.nft_type === "per" ? (
                          <>
                            <VerifiedIcon className="premium" />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div
                        className="track-artist"
                        onClick={(e) => {
                          navigate(`/artistpage/${music?.createUser?._id}`);
                        }}
                      >
                        {/* {artist} */}
                        <span>By</span>
                        {music?.createUser?.name}
                      </div>
                      <div className="play-btn-trackpage">
                        {(audioDurations[music?.audio] !== undefined &&
                          music?.nft_type === "nor") ||
                          music?.isMine === true ? (
                          <> {String(isplay && localStorage.getItem("songName") == music?.song_name) == "false" ? <Button
                            onClick={() => {
                              setAudio(music);
                              createMusic(music?._id);
                              setAudiosource(music?.audio);
                            }}
                          >
                            <PlayArrowIcon />
                            Play
                          </Button> : <></>}</>
                        ) : (
                          <>{audioDurations[music?.audio] !== undefined &&
                            music?.nft_type == "per" ?
                            <Button
                              onClick={() => {
                                setAudios(music);
                                createMusic(music?._id);
                                setAudiosource(music?.pre_audio);
                              }}
                            >
                              <PlayArrowIcon />
                              Play
                            </Button>
                            : <></>} </>
                        )}
                        <span>
                          {music?.players?.length ? music?.players?.length : 0}{" "}
                          <span className="plays">Plays</span>
                        </span>
                      </div>
                      <div className="trackspage-tags">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="11"
                            viewBox="0 0 18 11"
                            className="repeat"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14.6992 0.375C15.1067 0.375 15.4375 0.653048 15.4375 0.99574V5.82692H16.7394C16.9284 5.82692 17.0862 5.96322 17.1191 6.14313L17.125 6.21245C17.125 6.28722 17.1031 6.36043 17.0626 6.42274L14.517 10.317C14.4031 10.4392 14.2883 10.5 14.1719 10.5C14.0554 10.5 13.9407 10.4392 13.8259 10.317L11.2812 6.42274C11.2249 6.3368 11.2052 6.23462 11.226 6.13603C11.2467 6.03745 11.3064 5.94949 11.3934 5.88923C11.455 5.84877 11.5287 5.82699 11.6043 5.82692H12.9062L12.9054 2.71076L6.757 2.71154C6.56969 2.71154 6.39419 2.59938 6.28956 2.41324L5.34456 0.720807C5.32347 0.682709 5.31248 0.640533 5.3125 0.59775C5.3125 0.474692 5.39687 0.375 5.49981 0.375H14.6992ZM2.67578 10.5C2.26825 10.5 1.9375 10.222 1.9375 9.87926L1.93666 5.04808H0.635594C0.542009 5.04535 0.452502 5.01209 0.382983 4.95419C0.313464 4.89629 0.268437 4.81752 0.255906 4.73187L0.25 4.66255C0.25 4.58778 0.271937 4.51457 0.312437 4.45226L2.85803 0.558029C2.97194 0.43575 3.08669 0.375 3.20312 0.375C3.31956 0.375 3.43431 0.43575 3.54906 0.558029L6.09381 4.45226C6.15009 4.5382 6.16978 4.64038 6.14904 4.73897C6.1283 4.83755 6.06863 4.92551 5.98159 4.98577C5.91998 5.02623 5.84627 5.04801 5.77066 5.04808H4.46875V8.16346H10.618C10.8053 8.16346 10.9808 8.27561 11.0854 8.46176L12.0304 10.1542C12.0515 10.1923 12.0625 10.2345 12.0625 10.2773C12.0625 10.4003 11.9781 10.5 11.8752 10.5H2.67578Z"
                            />
                          </svg>
                          <span>
                            {" "}
                            {music?.reposts?.length
                              ? music?.reposts?.length
                              : 0}{" "}
                            Repost
                          </span>
                        </div>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 25 20"
                            fill="var(--clr-font)"
                            className="unfav"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.08938 0.875C3.15975 0.875 0.75 4.25876 0.75 6.75321C0.75 12.1451 7.67475 16.813 12.5625 20C17.4503 16.8116 24.375 12.1465 24.375 6.75321C24.375 4.25876 21.9653 0.875 18.0356 0.875C15.8438 0.875 13.9669 2.42413 12.5625 3.89402C11.1555 2.42413 9.28125 0.875 7.08938 0.875Z"
                            />
                          </svg>
                          <span>
                            {" "}
                            {music?.fav_count ? music?.fav_count : 0} Favourite
                          </span>
                        </div>
                      </div>
                      <div className="follow-us-div">
                        <SendMessages
                          handleModelClose1={handleModelClose1}
                          handleOpen1={handleOpen1}
                          openModel1={openModel1}
                          id={`/trackpage/${music?._id}`}
                        />

                        <Tooltip
                          title={
                            music?.isRepost === true ? "Unrepost" : "Repost"
                          }
                          placement="top"
                        >
                          <div
                            onClick={() => {
                              editSong({ id: music?._id, status: "repost" });
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 18 11"
                              fill="var(--clr-font)"
                              className={
                                music?.isRepost === true ? "repeat" : "Repost"
                              }
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M14.6992 0.375C15.1067 0.375 15.4375 0.653048 15.4375 0.99574V5.82692H16.7394C16.9284 5.82692 17.0862 5.96322 17.1191 6.14313L17.125 6.21245C17.125 6.28722 17.1031 6.36043 17.0626 6.42274L14.517 10.317C14.4031 10.4392 14.2883 10.5 14.1719 10.5C14.0554 10.5 13.9407 10.4392 13.8259 10.317L11.2812 6.42274C11.2249 6.3368 11.2052 6.23462 11.226 6.13603C11.2467 6.03745 11.3064 5.94949 11.3934 5.88923C11.455 5.84877 11.5287 5.82699 11.6043 5.82692H12.9062L12.9054 2.71076L6.757 2.71154C6.56969 2.71154 6.39419 2.59938 6.28956 2.41324L5.34456 0.720807C5.32347 0.682709 5.31248 0.640533 5.3125 0.59775C5.3125 0.474692 5.39687 0.375 5.49981 0.375H14.6992ZM2.67578 10.5C2.26825 10.5 1.9375 10.222 1.9375 9.87926L1.93666 5.04808H0.635594C0.542009 5.04535 0.452502 5.01209 0.382983 4.95419C0.313464 4.89629 0.268437 4.81752 0.255906 4.73187L0.25 4.66255C0.25 4.58778 0.271937 4.51457 0.312437 4.45226L2.85803 0.558029C2.97194 0.43575 3.08669 0.375 3.20312 0.375C3.31956 0.375 3.43431 0.43575 3.54906 0.558029L6.09381 4.45226C6.15009 4.5382 6.16978 4.64038 6.14904 4.73897C6.1283 4.83755 6.06863 4.92551 5.98159 4.98577C5.91998 5.02623 5.84627 5.04801 5.77066 5.04808H4.46875V8.16346H10.618C10.8053 8.16346 10.9808 8.27561 11.0854 8.46176L12.0304 10.1542C12.0515 10.1923 12.0625 10.2345 12.0625 10.2773C12.0625 10.4003 11.9781 10.5 11.8752 10.5H2.67578Z"
                              />
                            </svg>
                          </div>
                        </Tooltip>

                        <Tooltip
                          title={
                            music?.userFav === false
                              ? "Add to Favourite"
                              : "Remove From Favourite"
                          }
                          placement="top"
                        >
                          <div
                            onClick={() => {
                              addFavorite(music?._id);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 25 20"
                              fill="var(--clr-font)"
                              className={
                                music?.userFav === false ? "fav" : "unfav"
                              }
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.08938 0.875C3.15975 0.875 0.75 4.25876 0.75 6.75321C0.75 12.1451 7.67475 16.813 12.5625 20C17.4503 16.8116 24.375 12.1465 24.375 6.75321C24.375 4.25876 21.9653 0.875 18.0356 0.875C15.8438 0.875 13.9669 2.42413 12.5625 3.89402C11.1555 2.42413 9.28125 0.875 7.08938 0.875Z"
                              />
                            </svg>
                            {/* <span>favourite{music?.userFav}</span> */}
                          </div>
                        </Tooltip>
                        {music?.userFound === false && music?.nftclaim > 0 ? (
                          <>
                            <Tooltip title="Add To Library" placement="top">
                              <div
                                onClick={() => {
                                  addLibrary(music?._id);
                                }}
                              >
                                <DownloadIcon />
                              </div>
                            </Tooltip>
                          </>
                        ) : (
                          <></>
                        )}
                        {music?.userFound === true ? (
                          <>
                            <Tooltip
                              title="Remove from Library"
                              placement="top"
                            >
                              <div
                                onClick={() => {
                                  addLibrary(music?._id);
                                }}
                              >
                                <UndoIcon />
                              </div>
                            </Tooltip>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="discription-trackpage">
                      <div>
                        Duration<span>{audioDurations[music?.audio]}</span>
                      </div>
                      <div>
                        Released
                        <span>
                          {new Date(music?.createdAt).toLocaleDateString()}{" "}
                        </span>
                      </div>
                      <div>
                        Genre<span>{music?.categorey}</span>
                      </div>
                      <div>
                        Mood
                        <span>
                          {music?.song_moods ? music?.song_moods : "-"}
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Container maxWidth="md">
            <Grid container spacing={0} sx={{ marginTop: "-250px" }}>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="tab-cnt">
                  <Grid container spacing={2}>
                    {usermusic?.length > 0 ? (
                      <>
                        {usermusic &&
                          usermusic?.map((row, index) => {
                            return (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <div className="feed-music-main-div">
                                    <div
                                      className="feed-music-box"
                                      key={row?._id}
                                    >
                                      <div className="music-detail">
                                        <div className="album-img hover-play-div">
                                          <img
                                            src={row?.image &&
                                              !row?.image?.includes(
                                                "localhost"
                                              ) ? row?.image : ava1}
                                            alt={Album}

                                          />
                                          {row?.nft_type === "per" ?
                                            <Tooltip title={row?.nft_type == "per" && audioDurations1[row?.pre_audio] == undefined ? "Can't Play" : ""} placement="top">

                                              <img
                                                src={nrplay}
                                                style={{
                                                  width: "32px",
                                                  height: "32px",
                                                }}
                                                onMouseOver={() => setAudios(row)}
                                                onMouseOut={() => setisplay(false)}
                                                className="hover-play3"
                                              />
                                            </Tooltip> : <></>}
                                        </div>

                                        <div className="album-detail">
                                          <div className="music-time">
                                            <div className="time-music">
                                              <div className="music-name">
                                                <div
                                                  onClick={(e) => {
                                                    navigate(
                                                      `/trackpage/${row._id}`
                                                    );
                                                  }}
                                                >
                                                  {row?.song_name}-{row?.ISRC}{" "}
                                                  {row?.nft_type === "per" ? (
                                                    <>
                                                      <VerifiedIcon className="premium" />
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              </div>
                                              <div
                                                className="link-badge"
                                                onClick={(e) => {
                                                  navigate(
                                                    `/artistpage/${row.createUser?._id}`
                                                  );
                                                }}
                                              >
                                                <Link to="">
                                                  {row?.createUser?._id}
                                                </Link>
                                                {/* <img src={Mbadge} alt={Mbadge} /> */}
                                              </div>
                                            </div>
                                            <div className="music-timing">
                                              {audioDurations[row?.audio] !==
                                                undefined ? (
                                                <p>
                                                  {audioDurations[row?.audio]}
                                                </p>
                                              ) : (
                                                <p>-</p>
                                              )}
                                            </div>
                                          </div>
                                          <div className="loop-times">
                                            <div className="loop-part">
                                              <div className="company-logo">
                                                {(audioDurations[row?.audio] !==
                                                  undefined &&
                                                  row?.nft_type === "nor") ||
                                                  row?.isMine === true ? (
                                                  <>
                                                    {audioplays[index] === 0 ||
                                                      isplay === false ? (
                                                      <>
                                                        {" "}
                                                        {audioDurations[
                                                          row?.audio
                                                        ] !== undefined &&
                                                          (row?.nft_type ===
                                                            "nor" ||
                                                            row?.isMine ===
                                                            true) ? (
                                                          <img
                                                            onClick={() => {
                                                              setAudio(row);
                                                              createMusic(
                                                                row?._id
                                                              );
                                                              setAudiosource(
                                                                row?.audio
                                                              );
                                                            }}
                                                            src={Companylogo}
                                                            alt={Companylogo}
                                                          />
                                                        ) : (
                                                          <img
                                                            src={Companylogo}
                                                            alt={Companylogo}
                                                          />
                                                        )}{" "}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <img
                                                          src={Companylogo1}
                                                          id="pauseImage"
                                                          onClick={() => {
                                                            setisplay(false);
                                                            setValues(!values);
                                                          }}
                                                          alt={Companylogo}
                                                        />
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    <Tooltip
                                                      title={
                                                        row?.nft_type === "per"
                                                          ? "Premium Song"
                                                          : "Can't play"
                                                      }
                                                      placement="top"
                                                    >
                                                      <img
                                                        src={Companylogo}
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        alt="nr"
                                                      />
                                                    </Tooltip>
                                                  </>
                                                )}{" "}
                                              </div>
                                              <div className="repost-loop">
                                                <p>
                                                  {" "}
                                                  <RepeatIcon />
                                                  {row?.reposts?.length
                                                    ? row?.reposts?.length
                                                    : 0}{" "}
                                                  reposts
                                                </p>
                                              </div>
                                              <div className="repost-loop">
                                                <p>
                                                  {" "}
                                                  <FavoriteIcon className="unfav" />
                                                  {row?.fav_count
                                                    ? row?.fav_count
                                                    : 0}{" "}
                                                  Favorite
                                                </p>
                                              </div>
                                            </div>
                                            <div className="music-timing">
                                              <p>
                                                {row?.players?.length
                                                  ? row?.players?.length
                                                  : 0}{" "}
                                                Plays
                                              </p>
                                            </div>
                                          </div>
                                          <div className="like-dot">
                                            <div className="share-btn">
                                              {row?.isRepost === true ? (
                                                <Tooltip
                                                  title="Unrepost"
                                                  placement="top"
                                                >
                                                  <RepeatIcon
                                                    className="repeat"
                                                    onClick={() => {
                                                      editSong({
                                                        id: row?._id,
                                                        status: "repost",
                                                      });
                                                    }}
                                                  />
                                                </Tooltip>
                                              ) : (
                                                <Tooltip
                                                  title="Repost"
                                                  placement="top"
                                                >
                                                  <RepeatIcon
                                                    onClick={() => {
                                                      editSong({
                                                        id: row?._id,
                                                        status: "repost",
                                                      });
                                                    }}
                                                  />
                                                </Tooltip>
                                              )}

                                              {row?.userFav === true ? (
                                                <Tooltip
                                                  title="Unfavourite"
                                                  placement="top"
                                                >
                                                  <FavoriteIcon
                                                    className="unfav"
                                                    onClick={() => {
                                                      addFavorite(row?._id);
                                                    }}
                                                  />
                                                </Tooltip>
                                              ) : (
                                                <Tooltip
                                                  title="Favourite"
                                                  placement="top"
                                                >
                                                  <FavoriteIcon
                                                    onClick={() => {
                                                      addFavorite(row?._id);
                                                    }}
                                                  />
                                                </Tooltip>
                                              )}

                                              <div
                                                onClick={() => {
                                                  sendTracks(row?._id);
                                                }}
                                              >
                                                <ShortcutIcon />
                                              </div>
                                              <SendTrack
                                                handleModelClose2={
                                                  handleModelClose2
                                                }
                                                openModel2={openModel2}
                                                id={`/trackpage/${send}`}
                                              />

                                              {row?.nftclaim !== 0 &&
                                                row?.userFound === false &&
                                                row?.isMine === false ? (
                                                <DownloadIcon
                                                  onClick={() => {
                                                    addLibrary(row?._id);
                                                  }}
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                            <div className="music-timing">
                                              <MoreHorizIcon
                                                onClick={(e) => {
                                                  handleClick(e);
                                                  setsongid(row?._id);
                                                }}
                                              />
                                              <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                  vertical: "center",
                                                  horizontal: "center",
                                                }}
                                                transformOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "right",
                                                }}
                                                className="playlist-popover"
                                              >
                                                <div
                                                  className="playlist-more-div"
                                                  onClick={() =>
                                                    addPlay(row._id)
                                                  }
                                                >
                                                  Add to Playlist
                                                </div>

                                                <div
                                                  className="playlist-more-div"
                                                  onClick={(e) => {
                                                    navigate(
                                                      `/artistpage/${row.user_id}`
                                                    );
                                                  }}
                                                >
                                                  Visit Artist Page
                                                </div>
                                              </Popover>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </>
                            );
                          })}
                      </>
                    ) : (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="feed-music-box">
                          <div className="music-detail">
                            <div className="album-detail">
                              <div className="no-music">
                                <div className="time-music">
                                  <div className="newno">
                                    <img src={nodata} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </Grid>
            </Grid>

            <Modal
              keepMounted
              open={openModel}
              onClose={handleModelClose}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <Box sx={style2}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    color: "var(--clr-font)",
                  }}
                >
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      borderBottom: "1px solid var(--clr-search-border)",
                      display: "flex",
                    }}
                  >
                    <Grid xs={1} sm={1} md={1} lg={1} xl={1}>
                      <div>
                        <CloseIcon onClick={handleModelClose} />
                      </div>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={11} xl={11}>
                      <div className="edit-playlist-title">
                        {/* <QueueMusicIcon /> */}
                        Add to Playlist
                      </div>
                    </Grid>
                  </Grid>

                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div id="search-wrap2">
                      <InputBase
                        id="search-input"
                        sx={{ ml: 1, flex: 1 }}
                        value={playname ? playname : ""}
                        onChange={(e) => setPlayname(e.target.value)}
                        placeholder="Search your music"
                        inputProps={{ "aria-label": "search google maps" }}
                      />
                      <IconButton
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        id="search-input"
                      >
                        <SearchIcon />
                      </IconButton>
                    </div>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div
                      className="new-playlist"
                      onClick={(e) => {
                        navigate("/createplaylist", { state: { song_id } });
                      }}
                    >
                      <AddCircleIcon />
                      <div>New Playlist</div>
                    </div>
                  </Grid>
                  {play.length > 0 ? (
                    <>
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="grid-height"
                      >
                        {play &&
                          play.map((row, ind) => {
                            return (
                              <Grid
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                key={row?._id}
                                onClick={() => {
                                  handleAdd(row?._id);
                                }}
                              >
                                <div className="new-playlist created">
                                  <div className="d-flex">
                                    {row?.image ? (
                                      <img src={row?.image} alt="bg2" />
                                    ) : (
                                      <img src={Companylogo} alt="bg2" />
                                    )}
                                    {/* <img src={row?.image} alt='img' /> */}
                                  </div>
                                  <div>{row?.playlist}</div>
                                </div>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <div className="newno">
                        <img src={nodata} />
                      </div>
                    </>
                  )}
                </Grid>
              </Box>
            </Modal>
          </Container>
          {localStorage.getItem("playsong") === "true" ? (
            <>
              {audiosources.length > 0 ? (
                <Tracks audiosources={audiosources} tracks={tracks} />
              ) : (
                <></>
              )}{" "}
            </>
          ) : (
            <></>
          )}
          {(localStorage.getItem("playsong") === "false" && audiosource) ===
            true ? (
            <Track
              audiosource={audiosource}
              isplay={isplay}
              setisplay={setisplay}
            />
          ) : (
            <>
              {(localStorage.getItem("playsong") === "false" &&
                audio !== null) === true ? (
                <Track
                  audiosource={audio}
                  isplay={isplay}
                  setisplay={setisplay}
                />
              ) : (
                <></>
              )}{" "}
            </>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default Trackpage;
