import Grid from "@mui/material/Grid";
// import "../Music-player/Player.css";
import "./Contactus.css"
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import Header from "../Header/Header";
import Box from "@mui/material/Box";
import Footer from "../Footer/Footer";

import distribute from "../../img/distribute.svg";
import fanzone from "../../img/fanzone.svg";
import copyright from "../../img/copyright.svg";
import license from "../../img/license.svg";
import appGroup from "../../img/app-group-distribute.png";
import dreamsterMinion from "../../img/dreamster-minion.png";
import nft from "../../img/nft.svg";
import tip1 from "../../img/tip1-img.png";
import tip2 from "../../img/tip2-img.png";
import tip3 from "../../img/tip3-img.png";
import tip4 from "../../img/tip4-img.png";
import subimg from "../../img/fanzone-subimg.png";
import converter from "../../img/converter.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { Link, Navigate, useNavigate } from 'react-router-dom'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    cardbg: {
        borderRadius: "12px !important",
        background: "rgba(255, 255, 255, 0.10)",
        boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
        backdropFilter: "blur(37.5px)",
        padding: "0 !important",

    },

    pwrback: {
        borderRadius: "12px !important",
        border: "1px solid rgba(255, 255, 255, 0.20)",
        background: "#000000 !important",
    },

    learn: {
        borderRadius: "12px !important",
        background: 'transparent !important',
        boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
    },

    containWidth: {
        maxWidth: '1440px',
        margin: 'auto',
        background: 'none !important',
        padding: '0 !important',
        boxShadow: 'none !important',

        "@media (max-width: 1199.98px)": {
            padding: '20px !important',
        },
    }

});


function Contactus() {
    const classes = useStyles();
    return (
        <>
            <ToastContainer
          
        autoClose={3000}
          position="top-right"
           
        />
            <div className="fanzone" sx={{ bgcolor: '#000' }}>
                <div className="playerBanner"></div>

                <Grid container spacing={0} className="music-player-grid">
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.containWidth}>
                            <Header />
                        </Item>
                    </Grid>

                    <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="music-player-banner-grid"
                    >
                        <Item className={classes.containWidth}>
                            <div className="fanzone-body">
                                <h1 className="faq-platform">
                                    We Are Coming, Stay Updated!
                                </h1>

                            </div>
                        </Item>
                    </Grid>





                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Item className={classes.containWidth}>
                            <Footer />
                        </Item>
                    </Grid> */}
                </Grid>
            </div>
        </>
    );
}

export default Contactus;
