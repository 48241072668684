import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Avatar, Grid } from '@mui/material';
import { PlayArrow, Pause, SkipNext, SkipPrevious } from '@mui/icons-material';
import './Musiccontrol.css'
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import Slider from '@mui/material/Slider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useTheme, useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import dwhite from '../../img/dwhite.png'
import ddark from '../../img/d.dark.png'
import { forwardRef, useImperativeHandle, useRef } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import bs2 from "../../img/bs2.png"
import Axios from '../../Axios'
import { ToastContainer, toast } from 'react-toastify';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import SendTrack from "../ShareLink/SendTrack";
import { useNavigate } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const Musiccontrol = forwardRef((props, ref) => {
    // console.log(props, 'props');

    //changes
    const [track, setTrack] = useState(props?.tracks)
    // console.log(track, 'track');

    // console.log(props.audiosource[track]?.audio, 'audio');
    const navigate = useNavigate()

    const [play, setPlay] = useState(false)
    const audioRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0)
    const volumes = localStorage.getItem("volume")
    const [volume, setVolume] = useState(volumes !== null ? volumes : 100);
    const [profileData, setProfileData] = useState({});

    const [isMoblie, setIsMobile] = useState(false)
    // console.log(isMoblie, 'sankar');
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const tokens = localStorage.getItem("iphephile");

    const getArtist = props?.getArtist
    const getTopmusic = props?.getTopmusic
    const getMusic = props?.getMusic
    const getOneSong = props?.getOneSong
    const getUserFav = props?.getUserFav
    const getOneAlbum = props?.getOneAlbum
    const getOneTrack = props?.getOneTrack
    const getplayLists = props?.getplayLists
    const getUserSaved = props?.getUserSaved

    // console.log(currentTime, 'currentTime');
    // console.log(duration, 'duration');
    // console.log(track, 'tracks');
    // console.log(profileData, 'profileData');


    useEffect(() => {
        if (props.tracks !== undefined && props.tracks != track) {
            localStorage.setItem("tracks", props.tracks)
            setTrack(props?.tracks)
        }
    }, [props?.tracks]);

    const avatar = (
        <div className='avatar-music'>
            <Avatar
                // src={localStorage.getItem("Image") !== undefined ? localStorage.getItem("Image") : bs2}
                src={props?.audiosource?.[track]?.image !== undefined ? props?.audiosource?.[track]?.image : bs2}
            // src="https://imgs.search.brave.com/gTGcFA5drHatTMbjYOP3X-CROolnU7u_bxbi4rdlrWg/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9y/ZWxheGVkLWFmcmlj/YW4tZ2lybC13aXRo/LWJpZy1leWVzLXNt/aWxpbmctYXBwZWFs/aW5nLWxhZHktbGlz/dGVuaW5nLW11c2lj/LXdoaXRlXzE5NzUz/MS0xMTI4MS5qcGc_/c2l6ZT02MjYmZXh0/PWpwZw"
            />
        </div>
    )

    const handlePlayPause = () => {
        if (isPlaying) {
            handlePause();
        } else {
            handlePlay();
        }
    };

    // useEffect = (() => {
    //     let mob = localStorage.getItem("isPause")
    //     if (mob === 'false' && isLgUp) {
    //         setIsMobile(!isMoblie)
    //     }
    // }, [isMoblie])

    const handleFarward = () => {
        audioRef.current.currentTime -= 10;
        audioRef.current.play();
    }

    const handlebackward = () => {
        audioRef.current.currentTime += 10;
        audioRef.current.play();
    }
    const audio = localStorage.getItem("props.audiosource")

    const changeTrack = () => {
        if (profileData?.shuffle) {
            const random = Math.floor(Math.random() * (props?.audiosource?.length))
            audioRef.current.currentTime = 0;
            // console.log('current');
            if (track == random) {
                handlePlay()
                setTrack(random)
            }
            else {
                setTrack(random)
            }
            if (props.component === 'topmusic') {
                props?.handlePlay(random)
            }
            else if (props?.component == 'recent') {
                props?.handlePlayPause(random)
            }
            else {
                props?.handlePlay(random)
            }
        }
        else if (Number(track) + 1 === Number(props.audiosource.length)) {
            audioRef.current.currentTime = 0;
            setTrack(0)
            handleStop()
            if (props.component === 'topmusic') {
                props?.handlePlay(0)
            }
            else if (props?.component == 'recent') {
                props?.handlePlayPause(0)
            }
            else {
                props?.handlePlay(0)
            }
        }
        else {
            audioRef.current.currentTime = 0;
            setTrack(track + 1)
            if (props.component === 'topmusic') {
                props?.handlePlay(track + 1)
            }
            else if (props?.component == 'recent') {
                props?.handlePlayPause(track + 1)
            }
            else {
                props?.handlePlay(track + 1)
            }
        }
    }

    const SkipNextSong = () => {
        if (Number(track) + 1 === Number(props.audiosource.length)) {
            setTrack(0)
            audioRef.current.currentTime = 0;
            localStorage.setItem('current', 0)
            if (props.component === 'topmusic') {
                props?.handlePlay(0)
            }
            else if (props?.component == 'recent') {
                props?.handlePlayPause(0)
            }
            else {
                props?.handlePlay(0)
            }
            handleStop()
        }
        else {
            setTrack(track + 1)
            audioRef.current.currentTime = 0;
            localStorage.setItem('current', 0)
            if (props.component === 'topmusic') {
                props?.handlePlay(track + 1)
            }
            else if (props?.component == 'recent') {
                props?.handlePlayPause(track + 1)
            }
            else {
                props?.handlePlay(track + 1)
            }
        }
    }

    const SkipPreviousSong = () => {
        if (track === 0) {
            setTrack(props?.audiosources?.length - 1)
            audioRef.current.currentTime = 0;
            localStorage.setItem('current', 0)
            if (props.component === 'topmusic') {
                props?.handlePlay(props?.audiosources?.length - 1)
            }
            else if (props?.component == 'recent') {
                props?.handlePlayPause(props?.audiosources?.length - 1)
            }
            else {
                props?.handlePlay(props?.audiosources?.length - 1)
            }
        }
        else {
            setTrack(track - 1)
            audioRef.current.currentTime = 0;
            localStorage.setItem('current', 0)
            if (props.component === 'topmusic') {
                props?.handlePlay(track - 1)
            }
            else if (props?.component == 'recent') {
                props?.handlePlayPause(track - 1)
            }
            else {
                props?.handlePlay(track - 1)
            }
        }
    }

    const repeatSong = async (id) => {
        try {
            if (tokens) {
                const res = await Axios.post('/users/repeatsong', { id: id }, {
                    headers: {
                        Authorization: tokens
                    }
                })
                if (res.data.success) {
                    toast.success(res?.data?.message)
                    if (props?.component == 'topmusic') {
                        getMusic();
                        getTopmusic();
                    }
                    else if (props?.component == 'recent') {
                        getMusic();
                    }
                    else if (props?.component == 'albumnew') {
                        getOneSong();
                    }
                    else if (props?.component == 'fav') {
                        getUserFav();
                    }
                    else if (props?.component == 'track') {
                        getOneTrack(props?.audiosource?.[track]?._id);
                    }
                    else if (props?.component == 'album') {
                        getOneAlbum(props?.audiosource?.[track]?.album_id);
                        // getOneTrack(props?.audiosource?.[track]?._id)
                    }
                    else if (props?.component == 'playlist') {
                        getplayLists(props?.audiosource?.[track]?.play_id);
                    }
                    else if (props?.component == 'saved') {
                        getUserSaved();
                    }
                }
                else {
                    toast.success(res?.data?.message)
                    if (props?.component == 'topmusic') {
                        getMusic();
                        getTopmusic();
                    }
                    else if (props?.component == 'recent') {
                        getMusic();
                    }
                    else if (props?.component == 'albumnew') {
                        getOneSong();
                    }
                    else if (props?.component == 'fav') {
                        getUserFav();
                    }
                    else if (props?.component == 'track') {
                        getOneTrack(props?.audiosource?.[track]?._id);
                    }
                    else if (props?.component == 'album') {
                        getOneAlbum(props?.audiosource?.[track]?.album_id);
                        // getOneTrack(props?.audiosource?.[track]?._id)
                    }
                    else if (props?.component == 'playlist') {
                        getplayLists(props?.audiosource?.[track]?.play_id);
                    }
                    else if (props?.component == 'saved') {
                        getUserSaved();
                    }
                }
            }
            else {
                toast.error('Please Login to repeat this song')
                setTimeout(() => {
                    navigate('/loginnew')
                }, 2000)
            }

        } catch (error) {
            console.log(error);

        }
    }

    const shuffleSong = async () => {
        try {
            if (tokens) {
                const res = await Axios.post('/users/shuffleSong', {}, {
                    headers: {
                        Authorization: tokens
                    }
                })
                if (res.data.success) {
                    toast.success(res?.data?.message)
                    getProfile()
                }
                else {
                    toast.success(res?.data?.message)
                } getProfile()
            }
            else {
                toast.error('Please Login to Shuffle')
                setTimeout(() => {
                    navigate('/loginnew')
                }, 2000)
            }

        } catch (error) {
            console.log(error);

        }
    }

    const getProfile = async () => {
        try {
            await Axios.get(`/profile/getprofile`, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        setProfileData(res?.data?.result);
                    } else {
                        // toast.error("Can't Fetch Detail");
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
        } catch (error) {
            // console.log(error);
        }
    };

    // console.log(props?.audiosource, 'props');

    useEffect(() => {
        getProfile()
    }, [])

    const controls = (<>
        {/* Middle: Player controls */}
        <Box className="player-controls" style={{ justifyContent: !isLgUp ? 'space-evenly' : 'center' }}>
            {isLgUp || isMoblie ? <IconButton>
                {isPlaying ? <FastRewindIcon onClick={handleFarward} /> : <FastRewindIcon disabled />}
            </IconButton> : null}
            {<IconButton>
                {isNaN(track) || track == 0 ? <SkipPrevious disabled /> : <SkipPrevious onClick={() => { SkipPreviousSong() }} />}
            </IconButton>}
            <IconButton className='pasue-play'>
                {!isPlaying ? <PlayArrow onClick={() => { handlePlayPause() }} /> : <Pause onClick={() => { handlePlayPause() }}
                // onClick={() => setPlay(!play)}
                />}
            </IconButton>
            <IconButton>
                {isNaN(track) ? <SkipNext disabled /> : <SkipNext onClick={() => { SkipNextSong() }} />}
            </IconButton>
            {isLgUp || isMoblie ? <IconButton>
                {isPlaying ? <FastForwardIcon onClick={handlebackward} /> : <FastForwardIcon disabled />}
            </IconButton> : null}
        </Box>
    </>)



    const handlePlay = () => {

        if (audioRef.current.src !== null && audioRef.current.src !== undefined) {

            const audio = audioRef?.current?.play();

            if (audio !== undefined) {
                audio.then(() => {
                    // Autoplay started
                    localStorage.setItem("isPause", false)

                    if (props.component === 'topmusic') {
                        props?.setisplay(true)
                        setIsPlaying(true);
                    }
                    else if (props.component === 'recent') {
                        props?.setisplay(true)
                        setIsPlaying(true);
                    }
                    else {
                        props?.setisplay(true)
                        setIsPlaying(true);
                    }


                }).catch(error => {
                    // Autoplay was prevented.
                    // handlePlay()
                    console.log(error, 'play error');
                    // video.muted = true;
                    // video.play();
                });
            }

        }


    };

    useEffect(() => {
        const isPause = localStorage.getItem("isPause")
        // console.log(props?.audiosource?.[track]?.audio, 'props?.audiosource?.[track]?.audio');

        // if (props.audiosource !== undefined) {
        if (props?.audiosource?.length > 0 && props?.audiosource?.[track]?.audio != undefined) {
            const current = localStorage.getItem("current")
            if (isPause && isPause === "false") {
                if (current !== undefined && current !== null && current !== 0) {
                    audioRef.current.currentTime = Number(current)
                    console.log('handleplay')
                    handlePlay()
                }
                else {
                    audioRef.current.currentTime = currentTime
                    handlePlay()
                }
            }
            else {
                if (current !== undefined && current !== null && current !== 0) {
                    audioRef.current.currentTime = Number(current)

                }
                else {
                    audioRef.current.currentTime = currentTime
                }
                handlePause()

            }

        }
        else {
            handleStop()
        }
    }, [track])



    useEffect(() => {
        const isPause = localStorage.getItem("isPause")
        if (props?.isplay === false || isPause === "true") {
            handlePause();
        } else {
            handlePlay();
        }

    }, [props?.isplay])


    const handlePause = () => {

        if (props.component === 'topmusic') {
            localStorage.setItem("props.audiosource", audioRef.current.src)
            localStorage.setItem("current", audioRef.current.currentTime)

            localStorage.setItem("isPause", true)

            audioRef.current.pause();
            props?.setisplay(false)
            setIsPlaying(false);
        }
        else if (props.component === 'recent') {
            localStorage.setItem("props.audiosource", audioRef.current.src)
            localStorage.setItem("current", audioRef.current.currentTime)

            localStorage.setItem("isPause", true)

            audioRef.current.pause();
            props?.setisplay(false)
            setIsPlaying(false);

        }
        else {
            localStorage.setItem("props.audiosource", audioRef.current.src)
            localStorage.setItem("current", audioRef.current.currentTime)

            localStorage.setItem("isPause", true)

            audioRef.current.pause();
            props?.setisplay(false)
            setIsPlaying(false);
        }

        // props?.setisplay(false)
    };

    const handleStop = () => {
        localStorage.setItem("props.audiosource", audioRef.current.src)
        localStorage.setItem("current", audioRef.current.currentTime)
        audioRef.current.pause();
        setIsPlaying(false);
    };

    const handleTimeUpdate = () => {

        if (audioRef.current === null) {
            setCurrentTime(0)
            setDuration(0)
        }
        else if (audioRef.current.currentTime === audioRef.current.duration) {
            // handlePause()
            // console.log(props?.audiosource?.[track], 'repeat');
            getProfile()
            if (props?.audiosource?.[track]?.repeat) {
                // console.log(track, 'track');
                setTrack(track)
                audioRef.current.currentTime = 0
                localStorage.setItem('current', 0)
                handlePlay()
            }
            else {
                audioRef.current.currentTime = 0
                localStorage.setItem('current', 0)
                changeTrack()
            }
            // localStorage.setItem("current", 0)
        }
        else {
            localStorage.setItem("current", audioRef.current.currentTime)
            setCurrentTime(audioRef.current.currentTime);
            setDuration(audioRef.current.duration);

        }

    };
    const handleSeek = (e) => {

        if (audioRef.current === null) {
            setCurrentTime(0)
        }
        else {

            if (isNaN(e.target.value)) {

                audioRef.current.currentTime = 0;
                setCurrentTime(0);

            }
            else {
                audioRef.current.currentTime = e.target.value;
                setCurrentTime(e.target.value);
            }

        }
    };

    function formatDuration(durationSeconds) {
        const minutes = Math.floor(durationSeconds / 60);
        const seconds = Math.floor(durationSeconds % 60);
        const formattedSeconds = seconds.toString().padStart(2, "0");
        return `${minutes}:${formattedSeconds}`;
    }

    const slider = (
        <>
            {isLgUp || isMoblie ? <Box sx={{ width: !isLgUp ? "85%" : '50%', margin: 'auto', display: 'flex', alignItems: 'center', gap: "20px" }}>
                <Typography variant="body2" color="white">
                    {formatDuration(currentTime)}
                </Typography>
                <Slider
                    aria-label="time-indicator"
                    size="small"
                    value={currentTime}
                    min={0}
                    step={1}
                    max={duration}
                    onChange={handleSeek}
                    className='control-timer'

                />
                <Typography variant="body2" color="white">
                    {currentTime === 0 ? '0:00' : formatDuration(duration)}
                </Typography>

                {/* <audio ref={audioRef} src={audiosource} />
                            <div className='audio-timmer'>
                                <div>
                                    {formatDuration(currentTime)}

                                </div>
                                <div>
                                    {currentTime === 0 ? '0:00' : formatDuration(duration)}

                                </div>
                            </div> */}
                {/* <audio ref={audioRef} src={props.audiosource} /> */}


            </Box> : null
            }
        </>
    )

    useEffect(() => {
        audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
        // console.log(props?.audiosource?.[track], 'useEffect');
        return () => {
            if (audioRef?.current !== null) {
                // handlePlay()
                audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
            }
            // audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        };
    }, [track, props?.audiosource?.[track]?.repeat, profileData?.shuffle]);

    useEffect(() => {
        if (audioRef) {
            audioRef.current.volume = volume / 100;
        }
    }, [volume, audioRef]);


    // to repost/unrepost Songs
    const editSong = async (data) => {
        // console.log(data, 'data');
        try {
            if (tokens) {
                const create_data = {
                    songId: data.id,
                    status: data.status,
                };

                await Axios.post("/users/edit_song_details", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            toast.success(res?.data?.message)
                        } else {
                            toast.error(res?.data?.message);
                        }
                    })
                    .catch((err) => {
                        // console.log(err, "err")
                        toast.error(err?.response?.data?.message);
                    });
            }
            else {
                toast.error("Please Login to Repost this Song")
            }
        } catch (error) {
            console.log(error, "error");
        }
    };

    // console.log(props?.component, 'component');

    const addFavorite = async (data) => {

        try {
            if (tokens) {
                const create_data = {
                    song_id: data,
                };

                await Axios.post("/users/add_to_favourite", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            // props?.setIsAlive ? props?.setIsAlive(false) : ''
                            if (props?.setIsAlive) {
                                props?.setIsAlive(false)
                            }
                            toast.success(res?.data?.message);
                            if (props?.component == 'topmusic') {
                                getMusic();
                                getTopmusic();
                            }
                            else if (props?.component == 'recent') {
                                getMusic();
                            }
                            else if (props?.component == 'albumnew') {
                                getOneSong();
                            }
                            else if (props?.component == 'fav') {
                                if (res?.data?.message == 'Song is Removed from your Favourite') {
                                    localStorage.setItem('playsong', true)
                                }
                                getUserFav();
                            }
                            else if (props?.component == 'track') {
                                getOneTrack(props?.audiosource?.[track]?._id);
                            }
                            else if (props?.component == 'album') {
                                getOneAlbum(props?.audiosource?.[track]?.album_id);
                                // getOneTrack(props?.audiosource?.[track]?._id)
                            }
                            else if (props?.component == 'playlist') {
                                getplayLists(props?.audiosource?.[track]?.play_id);
                            }
                            else if (props?.component == 'saved') {
                                getUserSaved();
                            }
                            else if (props?.component == 'recent') {
                                getOneSong();
                            }
                            else if (props?.component == 'artist') {
                                getArtist();
                            }
                        } else {
                            toast.error(res?.data?.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err, "err")
                        toast.error(err?.response?.data?.message);
                    });
            }
            else {
                toast.error("Please Login to add this Song to your Favourite")
                setTimeout(() => {
                    navigate('/loginnew')
                }, 2000)
            }
        } catch (error) {
            // console.log(error, "error");
        }
    };

    const [vol, setVol] = useState()
    const handleVol = (e) => {
        setVol(e.target.value)
        // console.log('log', vol);
    }

    const quicker = (

        <>
            {isLgUp && <div className="display-1">
                {vol === 0 ? <VolumeOffIcon /> : vol <= 50 ? <VolumeDownIcon /> : <VolumeUpIcon />}
                <Slider
                    size="small"
                    defaultValue={100}
                    min={0}
                    max={100}
                    value={volume}
                    // onChange={handleVol}
                    onChange={(e) => { setVolume(e.target.value); localStorage.setItem("volume", e.target.value) }}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    sx={{ width: '100px' }}
                    className='control-timer'
                />

            </div>}
            {isLgUp || isMoblie ? <Stack direction="row" spacing={4} style={{ justifyContent: !isLgUp ? 'space-evenly' : 'center' }}>
                <div
                    onClick={() => {
                        shuffleSong()
                    }}>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.7207 1L18.38 3.65934L15.7207 6.31868" stroke={profileData?.shuffle ? '#1db954' : "#7A7A7A"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M11.7324 3.65918H18.3808" stroke={profileData?.shuffle ? '#1db954' : "#7A7A7A"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.7207 8.97852L18.38 11.6379L15.7207 14.2972" stroke={profileData?.shuffle ? '#1db954' : "#7A7A7A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M1.09473 3.65918H5.74857L11.0673 11.6372H18.3804" stroke={profileData.shuffle ? '#1db954' : "#7A7A7A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M1.09473 11.6377H5.74857" stroke={profileData?.shuffle ? '#1db954' : "#7A7A7A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </div>
                {
                    <div
                        onClick={() => {
                            repeatSong(props?.audiosource?.[track]?._id)
                            // editSong({
                            //     id: props?.data,
                            //     status: 'repost'
                            // })
                        }}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.53835 17L3.46143 13.9231L6.53835 10.8462" stroke={props?.audiosource?.[track]?.repeat ? '#1db954' : "#7A7A7A"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16.9999 10.2296V12.6911C16.9999 13.0175 16.8702 13.3306 16.6394 13.5614C16.4086 13.7922 16.0955 13.9219 15.7691 13.9219L3.46143 13.9219" stroke={props?.audiosource?.[track]?.repeat ? '#1db954' : "#7A7A7A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M11.4616 1.0024L14.5385 4.07933L11.4616 7.15625" stroke={props?.audiosource?.[track]?.repeat ? '#1db954' : "#7A7A7A"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M1 7.76562L1 5.30409C1 4.97767 1.12967 4.66462 1.36048 4.4338C1.5913 4.20299 1.90435 4.07332 2.23077 4.07332L14.5385 4.07332" stroke={props?.audiosource?.[track]?.repeat ? '#1db954' : "#7A7A7A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                }

                {/* <div>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.53835 17L3.46143 13.9231L6.53835 10.8462" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M16.9999 10.2296V12.6911C16.9999 13.0175 16.8702 13.3306 16.6394 13.5614C16.4086 13.7922 16.0955 13.9219 15.7691 13.9219L3.46143 13.9219" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M11.4616 1.0024L14.5385 4.07933L11.4616 7.15625" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M1 7.76562L1 5.30409C1 4.97767 1.12967 4.66462 1.36048 4.4338C1.5913 4.20299 1.90435 4.07332 2.23077 4.07332L14.5385 4.07332" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>


                </div> */}
                <div onClick={() => { addFavorite(props?.audiosource?.[track]?._id) }} >
                    <svg width="21" height="18" viewBox="0 0 21 18" fill={props?.audiosource?.[track]?.fav ? "#ed2c3f" : "#7A7A7A"} xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.6007 17L2.53766 9.63024C-1.84443 5.20839 4.59724 -3.28158 10.6007 3.58704C16.6042 -3.28158 23.0166 5.23786 18.6637 9.63024L10.6007 17Z" stroke={props?.audiosource?.[track]?.fav ? "#ed2c3f" : "#7A7A7A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </div>
                <div onClick={() => { sendTracks(props?.audiosource?.[track]?._id) }}>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.34801 13.6521C6.14474 13.6521 7.60129 12.1955 7.60129 10.3988C7.60129 8.60205 6.14474 7.14551 4.34801 7.14551C2.55127 7.14551 1.09473 8.60205 1.09473 10.3988C1.09473 12.1955 2.55127 13.6521 4.34801 13.6521Z" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M16.638 19.7966C18.4348 19.7966 19.8913 18.3401 19.8913 16.5433C19.8913 14.7466 18.4348 13.29 16.638 13.29C14.8413 13.29 13.3848 14.7466 13.3848 16.5433C13.3848 18.3401 14.8413 19.7966 16.638 19.7966Z" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M16.638 7.50656C18.4348 7.50656 19.8913 6.05002 19.8913 4.25328C19.8913 2.45654 18.4348 1 16.638 1C14.8413 1 13.3848 2.45654 13.3848 4.25328C13.3848 6.05002 14.8413 7.50656 16.638 7.50656Z" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.25391 8.9525L13.7315 5.69922" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.25391 11.8447L13.7315 15.098" stroke="#7A7A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </div>
            </Stack> : null}
        </>
    )


    const [state, setState] = useState(false);



    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);


        setIsMobile(!isMoblie);
    };

    useImperativeHandle(ref, () => ({
        toggleDrawer,
    }));

    const songname = localStorage.getItem('songName')
    const list = () => (
        <Box
            sx={{ width: 'auto' }}
            role="presentation"
            className='mobile-box'
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className='text-right'>
                <KeyboardArrowDownIcon onClick={toggleDrawer(false)} />
            </div>
            <div className='text-center'>
                <div className="header-logo">
                    <img src={localStorage.getItem("theme") === 'true' ? ddark : dwhite} alt="logo" />
                </div>
            </div>
            <div className='mobile-avatar'>
                {avatar}
            </div>
            <Box sx={{ my: 4 }} className='marquee-container'>
                <Typography variant="subtitle1" color="white" className='subtitle1 text-center'>
                    {/* {songname?.length > 15 ? `${songname?.slice(0, 15)}...` : songname} */}
                    {props?.audiosource?.[track]?.song_name?.length > 15 ? `${props?.audiosource?.[track]?.song_name?.slice(0, 15)}...` : props?.audiosource?.[track]?.song_name}
                    {/* {localStorage.getItem("songName") !== undefined ? localStorage.getItem("songName") : "Song Name"} */}
                </Typography>
                <Typography variant="subtitle2" color="secondary" className='subtitle2 text-center'>
                    {/* {localStorage.getItem("name") !== undefined ? localStorage.getItem("name") : "Maroon 5"} */}
                    {props?.audiosource?.[track]?.name !== undefined ? props?.audiosource?.[track]?.name : "Maroon 5"}
                </Typography>
            </Box>
            <div>
                {slider}

            </div>
            <div>
                {controls}
            </div>
            <Box sx={{ my: 1 }}>
                {quicker}
            </Box>
            <Box sx={{ my: 1, textAlign: 'center' }}>
                <div className="display-1" style={{ justifyContent: 'center' }}>
                    {vol === 0 ? <VolumeOffIcon /> : vol <= 50 ? <VolumeDownIcon /> : <VolumeUpIcon />}
                    <Slider
                        size="small"
                        defaultValue={70}
                        onChange={handleVol}
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        sx={{ width: '75%' }}
                        className='control-timer'
                    />

                </div>
            </Box>


        </Box>
    );

    //share songs
    const [openModel2, setOpenModel2] = React.useState(false);
    const handleOpen2 = () => setOpenModel2(true);
    const handleModelClose2 = () => setOpenModel2(false);

    const [send, setSend] = useState();
    const sendTracks = (data) => {
        // handleClose();
        if (tokens) {
            handleOpen2();
            setSend(data);
        }
        else {
            toast.error("Please Login to Share Songs")
            setTimeout(() => {
                navigate('/loginnew')
            }, 2000)
        }
    };


    return (
        <>
            <Grid container spacing={0} sx={{ justifyContent: 'center', position: !isLgUp && 'sticky', bottom: !isLgUp && '0', zIndex: !isLgUp && '2000' }}>
                <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                // sx={{ padding: "15px" }}
                >


                    <div>

                        <React.Fragment >
                            {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}

                            {!isMoblie && <Box className="fixed-bottom-player" style={{ position: !isLgUp && 'relative' }}>

                                <div className='inner-fixed' style={{ maxWidth: isLgUp ? '80%' : '100%' }} onClick={!isLgUp ? toggleDrawer(true) : null}>
                                    {/* Left: Track details */}
                                    <Box className="player-content" >
                                        <>
                                            {avatar}
                                        </>

                                        <Box sx={{ ml: 2 }} className={isLgUp ? "marquee-container" : null}>
                                            <div className={`subtitle1 ${isLgUp ? 'marquee-ply' : null}`}>
                                                {/* {localStorage.getItem("songName") !== undefined ? localStorage.getItem("songName") : "Song Name"} */}
                                                {/* {songname?.length > 15 ? `${songname?.slice(0, 15)}...` : songname} */}
                                                {props?.audiosource?.[track]?.song_name?.length > 15 ? `${props?.audiosource?.[track]?.song_name?.slice(0, 15)}...` : props?.audiosource?.[track]?.song_name}
                                            </div>
                                            <div className='subtitle2'>
                                                {/* {localStorage.getItem("name") !== undefined ? localStorage.getItem("name") : "Maroon 5"} */}
                                                {props?.audiosource?.[track]?.name !== undefined ? props?.audiosource?.[track]?.name : "Maroon 5"}
                                            </div>
                                        </Box>
                                    </Box>

                                    {controls}
                                    {slider}
                                    {quicker}
                                </div>
                            </Box>
                            }
                            <Drawer
                                anchor="bottom"
                                open={state}
                                onClose={toggleDrawer(true)}
                                PaperProps={{
                                    sx: {
                                        height: "100vh" // Full view height
                                    }
                                }}
                                className="sidebar"
                            >
                                {list()}

                            </Drawer>
                        </React.Fragment>

                    </div>



                </Grid >
            </Grid >
            <audio ref={audioRef}
                // src={props.audiosource}
                src={props?.audiosource?.[track]?.audio}
            />
            <SendTrack
                handleModelClose2={handleModelClose2}
                handleOpen2={handleOpen2}
                openModel2={openModel2}
                id={`/albumnew/${send}`}
            />
        </>

    );
});

export default Musiccontrol;
