import React, { useEffect, useRef, useState } from "react";
import Axios from "../../Axios";
import Header2 from "../Header/Header2";
import Box from "@mui/material/Box";
import ava1 from "../../img/ava1.webp";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import Grid from "@mui/material/Grid";
import Track from "../NewMusicTrack/NewMusicTrack";
import Album from "../../img/music-logo.png";
import { Link } from "react-router-dom";
import Mbadge from "../../img/badge.svg";
import Companylogo1 from "../../img/musicwave.gif";
import Companylogo from "../../img/nr-play.png";
import RepeatIcon from "@mui/icons-material/Repeat";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import "./History.css";
import VerifiedIcon from "@mui/icons-material/Verified";
import SendTrack from "../ShareLink/SendTrack";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import cart from "../../img/cart.png";
import HeaderNew from "../HeaderNew/HeaderNew";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Tracks from "../Music-traxk/Tracks";
import { useSelector } from "react-redux";
import UndoIcon from "@mui/icons-material/Undo";
import newno from "../../img/newnodata.gif";
import nrplay from "../../img/nrplay.png";

const drawerWidth = 280;

function History() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [values, setValues] = React.useState(true);
  const [music, setMusic] = React.useState([]);
  const [audiosource, setAudiosource] = React.useState();
  const [count, setCount] = React.useState();
  const [isplay, setisplay] = useState(0);
  const tokens = localStorage.getItem("iphephile");
  const [name, setName] = React.useState();
  const [openModel2, setOpenModel2] = React.useState(false);
  const handleOpen2 = () => setOpenModel2(true);
  const handleModelClose2 = () => setOpenModel2(false);
  const navigate = useNavigate();
  const [audioDurations, setAudioDurations] = useState([]);
  const [audioDurations1, setAudioDurations1] = useState([]);
  const [tracks, setTracks] = useState(0);
  const songlist = useSelector((state) => state.songs);
  const play_mood = useSelector((state) => state.play_mood);
  const [audiosources, setAudiosources] = React.useState(songlist);
  const index = useSelector((state) => state.index);
  const [audioplays, setAudioplays] = useState([]);

  const getAudioDuration = async (audioSrc) => {
    try {
      const audio = new Audio(audioSrc);
      await audio.addEventListener("loadedmetadata", () => {
        const duration = audio.duration;
        const durationInSeconds = audio.duration;
        const durationInMinutes = (durationInSeconds / 60).toFixed(2);
        setAudioDurations((prev) => ({
          ...prev,
          [audioSrc]: durationInMinutes,
        }));
      });
    } catch (error) {
      console.error(error);
      setAudioDurations((prev) => ({
        ...prev,
        [audioSrc]: 0, // Default duration or handle error accordingly
      }));
    }
  };
  const getPreAudioDuration = async (audioSrc) => {
    try {
      const audio = new Audio(audioSrc);
      await audio.addEventListener("loadedmetadata", () => {
        const duration = audio.duration;
        const durationInSeconds = audio.duration;
        const durationInMinutes = (durationInSeconds / 60).toFixed(2);
        setAudioDurations1((prev) => ({
          ...prev,
          [audioSrc]: durationInMinutes,
        }));
      });
    } catch (error) {
      console.error(error);
      setAudioDurations1((prev) => ({
        ...prev,
        [audioSrc]: 0, // Default duration or handle error accordingly
      }));
    }
  };

  const getaudioPlays = async (audioSrc, index) => {
    try {
      if (audioSrc === localStorage.getItem("songName")) {
        setAudioplays((prev) => ({
          ...prev,
          [index]: 1,
        }));
        setisplay(true);
      } else {
        setAudioplays((prev) => ({
          ...prev,
          [index]: 0,
        }));
      }
    } catch (error) {
      setAudioplays((prev) => ({
        ...prev,
        [index]: 0,
      }));
    }
  };

  useEffect(() => {
    if (music) {
      music.forEach((row) => {
        getAudioDuration(row?.music_details.audio);
        getPreAudioDuration(row?.music_details.pre_audio);

      });
    }

    if (music) {
      music.forEach((row, index) => {
        getaudioPlays(row?.music_details.song_name, index);
      });
    }
  }, [music]);

  useEffect(() => {
    if (music) {
      music.forEach((row, index) => {
        getaudioPlays(row?.music_details.song_name, index);
      });
    }
  }, [values]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {

    setTracks(
      index !== undefined ? index : Number(localStorage.getItem("tracks"))
    );
  }, []);
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current == false) {
      getMusic();
      isMounted.current = true;
    }

  }, [name]);
  // to get history of songs
  const getMusic = async () => {
    try {
      const datas = await Axios.post(
        "/users/get_music_history",
        { name: name },
        {
          headers: {
            Authorization: tokens,
          },
        }
      )
        .then((res) => {
          if (res?.data?.success) {
            setMusic(res?.data?.result);
            setCount(res?.data?.result.length);
          } else {
            //toast.error(err?.response?.data?.message)
            setMusic([]);
          }
        })
        .catch((err) => {
          // console.log(err,"err")
          if(err?.response?.data?.message == "Admin Blocked You"){
            localStorage.clear();
            navigate('/');
            toast.error('Admin Blocked You');
          }
        });
    } catch (error) {
      // console.log(error,"error");
    }
  };
  // to add song to history
  const createMusic = async (data) => {
    try {
      const create_data = {
        song_id: data,
      };

      await Axios.post("/users/add_history", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            //   toast.success(res?.data?.message)

          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };
  const audio = localStorage.getItem("audiosource");
  // to get song detail which is currently playing
  const setAudio = (data) => {
    setValues(!values);
    localStorage.setItem("audiosource", data.audio);
    localStorage.setItem("songName", data.song_name);
    localStorage.setItem("Image", data.image);
    localStorage.setItem("current", 0);
    localStorage.setItem("isPause", false);
    setAudiosource(data.audio);
    localStorage.setItem("tracks", 0);
    localStorage.setItem("playsong", "false");
    setisplay(true);
  };
  const setAudios = (data) => {
    if (audioDurations1[data?.pre_audio] !== undefined) {
      localStorage.setItem("audiosource", data.pre_audio);
      localStorage.setItem("songName", data.song_name);
      localStorage.setItem("Image", data.image);
      localStorage.setItem("current", 0);
      localStorage.setItem("tracks", 0);
      localStorage.setItem("isPause", false);
      setAudiosource(data.pre_audio);
      localStorage.setItem("playsong", "false");
      setValues(!values);
      setisplay(true);
    }
  };
  // to add song to library
  const addLibrary = async (data) => {
    try {
      const create_data = {
        song_id: data,
      };

      await Axios.post("/users/add_library", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            getMusic();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };
  // to favourite/unfavorite a song
  const addFavorite = async (data) => {
    try {
      const create_data = {
        song_id: data,
      };

      await Axios.post("/users/add_to_favourite", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            // toast.success(res?.data?.message)
            getMusic();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };
  // to repost/unrepost Songs
  const editSong = async (data) => {
    try {
      const create_data = {
        songId: data.id,
        status: data.status,
      };

      await Axios.post("/users/edit_song_details", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            //toast.success(res?.data?.message)
            getMusic();
            // console.log(res?.data?.message)
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      console.log(error, "error");
    }
  };
  const [send, setSend] = useState();
  const sendTracks = (data) => {
    handleOpen2();
    setSend(data);
  };

  const [title, setTitle] = useState("history");

  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowMessage(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [showMessage]);

  return (
    <div className="History">
      <ToastContainer autoClose={3000} position="top-right" />
      <Box sx={{ display: "flex" }} className="display-block">
        <HeaderNew name={name} setName={setName} title={title} />

        <Box
          component="main"
          className="History-box"
          sx={{
            flexGrow: 1,
            py: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            marginTop: "80px",
          }}
        >
          <Grid container spacing={0} className="heading-container">
            <Container maxWidth="lg">
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
            </Container>
          </Grid>

          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3 }}
            className="History-box2"
          >
            {/* <div className="feed-new-whole">
              <div></div>
              <div className="feed-new-search">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search playlist, songs, albums..."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div> */}
            <Container maxWidth="md">
              <Grid container spacing={2}>

                {music.length > 0 ? (
                  <>
                    {music &&
                      music.map((row, index) => {
                        return (
                          <>
                            {showMessage ? (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <div className="feed-music-main-div">
                                  <Stack
                                    spacing={1}
                                    className="lib-stack"
                                    direction="row"
                                  >
                                    <Stack
                                      spacing={1}
                                      direction="row"
                                      className="lib2"
                                    >
                                      <Skeleton
                                        variant="rectangular"
                                        width={105}
                                        height={125}
                                        sx={{ bgcolor: "var(--clr-skel)" }}
                                      />
                                      <Stack spacing={2} className="lib2">
                                        <Skeleton
                                          variant="rounded"
                                          sx={{
                                            bgcolor: "var(--clr-skel)",
                                            width: "180px",
                                            height: "10px",
                                            "@media (max-width: 575.98px)": {
                                              width: "120px",
                                            },
                                          }}
                                        />
                                        <Skeleton
                                          variant="rounded"
                                          sx={{
                                            bgcolor: "var(--clr-skel)",
                                            width: "150px",
                                            height: "10px",
                                            "@media (max-width: 575.98px)": {
                                              width: "100px",
                                            },
                                          }}
                                        />
                                        <Skeleton
                                          variant="rounded"
                                          sx={{
                                            bgcolor: "var(--clr-skel)",
                                            width: "130px",
                                            height: "10px",
                                            "@media (max-width: 575.98px)": {
                                              width: "80px",
                                            },
                                          }}
                                        />
                                      </Stack>
                                    </Stack>
                                    <Stack spacing={2}>
                                      <Skeleton
                                        variant="rounded"
                                        width={30}
                                        height={5}
                                        sx={{
                                          bgcolor: "var(--clr-skel)",
                                          "@media (max-width: 575.98px)": {
                                            display: "none",
                                          },
                                        }}
                                      />
                                      <Skeleton
                                        variant="rounded"
                                        width={20}
                                        height={5}
                                        sx={{
                                          bgcolor: "var(--clr-skel)",
                                          "@media (max-width: 575.98px)": {
                                            display: "none",
                                          },
                                        }}
                                      />
                                      <Skeleton
                                        variant="rounded"
                                        width={15}
                                        height={5}
                                        sx={{
                                          bgcolor: "var(--clr-skel)",
                                          "@media (max-width: 575.98px)": {
                                            display: "none",
                                          },
                                        }}
                                      />
                                    </Stack>
                                  </Stack>
                                </div>
                              </Grid>
                            ) : (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <div className="feed-music-box" key={row?._id}>
                                  <div className="music-detail">
                                    <div className="album-img hover-play-div">
                                      <img
                                        src={row?.music_details?.image && !row?.music_details?.image.includes('localhost') ? row.music_details.image : ava1}
                                        alt={Album}

                                      />
                                      {row?.music_details?.nft_type === "per" ?
                                        <Tooltip title={row?.music_details?.nft_type == "per" && audioDurations1[row?.music_details?.pre_audio] == undefined ? "Can't Play" : ""} placement="top">
                                          <img
                                            src={nrplay}
                                            style={{
                                              width: "32px",
                                              height: "32px",
                                            }}
                                            className="hover-play3"
                                            onMouseOver={() =>
                                              setAudios(row?.music_details)
                                            }
                                            onMouseOut={() => setisplay(false)}
                                          />
                                        </Tooltip> : <></>}
                                    </div>

                                    <div className="album-detail">
                                      <div className="music-time">
                                        <div className="time-music">
                                          <div
                                            className="music-name"
                                            onClick={(e) => {
                                              navigate(
                                                `/trackpage/${row?.music_details?._id}`
                                              );
                                            }}
                                          >
                                            <Link to="">
                                              {row?.music_details?.song_name}-
                                              {row?.music_details?.ISRC}
                                            </Link>{" "}
                                            {row?.music_details?.nft_type ===
                                              "per" ? (
                                              <>
                                                <VerifiedIcon className="premium" />
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                        <div className="music-timing">
                                          <p>
                                            {audioDurations[
                                              row?.music_details?.audio
                                            ] !== undefined
                                              ? audioDurations[
                                              row?.music_details?.audio
                                              ]
                                              : "-"}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="loop-times">
                                        <div className="loop-part">
                                          <div className="company-logo">
                                            {(audioDurations[
                                              row?.music_details?.audio
                                            ] !== undefined &&
                                              row?.music_details?.nft_type ===
                                              "nor") ||
                                              row?.music_details?.isMine ===
                                              true ? (
                                              <>
                                                {audioplays[index] === 0 ||
                                                  isplay === false ? (
                                                  <Tooltip
                                                    title="Play Song"
                                                    placement="top"
                                                  >
                                                    <img
                                                      onClick={() => {
                                                        setAudio(row);
                                                        createMusic(row?.music_details?._id);
                                                        setAudiosource(
                                                          row?.music_details
                                                            ?.audio
                                                        );
                                                      }}
                                                      src={Companylogo}
                                                      alt={Companylogo}
                                                    />
                                                  </Tooltip>
                                                ) : (
                                                  <img
                                                    src={Companylogo1}
                                                    id="pauseImage"
                                                    onClick={() => {
                                                      setisplay(false);
                                                    }}
                                                    alt={Companylogo}
                                                  />
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {row?.music_details
                                                  ?.nft_type === "per" &&
                                                  audioDurations[
                                                  row?.music_details?.audio
                                                  ] !== undefined ? (
                                                  <Tooltip
                                                    title="Premium Song"
                                                    placement="top"
                                                  >
                                                    {(audioplays[index] == 0 ||
                                                      isplay == false) ==
                                                      true ? (
                                                      <img
                                                        src={Companylogo}
                                                        onClick={() => {
                                                          setAudios(
                                                            row.music_details
                                                          );
                                                          createMusic(
                                                            row?.music_details
                                                              ?._id
                                                          );
                                                          setAudiosource(
                                                            row?.music_details
                                                              ?.pre_audio
                                                          );
                                                        }}
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        alt="nr"
                                                      />
                                                    ) : (
                                                      <img
                                                        src={Companylogo1}
                                                        id="pauseImage"
                                                        onClick={() => {
                                                          setisplay(false);
                                                        }}
                                                        alt={Companylogo}
                                                      />
                                                    )}
                                                  </Tooltip>
                                                ) : (
                                                  <Tooltip
                                                    title="Can't Play"
                                                    placement="top"
                                                  >
                                                    <img
                                                      src={Companylogo}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      alt="nr"
                                                    />
                                                  </Tooltip>
                                                )}
                                              </>
                                            )}
                                            {/* {audioplays[index] === 0 || isplay === false ? <>{audioDurations[row?.music_details?.audio] !== undefined ? <img onClick={() => { setAudio(row?.music_details); createMusic(row?.music_details?._id); setAudiosource(row?.music_details?.audio) }} src={Companylogo} alt={Companylogo} /> :
                                                                            <img src={Companylogo} alt={Companylogo} />}</> : <><img src={Companylogo1} id="pauseImage" onClick={() => { setisplay(false) }} alt={Companylogo} /></>} */}
                                          </div>
                                          <div className="repost-loop">
                                            <p>
                                              {" "}
                                              <RepeatIcon className="repeat" />
                                              {row?.music_details?.reposts
                                                ?.length
                                                ? row?.music_details?.reposts
                                                  ?.length
                                                : 0}{" "}
                                              Repost
                                            </p>
                                          </div>
                                          <div className="repost-loop">
                                            <p>
                                              {" "}
                                              <FavoriteIcon className="unfav" />
                                              {row?.music_details?.fav_count
                                                ? row?.music_details?.fav_count
                                                : 0}{" "}
                                              Favorite
                                            </p>
                                          </div>
                                        </div>
                                        <div className="music-timing">
                                          <p>
                                            {row?.music_details?.players?.length
                                              ? row?.music_details?.players
                                                ?.length
                                              : 0}{" "}
                                            Plays
                                          </p>
                                        </div>
                                      </div>
                                      <div className="like-dot">
                                        <div className="share-btn">
                                          {row?.music_details?.isRepost ===
                                            true ? (
                                            <Tooltip
                                              title="Unrepost"
                                              placement="top"
                                            >
                                              <RepeatIcon
                                                className="repeat"
                                                onClick={() => {
                                                  editSong({
                                                    id: row?.music_details?._id,
                                                    status: "repost",
                                                  });
                                                }}
                                              />
                                            </Tooltip>
                                          ) : (
                                            <Tooltip
                                              title="Repost"
                                              placement="top"
                                            >
                                              <RepeatIcon
                                                onClick={() => {
                                                  editSong({
                                                    id: row?.music_details?._id,
                                                    status: "repost",
                                                  });
                                                }}
                                              />
                                            </Tooltip>
                                          )}

                                          {row?.music_details?.userFav ===
                                            true ? (
                                            <Tooltip
                                              title="Unfavourite"
                                              placement="top"
                                            >
                                              <FavoriteIcon
                                                className="unfav"
                                                onClick={() => {
                                                  addFavorite(
                                                    row?.music_details?._id
                                                  );
                                                }}
                                              />
                                            </Tooltip>
                                          ) : (
                                            <Tooltip
                                              title="Favourite"
                                              placement="top"
                                            >
                                              <FavoriteIcon
                                                onClick={() => {
                                                  addFavorite(
                                                    row?.music_details?._id
                                                  );
                                                }}
                                              />
                                            </Tooltip>
                                          )}
                                          <div
                                            onClick={() => {
                                              sendTracks(
                                                row?.music_details?._id
                                              );
                                            }}
                                          >
                                            <ShortcutIcon />
                                          </div>
                                          <SendTrack
                                            handleModelClose2={
                                              handleModelClose2
                                            }
                                            handleOpen2={handleOpen2}
                                            openModel2={openModel2}
                                            id={`/trackpage/${send}`}
                                          />

                                          {row?.music_details?.nftclaim !== 0 &&
                                            row?.music_details?.userFound ===
                                            false &&
                                            row?.music_details?.isMine ===
                                            false ? (
                                            <Tooltip
                                              title="Add to Library"
                                              placement="top"
                                            >
                                              <DownloadIcon
                                                onClick={() => {
                                                  addLibrary(
                                                    row?.music_details?._id
                                                  );
                                                }}
                                              />
                                            </Tooltip>
                                          ) : (
                                            <></>
                                          )}

                                          {row?.music_details?.userFound ===
                                            false ? (
                                            <></>
                                          ) : (
                                            <Tooltip
                                              title="Remove from Library"
                                              placement="top"
                                            >
                                              <UndoIcon
                                                onClick={() => {
                                                  addLibrary(
                                                    row?.music_details?._id
                                                  );
                                                }}
                                              />
                                            </Tooltip>
                                          )}
                                        </div>
                                        <div className="music-timing"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                            )}
                          </>
                        );
                      })}
                  </>
                ) : (
                  <>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="description-History">
                        You haven’t listened to any tracks yet.
                      </div>
                      <div className="description-History">
                        Once you have, this is where you’ll find them!
                      </div>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="start-listen-btn2">
                        {/* <Button onClick={() => (navigate('/feed'))}>No Data Found</Button> */}
                        <div className="newno">
                          <img src={newno} />
                        </div>
                      </div>
                    </Grid>
                  </>
                )}
              </Grid>
            </Container>
          </Box>

          {localStorage.getItem("playsong") === "true" ? (
            <>
              {audiosources.length > 0 ? (
                <Tracks audiosources={audiosources} tracks={tracks} />
              ) : (
                <></>
              )}{" "}
            </>
          ) : (
            <></>
          )}
          {(localStorage.getItem("playsong") === "false" && audiosource) ===
            true ? (
            <Track
              audiosource={audiosource}
              isplay={isplay}
              setisplay={setisplay}
            />
          ) : (
            <>
              {(localStorage.getItem("playsong") === "false" &&
                audio !== null) === true ? (
                <Track
                  audiosource={audio}
                  isplay={isplay}
                  setisplay={setisplay}
                />
              ) : (
                <></>
              )}{" "}
            </>
          )}
        </Box>
      </Box>
    </div>
  );
}

export default History;
